import React from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.scss';
import { actionCloseModal } from '../../redux/user/action';
import Contacts from '../../redux/contacts/types';
import { actionCreator } from '../../shared/redux/actionHelper';
import { useLocalization } from '../../LocalizationContext';

const cx = classNames.bind(styles);

const ConfirmDeleteContact = () => {
  const dispatch = useDispatch();
  const { t } = useLocalization();
  const { dataPayload } = useSelector((redux) => redux.user);
  const itemFromPage = useSelector((state) => state.content.contentData[dataPayload.id]);
  const itemFromSidebar = useSelector((state) => state.support.contactNicknameMap[dataPayload.id]);
  const item = (itemFromPage?.id ? itemFromPage : itemFromSidebar) || {};
  const idFromSidebar = itemFromSidebar?.id ? item?.id : null;
  const contactName = item?.first_name ? item?.first_name + item?.last_name : item.username;
  const close = () => {
    dispatch(actionCloseModal());
  };
  const deleteItem = () => {
    close();
    dispatch(
      actionCreator(Contacts.RemoveContact, {
        contactId: itemFromPage?.id ? item?.id : item?.contactId,
        contactEmail: item?.email,
        id: idFromSidebar,
        contactName,
      }),
    );
    if (dataPayload.newPath && dataPayload.history) {
      dataPayload.history.push(dataPayload.newPath);
    }
  };
  return (
    <div onClick={close} className={cx('modal_wrapper')}>
      <div
        onClick={(e) => e.stopPropagation()}
        className={cx('modal', 'DeleteTag')}
      >
        <div className={cx('title')}>{t.deleteContactQuestionT}</div>

        <div className={cx('sub_title')}>
          {t.deleteContactFromEveryAdminChannelT}
        </div>

        <div className={cx('group_buttons')}>
          <div onClick={close} className={cx('group_buttons_cancel', 'tags')}>
            {t.cancelT}
          </div>

          <div
            data-cy="delete"
            onClick={deleteItem}
            className={cx('group_buttons_delete', 'tags')}
          >
            {t.deleteT}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteContact;
