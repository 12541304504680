import React from 'react';
import { ReactComponent as LogoSvgWhite } from '../../images/2023/svg/Logo_white_text.svg';
import QuidziLogoNeon from '../../images/png/quidzi_logo.png';
import styles from './Login.module.scss';
import { Logo } from './ForgotPassword/ForgotView';
import { useLocalization } from '../../LocalizationContext';
import LanguageSwitcher from '../../components/LanguageSwitcher/LanguageSwitcherComponent';

const LoginIntro = () => {
  const { t } = useLocalization();

  return (
    <div className={styles.page_body}>
      <Logo isLoginPageText isNotClickable>
        <LogoSvgWhite />
        <LanguageSwitcher isLogin />
      </Logo>
      <p className={styles.page_body_title}>
        {t.introLine1T}
      </p>
      <p className={styles.page_body_text}>{t.introLine2T}</p>
      <p className={styles.page_body_text}>{t.introLine3T}</p>
      <p className={styles.page_body_text}>{t.introLine4T}</p>
      <img src={QuidziLogoNeon} className={styles.page_body_image} alt="QuidziLogoNeon" />
    </div>
  );
};

export default LoginIntro;
