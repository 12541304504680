import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { actionWriteCombination } from '../../redux/shortcuts/action';
import { DEFAULT_TITLE } from '../../utils/constants';
import {
  actionEditDescriptionPlaylistS,
  actionEditTitlePlaylist,
} from '../../redux/playlists/action';
import { isRoleInPlaylist } from '../../utils/permissions';
import { Title } from './components/Title/Title';
import { Description } from './components/Description/Description';
import { useLocalization } from '../../LocalizationContext';

const OSName = window.navigator.platform;
const PlaceHolderWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 36px;
  line-height: 40px;
  color: #343434;
  padding: 0 0 0 22px;
  margin-top: 33px;
  cursor: pointer;
  letter-spacing: 0.75px;
  ${({ placeholderMode }) => (placeholderMode
    ? `
      color: #939393;
      font-style: normal;
      font-weight: 600;
      font-family: "Roboto", sans-serif;
      font-size: 36px;
      line-height: 40px;
    `
    : '')}
  ${({ isShowLibraryWidget }) => (isShowLibraryWidget
    ? `
      width: 75%;
    `
    : ''
  )}
`;

const HeaderWrapper = ({ text, type, playlistID, currentRole, isShowLibraryWidget }) => {
  const { isUpdateForbidden } = useSelector((state) => state.currentPage);
  const inputTitle = useRef(null);
  const inputDescription = useRef(null);
  const { t } = useTranslation();
  const [stateDescription, setStateDescription] = useState(text || '');
  const [currentTitle, setCurrentTitle] = useState(text || '');
  const [isTitleFocused, setIsTitleFocused] = useState(false);
  const { isOwner } = isRoleInPlaylist;
  const [isDescriptionFocused, setDescriptionFocused] = useState(false);

  const canUpdForRedoUndo = useRef(null);
  const timerId = useRef(null);
  const cmdZ = useSelector(
    (reduxState) => reduxState.shortcut?.combination['mod+z'],
  );
  const cmdShZ = useSelector(
    (reduxState) => reduxState.shortcut?.combination['mod+shift+z'],
  );
  const socketUpd = useSelector(
    (reduxState) => reduxState.shortcut?.combination.socketUpd,
  );
  const disableChanges = !isOwner[currentRole];

  const dispatch = useDispatch();

  const waitUntilTitleWillBeRendered = () => {
    setTimeout(() => {
      inputTitle?.current?.focus();
      inputTitle?.current?.setSelectionRange(
        inputTitle?.current.value.length,
        inputTitle?.current.value.length,
      );
    }, 0);
  };

  const showTitleInputAndFocusIt = () => {
    setIsTitleFocused(true);
  };
  const setCanUpdForRedo = () => {
    canUpdForRedoUndo.current = true;
    if (timerId.current) {
      clearTimeout(timerId.current);
    }
    timerId.current = setTimeout(() => {
      canUpdForRedoUndo.current = false;
    }, 1000);
  };

  useEffect(() => {
    if (cmdZ || cmdShZ || socketUpd) {
      setCanUpdForRedo();
    }
  }, [cmdZ, cmdShZ, socketUpd]);

  useEffect(() => {
    if (isTitleFocused) waitUntilTitleWillBeRendered();
  }, [inputTitle?.current, isTitleFocused]);

  useEffect(() => {
    if (isUpdateForbidden) return;
    if (type === 'description') {
      setStateDescription(text);
    } else {
      setCurrentTitle(text);
    }
  }, [text]);

  useEffect(() => {
    if (inputDescription.current) {
      // inputDescription.current.rows = 1;
      const exRows = (inputDescription.current.scrollHeight / 20).toFixed() * 1 || 1;
      inputDescription.current.rows = exRows > 4 ? 4 : exRows;
    }
  }, [
    inputDescription.current,
    inputTitle.current,
    stateDescription,
    currentTitle,
  ]);

  const saveTitle = (oldTitle, currentTitleValue) => {
    setIsTitleFocused(false);
    const clearTitle = currentTitleValue;
    if (clearTitle !== oldTitle) {
      setCurrentTitle(clearTitle);
      dispatch(actionEditTitlePlaylist(playlistID, clearTitle, true));
    }
  };
  const saveDescription = (oldDescription, newStateDescription) => {
    setDescriptionFocused(false);
    inputDescription?.current?.scrollTo(0, 0);
    const clearDescription = newStateDescription
      ?.trim()
      .replace(/(\r\n|\r)/gm, '\\n');
    if (oldDescription !== newStateDescription) {
      setStateDescription(newStateDescription);
      dispatch(actionEditDescriptionPlaylistS(playlistID, clearDescription));
    }
  };

  const handleKeyPressTitle = (event) => {
    if (disableChanges) return;
    if (type === 'description') setDescriptionFocused(true);

    if (type === 'title') {
      setIsTitleFocused(true);
      if (event.key === 'Enter') {
        inputTitle.current && inputTitle.current.blur();
      }
    }
  };
  const handleUNDO = (event) => {
    if (
      event.keyCode === 90
      && event.shiftKey
      && (event.metaKey || (OSName === 'Win32' && event.ctrlKey))
    ) {
      event.preventDefault();
      dispatch(actionWriteCombination('mod+shift+z'));
      return;
    }
    if (
      event.keyCode === 90
      && (event.metaKey || (OSName === 'Win32' && event.ctrlKey))
    ) {
      event.preventDefault();
      dispatch(actionWriteCombination('mod+z'));
    }
  };

  return (
    <div
      id={
        type === 'title' ? 'playlistTitleParent' : 'playlistDescriptionParent'
      }
    >
      {type === 'title' && (
        <>
          {' '}
          {!isTitleFocused && (
            <PlaceHolderWrapper
              data-cy="titleEntity"
              placeholderMode={!currentTitle}
              onClick={showTitleInputAndFocusIt}
              isShowLibraryWidget={isShowLibraryWidget}
            >
              {currentTitle || t('defaultSmartFileTitleT')}
            </PlaceHolderWrapper>
          )}
          {isTitleFocused && (
            <Title
              inputTitle
              handleKeyPressTitle={handleKeyPressTitle}
              handleUND={handleUNDO}
              onBlur={() => saveTitle(text, currentTitle)}
              titleRef={inputTitle}
              value={currentTitle}
              setCurrentTitle={setCurrentTitle}
              disableChanges={disableChanges}
            />
          )}
        </>
      )}
      {type === 'description' && (
        <Description
          handleKeyPressTitle={handleKeyPressTitle}
          handleUNDO={handleUNDO}
          onBlur={() => saveDescription(text, stateDescription)}
          descriptionRef={inputDescription}
          setStateDescription={setStateDescription}
          value={stateDescription}
          isDescriptionFocused={isDescriptionFocused}
          isDescription={isDescriptionFocused}
          setDescriptionFocused={setDescriptionFocused}
          disableChanges={disableChanges}
        />
      )}
    </div>
  );
};

export default HeaderWrapper;
