import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames/bind';
import styles from './index.module.scss';
import { stopPropagation } from '../../../utils/constants';
import DatePicker from '../../DatePicker';
import Button2023 from '../../Buttons/Button2023';
import { actionCreator } from '../../../shared/redux/actionHelper';
import EditPlaylist from '../../../redux/playlists/types';
import { DateRangeMark4SharedPlaylist } from '../../DatePicker/helpers/enum';
import { useLocalization } from '../../../LocalizationContext';

const cx = classNames.bind(styles);

const PublishUpdate = ({ refMenu, close, dataParent, isMakerStyles, channelId, isModify }) => {
  const dispatch = useDispatch();
  const { t } = useLocalization();
  const [localState, setLocalState] = useState({ dateRangeMark: DateRangeMark4SharedPlaylist.OPEN_TO_READ });
  const [selectedOption, setSelectedOption] = useState(null);

  const itemTypeReadable = 'SmartFile';

  const shareHandler = () => {
    dispatch(actionCreator(EditPlaylist.updatePublishStatePlaylistSR, { channelId, updateTime: { ...localState } }));
    close && close();
  };

  const modalTitle = 'Modify availability';

  return (
    <>

      <div
        ref={refMenu}
        className={cx('share_menu', isMakerStyles && 'share_maker_option', {
          isPlaylist: true,
          isModify,
        })}
        data-cy="share-playlist-modal"
        data-parent={dataParent}
        onClick={stopPropagation}
      >
        {isModify && (
          <>
            <div
              data-parent={dataParent}
              className={`${styles.share_menu__heading_block}`}
            >
              <div>{modalTitle}</div>
            </div>
            <DatePicker
              isModify
              type={`Share${itemTypeReadable}`}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              dataParent={dataParent}
              setLocalState={setLocalState}
              localState={localState}
            />
          </>

        )}

        <div
          data-parent={dataParent}
          className={`${styles.button_group} ${styles.isModify}`}
        >
          <Button2023
            variant="primary"
            height={32}
            text={t.cancelUpT}
            handleButtonClick={close}
            noMargins
          />
          <Button2023
            variant="secondary"
            height={32}
            text={t.saveUpT}
            handleButtonClick={shareHandler}
            noMargins
          />
        </div>
      </div>
    </>
  );
};

export default PublishUpdate;
