import React from 'react';
import { useSelector } from 'react-redux';
import ShortcutsTable from './ShortcutsTable';
import { Label, SectionShell, HeaderTitle } from '../../CommonComponents/Layout';
import { useLocalization } from '../../../../LocalizationContext';

const ShortcutsAll = () => {
  const { shortcuts } = useSelector((state) => state.settings);
  const { t } = useLocalization();
  return (
    <SectionShell withTop paddingForSetting>
      <HeaderTitle marginBottom32>{t.shortcutsT}</HeaderTitle>
      <Label isPaddingLeft isWorkplans> {t.editorT}</Label>

      <ShortcutsTable shortcuts={shortcuts.editor} />

      {!!shortcuts?.workspace && (
        <>
          <Label marginTop> {t.workspaceT} : </Label>

          <ShortcutsTable shortcuts={shortcuts?.workspace} />
        </>
      )}
    </SectionShell>
  );
};

export default ShortcutsAll;
