import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { v4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import moduleStyles from './index.module.scss';
import { actionCheckLinkForLibraryComponent } from '../../redux/library/actions';
import { validateURL } from '../../utils/helpers';
import { LibraryComponents } from '../../redux/library/types';
import { actionCreator } from '../../shared/redux/actionHelper';
import { useLocalization } from '../../LocalizationContext';

const styles = classNames.bind(moduleStyles);

const CreateLinkComponentPopUp = ({ wrapperRef, setIsCreateLinkVisible }) => {
  const history = useHistory();
  const [localError, setLocalError] = useState(false);
  const [urlText, setUrlText] = useState('');
  const dispatch = useDispatch();
  const { t } = useLocalization();

  const onSaveButtonClick = () => {
    let url = urlText;
    if (!url) return;
    if (
      !url.includes('http://')
      && !url.includes('https://')
      && !url.includes('ftp://')
    ) {
      url = `https://${url}`;
    }
    if (url && url.startsWith('<iframe') && url.endsWith('</iframe>')) {
      const newId = v4();
      dispatch(
        actionCreator(LibraryComponents.CreateEmbedLibraryComponent, {
          iframeText: url,
          newId,
          history,
        }),
      );
      setIsCreateLinkVisible(false);
      return;
    }
    if (!validateURL(url)) {
      setLocalError(t.provideCorrectURLLinkErrorT);
      return;
    }
    dispatch(
      actionCheckLinkForLibraryComponent(
        urlText,
        setIsCreateLinkVisible,
        setLocalError,
      ),
    );
  };

  const onUrlInputChange = (e) => {
    if (localError) setLocalError(false);
    setUrlText(e.target.value);
  };

  const handleInputKeyPress = (e) => {
    if (e.code === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      onSaveButtonClick(e);
    }
  };

  return (
    <div ref={wrapperRef} className={styles('createLink_wrapper')}>
      <div className={styles('createLink_container')}>
        <div className={styles('title_container')}>{t.addWebpageLinkT}</div>
        <div className={styles('input_wrapper')}>
          <div
            className={styles('input_container', { errorBorder: localError })}
          >
            <input
              type="link_url"
              value={urlText}
              onChange={onUrlInputChange}
              placeholder={t.pasteWebpageLinkT}
              autoFocus
              onKeyPress={handleInputKeyPress}
            />
          </div>
          <div className={styles('save_button')} onClick={onSaveButtonClick}>
            {t.saveUpT}
          </div>
        </div>
        {localError && (
          <div className={styles('error_message_container')}>{localError}</div>
        )}
      </div>
    </div>
  );
};

export default CreateLinkComponentPopUp;
