import React, { useEffect, useState } from 'react';
import { EditorState } from 'draft-js';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ImageSvg } from '../../images/icons/image.svg';
import { ReactComponent as VideoSvg } from '../../images/icons/video.svg';
import styles from './index.module.css';
import { ReactComponent as LinkSvg } from '../../images/icons/link.svg';
import { requestParser } from '../../utils/request';
import { FileUpload } from '../../redux/filesUpload/types';
import { BlockTypes } from '../../utils/constants';
import { useLocalization } from '../../LocalizationContext';

const LinkParseOption = ({
  setIsOptionVisible,
  parentRef,
  editorState,
  onChange,
  addVideo,
  setUploadUrlData,
  selectAddEditableBlock,
  id,
  setType,
  currentBlockType,
}) => {
  // eslint-disable-next-line max-len
  const regExYoutube = /(^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$)/gm;
  const dispatch = useDispatch();
  const { t } = useLocalization();

  const [url, setUrl] = useState('');
  useEffect(() => {
    if (editorState.getCurrentContent) {
      const processedUrl = editorState
        .getCurrentContent()
        .getPlainText('\u0001');
      if (processedUrl) setUrl(processedUrl);
    }
  }, [editorState]);
  const calculatePosition = () => {
    if (parentRef.current) {
      return 30;
    }
    return 0;
  };
  const divStyle = {
    top: calculatePosition(),
  };

  const addVideoBlock = () => {
    const emptyEd = EditorState.createEmpty();
    selectAddEditableBlock(BlockTypes.video);
    onChange(addVideo(emptyEd, { src: url }));
  };
  const addSiteBlock = () => {
    selectAddEditableBlock(BlockTypes.webSite);
    onChange({ data: { url, download: true } });
    requestParser({ url })
      .then((data) => {
        if (data.data?.data.mediaType === BlockTypes.image) {
          const dataUrl = data.data?.data.url;
          const newId = uuidv4();
          dispatch({
            type: FileUpload.CreateImageUploadByLink,
            payload: { url: dataUrl, blockId: id, newId },
          });
          setType(BlockTypes.image);
        } else {
          setUploadUrlData(data);
          onChange(data.data);
        }
      })
      .catch();
  };
  const addEmbedBlock = (text) => {
    if (text && text.startsWith('<iframe') && url.endsWith('</iframe>')) {
      selectAddEditableBlock(BlockTypes.embed, null, null, null, text);
      onChange({ data: { text, isPreview: true } });
    }
  };

  return (
    <div style={divStyle} className={styles.dropdown_parse_option}>
      <div
        className={styles.dropdown__item}
        onClick={() => {
          setIsOptionVisible(false);
        }}
      >
        <LinkSvg />
        <div> {t.keepTextLinkT}</div>
      </div>

      {currentBlockType?.type !== BlockTypes.embed && (
        <div
          className={styles.dropdown__item}
          onClick={() => {
            setIsOptionVisible(false);
            addSiteBlock();
          }}
        >
          <ImageSvg />

          <div>{t.createLinkPreviewT}</div>
        </div>
      )}
      {currentBlockType?.type === BlockTypes.embed && (
        <div
          className={styles.dropdown__item}
          onClick={() => {
            setIsOptionVisible(false);
            addEmbedBlock(currentBlockType?.text);
          }}
        >
          <ImageSvg />

          <div>{t.createEmbedPreviewT}</div>
        </div>
      )}

      {url?.match(regExYoutube) && (
        <div
          className={styles.dropdown__item}
          onClick={() => {
            addVideoBlock();

            setIsOptionVisible(false);
          }}
        >
          <VideoSvg />
          <div>{t.createEmbedVideoT}</div>
        </div>
      )}
    </div>
  );
};

export default LinkParseOption;
