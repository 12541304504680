import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as ThreeDotsSVG } from '../../images/2023/svg/small/player/three_dots.svg';
import { ReactComponent as CloseSVG } from '../../images/2023/svg/small/player/close_cross.svg';
import { ReactComponent as LockedSVG } from '../../images/2023/svg/small/player/lock_wrapper.svg';
import { ReactComponent as DownloadSVG } from '../../images/2023/svg/small/player/download_wrapper.svg';
import {
  PHActions,
  PHActionsSVGWrapper,
  PHLeftSide,
  PHTitle,
  PlayerHeaderWrapper,
  TopShadow,
} from './styled';
import { getItemTitle } from './helpers';
import { actionCreator } from '../../shared/redux/actionHelper';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import ThreeDotsDropDownUPV from '../Maker/MakerCommon/Table/ThreeDotsDropDown';
import { emptyCallback, Group, MessageType } from '../../utils/constants';
import { actionShowMessage } from '../../redux/support/action';
import { axiosAbortarium } from '../../utils/axiosAbortarium';
import Playlist from '../../redux/playlists/types';
import { getItemName } from '../../utils/helpers';
import { useLocalization } from '../../LocalizationContext';


const Header = ({ isMouseMoveShowControls, closePlayer, currentItem }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useLocalization();
  const [isDownloadBtnBlocked, setIsDownloadBtnBlocked] = useState(false);
  const { id: playlistId } = useParams();
  const currentItemNumber = parseInt(currentItem.customIndex);

  const currentPage = useSelector((state) => state.currentPage);

  const { isContentEditable, socketId, linkPages = [] } = currentPage;

  const nonTextElementsLength = linkPages.filter(elem => elem.type !== 'elementText').length;


  const [refMenu, isActive, setIsActive] = useComponentVisible(
    false,
    'miniPage',
  );

  const title = useSelector(state => state.currentPage.title) || t.untitledSmartFileT;
  const { id: userId } = useSelector((state) => state.user);

  const lc = currentItem?.libraryComponent || currentItem;
  const isForSharedModeMaker = history.location.pathname.startsWith('/shared_player');
  const isOwner = userId === currentItem?.owner?.id
    || (currentItem?.users?.length && userId === currentItem?.users[0].id);
  const isComponent = lc?.type === 'component';
  const isLink = lc?.type === 'text/html';
  const isSharedToMePage = lc?.type === 'sharedPage';
  const isLinkOrComponent = isComponent || isLink;
  const itemOwner = userId === currentItem?.owner?.id;
  const isLocked = currentItem?.isRemixLocked;
  const isCanRemix = itemOwner || !isLocked;
  const type = lc?.type;
  const isDownloadable = (type && type !== 'page' && type !== 'text/html')
    || currentItem?.textComponent?.id;
  const isCanDownload = isCanRemix && isDownloadable;
  const libraryComponentId = currentItem?.libraryComponent?.id
    || currentItem?.textComponent?.id
    || currentItem?.id;
  const libraryComponentType = currentItem?.libraryComponent?.type || currentItem?.type;
  const libraryComponentTitle = currentItem?.title || currentItem?.libraryComponent?.title;


  const downloadItemClickHandler = useCallback((e) => {
    setIsDownloadBtnBlocked(true);
    e.stopPropagation();
    dispatch(
      actionShowMessage({
        type: MessageType.DownloadOneItem,
        itemName: 'playlist',
        mode: Group.processing,
        id: libraryComponentId,
      }),
    );
    axiosAbortarium.generateNew(libraryComponentId);
    dispatch(
      actionCreator(Playlist.DownloadOneElement, {
        elementId: libraryComponentId,
        elementTitle: libraryComponentTitle,
        elementType: libraryComponentType,
        isText: !!currentItem.textComponent?.id,
        text: getItemName(currentItem),
        owner: currentItem.owner || { id: userId },
      }),
    );
    setTimeout(() => setIsDownloadBtnBlocked(false), 3000);
  }, [currentItem, libraryComponentId, libraryComponentTitle, libraryComponentType, userId]);

  const isTextBlock = currentItem.type === 'elementText';

  return (
    <PlayerHeaderWrapper isMouseMoveShowControls={isMouseMoveShowControls}>
      {!isTextBlock && <PHLeftSide isFirst>{currentItemNumber}/{nonTextElementsLength}</PHLeftSide>}
      {!isTextBlock && <PHLeftSide>{getItemTitle(currentItem)}</PHLeftSide>}
      <PHTitle>{title}</PHTitle>
      <PHActions>
        {!isCanDownload
          ? <PHActionsSVGWrapper><LockedSVG className="locked_svg" /></PHActionsSVGWrapper>
          : <PHActionsSVGWrapper onClick={isDownloadBtnBlocked ? emptyCallback : downloadItemClickHandler} isBlocked={isDownloadBtnBlocked}><DownloadSVG className="download_svg" /></PHActionsSVGWrapper> }
        <PHActionsSVGWrapper>
          <ThreeDotsSVG
            onClick={() => setIsActive(!isActive)}
          />
        </PHActionsSVGWrapper>
        {isActive && (
          <ThreeDotsDropDownUPV
            isForSharedModeMaker={isForSharedModeMaker}
            isPlayer
            show={isActive}
            refMenu={refMenu}
            isActive={isActive}
            setIsActive={setIsActive}
            playlistId={playlistId}
            isContentEditable={isContentEditable}
            socketId={socketId}
            isOwner={isOwner}
            item={currentItem}
            goToItem={emptyCallback}
            currentPage={currentPage}
            play={emptyCallback}
            isLinkOrComponent={isLinkOrComponent}
            isSharedToMePage={isSharedToMePage}
            goNext={emptyCallback}
          />
        )}
        <PHActionsSVGWrapper><CloseSVG onClick={closePlayer} /></PHActionsSVGWrapper>
      </PHActions>
      <TopShadow />

    </PlayerHeaderWrapper>
  );
};

export default Header;
