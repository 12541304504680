import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';

import { actionCloseModal } from '../../redux/user/action';

import rawStyles from './index.module.scss';
import DownloadPlaylistModalBody from './DownloadPlaylistModalBody';
import { useLocalization } from '../../LocalizationContext';

const cx = classNames.bind(rawStyles);

const DownloadPlaylistModal = () => {
  const dispatch = useDispatch();
  const { t } = useLocalization();
  const {
    id,
    img,
    cropUrl,
    linkPages,
    title,
    defaultPreviewColor,
  } = useSelector((state) => state.currentPage);

  const { dataPayload } = useSelector((state) => state.user);

  const closeModal = () => {
    dispatch(actionCloseModal(''));
  };

  return (
    <div className={`${cx('modal_wrapper')} global_modal`} onClick={closeModal}>
      <div>
        <DownloadPlaylistModalBody
          id={id}
          img={img}
          cropUrl={cropUrl}
          linkPages={linkPages}
          title={title || t.untitledSmartFileT}
          download={dataPayload.download}
          defaultPreviewColor={defaultPreviewColor}
        />
      </div>
    </div>
  );
};

export default DownloadPlaylistModal;
