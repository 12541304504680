import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { useHistory, useParams } from 'react-router-dom';
import { actionCreator } from '../../shared/redux/actionHelper';
import Tooltip from '../../shared/Tooltips/Tooltip';
import { ReactComponent as CheckboxOnSvg } from '../../images/icons/checkbox_on.svg';
import { ReactComponent as CheckboxOffSvg } from '../../images/icons/checkbox_off.svg';
import downloadStatus from '../../utils/dataUtil';
import CardInfiniteScroll from '../Inbox/CardInfiniteScroll';
import { ItemPlaceHolder } from '../../components/MiniPlaylist/NewPlaylist/CardView/ItemPlaceHolder';
import { FILTER_CARD } from '../Inbox/utils';
import { OutboxAction } from '../../redux/outbox/types';
import OutboxCard from './OutboxCard';
import OutboxHeader from './OutboxHeader';
import OutboxCardButtons from './OutboxCardButtons';
import { useLocalization } from '../../LocalizationContext';

const SelectAllWrapper = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
`;

const SelectAllText = styled.div`
  margin-left: 7px;
  color: #484343;
  font-family: RobotoFlex, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.6px;
  text-transform  : uppercase;
`;

const SelectAllCheckBoxWrapper = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg path {
    stroke: #484343;
  }
  ${({ isSelectedAll }) => (isSelectedAll
    ? ` 
      background: #FFC72C;
  ` : ''
  )}
  :hover {
    background: #FFEEBF;
  }
`;
const Header = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  z-index: 101;
  justify-content: space-between;
  padding: 0 21px 0 7px;
  align-items: center;
  height: 60px;

  ${({ isEmptyState }) => (isEmptyState
    ? ` 
      padding-right: 30px;
      max-width: 100%;
    ` : ''
  )}
  
  @media (max-width: 900px) {
    padding-left: 0;
  }
`;

const InboxShell = styled.div`
  width: 100%;
  padding-left: 30px;

`;
const FlexWrapper = styled.div`
  width: 100%;
  background-color: #f6f6f5;
  height: calc(100vh - 69px);
  position: relative;
  overflow: scroll;
  display: flex;
  justify-content: center;

  ::-webkit-scrollbar {
    width: 8px;
    background-color: #fafaff;
  }
  ::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 8px;
    background-color: #e2e1e1;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }
  ::-webkit-scrollbar-track-piece {
    display: none;
    background-color: #fafaff;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
`;

const EmptyStateWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -23px;
  margin-left: -10px;
`;

const EmptyStateHeader = styled.div`
  color: #484343;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  margin-top: 16px;
`;

const EmptyStateText = styled.div`
  color: #939393;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 10px;
`;

const SelectedAllCountText = styled.div`
  color: #939393;
  font-family: RobotoFlex, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.7px;
  margin-left: 12px;
`;

const OutBox = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useLocalization();
  const { type = 'all' } = useParams();

  const currentFilter = FILTER_CARD[type.toUpperCase()] || FILTER_CARD.ALL;
  const scrollParentRef = useRef(null);
  const [selectedCards, setSelectedCards] = useState([]);

  const { cards, status } = useSelector(state => state.outbox);
  const { myChannels } = useSelector((redux) => redux.channels);

  useEffect(() => {
    return () => dispatch(actionCreator(OutboxAction.Clear, {}));
  }, [currentFilter]);

  useEffect(() => {
    if (status === downloadStatus.init) {
      dispatch(actionCreator(OutboxAction.UpdateCounterS));
      dispatch(actionCreator(OutboxAction.UploadOutboxCardSaga, { filter: currentFilter }));
    }
  }, [status]);

  const pickCard = useCallback((card) => {
    const withoutPickedCard = selectedCards.filter(item => card.id !== item.id);
    if (withoutPickedCard.length !== selectedCards.length) {
      setSelectedCards(withoutPickedCard);
    } else {
      setSelectedCards([...selectedCards, card]);
    }
  }, [selectedCards]);


  const isAllCardsSelected = (selectedCards.length === cards.length) && cards.length > 0;

  useEffect(() => {
    const newCardsStatuses = cards.reduce((acc, cur) => {
      return { ...acc, [cur.id]: cur.isRead };
    }, {});
    setSelectedCards((prev) => prev.map(card => ({ ...card, isRead: newCardsStatuses[card.id] })));
  }, [cards]);

  const selectAll = () => {
    if (!isAllCardsSelected) {
      const allCards = cards.map(card => ({ id: card.id, type: card.type, isRead: card.isRead }));
      setSelectedCards(allCards);
    } else {
      setSelectedCards([]);
    }
  };

  const clearCards = () => {
    setSelectedCards([]);
  };

  const shouldHeaderBeVisible = !!cards?.length || !cards?.length && (type === 'read' || type === 'unread');

  return (
    <FlexWrapper ref={scrollParentRef}>
      <InboxShell>
        {shouldHeaderBeVisible && (
          <Header isEmptyState={!cards?.length && status === downloadStatus.success}>
            <SelectAllWrapper onClick={selectAll}>
              <Tooltip text={t.selectT} direction="up">
                <SelectAllCheckBoxWrapper isSelectedAll={isAllCardsSelected}>
                  {isAllCardsSelected ? <CheckboxOnSvg /> : <CheckboxOffSvg />}
                </SelectAllCheckBoxWrapper>
              </Tooltip>
              <SelectAllText>{t.selectAllT}</SelectAllText>
              {!!selectedCards.length && (
                <>
                  <SelectedAllCountText>{selectedCards.length} {t.selectedLowT}</SelectedAllCountText>
                  <OutboxCardButtons items={selectedCards} inHeader clearCards={clearCards} />
                </>
              )}
            </SelectAllWrapper>
          </Header>
        )}
        {shouldHeaderBeVisible && (<OutboxHeader />)}
        {!!cards?.length && (
          <CardInfiniteScroll
            scrollParentRef={scrollParentRef}
            currentFilter={currentFilter}
            channels={myChannels}
            selectedCards={selectedCards}
            pickCard={pickCard}
            Card={OutboxCard}
            isOutbox
          />
        )}
        {(status !== downloadStatus.success) && (
          <ItemPlaceHolder
            type="notification"
          />
        )}

        {status === downloadStatus.success && !cards?.length && (
        <EmptyStateWrapper>
          <EmptyStateHeader>
            {t.yourOutboxIsEmptyT}
          </EmptyStateHeader>
          <EmptyStateText>
            {t.notificationsWillAppearMsgT}
          </EmptyStateText>
        </EmptyStateWrapper>
        )}
      </InboxShell>
    </FlexWrapper>
  );
};

export default OutBox;
