import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import rawStyles from './index.module.scss';
import ActivityTableHeader from './ActivityTableHeader';
import ActivityTableRow from './ActivityTableRow';
import { sortedFunctions } from '../../../../utils/dateConvert';
import { emptyArr } from '../../../../utils/constants';
import { Settings } from '../../../../redux/settings/types';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import { HeaderTitle } from '../../CommonComponents/Layout';
import { useLocalization } from '../../../../LocalizationContext';

const cn = classNames.bind(rawStyles);

const Activity = () => {
  const dispatch = useDispatch();
  const { channels } = useSelector((state) => state.settings.activity);
  const { folderId: activeChannelId } = useSelector((state) => state.user);
  const { myChannels } = useSelector((state) => state.channels);
  const [sortType, setSort] = useState(Object.keys(sortedFunctions)[0]);
  const [displayItems, setDisplayItems] = useState(emptyArr);
  const { t } = useLocalization();

  useEffect(() => {
    dispatch(actionCreator(Settings.Activity.GetChannels));
  }, []);

  useEffect(() => {
    setDisplayItems(sortedFunctions[sortType](Object.values(channels)));
  }, [sortType, channels]);
  return (
    <div className={cn('activity_main')}>
      <HeaderTitle>{t.channelsT}</HeaderTitle>
      <div className={cn('title')}>
        {t.listOfYourChannelsT}
      </div>

      <div className={cn('activity_table')}>
        <ActivityTableHeader setSort={setSort} sortType={sortType} />
        {displayItems.map((item) => (
          <ActivityTableRow
            key={item.id}
            id={item.id}
            channelName={item.name}
            isUnpin={item.isUnpin}
            userRole={item.role}
            authorName={item.authorName}
            isUserOwner={item.isUserOwner}
            activeChannelId={activeChannelId}
            sidebarChannels={myChannels}
          />
        ))}
      </div>
    </div>
  );
};

export default Activity;
