import React from 'react';
import classNames from 'classnames/bind';
import { ReactComponent as PlusSvg } from '../../../images/icons/thin_plus_20.svg';
import { ReactComponent as FitSvg } from '../../../images/icons/fit_items_20.svg';
import { ReactComponent as MinusSvg } from '../../../images/icons/minus_20.svg';

import styles from './index.module.scss';
import { DATA_PARENTS } from '../../../utils/constants';
import Tooltip from '../../../shared/Tooltips/Tooltip';
import { useLocalization } from '../../../LocalizationContext';

const cn = classNames.bind(styles);

const ZoomControls = ({
  isPages,
  moveLeft,
  moveLeftBig,
  moveLeftSmall,
  isChannels,
  ...props
}) => {
  const { t } = useLocalization();
  return (
    <div
      className={cn('footer__wrapper', {
        small: isPages,
        moveLeft,
        moveLeftBig,
        moveLeftSmall,
        bottomShift: isChannels,
      })}
    >
      <div
        className={cn('footer__section')}
        data-parent={DATA_PARENTS.TableOfContents}
      >
        <Tooltip text={t.zoomInT} direction="down" place="ZoomControls">
          <div
            className={cn('item', 'footer__item')}
            onClick={props.onZoomInClick}
            data-parent={DATA_PARENTS.TableOfContents}
          >
            <PlusSvg />
          </div>
        </Tooltip>
        <Tooltip
          text={t.backToDefaultSizeT}
          direction="down"
          place="ZoomControls"
        >
          <div
            className={cn('item', 'footer__item')}
            onClick={props.onZoomDefaultClick}
            data-parent={DATA_PARENTS.TableOfContents}
          >
            <FitSvg />
          </div>
        </Tooltip>
        <Tooltip text={t.zoomOutT} direction="down" place="ZoomControls">
          <div
            className={cn('item', 'footer__item')}
            onClick={props.onZoomOutClick}
            data-parent={DATA_PARENTS.TableOfContents}
          >
            <MinusSvg />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default ZoomControls;
