import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styled from 'styled-components/macro';
import rawStyles from './UsedMenu.module.scss';
import { REQUEST_STATUS } from '../../../utils/helpers';
import UsedIn from '../../ComponentDescription/2023/UsedIn';
import { useLocalization } from '../../../LocalizationContext';

const cn = classNames.bind(rawStyles);

const TitleWrapper = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  color: #d3c8c8;
  margin-top: 8px;
  ${({ forCard }) => (forCard ? 'padding-left:16px;' : '')}
  `;

const UsedContainer = ({
  usedRef,
  divStyle,
  usedIn,
  isHideBoxShadow,
  isUsedActive,
  forCard,
  dataParent,
}) => {
  const [requestStatus, setRequestStatus] = useState(REQUEST_STATUS.IDLE);
  const { t } = useLocalization();

  useEffect(() => {
    if (REQUEST_STATUS.IS_SENT(requestStatus)) {
      setRequestStatus(REQUEST_STATUS.SUCCESS);
    }
  }, [usedIn]);

  useEffect(() => {
    if (isUsedActive) {
      setRequestStatus(REQUEST_STATUS.SENT);
    }
  }, [isUsedActive]);
  return (
    <div
      data-parent={dataParent}
      ref={usedRef}
      className={cn('container', { no_box_shadow: isHideBoxShadow, forCard })}
      style={divStyle}
    >
      <TitleWrapper forCard={forCard}>{t.usedInUpT}</TitleWrapper>
      <UsedIn
        dataParent={dataParent}
        data={usedIn}
        forCard
      />
    </div>
  );
};

export default UsedContainer;
