import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as CheckBoxOff } from '../../../images/icons/checkbox_off_16.svg';
import { ReactComponent as CheckBoxOn } from '../../../images/icons/checkbox_on_16.svg';
import { ReactComponent as ThreeDotsSvg } from '../../../images/2023/svg/small/3dots_13_2.svg';
import { ReactComponent as EyeOff } from '../../../images/icons/eye_off.svg';
import { ReactComponent as EyeOn } from '../../../images/icons/unseen.svg';
import { ReactComponent as Favorite2Svg } from '../../../images/2023/svg/small/favorite_16_16.svg';
import BoardColumnLabelLayer from '../NewPlaylist/CardView/BoardColumnLabelLayer';
import {
  actionRemoveSelectedPage,
  actionSelectedPage,
} from '../../../redux/selectedPage/action';
import {
  DEFAULT_TITLE,
  empty,
  MessageType,
} from '../../../utils/constants';
import { actionCreator } from '../../../shared/redux/actionHelper';
import { actionShowMessage } from '../../../redux/support/action';
import useComponentVisible from '../../../utils/hooks/useComponentVisible';
import { MiniPlaylists } from '../../../redux/miniPlaylists/types';
import { OverlayWrapper, SvgIcoWrapper } from './styled';
import SupportAction from '../../../redux/support/types';
import { useOpenPlaylist } from './helpers/openPlaylistHook';
import EditPlaylist from '../../../redux/playlists/types';
import { FolderActiveOverlay } from '../../../pages/Maker/styled';
import { ReactComponent as FolderIconActiveLayerSvg } from '../../../images/icons/icon_140/folderIconActiveLayer.svg';
import { useLocalization } from '../../../LocalizationContext';

const CardOverlay = ({ item, ...props }) => {
  const handlerOpenPlaylist = useOpenPlaylist(item?.status);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useLocalization();
  const { folderId } = useSelector((state) => state.user) || empty;
  const selectedPage = useSelector((state) => state.selectedPage) || empty;
  const [_, isMenuVisible, setIsMenuVisible] = useComponentVisible(null);
  const refPage = useRef(null);
  const isSelected = folderId
    ? selectedPage[folderId] && selectedPage[folderId][item.id]
    : selectedPage[item.id];
  const currentPage = useSelector((state) => state.currentPage);
  const { id: channelId } = useSelector((state) => state.currentPage);
  const {
    // isRequestSentForReadChannel,
    unseenInChannel,
    // isRequestSentForReadPlaylist,
    unseenPlaylistManagerMapInAll,
  } = useSelector((state) => state.support);
  const isChannels = history.location.pathname.startsWith('/channel/');
  const isHelp = history.location.pathname.startsWith('/help');

  const toggleSelected = (e) => {
    e.stopPropagation();
    if (isSelected) dispatch(actionRemoveSelectedPage(item.id, folderId));
    else {
      if (!props.isSimplifiedMultiselect) {
        props.setSimplifiedMultiselect(true);
      }
      dispatch(actionSelectedPage(item.id, folderId));
    }
  };

  const toggleFavorite = (e) => {
    e.stopPropagation();
    if (isMenuVisible) setIsMenuVisible(false);
    if (!item.isFavorite) {
      dispatch(
        actionShowMessage({
          type: MessageType.PageToFavorite,
          itemName: item?.title || t.defaultSmartPageTitleT,
        }),
      );
    }
    if (props.isFavorite && refPage.current) {
      refPage.current.className = `${refPage.current.className}${
        currentPage.isCardView ? 'to_delete' : 'to_delete_row'
      }`;
      setTimeout(() => {
        dispatch(
          actionCreator(EditPlaylist.ToggleFavorite, {
            id: item.id,
            isFavorite: !item.isFavorite,
            channelId: props.isChannel ? folderId : null,
            wrapperId: item.wrapperId,
          }),
        );
      }, 1000);
    }
    dispatch(
      actionCreator(EditPlaylist.ToggleFavorite, {
        id: item.id,
        isFavorite: !item.isFavorite,
        channelId: props.isChannel ? folderId : null,
        wrapperId: item.wrapperId,
      }),
    );
  };

  const toggleShowHideHandler = (event) => {
    event.stopPropagation();
    dispatch(
      actionCreator(MiniPlaylists.ToggleHide, {
        id: item.wrapperId,
        playlistId: item.id,
        isHide: !item.isHide,
      }),
    );
  };

  const checkUnseen = () => {
    if (!isChannels && unseenPlaylistManagerMapInAll[item.wrapperId]) {
      dispatch(
        actionCreator(SupportAction.DeleteUnseenPlaylistR, {
          playlistManagerId: item.wrapperId,
        }),
      );
    }
    if (
      isChannels
      && unseenInChannel[channelId]
      && unseenInChannel[channelId][item.playlistManagerId]
    ) {
      dispatch(
        actionCreator(SupportAction.DeleteUnseenPlaylistInChannel, {
          channelId,
          playlistId: item.id,
          playlistManagerId: item.playlistManagerId,
        }),
      );
    }
  };

  const openPlaylist = (event) => {
    checkUnseen();
    handlerOpenPlaylist({ playlistId: item.id, event });
  };

  return (
    <OverlayWrapper
      className="card-overlay-wrapper"
      isSelected={isSelected}
      isHide={item.isHide}
      ref={refPage}
      onClick={openPlaylist}
    >
      {!isHelp && (
        <>
          {!item.isHide && (
            <SvgIcoWrapper
              data-parent="miniPage"
              isSelector
              isSelected={isSelected}
              onClick={toggleSelected}
            >
              {' '}
              {isSelected ? (
                <CheckBoxOn data-parent="miniPage" />
              ) : (
                <CheckBoxOff data-parent="miniPage" />
              )}
            </SvgIcoWrapper>
          )}
          {!isSelected && !item.isHide && (
            <SvgIcoWrapper
              onClick={props.toggleShowThreeDots}
              active={props.showThreeDotsDropDown}
              is3Dots
            >
              <ThreeDotsSvg />
            </SvgIcoWrapper>
          )}
          {!isSelected && !item.isHide && (
            <SvgIcoWrapper
              data-parent="miniPage"
              isFavourite
              active={item.isFavorite}
              onClick={toggleFavorite}
            >
              <Favorite2Svg />
            </SvgIcoWrapper>
          )}
          {!isSelected && (
            <SvgIcoWrapper
              isEye
              onClick={toggleShowHideHandler}
              isHide={item.isHide}
              id="card-eye"
            >
              {item.isHide ? <EyeOn /> : <EyeOff />}
            </SvgIcoWrapper>
          )}
        </>
      )}
      {!isHelp && !isSelected && !item.isHide && (
        <BoardColumnLabelLayer item={item} isCard />
      )}
      {isSelected && (
        <FolderActiveOverlay data-parent="miniPage">
          <FolderIconActiveLayerSvg />
        </FolderActiveOverlay>
      )}
    </OverlayWrapper>
  );
};

export default CardOverlay;
