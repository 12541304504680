import React from 'react';
import { useSelector } from 'react-redux';
import {
  ExpandedCardContentWrapper,
  ExpandedCardMainWrapper,
  NoPreviewAvailable,
} from './styled';
import TopPartControls from './TopPartControls';
import { UploadForExpanded } from './ProgressBarPart';
import PlaylistElementCaption from '../../MakerCardView/PlaylistElementCard/PlaylistElementCaption';
import useComponentVisible from '../../../../utils/hooks/useComponentVisible';
import { useLocalization } from '../../../../LocalizationContext';

const CommonCardLayouts = ({ children, isPage, ...props }) => {
  const { t } = useLocalization();
  const { components: uploads } = useSelector((state) => state.uploads);
  const lcID = props.linkPage?.libraryComponent?.id;
  const isNotReady = lcID && uploads[lcID] && uploads[lcID].status !== 'READY';
  const isError = uploads[lcID]?.status === 'ERROR';
  const cardWrapperRef = React.useRef(null);
  const [threeDotRef, isThreeDotsActive, setIsThreeDotsActive] = useComponentVisible(false, 'miniPage');
  const toggleShowThreeDots = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsThreeDotsActive(!isThreeDotsActive);
  };

  const goToFullScreenView = () => {
    const position = cardWrapperRef.current.getBoundingClientRect();
    props.goToFullScreenView(props.itemIndex, props.linkPage.id, position);
  };
  return (
    <ExpandedCardMainWrapper
      onContextMenu={toggleShowThreeDots}
      className="highlightedPlace"
      draggable={false}
      withCaption={props.linkPage.isShowCaption}
      blinkIt={props.isBlink}
      onClick={goToFullScreenView}
      ref={cardWrapperRef}
    >
      <TopPartControls
        {...props}
        threeDotRef={threeDotRef}
        setIsThreeDotsActive={setIsThreeDotsActive}
        isThreeDotsActive={isThreeDotsActive}
        itemRef={cardWrapperRef}
        handleOpen={goToFullScreenView}
      />
      <ExpandedCardContentWrapper
        isPage={isPage}
        withPaddings={props.noPreview}
        isText={props.isText || props.isLexical}
      >
        {isNotReady ? (
          <UploadForExpanded
            error={isError}
            value={uploads[lcID]?.progress || 0}
          />
        ) : (
          children
        )}
        {props.noPreview && !isNotReady && !isError && (
          <NoPreviewAvailable>{t.noPreviewAvailableT}</NoPreviewAvailable>
        )}
      </ExpandedCardContentWrapper>
      <PlaylistElementCaption
        caption={props.caption}
        linkPageId={props.linkPageId}
        isShowCaption={props.isShowCaption}
        isNew={props.linkPage.isNew}
        isExpandedView
      />
    </ExpandedCardMainWrapper>
  );
};

export default CommonCardLayouts;
