import React from 'react';
import classNames from 'classnames/bind';
import rawStyles from './index.module.scss';
import { sortedFunctions } from '../../../../utils/dateConvert';
import { plainArrowType } from '../../../../utils/tableHelpers/tableUi';
import { useLocalization } from '../../../../LocalizationContext';

const cn = classNames.bind(rawStyles);

const ActivityTableHeader = (props) => {
  const arraySortedKey = Object.keys(sortedFunctions);
  const { t } = useLocalization();

  const handlerClick = (type) => {
    if (arraySortedKey[type] === props.sortType) props.setSort(arraySortedKey[type + 1]);
    else props.setSort(arraySortedKey[type]);
  };

  return (
    <div className={cn('row', 'header')}>
      <div
        className={cn('cell', 'channelName', 'first')}
        onClick={() => handlerClick(0)}
      >
        {t.namesT}
        <div>{plainArrowType(0, props.sortType)}</div>
      </div>
      <div className={cn('cell', 'userRole')} onClick={() => handlerClick(12)}>
        {t.roleT}
        <div>{plainArrowType(12, props.sortType)}</div>
      </div>
      <div
        className={cn('cell', 'authorName')}
        onClick={() => handlerClick(20)}
      >
        {t.creatorsT}
        <div>{plainArrowType(20, props.sortType)}</div>
      </div>
      <div className={cn('cell', 'actions')}>{t.actionsT}</div>
    </div>
  );
};

export default ActivityTableHeader;
