import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  DATA_PARENTS,
} from '../../utils/constants';
import FallbackImage from '../FallBackImage/FallBackImage';
import useThrottle from '../../utils/hooks/useThrottle';
import playlistStyles from '../MiniPlaylist/index.module.css';
import {
  actionCleanBlinkId,
} from '../../redux/support/action';
import {
  Shared,
  SharedByMe,
} from '../../utils/UIHelpers/ItemTypes/library';

import FavoriteIcon from './FavoriteIcon/FavoriteIcon';
import MiniPageUsedMenu from './UsedMenu/MiniPageUsedMenu';
import CardViewActions from './CardViewActions';
import BlockCreatorComponent from './BlockCreatorComponent';
import styles from './index.module.css';
import { useLocalization } from '../../LocalizationContext';

const CardViewPage = React.memo(function CardViewPage({
  isPdf,
  cursorLoader,
  componentDescriptionId,
  item,
  refPage,
  bigSizeCondition,
  isSelected,
  isUPV,
  onCompositeClickHandler,
  isUsedActive,
  isChannelMiniPage,
  dragStartHandler,
  dragEndHandler,
  isPageUsed,
  isBlurred,
  isComponentDescriptionOpened,
  ...actionsProps
}) {
  const { selectorType: contentSelector } = useSelector(state => state.content);
  const { blinkIdsArray, unseenPagesManagerMap } = useSelector(state => state.support);

  const dispatch = useDispatch();
  const { t } = useLocalization();

  const [throttledClearBlinkId] = useThrottle(2200);

  const isSharedByMe = item.type !== 'sharedPage'
    && (!!item.usersToSharing?.length
      || !!item.usersInviteToSharing?.length
      || item.isShareToWeb);

  const handleToggleFavorite = (e) => {
    e.stopPropagation();
    e.preventDefault();
    actionsProps.toggleFavorite(e);
  };

  const isSharedToMe = item.type === 'sharedPage';

  useEffect(() => {
    if (blinkIdsArray && blinkIdsArray.length && blinkIdsArray?.includes(item.id)) {
      setTimeout(() => {
        refPage.current.scrollIntoView({ behavior: 'smooth' });
      }, 300);
      throttledClearBlinkId(() => () => dispatch(actionCleanBlinkId()));
    }
  }, [blinkIdsArray]);

  return (
    <>
      {isSharedToMe && (
        <div
          data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
          className={`${styles.shared_socket} `}
        >
          <Shared />
        </div>
      )}

      {(contentSelector === 'sharedByMe' || isSharedByMe)
        && (
          <div
            data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
            className={`${styles.shared_socket} `}
          >
            <SharedByMe />
          </div>
        )}

      {unseenPagesManagerMap
        && unseenPagesManagerMap[item.libraryComponentId]
        && <div className={styles.bubble}>{t.newUpT}</div>}

      <div
        draggable={item.type !== 'sharedPage'}
        onDragEnd={dragEndHandler}
        onDragStartCapture={dragStartHandler}
        data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
        className={`
          ${styles.preview}
          ${bigSizeCondition ? styles.previewPage : ''}
          ${isUPV ? styles.isUPVLib : ''}
          ${blinkIdsArray?.includes(item.id) ? styles.blink_it : ''}
          ${
            ((isSelected && !isBlurred)
            || componentDescriptionId === item?.id
            || cursorLoader) && isComponentDescriptionOpened
              ? styles.selected_active_meta
              : ''
          }
          ${isSelected || (isUsedActive && !isBlurred) ? styles.selected_active : ''}
        `}
      >
        {!isPdf && (
          <div className={`${styles.wrapper_pro}`}>
            <BlockCreatorComponent
              hasMark={(unseenPagesManagerMap && unseenPagesManagerMap[item.libraryComponentId])
                || (contentSelector === 'sharedByMe' || isSharedByMe)
              }
              isPreview
              forDescription
              item={item}
            />
          </div>
        )}

        {isPdf && (
          <div className={`${styles.pdf_preview}`}>
            <FallbackImage
              src={item.pdfPreviewUrl || item.urlFile}
              alt=""
              type="application/pdf"
              item={item}
              isChannelMiniPage={isChannelMiniPage}
              isPagePdf={isPdf}
            />
          </div>
        )}
        <div
          data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
          className={`
                  ${bigSizeCondition ? styles.usedItem_page : styles.usedItem}
                  ${styles.overlayUsed}
                  ${styles.overlayUsedOffset}
                `}
        >
          <MiniPageUsedMenu
            dataParent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
            usedRef={actionsProps.usedRef}
            isUsedActive={isUsedActive}
            setIsUsedActive={actionsProps.setIsUsedActive}
            libraryComponentId={item.id}
            usedIn={item.usedIn}
            usedTimesCounter={item.usedTimesCounter}
            isNoChannels={item.isNoChannels}
          />
        </div>
        {item.isFavorite && (
          <FavoriteIcon
            isFavorite={item.isFavorite}
            handleToggleFavorite={handleToggleFavorite}
            bigSizeCondition={bigSizeCondition}
          />
        )}
        <CardViewActions
          item={item}
          bigSizeCondition={bigSizeCondition}
          isSelected={isSelected}
          isUPV={isUPV}
          onCompositeClickHandler={onCompositeClickHandler}
          isUsedActive={isUsedActive}
          isChannelMiniPage={isChannelMiniPage}
          isPdf={isPdf}
          refPage={refPage}
          {...actionsProps}
        />

        {!item.isOwnContent
          && isChannelMiniPage
          && (
            <div
              className={`
                ${playlistStyles.ownerPlate} ${playlistStyles.miniPage}
                ${item.owner?.role === 'co_admin' ? playlistStyles.coAdmin : ''}
                ${item.owner?.role === 'admin' ? playlistStyles.admin : ''}
              `}
            >
              {item.owner?.name}
            </div>
          )}
      </div>
    </>
  );
});

export default CardViewPage;
