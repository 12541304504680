import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import useTextareaAutosize from '../../../../utils/hooks/useTextareaAutosize';
import { CurrentPage } from '../../../../redux/currentPage/types';
import { useLocalization } from '../../../../LocalizationContext';

const MAX_CAPTION_LENGTH = 250;

const PlaylistElementCaptionShell = styled.div`
  //background-color: #59608033;
  width: 100%;

  ${({ isExpandedView }) => (isExpandedView
    ? `

    width: 90%;
    top:0px;
    position: absolute;
    left: 13px;
    z-index: 7;
  `
    : `  margin-left: 14px;
  margin-top: 2px;`)}
  &:has(textarea:focus ) {
    div {
      display: none;
    }
  }
`;
const PlaylistElementCaptionShellWrapper = styled.div`
  bottom: 0px;
  ${({ isExpandedView }) => (isExpandedView
    ? `

    position: absolute;
  `
    : '')}
  width: 100%;

`;
const TextArea = styled.textarea`
  width: 100%;
  border: transparent;
  resize: none;
  background-color: inherit;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  height: 53px;
  color: #605a5f;

  &::placeholder {
    color: #6b686a;
  }

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const PlaylistElementCaption = ({
  caption,
  isShowCaption,
  linkPageId,
  isNew,
  isExpandedView,
}) => {
  const dispatch = useDispatch();
  const { id: playlistId } = useSelector((state) => state.currentPage);
  const [initialCaption, setInitialCaption] = useState(caption);
  const [tmpCaption, setTmpCaption] = useState(caption);
  const textareaRef = useRef(null);
  useTextareaAutosize(textareaRef, tmpCaption);
  const { t } = useLocalization();

  useEffect(() => {
    setTmpCaption(caption);
    setInitialCaption(caption);
  }, [caption]);

  const onTextAreaChange = (event) => {
    setTmpCaption(event.target.value);
  };

  const saveCaption = (newCaption) => {
    dispatch(
      actionCreator(CurrentPage.UpdateCaption, {
        playlistId,
        linkPageId,
        newCaption,
      }),
    );
  };

  const onKeyPressCaption = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      setTmpCaption(`${tmpCaption}`);
      saveCaption(`${tmpCaption}`);
    }
    if (e.key === 'Escape') {
      e.stopPropagation();
      e.preventDefault();
      setTmpCaption(initialCaption);
    }
  };

  if (!isShowCaption) return null;

  const reallyNoCaption = !tmpCaption || tmpCaption === 'undefined' || tmpCaption === 'null';
  return (
    <PlaylistElementCaptionShellWrapper isExpandedView={isExpandedView}>
      <PlaylistElementCaptionShell
        onDragStart={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }
      }
        draggable
        onClick={(e) => e.stopPropagation()}
        isExpandedView={isExpandedView}
      >
        <TextArea
          data-cy="input-caption"
          ref={textareaRef}
          row={1} // to avoid resize logic height offsets
          maxLength={MAX_CAPTION_LENGTH}
          value={reallyNoCaption ? '' : tmpCaption}
          placeholder={t.captionPlaceholderT}
          onChange={onTextAreaChange}
          onKeyDown={onKeyPressCaption}
          autoFocus={isNew}
          onBlur={() => {
            saveCaption(`${tmpCaption}`);
          }}
        />
      </PlaylistElementCaptionShell>
    </PlaylistElementCaptionShellWrapper>
  );
};

export default PlaylistElementCaption;
