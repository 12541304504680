import styled from 'styled-components';
import React from 'react';
import { useLocalization } from '../../LocalizationContext';

const NewProgressBar = styled.div`
  position: relative;
  background: #d7ddf7;
  box-sizing: border-box;
  border-radius: 4px;
  height: 6px;
  width: 100%;
`;

const TiniCartProgressBar = styled.div`
  width: 120px;
  height: 6px;

  background: #3434341A;
  border-radius: 8px;
`;

const ProgressInfill = styled.div`
  background: #29BF12;
  border-radius: 4px;
  height: 8px;
  ${({ isSmall }) => (isSmall ? 'height: 6px; ' : '')}
  width: ${({ percentage }) => percentage || 0}%;
  transition: 0.4s width ease-in-out;
`;

const ProgressTiniCartInfill = styled.div`
  padding: 0 1px;
  box-sizing: content-box;
  position: relative;
  top: -1px;
  left: -1px;

  height: 6px;
  width: ${({ percentage }) => percentage || 0}%;
  background: #4cb569;
  border-radius: 3px;
`;

const Message = styled.div`
  display: flex;
  height: 6px;
  width: 60px;
  align-items: center;
  justify-content: center;
  color: #596080;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
`;

export const NewMiniPlaylistProgressBar = ({ percentage = 0, isSmall }) => {
  return (
    <NewProgressBar>
      <ProgressInfill percentage={percentage} isSmall={isSmall} />
    </NewProgressBar>
  );
};

export const TiniCartPlaylistProgressBar = ({ percentage = 0 }) => {
  const { t } = useLocalization();
  if (percentage === 100) return <Message>{t.closedT}</Message>;
  return (
    <TiniCartProgressBar>
      <ProgressTiniCartInfill percentage={percentage} />
    </TiniCartProgressBar>
  );
};
