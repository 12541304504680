import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames/bind';
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
import useWindowDimensions from '../../../utils/hooks/useWindowDimensions';
import useThrottle from '../../../utils/hooks/useThrottle';
import { APPURL } from '../../../environments/environments';
import { ReactComponent as ChevyLeftSvg } from '../../../images/icons/chevron_left_14_24_noContainer.svg';
import { loader } from '../../../components/MomentBlock/CustomBlocks/pdfBlock/PdfAsPreview';
import { ReactComponent as ChevyRightSvg } from '../../../images/icons/chevrom_right_14_24_noContainer.svg';
import { ReactComponent as ChevLeftSvg } from '../../../images/icons/chevron_left_16.svg';
import { ReactComponent as ChevRightSvg } from '../../../images/icons/chevron_right_16x16.svg';
import rawStyles from '../index.module.scss';
import { useLocalization } from '../../../LocalizationContext';

const styles = classNames.bind(rawStyles);

export const PdfContent = ({
  forUPV,
  changePage,
  setTotalPages,
  isReadOnly = false,
  pdfRef,
  ...props
}) => {
  const { t } = useLocalization();
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();
  const [pageNumber, setPageNumber] = useState(1);

  const [file, setFile] = useState({
    httpHeaders: {
      'Access-Control-Allow-Origin': `${APPURL}`,
      'Access-Control-Request-Headers': `${APPURL}`,
      'Cache-Control': 'no-cache',
    },
    url: props.item.pdfPreviewUrl || props.item.urlFile,
  });
  const widthCalc = (props.isWhiteGallery || props.isPlayer) ? Math.min(windowWidth - 500, windowWidth - windowWidth / 10, 1200) : 610;
  const heightCalc = (props.isWhiteGallery || props.isPlayer) ? windowHeight - 300 : null;

  const whiteGalleryImageRef = useRef();

  useEffect(() => {
    setPageNumber(1);
    setFile({
      httpHeaders: {
        'Access-Control-Allow-Origin': `${APPURL}`,
        'Access-Control-Request-Headers': `${APPURL}`,
        'Cache-Control': 'no-cache',
      },
      url: props.item.pdfPreviewUrl || props.item.urlFile,
    });
  }, [props.item.pdfPreviewUrl, props.item.urlFile]);

  useEffect(() => {
    setPageNumber(props.pages.current);
  }, [props.pages.current]);


  async function onDocumentLoadSuccess(pdf) {
    setTotalPages(pdf.numPages);

    if (props.setSiblingComponentIsLoading) {
      props.setSiblingComponentIsLoading(true);
    }

    if (props.updateAfterLoadPdf && props.zoomWrapRef?.current) {
      props.zoomWrapRef.current.style = 'opacity: 0';
      props.updateAfterLoadPdf();
    }
  }

  const goPreviousPage = (e) => {
    e.stopPropagation();
    if (pageNumber > 1) {
      setPageNumber((prev) => prev - 1);
    }
  };
  const goNextPage = (e) => {
    e.stopPropagation();
    if (pageNumber < props.pages.max) {
      setPageNumber((prev) => prev + 1);
    }
  };
  // const onPageLoadHandler = (page => {
  //   if (page.originalWidth > 618) {
  //     setMaxWidth(props.isWhiteGallery ? 680 : 618);
  //   } else {
  //     setMaxWidth(null);
  //   }
  // });

  return (
    <div
      ref={props.zoomWrapRef}
      className={styles('content_wrapper', {
        isPlayerHeight: props.isPlayer,
        isPlayerPadding: props.isPlayer,
        isExpandHeight: props.isExpandView,
        isWhiteGallery: props.isWhiteGallery,
        hideWithoutHover: !props.isPlayer,
      })}
    >
      {!props.isPlayer && !props.isPDFPreview && (
        <div className={styles('side_controls_container', 'left')}>
          <div
            data-operation="decrease"
            className={styles('content_side_controls', {
              disabled: props.pages.current === 1,
            })}
            onClick={changePage('decrease')}
          >
            <div data-operation="decrease" className={styles('container')}>
              <ChevyLeftSvg />
            </div>
          </div>
        </div>
      )}
      <div
        ref={pdfRef}
        className={styles('content_main_section', 'isPdf', {
          isPlayerTOCCardView: props.isPlayerTOCPreview && !!props.isCardView,
          isPlayerTOCRowView: props.isPlayerTOCPreview && !props.isCardView,
          isPlayerPreview: props.isPlayerPreview,
          isWhiteGallery: props.isWhiteGallery,
        })}
      >
        <div
          className="fullScreen"
          ref={props.zoomRef}
          style={
            props.scale
              ? {
                zoom: `${props.scale}%`,
              }
              : {}
          }
        >
          <Document
            error={t.FailedToLoadAFileT}
            file={file}
            onLoadSuccess={(data) => onDocumentLoadSuccess(data)}
            loading={() => loader(t.downloadingFilePleaseWaitT, isReadOnly)}
            imageResourcesPath="../../../images/icons/"
          >
            <Page
              inputRef={props.isWhiteGallery ? whiteGalleryImageRef : props.imageRef}
              className={styles('pdf_canvas')}
              pageNumber={pageNumber}
              loading={() => <></>}
              imageResourcesPath=""
              renderAnnotationLayer={false}
              scale={1}
              height={heightCalc}
              width={widthCalc}
              // width={200}
              // onLoadSuccess={onPageLoadHandler}
            />
          </Document>
        </div>
      </div>
      {!props.isPlayer && !props.isPDFPreview && (
        <div className={styles('side_controls_container', 'right')}>
          <div
            data-operation="increase"
            className={styles('content_side_controls', {
              disabled: props.pages.current === props.pages.max,
            })}
            onClick={changePage('increase')}
          >
            <div data-operation="increase" className={styles('container')}>
              <ChevyRightSvg />
            </div>
          </div>
        </div>
      )}
      {((props.isPlayer && props.isMouseMoveShowControls) || !props.isPlayer) && props.pages.max > 1 && (
        <>
          {!!file && (
            <div className={styles('controls_container', { forUPV })}>
              <div className={styles('icon')} onMouseDown={goPreviousPage}>
                <ChevLeftSvg />
              </div>
              <div className={styles('info')}>
                {pageNumber}/{props.pages.max}
              </div>
              <div className={styles('icon')} onMouseDown={goNextPage}>
                <ChevRightSvg />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
