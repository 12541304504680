import React from 'react';
import { DEFAULT_TITLE } from '../../../../utils/constants';
import { InputArea } from './styled';
import { useLocalization } from '../../../../LocalizationContext';

const LIMIT = 55;
export const Title = ({
  handleKeyPressTitle,
  onBlur,
  titleRef,
  setCurrentTitle,
  value,
  disableChanges,
  handleUNDO,
}) => {
  const { t } = useLocalization();
  const onChangeTitle = (event) => {
    const titleInput = event.target.value;
    setCurrentTitle(titleInput.substring(0, LIMIT));
  };

  return (
    <InputArea
      data-cy="titleEntity"
      placeholder={t.defaultSmartFileTitleT}
      onKeyPress={handleKeyPressTitle}
      onKeyDown={handleUNDO}
      onBlur={onBlur}
      ref={titleRef}
      onChange={onChangeTitle}
      value={value}
      wrap="off"
      rows={1}
      disabled={disableChanges}
    />
  );
};
