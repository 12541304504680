import React from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames/bind';
import styled from 'styled-components/macro';
import rawStyles from './listDropOptions.module.scss';
import { actionOpenModal } from '../../redux/user/action';
import { openModalType } from '../../utils/constants';
import { useLocalization } from '../../LocalizationContext';

const styles = classnames.bind(rawStyles);
const Title = styled.div`
  margin-top: 16px;
  font-family: "RobotoFlex", sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  color: #d3c8c8;
`;
const TagListDropOptionsColorPaletteEnd = ({
  itemId,
  deleteHandler,
  dataParent,
  isLibrarySideBarTags,
  itemName,
}) => {
  const dispatch = useDispatch();
  const { t } = useLocalization();

  const openDeleteTagModal = () => {
    dispatch(actionOpenModal(openModalType.DeleteTag, { id: itemId, tagName: itemName }));
  };
  return (
    <div>
      <div className={styles('line')} />
      <Title>{t.deleteTagT}</Title>
      <div
        data-cy="tag-delete"
        className={styles('delete_option', { isLibrarySideBarTags })}
        onClick={deleteHandler || openDeleteTagModal}
        data-parent={dataParent}
      >
        <div>{t.deleteT}</div>
      </div>
    </div>
  );
};

export default TagListDropOptionsColorPaletteEnd;
