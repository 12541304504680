import React from 'react';
import TableOfContentRow from './TableOfContentRow';
import {
  TableOfContentMainWrapper,
  TableOfContentTitleWrapper,
} from './styled';
import { useLocalization } from '../../../LocalizationContext';

const TableOfContent = ({ ...props }) => {
  const id = props.id;
  const { t } = useLocalization();
  return (
    <TableOfContentMainWrapper ref={props.refMenu}>
      <TableOfContentTitleWrapper>
        <div>{t.tableOfContentsT}</div>
        <div>{props.linkPages?.length || 0}</div>
      </TableOfContentTitleWrapper>
      {!!props.linkPages?.length
        && props.linkPages.map((it, index) => {
          return (
            <TableOfContentRow
              setIsShowRightSideBarLib={props.setIsShowRightSideBarLib}
              isSharedSmartFile={props.isSharedSmartFile}
              id={id}
              item={it}
              title={it.libraryComponent.title}
              type={it.libraryComponent.type}
              number={index + 1}
            />
          );
        })}
    </TableOfContentMainWrapper>
  );
};

export default TableOfContent;
