import React, { createContext, useState, useEffect, useContext } from 'react';
import en from './locales/en.json';
import fr from './locales/fr.json';

const LocalizationContext = createContext();

const translations = {
  en,
  fr,
};

export const LocalizationProvider = ({ children }) => {
  const [language, setLanguage] = useState('en');
  const [t, setT] = useState(() => translations[language]);

  useEffect(() => {
    setT(translations[language]);
  }, [language]);

  const changeLanguage = (lng) => {
    setLanguage(lng);
  };

  return (
    <LocalizationContext.Provider value={{ t, changeLanguage }}>
      {children}
    </LocalizationContext.Provider>
  );
};

export const useLocalization = () => {
  return useContext(LocalizationContext);
};
