import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { v4 as uuid } from 'uuid';
import rawStyles from './createComponent.module.scss';
import { ReactComponent as CloseSvg } from '../../images/icons/close_20.svg';
import {
  actionBlockUnloading,
  actionUnblockUnloading,
} from '../../redux/user/action';
import { actionToggleLibraryDrag } from '../../redux/currentPage/action';
import { DATA_PARENTS, TagsParams } from '../../utils/constants';
import CreateComponentHashtagsInput from '../../pages/Playlist/CreteComponentHashtagsInput';
import { actionCreator } from '../../shared/redux/actionHelper';
import { LibraryComponents } from '../../redux/library/types';
import { useLocalization } from '../../LocalizationContext';

const styles = classNames.bind(rawStyles);

const CreateBlocksComponent = ({
  setBlocksToCreateLComponent,
  isCreateComponentMenuVisible,
  blocksToCreateLComponent,
  // blocksIndices,
  // blocksIds,
  // setIsVisible,
  // setCreateNewCollectionId,
  // createNewCollectionId,
  // isCreateNew,
}) => {
  // const [isShowMoreSettings, setIsShowMoreSettings] = useState(false);
  const [name, setName] = useState('');
  const [newLibraryComponentId] = useState(uuid());
  const [tagsToAttach, setTagsToAttach] = useState([]);
  // const { collections } = useSelector(state => state.library) || empty;
  // const { id } = useSelector(state => state.currentPage) || empty;
  const isUnloadingBlocked = useSelector(
    (state) => state.user.isUnloadingBlocked,
  );
  const { id: currentPageId } = useSelector((state) => state.currentPage);
  const dispatch = useDispatch();
  const { t } = useLocalization();

  // useEffect(() => {
  //   const it = collections[createNewCollectionId];
  //   setSelectedCollection(it || { title: 'All components' });
  // }, [createNewCollectionId, isCreateNew, collections]);

  useEffect(() => {
    const hasComponents = !!Object.values(blocksToCreateLComponent || {})
      .length;
    if (hasComponents && !isCreateComponentMenuVisible) {
      const hasReadyComponents = !!Object.values(
        blocksToCreateLComponent || {},
      ).filter((i) => i.status === 'ready').length;
      if (hasReadyComponents) return;
      setBlocksToCreateLComponent({});
    }
  }, [isCreateComponentMenuVisible]);

  useEffect(() => {
    if (!!name && !isUnloadingBlocked) dispatch(actionBlockUnloading());
  }, [name]);

  const onNameChangeHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setName(e.target.value);
  };
  // const onDescriptionChangeHandler = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   setDescription(e.target.value);
  // };

  // const onClickShowMoreHandler = (e) => {
  //   e.stopPropagation();
  //   e.preventDefault();
  //   setIsShowMoreSettings(!isShowMoreSettings);
  // };

  const createLibraryComponent = () => {
    const blocksToSave = Object.values(blocksToCreateLComponent || {})
      .filter((i) => i.status === 'pending')
      .reduce((acc, item) => {
        return { ...acc, [item.id]: { ...item, status: 'ready' } };
      }, {});
    const blockId = uuid();

    dispatch(
      actionCreator(LibraryComponents.CreateLibraryComponentFromBlocks, {
        selectedBlocks: Object.keys(blocksToSave),
        name,
        newLibraryComponentId,
        tags: tagsToAttach,
        blockId,
        currentPageId,
      }),
    );
    setBlocksToCreateLComponent({});
    dispatch(actionUnblockUnloading());
  };

  const onEnterPressHandler = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      createLibraryComponent();
    }
  };

  const onCreateClickHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    createLibraryComponent();
  };

  const onCancelClickHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const blocksToSave = { ...blocksToCreateLComponent };
    Object.values(blocksToCreateLComponent || {})
      .filter((i) => i.status === 'pending')
      .forEach((item) => {
        delete blocksToSave[item.id];
      }, {});
    setBlocksToCreateLComponent({});
    // setCreateNewCollectionId(false);
    dispatch(actionToggleLibraryDrag(false));
    dispatch(actionUnblockUnloading());
  };

  return (
    <div
      className={styles('section_wrapper', {
        show: isCreateComponentMenuVisible,
      })}
      onMouseUp={(e) => {
        // e.stopPropagation();
        e.preventDefault();
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
      onKeyPress={(event) => event.stopPropagation()}
      onKeyDown={(event) => event.stopPropagation()}
      data-parent={DATA_PARENTS.createLibrary}
    >
      <div
        className={styles('section_header')}
        data-parent={DATA_PARENTS.createLibrary}
      >
        <div className={styles('title')}>{t.createComponentT}</div>
        <div className={styles('controls')} onClick={onCancelClickHandler}>
          <CloseSvg />
        </div>
      </div>

      <div
        className={styles('input_item')}
        data-parent={DATA_PARENTS.createLibrary}
      >
        <div className={styles('input_label')}>{t.nameT}</div>
        <div
          className={styles('input_body')}
          data-parent={DATA_PARENTS.createLibrary}
        >
          <input
            data-parent={DATA_PARENTS.createLibrary}
            type="text"
            value={name}
            onChange={onNameChangeHandler}
            autoFocus
            onKeyPress={onEnterPressHandler}
          />
        </div>
      </div>

      <div
        className={styles('input_item')}
        data-parent={DATA_PARENTS.createLibrary}
      >
        <div
          className={styles('add_tags')}
          data-parent={DATA_PARENTS.createLibrary}
        >
          <CreateComponentHashtagsInput
            isPage
            noDescription
            isEditable
            place="LibraryComponent"
            type="LibraryComponent"
            state={TagsParams.createComponentTags}
            newLibraryComponentId={newLibraryComponentId}
            tagsToAttach={tagsToAttach}
            setTagsToAttach={setTagsToAttach}
          />
        </div>
      </div>

      <div
        data-parent={DATA_PARENTS.createLibrary}
        className={styles('actions_container')}
      >
        <div
          data-parent={DATA_PARENTS.createLibrary}
          onClick={onCancelClickHandler}
        >
          <div className={styles('cancel_btn')}>{t.cancelT}</div>
        </div>
        <div
          data-parent={DATA_PARENTS.createLibrary}
          className={styles('actions_item')}
        >
          <div
            data-parent={DATA_PARENTS.createLibrary}
            className={styles('action_btn')}
            onClick={onCreateClickHandler}
          >
            {t.createT}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateBlocksComponent;
