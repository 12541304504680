import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';

import { useHistory } from 'react-router-dom';
import { actionLoadingItem, actionShowMessage } from '../../redux/support/action';
import { actionCloseModal } from '../../redux/user/action';
import closeIcon from '../../images/icons/close_24.svg';

import rawStyles from './index.module.scss';
import { actionCreator } from '../../shared/redux/actionHelper';
import EditPlaylist from '../../redux/playlists/types';
import DownloadPlaylistModalBody from './DownloadPlaylistModalBody';
import { Group, MessageType } from '../../utils/constants';
import { axiosAbortarium } from '../../utils/axiosAbortarium';
import { useLocalization } from '../../LocalizationContext';

const cx = classNames.bind(rawStyles);

const DownloadPlaylistModalOutSide = () => {
  const dispatch = useDispatch();
  const { t } = useLocalization();
  const contentData = useSelector((state) => state.content.contentData);
  const history = useHistory();
  const { selectorType: channelId } = useSelector((state) => state.content);

  const {
    dataPayload: { playlistId, itemId },
  } = useSelector((redux) => redux.user);
  const item = contentData[itemId];

  useEffect(() => {
    dispatch(actionCreator(EditPlaylist.UpdateInfoLinkPage, { playlistId, itemId }));
    return () => {
      dispatch(actionLoadingItem(''));
    };
  }, []);

  const closeModal = () => {
    dispatch(actionCloseModal(''));
  };


  const download = useCallback((e, filesArray, isLinkAdded) => {
    e.stopPropagation();
    if (!item.linkPages?.length) {
      return;
    }
    const isChannels = history.location.pathname.startsWith('/channel/');
    const _channelId = isChannels ? channelId : '';
    const type = item.status === 'shared' ? 'shared' : 'edit';
    const link = `${window.location.origin}/maker/${itemId}/${type}/${_channelId}`;


    dispatch(
      actionShowMessage({
        type: MessageType.DownloadWholePlaylist,
        itemName: 'playlist',
        mode: Group.processing,
        id: playlistId,
      }),
    );
    axiosAbortarium.generateNew(playlistId);
    dispatch(
      actionCreator(EditPlaylist.DownloadAllDownloadablePlaylistItems, {
        playlistId,
        playlistTitle: item.title,
        filesArray,
        isLinkAdded,
        smartfileLink: link,
      }),
    );
  }, [item]);

  if (!item?.isPrepareToDownload) {
    return (
      <div className={`${cx('modal_wrapper')} global_modal`} onClick={closeModal}>
        <div
          onClick={(e) => e.stopPropagation()}
          className={cx('modal', 'downloadPlaylist', 'downloadPlaylist_placeholder')}
        >
          <div className={cx('header')}>
            <img
              onClick={closeModal}
              src={closeIcon}
              className={cx('close')}
              alt="close"
            />
          </div>
          <div className="flex_loader-wrapper">
            <div className="flex_loader">{t.loadingT}</div>
          </div>
        </div>
      </div>
    );
  }


  return (
    <div className={`${cx('modal_wrapper')} global_modal`} onClick={closeModal}>
      <div>
        <DownloadPlaylistModalBody
          id={playlistId}
          img={item.img}
          cropUrl={item.cropUrl}
          linkPages={item.linkPages}
          title={item.title || t.untitledSmartFileT}
          download={download}
          defaultPreviewColor={item.defaultPreviewColor}
        />
      </div>
    </div>
  );
};

export default DownloadPlaylistModalOutSide;
