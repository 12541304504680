import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid, v4 as uuidv4 } from 'uuid';
import { EditorState } from 'draft-js';
import styles from './MediaAdd.module.scss';
import { ReactComponent as ImageSvg } from '../../images/icons/icon_40/image.svg';
import { actionCreateUpload } from '../../redux/filesUpload/action';
import {
  allowedFormatsImage,
  BlockTypes,
  MessageType,
} from '../../utils/constants';
import { actionCopyBlocksToPage, CopyBlockImageToPage } from '../../redux/library/actions';
import { actionAddManyEditableBlock } from '../../redux/pages/action';
import { maxFileSize } from '../../GLOBAL_CONST';
import { FileUpload } from '../../redux/filesUpload/types';
import { ReactComponent as Google16Svg } from '../../images/icons/google_drive_16.svg';
import { ReactComponent as Dropbox16Svg } from '../../images/icons/dropbox_16.svg';
import AddGoogleFile from '../LibraryHeaderMenu/childComponents/AddGoogleFile';
import { actionShowMessage } from '../../redux/support/action';
import AddDropboxFile from '../LibraryHeaderMenu/childComponents/AddDropboxFile';
import { useLocalization } from '../../LocalizationContext';

const cx = classNames.bind(styles);

const BlockImageAddStart = ({
  setStep,
  index,
  id,
  playlistField,
  isLibraryCreate,
  isSharedComponent,
  position,
  nextItemPosition,
}) => {
  const history = useHistory();
  const [activeMenu, setActiveMenu] = useState('computer');
  const currentPageId = useSelector((state) => state.currentPage.id);
  const { t } = useLocalization();

  const inputFile = useRef(null);
  const dispatch = useDispatch();
  const [url, setUrl] = useState('');
  const showCantEditNotification = () => {
    dispatch(
      actionShowMessage({
        type: MessageType.EnterComponentEditMode,
        effects: 'shaking',
      }),
    );
  };
  const onButtonClick = () => {
    if (isSharedComponent) {
      showCantEditNotification();
      return;
    }
    inputFile.current.click();
  };
  const embed = () => {
    if (!url.trim()) {
      return;
    }
    if (isSharedComponent) {
      showCantEditNotification();
      return;
    }
    const newId = uuidv4();
    dispatch({
      type: FileUpload.CreateImageUploadByLink,
      payload: { url, blockId: id, newId },
    });
  };
  const submitForm = (e) => {
    if (isSharedComponent) {
      showCantEditNotification();
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    const firstFile = e.target.files[0];
    const newId = uuidv4();
    if (firstFile.size > maxFileSize.bytes) {
      setStep({ type: 'error', info: 'isMore', title: firstFile.name });
    } else if (allowedFormatsImage[firstFile.type]) {
      dispatch(
        actionCreateUpload({
          newId,
          file: firstFile,
          title: firstFile.name,
          type: firstFile.type,
          blockId: id,
          listenREDO: true,
          callback: () => CopyBlockImageToPage(
            id,
            index,
            newId,
            firstFile,
          ),
          history,
        }),
      );
      setStep({
        type: 'loading',
        newId,
        title: firstFile.name,
        size: firstFile.size,
      });
    } else {
      setStep({ type: 'error', info: 'isDamage', title: firstFile.name });
    }
    if (e.target.files.length > 0) {
      const bbblok = [];
      const startIndex = index + 1;
      Array.from(e.target.files)
        .filter((i, fileIndex) => allowedFormatsImage[i.type] && !!fileIndex)
        .forEach((item, fileIndex) => {
          if (fileIndex > 6) return;

          const emptyEd = EditorState.createEmpty();
          const innerNewId = uuid();
          dispatch(
            actionCreateUpload({
              newId: innerNewId,
              file: item,
              title: item.name,
              type: item.type,
              callback: () => actionCopyBlocksToPage(
                currentPageId,
                { [innerNewId]: uuid() },
                startIndex + fileIndex,
                true,
                isLibraryCreate,
              ),
              history,
            }),
          );
          bbblok.push({
            state: emptyEd,
            id: innerNewId,
            type: BlockTypes.image,
          });
        });

      dispatch(
        actionAddManyEditableBlock(
          id,
          bbblok,
          currentPageId,
          null,
          playlistField,
          isLibraryCreate,
        ),
      );
    }
  };

  const clickOptionHandler = (option) => {
    if (isSharedComponent) {
      showCantEditNotification();
      return;
    }
    setActiveMenu(option);
  };

  return (
    <>
      <div className={cx('imageWrapper')}>
        <ImageSvg />
      </div>
      <div className={cx('menu')}>
        <div className={cx('buttonsBar')}>
          <div
            onClick={() => clickOptionHandler('computer')}
            className={cx('tab', 'tab_computer', {
              active: activeMenu === 'computer',
            })}
          >
            {t.computerUpT}
          </div>
          <div
            onClick={() => clickOptionHandler('embed')}
            className={cx('tab', 'tab_embed', {
              active: activeMenu === 'embed',
            })}
          >
            {t.embedLinkUpT}
          </div>
          <div
            onClick={() => clickOptionHandler('google')}
            className={cx('tab', 'tab_google', {
              active: activeMenu === 'google',
            })}
          >
            <Google16Svg className={cx('icon_margin')} />
            {t.googleDriveUpT}
          </div>
          <div
            onClick={() => clickOptionHandler('dropbox')}
            className={cx('tab', 'tab_dropbox', {
              active: activeMenu === 'dropbox',
            })}
          >
            <Dropbox16Svg className={cx('icon_margin')} />
            {t.dropboxUpT}
          </div>
        </div>

        <div className={cx('navigation_slider')}>
          <div className={cx('long_line')} />
          <div className={cx(`underline_${activeMenu}`)} />
        </div>

        <div className={cx('option')}>
          {activeMenu === 'computer' && (
            <>
              <div className={cx('file_picker')} onClick={onButtonClick}>
                {t.chooseFileT}
              </div>
              <input
                type="file"
                name="file"
                multiple
                accept={`image/png,
                    image/jpeg,
                    image/gif,
                    image/heic,
                    image/svg+xml,
                    image/bmp,
                `}
                ref={inputFile}
                style={{ display: 'none' }}
                onChange={(e) => {
                  submitForm(e);
                }}
              />
            </>
          )}
          {activeMenu === 'embed' && (
            <div className={cx('embed_section')}>
              <div className={cx('embed_input_wrapper')}>
                <input
                  onChange={(e) => setUrl(e.target.value)}
                  type="text"
                  value={url}
                  className={cx('embed_input')}
                  placeholder={t.pasteImageLinkT}
                />
              </div>
              <div onClick={embed} className={cx('embed_submit')}>
                {t.addUpT}
              </div>
            </div>
          )}
          {activeMenu === 'google' && (
            <div className={cx('google_section')}>
              <AddGoogleFile
                setIsVisible={() => {}}
                viewedTypes="DOCS"
                setStep={setStep}
                oldUploadBlockId={id}
                firstBlockIndex={index}
                oldUploadBlockPosition={position}
                nextItemPosition={nextItemPosition}
                isMediaAddBlock
                isMultiselect
              />
            </div>
          )}
          {activeMenu === 'dropbox' && (
            <div className={cx('google_section')}>
              <AddDropboxFile
                oldUploadBlockId={id}
                oldUploadBlockPosition={position}
                nextItemPosition={nextItemPosition}
                firstBlockIndex={index}
                isMediaAddBlock
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BlockImageAddStart;
