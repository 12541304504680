import React from 'react';
import { ReactComponent as GreenCheckMark } from '../../../images/2023/svg/small/simpletable/checkmark_18_14.svg';
import { ReactComponent as FailedCheckMark } from '../../../images/2023/svg/small/simpletable/line_17_1.svg';
import { ReactComponent as ArrowDown } from '../../../images/2023/svg/small/simpletable/arrow_11_10.svg';
import { SortArrowWrapper, TBodyWrapper, TDWrapper } from './styled';
import { useLocalization } from '../../../LocalizationContext';

const SimpleTable = ({ members, ...props }) => {
  const { t } = useLocalization();
  const isOwner = (id) => {
    return props.ownerID === id;
  };

  return (
    <TBodyWrapper>
      <tr>
        <th>
          <SortArrowWrapper
            rotate={!props.sortType}
            onClick={() => props.sortByName(0)}
          >
            {' '}
            <ArrowDown />
          </SortArrowWrapper>
          {t.nameT}
        </th>
        <th>{t.completedT}</th>
        <th>{t.buttonIDT}</th>
        <th>{t.approvedT}</th>
        <th>{t.isItCoolT}</th>
      </tr>
      {members.map((it) => {
        return (
          <tr key={it.id}>
            <td>{isOwner(it.id) ? `${it.name} (Author)` : it.name}</td>
            <TDWrapper failed={!it?.buttonsState?.closed.checked}>
              {it?.buttonsState?.closed.checked ? (
                <GreenCheckMark />
              ) : (
                <FailedCheckMark />
              )}
            </TDWrapper>
            <TDWrapper failed={!it.buttonID}>
              {it.buttonID ? <GreenCheckMark /> : <FailedCheckMark />}
            </TDWrapper>
            <TDWrapper failed={!it.approved}>
              {it.approved ? <GreenCheckMark /> : <FailedCheckMark />}
            </TDWrapper>
            <TDWrapper failed={!it.isItCool}>
              {it.isItCool ? <GreenCheckMark /> : <FailedCheckMark />}
            </TDWrapper>
          </tr>
        );
      })}
    </TBodyWrapper>
  );
};

export default SimpleTable;
