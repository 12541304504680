import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import Header from './Header';
import Bottom from './Bottom';
import { MainPlayerWrapper } from './styled';
import {
  actionClearCurrentPage,
  actionGetCurrentPlaylist,
  actionUpdateDraggableBlocks,
} from '../../redux/currentPage/action';
import { actionRefreshUserTags } from '../../redux/tags/action';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import { getSharedData } from './helpers';
import { actionCreator } from '../../shared/redux/actionHelper';
import { ServiceUser } from '../../redux/user/types';
import useThrottle from '../../utils/hooks/useThrottle';
import PlayerItemContent from '../Player/PlayerItemContent';
import { empty, SHARED_PLAYLIST_URL } from '../../utils/constants';
import ApprovalAndCaptionsModule from './ApprovalAndCaptionsModule';
import PlayerTableOfContents from '../Player/PlayerTableOfContents';
import { axiosAbortarium } from '../../utils/axiosAbortarium';
import EditPlaylist from '../../redux/playlists/types';
import { calculateItemsStylesMapMaker, getItemName } from '../../utils/helpers';
import rawStylesBlock from '../Maker/MakerCommon/elements.module.scss';
import PlaylistLastFrame from '../Maker/PlaylistLastFrame';
import { actionUpdateReadState } from '../../redux/playlists/action';
import MakerTOC from '../Maker/MakerToc/MakerTOC';
import { ToCAndExpandWrapper } from '../Maker/styled';
import { TOCSvgShell } from '../Maker/sharedStyled';
import Tooltip from '../../shared/Tooltips/Tooltip';
import { ReactComponent as TOCSvg } from '../../images/icons/icon_50/TOCMaker.svg';
import { useLocalization } from '../../LocalizationContext';

const cxBlock = classNames.bind(rawStylesBlock);


const NewPlayer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useLocalization();
  const { id, channelId, itemNumber } = useParams();

  const [throttleHideFast] = useThrottle(1000);
  const [throttleHideSlow] = useThrottle(3000);
  const contentScrollRef = useRef(null);
  const disableHide = useRef(null);
  const actualScrollY = useRef(window.pageYOffset);
  const tocScrollRef = useRef(null);

  const [refItem, open, setOpen] = useComponentVisible('');

  const {
    owner, linkPages = [], isDownload, currentRole,
  } = useSelector((state) => state.currentPage);
  const { playerSettings: { isFullScreenMode, isCaptionsAndApproveButtonsHidden } } = useSelector((state) => state.user);

  const [isMouseMoveShowControls, setIsMouseMoveShowControls] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [prevItemNumber, setPrevItemNumber] = useState(null);
  const [isEnd, setIsEnd] = useState(false);
  const [lineStylesObject, setLineStylesObject] = useState({});

  const memoLink = useMemo(() => linkPages
    .sort((a, b) => a.position - b.position), [linkPages]);

  let defaultIndex = 1;
  const reindexedLinkPages = useMemo(() => linkPages
    .map(page => {
      if (page.type !== 'elementText') {
        page.customIndex = defaultIndex;
        defaultIndex++;
      }
      return page;
    }), [linkPages]);

  const isForSharedToWeb = history.location.pathname.includes('shared_player');
  const isShared = channelId === 'shared';
  const refSelectedBlocks = { current: [] };

  const switchControls = () => {
    if (disableHide.current) return;
    setIsMouseMoveShowControls(false);
  };


  const mouseMoveHandler = useCallback((e) => {
    const pageY = e.pageY;
    const inTopPosition = e.pageY < 70;
    const inBottomPosition = (window.innerWidth) < pageY + 140;
    const isPreventHide = inTopPosition || inBottomPosition;
    disableHide.current = isPreventHide;
    throttleHideSlow(() => switchControls);
    if (isMouseMoveShowControls) return;
    setIsMouseMoveShowControls(true);
  }, [isMouseMoveShowControls]);


  const closePlayer = () => {
    // routeToBeReturned - correct way to be returned
    // routeToBeRedirected - the extra way to be returned if we don't know the correct way

    let routeToBeRedirected = '';
    if (channelId === 'shared') {
      routeToBeRedirected = `/maker/${id}/shared/?fromPageType=shared%20to%20me`;
    } else if (channelId === 'preview') {
      routeToBeRedirected = `/maker/${id}/edit/?fromPageType=draft`;
    } else {
      routeToBeRedirected = `/maker/${id}/publish/${channelId}?fromPageType=channel`;
    }
    isFullScreenMode && dispatch(
      actionCreator(ServiceUser.Player.ChangeFullscreen, {
        value: !isFullScreenMode,
      }),
    );
    isForSharedToWeb
      ? history.push(`/smartfile/${id}`, { mode: 'synthetic-', path: `/${SHARED_PLAYLIST_URL}/${id}` })
      : history.push(routeToBeRedirected, { mode: 'synthetic-', path: routeToBeRedirected });
  };

  const onKeyPressHandler = (e) => {
    if (e.key === 'Escape') {
      closePlayer();
    }
  };

  const escFunction = () => {
    if (!document.fullscreenElement) {
      dispatch(
        actionCreator(ServiceUser.Player.ChangeFullscreen, { value: false }),
      );
    }
  };

  const toggleIsCaptionsAndApproveButtonsHidden = () => {
    dispatch(
      actionCreator(ServiceUser.Player.IsCaptionsAndApproveButtonsHidden, {
        value: !isCaptionsAndApproveButtonsHidden,
      }),
    );
  };

  const scrollTo = (direction) => {
    const maxContentHeight = document.getElementById('player-content');
    let maximum;
    if (
      maxContentHeight?.offsetHeight
      && contentScrollRef.current.clientHeight
    ) {
      maximum = maxContentHeight?.offsetHeight - contentScrollRef.current.clientHeight;
    }
    if (direction === 'down') {
      actualScrollY.current = actualScrollY.current < maximum ? actualScrollY.current + 100 : actualScrollY.current;
    } else {
      actualScrollY.current = actualScrollY.current > 0 ? actualScrollY.current - 100 : actualScrollY;
    }
    contentScrollRef.current.scrollTo({
      top: actualScrollY.current,
      behavior: 'smooth',
    });
  };

  const scrollToItem = (clickedIndex) => {
    if (clickedIndex < 0 || clickedIndex > linkPages.length) return;
    isForSharedToWeb
      ? history.push(`/shared_player/${channelId}/${id}/${clickedIndex + 1}`)
      : history.push(`/player/${channelId}/${id}/${clickedIndex + 1}`);
  };

  const downloadFile = (e, item) => {
    e.stopPropagation();
    e.preventDefault();
    if (item.isRemixLocked) return;
    const libraryComponentType = item?.libraryComponent?.type;
    const libraryComponentTitle = item?.title || item?.libraryComponent?.title;

    const libraryComponentId = item?.libraryComponent?.id || item.textComponent?.id;
    axiosAbortarium.generateNew(libraryComponentId);

    dispatch(
      actionCreator(EditPlaylist.DownloadOneElement, {
        elementId: libraryComponentId,
        elementTitle: item.title === null ? libraryComponentTitle : item.title,
        elementType: libraryComponentType,
        isText: !!item.textComponent?.id,
        text: getItemName(item),
        owner,
      }),
    );
  };

  useEffect(() => {
    // keydown 'esc' is used only in non-open isFullScreenMode
    if (isFullScreenMode) {
      document.addEventListener('fullscreenchange', escFunction);
      document.addEventListener('webkitfullscreenchange', escFunction);
      document.addEventListener('mozfullscreenchange', escFunction);
      document.addEventListener('MSFullscreenChange', escFunction);
      document.removeEventListener('keydown', onKeyPressHandler);
      setIsMouseMoveShowControls(true);
      throttleHideSlow(() => switchControls);
    }
    return () => {
      document.removeEventListener('fullscreenchange', escFunction);
      document.removeEventListener('webkitfullscreenchange', escFunction);
      document.removeEventListener('mozfullscreenchange', escFunction);
      document.removeEventListener('MSFullscreenChange', escFunction);
      document.addEventListener('keydown', onKeyPressHandler);
    };
  }, [isFullScreenMode]);

  useEffect(() => {
    document.addEventListener('keydown', onKeyPressHandler);
    return () => {
      document.removeEventListener('keydown', onKeyPressHandler);
    };
  }, [isFullScreenMode]);


  useEffect(() => {
    if (id && !isShared) {
      dispatch(actionGetCurrentPlaylist(id, true, null, null, null, channelId));
      dispatch(actionRefreshUserTags());
    } else if (id) {
      getSharedData(null, dispatch, id, history);
    }
    return () => {
      dispatch(actionClearCurrentPage());
    };
  }, [id]);

  useEffect(() => {
    if (!linkPages.length) return;

    const itemsMap = calculateItemsStylesMapMaker(linkPages);
    setLineStylesObject(itemsMap);
    const idsArray = [];
    // const indexArray = [];
    // let calcTextElement = 0;
    if (selectedIds.length) {
      refSelectedBlocks.current.forEach((item) => {
        if (item && selectedIds.includes(item.id)) {
          // if (item.dataset.type === 'elementText')++calcTextElement;
          idsArray.push(item.id);
          // indexArray.push(index);
          item.setAttribute('class', cxBlock('selected_block'));
        } else if (item) item.removeAttribute('class');
      });
    }
    setSelectedIds(idsArray);
    dispatch(actionUpdateDraggableBlocks(idsArray));
  }, [linkPages]);

  useEffect(() => {
    if (itemNumber) {
      if (prevItemNumber) {
        const prevItem = linkPages[prevItemNumber - 1];
        if (!prevItem?.isViewed && prevItem?.mainUserInterface?.id) {
          dispatch(actionUpdateReadState(prevItem.mainUserInterface.id, prevItem.isViewed, prevItem.id));
        }
      }
      setPrevItemNumber(parseInt(itemNumber));
    }
  }, [itemNumber, isEnd]);

  const currentItem = reindexedLinkPages[itemNumber - 1] ?? empty;
  if (isDownload) {
    return (
      <div className="loader-wrapper-page">
        <div className="loader">{t.loadingT}</div>
      </div>
    );
  }

  if (!linkPages?.length) {
    return <></>;
  }

  return (

    <MainPlayerWrapper
      onMouseMove={mouseMoveHandler}
      ref={contentScrollRef}
      isFullScreenMode={isFullScreenMode}
    >
      {!isEnd && (
        <>
          <MakerTOC
            currentRole={currentRole}
            refItem={refItem}
            setOpen={setOpen}
            open={open === 'toc'}
            scrollToItem={scrollToItem}
            isForSharedToWeb={isForSharedToWeb}
            isPlayer
          />
          <ToCAndExpandWrapper>
            <TOCSvgShell>
              <Tooltip
                text={t.tableOfContentT}
                direction="left"
                place="TOC"
              >
                {open !== 'toc' && <TOCSvg onClick={() => setOpen('toc')} />}
              </Tooltip>
            </TOCSvgShell>
          </ToCAndExpandWrapper>
        </>
      )}
      {!isEnd && (<ApprovalAndCaptionsModule currentItem={currentItem} areControlsHidden={!isMouseMoveShowControls && isFullScreenMode} />)}
      { !isEnd && (
        <>
          <Header isMouseMoveShowControls={isMouseMoveShowControls} currentItem={currentItem} closePlayer={closePlayer} />
          <Bottom isMouseMoveShowControls={isMouseMoveShowControls} currentItem={currentItem} setIsEnd={setIsEnd} scrollTo={scrollTo} />
        </>
      )}
      {!isEnd && (
        <PlayerItemContent
          isForSharedToWeb={isForSharedToWeb}
          contentScrollRef={contentScrollRef}
          item={currentItem}
          isMouseMoveShowControls={isMouseMoveShowControls}
          toggleIsCaptionsAndApproveButtonsHidden={
            toggleIsCaptionsAndApproveButtonsHidden
          }
        />
      )}
      { isEnd && <PlaylistLastFrame setIsEnd={setIsEnd} setPrevItemNumber={setPrevItemNumber} closePlayer={closePlayer} />}
    </MainPlayerWrapper>
  );
};

export default NewPlayer;
