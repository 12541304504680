import React from 'react';
import { useDispatch } from 'react-redux';
import { actionOpenCreateComponent } from '../../../../redux/library/actions';
import Button2023 from '../../../Buttons/Button2023';
import SideBarTabs from '../../../../pages/Playlist/PlaylistTabs/SideBarTabs';
import SortAndView from '../SortAndView';
import { Divider, GroupIcon, Wrapper } from './StyledComponents';
import ButtonUpload from '../../../Buttons/ButtonUpload';
import { useLocalization } from '../../../../LocalizationContext';

const SecondaryComponent = (props) => {
  const dispatch = useDispatch();
  const { t } = useLocalization();

  const createNewComponent = () => {
    dispatch(actionOpenCreateComponent());
  };

  return (
    <GroupIcon newUPVMode={props.newUPVMode}>
      <Wrapper>
        <Button2023
          text={t.plusComponentUpT}
          width={110}
          height={props.lowMode ? 28 : 32}
          variant="secondary"
          handleButtonClick={createNewComponent}
        />
        <ButtonUpload
          dataParent={props.dataParent}
          newUPVMode={props.newUPVMode}
        />
        <Divider newUPVMode={props.newUPVMode} />
      </Wrapper>
      <SideBarTabs
        newUPVMode={props.newUPVMode}
        forSecondaryMenu
        isLibrary
        setIsShowFilter={() => {}}
        setShowNewSmartView={props.setShowNewSmartView}
        resetSmartview={props.resetSmartview}
        setSmartViewForEdit={props.setSmartViewForEdit}
        setChosenSmartView={props.setChosenSmartView}
        setChosenTag={props.setChosenTag}
      />
      <Divider ms newUPVMode={props.newUPVMode} />
      <SortAndView {...props} />
    </GroupIcon>
  );
};

export default SecondaryComponent;
