import React from 'react';
import styled from 'styled-components/macro';
import classNames from 'classnames/bind';
// import { ReactComponent as RoundedCrossSVG } from '../../../images/icons/close_28.svg';
import rawStyles from './elements.module.scss';
import { ReactComponent as TrashIcon } from '../../../images/icons/trash_Icon.svg';

import Tooltip from '../../../shared/Tooltips/Tooltip';
import { useLocalization } from '../../../LocalizationContext';

const DeleteButtonContainer = styled.div`
  width: 32px;
  height: 32px;
  border: 0.6px solid #ece2e2;
  border-radius: 32px;
  opacity: 0.5;
  padding-left: 7px;
  padding-top: 5px;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;
const DeleteButtonShell = styled.div`
  right: 10px;
  position: absolute;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  display: none;
  top: calc(50% - 40px);
  height: 80px;
  gap: 14px;
`;

const DeleteButtonBalancer = styled.div`
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  right:  29px;
  position: absolute;
  z-index: 100;
`;

const cx = classNames.bind(rawStyles);

const DeleteContentButton = ({ deleteHandler, children }) => {
  const { t } = useLocalization();
  return (
    <DeleteButtonBalancer>
      <DeleteButtonShell
        className={cx('rising_button_flex', 'rising_button')}
      >
        {children}

        <DeleteButtonContainer
          onClick={deleteHandler}
        >
          <Tooltip
            text={t.removeT}
            direction="down"
            place="ActionsTrash"
          >
            <TrashIcon />
          </Tooltip>
        </DeleteButtonContainer>

      </DeleteButtonShell>
    </DeleteButtonBalancer>
  );
};

export default DeleteContentButton;
