import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SearchInHeader from '../../MainHeader/SearchInHeader';
import Button2023 from '../../Buttons/Button2023';
import CloseRoundedBtnWithCross from '../../SharedComponents/CloseRoundedBtnWithCross';
import { convertDateForButton } from '../../../utils/dateConvert';
import DynamicCollectionDropListOptionTAGS from '../../DynamicCollectionCreator/DynamicCollectionDropListOptionTAGS';
import { DATA_PARENTS, LibCompSieveTypesTest, openModalType } from '../../../utils/constants';
import useComponentVisibleMouseDown from '../../../utils/hooks/useComponentVisibleMouseDown';
import DynamicCollectionDatePicker from '../../DynamicCollectionCreator/DynamicCollectionDatePicker';
import DynamicCollectionDropListOption from '../../DynamicCollectionCreator/DynamicCollectionDropListOption';
import { actionOpenModal } from '../../../redux/user/action';
import { actionEditDynamicCollection } from '../../../redux/dynamicCollection/action';
import { calcOption, getNameForTagsBTN, getNameForTypeBTN } from './helpers';
import { BTNGroupWrapper, Divider, MainWrapper } from './styled';
import { actionChangeSieveValue } from '../../../redux/library/actions';
import { useLocalization } from '../../../LocalizationContext';

const CreateSmartViewPanel = ({ ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useLocalization();

  const [refTags, isTagsOpened, setIsTagsOpened] = useComponentVisibleMouseDown(
    false,
    DATA_PARENTS.SideBar.tags,
  );
  const [refDatePicker, isDatePickerOpened, setIsDatePickerOpened] = useComponentVisibleMouseDown(false, DATA_PARENTS.SideBar.date);
  const [refTypes, isTypesOpened, setIsTypesOpened] = useComponentVisibleMouseDown(false, DATA_PARENTS.SideBar.fileType);

  const [tagsBTNName, setTagsBTNName] = useState('');
  const [dateBTNName, setDateBTNName] = useState('');
  const [typeBTNName, setTypeBTNName] = useState('');
  const [typesArr] = useState(calcOption(LibCompSieveTypesTest));

  const tags = useSelector((state) => state.user.tags);

  const exSetLibFilter = (params) => {
    props.setIgnoreSelectorType(false);
    props.setIsEdit(true);
    props.setLibFilter(params);
  };
  const exFilterSearch = (value) => {
    props.setIgnoreSelectorType(false);
    props.setIsEdit(true);
    props.setLibFilterSearch(value);
  };
  const clearAllFilters = (clearAll = true) => {
    const resetCondition = tagsBTNName !== 'TAGS'
      || dateBTNName !== 'DATE RANGE'
      || typeBTNName !== 'FILE TYPE'
      || !!props.libFilterSearch;
    if (resetCondition) {
      props.setIsEdit(false);
      props.setLibFilter({});
      props.setLibFilterSearch('');
      if (clearAll) {
        props.setIgnoreSelectorType(true);
      }
    }
  };

  const closeHandler = () => {
    clearAllFilters(false);
    props.setSmartViewForEdit({});
    props.setShowNewSmartView(false);
    dispatch(actionChangeSieveValue('[LibComponent]Pages', 'library'));
    if (history.location.pathname.includes('dynamic')) {
      history.push('/content/library/all');
    }
  };
  const toggleTags = () => setIsTagsOpened(!isTagsOpened);
  const toggleDatePicker = () => setIsDatePickerOpened(!isDatePickerOpened);
  const toggleTypes = () => setIsTypesOpened(!isTypesOpened);

  const openModal = () => {
    dispatch(
      actionOpenModal(openModalType.SmartViewModal, {
        libFilterSearch: props.libFilterSearch,
        libFilter: props.libFilter,
      }),
    );
  };
  const editCurrentSmartview = () => {
    dispatch(
      actionEditDynamicCollection({
        dynamicCollectionId: props.smartViewForEdit.id,
        name: props.smartViewForEdit.name,
        color: props.smartViewForEdit.color,
        filter: props.libFilter,
        libFilterSearch: props.libFilterSearch,
        iconKey: props.smartViewForEdit.iconKey,
      }),
    );
  };

  const save = (e) => {
    e.stopPropagation();
    if (props.smartViewForEdit.forEdit) {
      editCurrentSmartview();
    } else {
      openModal();
    }
    props.setShowNewSmartView(false);
    clearAllFilters(false);
    history.push('/content/library/all');
  };

  useEffect(() => {
    return () => {
      setIsTagsOpened(false);
    };
  }, []);

  useEffect(() => {
    setTagsBTNName(() => getNameForTagsBTN(props.libFilter.tags));
  }, [props.libFilter.tags]);

  useEffect(() => {
    setDateBTNName(convertDateForButton(props.libFilter));
  }, [props.libFilter]);

  useEffect(() => {
    setTypeBTNName(getNameForTypeBTN(props.libFilter.fileType));
  }, [props.libFilter.fileType]);

  useEffect(() => {
    props.setIgnoreSelectorType(false);
  }, []);

  return (
    <MainWrapper>
      <SearchInHeader
        libFilterSearch={props.libFilterSearch}
        setLibFilterSearch={exFilterSearch}
        forPlace="smart"
      />

      <BTNGroupWrapper>
        <Divider />
        <Button2023
          text={tagsBTNName}
          handleButtonClick={toggleTags}
          maxWidth={162}
          height={32}
          variant="primary"
          noMargins
          isActive={isTagsOpened}
        />
        {isTagsOpened && (
          <DynamicCollectionDropListOptionTAGS
            type="Tags"
            option={tags}
            setFilter={exSetLibFilter}
            active={isTagsOpened}
            filter={props.libFilter.tags}
            data={DATA_PARENTS.SideBar.tags}
            refParent={refTags}
          />
        )}
        <Button2023
          text={dateBTNName}
          handleButtonClick={toggleDatePicker}
          maxWidth={162}
          height={32}
          variant="primary"
          noMargins
          isActive={isDatePickerOpened}
        />
        {isDatePickerOpened && (
          <DynamicCollectionDatePicker
            setFilter={exSetLibFilter}
            active={isDatePickerOpened}
            data={DATA_PARENTS.SideBar.date}
            refParent={refDatePicker}
            toggleIsDateOpened={toggleDatePicker}
          />
        )}
        <Button2023
          text={typeBTNName}
          handleButtonClick={toggleTypes}
          maxWidth={162}
          height={32}
          variant="primary"
          noMargins
          isActive={isTypesOpened}
        />
        {isTypesOpened && (
          <DynamicCollectionDropListOption
            type="File Types"
            title={t.selectFileTypeT}
            option={typesArr}
            filter={props.libFilter.fileType}
            setFilter={exSetLibFilter}
            active={isTypesOpened}
            data={DATA_PARENTS.SideBar.fileType}
            refParent={refTypes}
          />
        )}
        <Divider />
        <Button2023
          text={t.resetUpT}
          handleButtonClick={clearAllFilters}
          width={58}
          height={32}
          noMargins
          variant="primary"
        />
        <Divider />
        <Button2023
          text={t.saveUpT}
          handleButtonClick={save}
          width={52}
          height={32}
          noMargins
          variant="secondary"
        />
        <CloseRoundedBtnWithCross
          moveTop={8}
          position="relative"
          left={12}
          onClick={closeHandler}
        />
      </BTNGroupWrapper>
    </MainWrapper>
  );
};
export default CreateSmartViewPanel;
