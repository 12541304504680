import React from 'react';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './index.module.scss';
import { actionCloseModal } from '../../redux/user/action';
import { actionRemoveImage } from '../../redux/playlists/action';
import { actionCreator } from '../../shared/redux/actionHelper';
import EditPlaylist from '../../redux/playlists/types';
import { useLocalization } from '../../LocalizationContext';

const cx = classNames.bind(styles);

const DeleteImageToPlaylist = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useLocalization();

  const isMaker = history.location.pathname.includes('/maker');

  const close = () => {
    dispatch(actionCloseModal());
  };
  const deleteImg = () => {
    if (isMaker) {
      dispatch(actionCreator(EditPlaylist.MakerDeleteImage));
    } else {
      dispatch(actionRemoveImage());
    }
    dispatch(actionCloseModal());
  };

  return (
    <div onClick={close} className={cx('modal_wrapper')}>
      <div
        onClick={(e) => e.stopPropagation()}
        className={cx('modal', 'DeleteImageToPlaylist')}
      >
        <div className={cx('title')}>{t.removeCoverQuestionT}</div>

        <div className={cx('description')}>
          {t.coverWillBeRemovedFromSmartFileT}
        </div>

        <div className={cx('group_buttons')}>
          <div onClick={close} className={cx('group_buttons_cancel')}>
            {t.cancelT}
          </div>
          <div
            data-cy="delete"
            onClick={deleteImg}
            className={cx('group_buttons_delete')}
          >
            {t.removeCoverT}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteImageToPlaylist;
