import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import classNames from 'classnames/bind';
import rawStyles from './index.module.scss';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import { Settings } from '../../../../redux/settings/types';
import { MAX_LENGTH_TEXTAREA } from '../../../../utils/SettingsHelpers/constants';
import { useLocalization } from '../../../../LocalizationContext';

const cn = classNames.bind(rawStyles);

const ProfileInputWrapper = ({
  inputType,
  isRequired,
  isTextarea,
  setFormTouched,
  placeholder = '',
  disabled = false,
}) => {
  const { inputs } = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const isShowError = !!inputs[inputType]?.errors?.length;
  const { t } = useLocalization();

  const onInputChange = (event, type) => {
    if (!type || type === 'textArea' && event.target.value.length <= 500) {
      dispatch(
        actionCreator(Settings.Profile.ChangeValue, {
          inputType,
          inputValue: event.target.value,
        }),
      );
      setFormTouched(true);
      dispatch(actionCreator(Settings.ValidateInputS, { inputType }));
    }
  };

  return (
    <div className={cn('input_container', { marginleft22: inputType === 'lastName' })}>
      {inputType !== 'lastName' && (
        <div className={cn('label', { marginBottom48: inputType === 'bio' })}>
          {t[`${inputType}T`]}
          {isRequired && <div className={cn('required')}>*</div>}
        </div>
      )}

      {!isTextarea && (
        <div className={cn('input_border')}>
          <input
            type="text"
            className={cn('input')}
            onChange={onInputChange}
            value={inputs[inputType]?.value}
            placeholder={placeholder}
            disabled={disabled}
          />
        </div>
      )}
      {!!isTextarea && (
        <div className={cn('textarea_border')}>
          <textarea
            className={cn('textarea')}
            onChange={(e) => onInputChange(e, 'textArea')}
            value={inputs[inputType]?.value}
            placeholder={placeholder}
          />
          <div className={cn('textarea_counter')}>{inputs[inputType]?.value.length}/{MAX_LENGTH_TEXTAREA} </div>
        </div>
      )}

      <div className={cn('error_wrapper', { show: isShowError, second_on_line: inputType === 'lastName' })}>
        <div className={cn('error_message')}>
          {!!inputs[inputType]?.errors?.length && inputs[inputType]?.errors[0]?.message}
        </div>
      </div>
    </div>
  );
};

export default ProfileInputWrapper;
