import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames/bind';
import { actionOpenModal } from '../../../redux/user/action';
import ContactAvatar from './CalculatedContactAvatar';
import { convertDateContact } from '../../../utils/dateConvert';
import { ReactComponent as HoverIconSvg } from '../../../images/2023/svg/pencil.svg';
import rawStyles from './MemberContent.module.scss';
import { openModalType } from '../../../utils/constants';
import { useLocalization } from '../../../LocalizationContext';

const cn = classNames.bind(rawStyles);

const ContactPopup = ({ item }) => {
  const dispatch = useDispatch();
  const { t } = useLocalization();

  const editContact = () => dispatch(actionOpenModal(openModalType.EditContact,
    { memberId: item.inviteUser?.id ?? item.user?.id },
  ));

  const parsedCreateDate = convertDateContact(item.inContact?.createDate);
  return (
    <div className={cn('popup-wrapper')}>
      <div className={cn('popup-wrapper_header')}>
        <ContactAvatar item={item} isPopup />
        <p>{item.name}</p>
        <div className={cn('popup-wrapper_header_svg-wrapper')} onClick={editContact}>
          <HoverIconSvg />
        </div>
      </div>
      <div className={cn('popup-wrapper_description')}>
        <div className={cn('popup-wrapper_description_item')}>
          <h4>{t.mailT}</h4>
          <p> <a href={`mailto:${item.email}`}>{item.email}</a> </p>
        </div>
        <div className={cn('popup-wrapper_description_item')}>
          <h4>{t.phoneT}</h4>
          <p>{item.inContact?.phone}</p>
        </div>
      </div>
      <div className={cn('divider')} />
      <div className={cn('popup-wrapper_body')}>
        <div className={cn('popup-wrapper_body_item')}>
          <h4>{t.memberSinceT}</h4>
          <p>{item.inContact?.createDate ? parsedCreateDate : '-'}</p>
        </div>
        <div className={cn('popup-wrapper_body_item')}>
          <h4>{t.organizationT}</h4>
          <p>{item.inContact?.organization ?? '-'}</p>
        </div>
        <div className={cn('popup-wrapper_body_item')}>
          <h4>{t.functionT}</h4>
          <p>{item.inContact?.function ?? '-'}</p>
        </div>
      </div>
    </div>
  );
};

export default ContactPopup;
