import React, { useEffect, useState } from 'react';
import { ReactComponent as SpinnerSvg } from '../../images/icons/spinner.svg';
import { useLocalization } from '../../LocalizationContext';

const LinkPreviewBlock = ({ data, editorWR }) => {
  const [imageUrl, setImageUrl] = useState('');
  const { t } = useLocalization();

  const openInNewTab = (e) => {
    e.stopPropagation();

    let url = data?.url;
    if (url) {
      if (!url.startsWith('https://') && !url.startsWith('http://')) url = `http://${url}`;
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    }
  };

  useEffect(() => {
    if (data?.images && Array.isArray(data?.images)) {
      const test = data?.images.find((i) => i.endsWith('.jpg'));
      setImageUrl(test || data?.images[0]);
    } else if (typeof data?.images === 'string') setImageUrl(data?.images);
  }, [data]);

  if (!data?.title) {
    return (
      <>
        <div onClick={openInNewTab} className="embed_website">
          <div className="loader_image_wrapper">
            <SpinnerSvg className="loader_image" />
          </div>
          <div className="embed_website_text">
            <div className="embed_website_loading">{t.loadingPreviewNoDotsT}</div>
            <div className="embed_website_url">{data?.url || ''}</div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div ref={editorWR} onClick={openInNewTab} className="embed_website">
        <div className="preview_image_wrapper">
          {data?.images.length && (
            <img className="preview_image" src={imageUrl} alt="preview" />
          )}
        </div>
        <div className="embed_website_text">
          <div className="embed_website_h1">{data?.title}</div>
          {data?.description && (
            <div className="embed_website_description">{data?.description}</div>
          )}
          <div className="embed_website_url">{data?.url || ''}</div>
        </div>
      </div>
    </>
  );
};

export default LinkPreviewBlock;
