import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isRoleInPlaylist } from '../../../../utils/permissions';
import { DATA_PARENTS, MessageType, emptyArr, openModalType } from '../../../../utils/constants';

import { actionOpenModal } from '../../../../redux/user/action';
import { actionShowMessage } from '../../../../redux/support/action';
import { actionUnPublishSPlaylistS } from '../../../../redux/playlists/action';
import { actionShowLibraryWidget } from '../../../../redux/currentPage/action';

import Button2023 from '../../../../components/Buttons/Button2023';
import { ReactComponent as SettingsSvg } from '../../../../images/2023/svg/settings.svg';
import { SettingsWrapper, ShareWrapper } from './styled';
import Tooltip from '../../../../shared/Tooltips/Tooltip';
import { useLocalization } from '../../../../LocalizationContext';

const ShareButtons = ({ forCutHeader, moveLeft }) => {
  const dispatch = useDispatch();
  const [buttonText, setButtonText] = useState('');
  const { t } = useLocalization();

  const {
    isPublish,
    currentRole,
    id,
    wrapperId,
    isShowLibraryWidget,
    singleUserShareState,
    shareState: { isShareToWeb, usersToSharing = emptyArr } } = useSelector(state => state.currentPage);

  const { requestSpinner } = useSelector((state) => state.support);
  const isHasShare = !!Object.values(singleUserShareState).length
  || !!usersToSharing?.length || isShareToWeb || isPublish;
  const { isOwner } = isRoleInPlaylist;
  const isButtonsBlocked = !!requestSpinner && requestSpinner !== 'init' && requestSpinner === id;
  const variant = isButtonsBlocked ? 'secondarySharedPlaylistDisabled' : 'gradientGreenSquare';
  const tooltipText = isHasShare ? t.unshareThisSmartFileT : t.shareThisSmartFileT;

  const unshare = useCallback((e) => {
    e.stopPropagation();
    dispatch(
      actionOpenModal(openModalType.ConfirmModalUniversal, {
        title: t.shareUpT,
        subject: t.unshareThisSmartFileQuestionT,
        description: t.unshareDescriptionT,
        confirm: () => {
          dispatch(actionUnPublishSPlaylistS(id, wrapperId));
          dispatch(
            actionShowMessage({
              type: MessageType.Regular,
              text: t.smartFileUnsharedT,
            }),
          );
        },
        cancelText: t.cancelUpT,
        okText: t.unshareUpT,
        type: 'unshare',
      }),
    );
  }, [id, wrapperId, dispatch]);

  const enableShare = useCallback((e) => {
    e.stopPropagation();
    dispatch(
      actionOpenModal(openModalType.Share, { itemType: 'playlist', isMakerStyles: true }),
    );
  }, [dispatch]);


  const onButtonClickHandler = useCallback((e) => {
    if (buttonText === t.shareUpT) {
      enableShare(e);
    } else {
      unshare(e);
    }
  }, [buttonText, enableShare, unshare]);

  const openSettings = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!isShowLibraryWidget) {
      dispatch(actionShowLibraryWidget(!isShowLibraryWidget, 'settings'));
    }
  };

  useEffect(() => {
    let text = isHasShare ? t.unshareUpT : t.shareUpT;
    if (
      !!requestSpinner
      && requestSpinner !== 'init'
      && requestSpinner === id
    ) {
      text = t.sharingUpT;
    }
    setButtonText(text);
  }, [requestSpinner, isHasShare, id]);

  return (
    <ShareWrapper forCutHeader={forCutHeader} moveLeft={moveLeft} isShowLibraryWidget={isShowLibraryWidget}>
      <Tooltip
        text={tooltipText}
        direction={forCutHeader ? 'up' : 'down'}
      >
        <Button2023
          variant={variant}
          derivedParentData={DATA_PARENTS.PlaylistBuilderLibrary}
          height={36}
          width={buttonText === t.shareUpT ? 79 : 90}
          text={buttonText}
          handleButtonClick={onButtonClickHandler}
          isDisabled={isButtonsBlocked}
          isCut
        />
      </Tooltip>
      {isOwner[currentRole] && (
        <Tooltip
          data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
          text={t.manageThisSmartFileT}
          direction={forCutHeader ? 'up' : 'down'}
        >
          <SettingsWrapper onClick={openSettings}>
            <SettingsSvg
              data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
              data-place="library"
            />
          </SettingsWrapper>
        </Tooltip>
      )}
    </ShareWrapper>
  );
};

export default ShareButtons;
