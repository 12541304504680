import React from 'react';
import {
  CoverDDOptionWrapper,
  CoverDDTitle,
  CoverDropDownWrapper,
  ColorPicker,
  HalfCircle,
  ColorPickerText, ColorCheck,
} from './styled';
import { ReactComponent as CheckedSvg } from '../../../images/icons/icon_16/checked_color.svg';
import { stopPropagation, folderColors } from '../../../utils/constants';
import { useLocalization } from '../../../LocalizationContext';

const DropDownCover = ({
  nestedRef,
  pickColor,
  dataParentDropDown,
  activeColor,
}) => {
  const { t } = useLocalization();
  const position = {
    top: -25,
    left: 141,
  };
  return (
    <CoverDropDownWrapper
      isColors
      position={position}
      data-parent={dataParentDropDown}
      ref={nestedRef}
      onClick={stopPropagation}
    >
      <CoverDDTitle>{t.selectColorLowT}</CoverDDTitle>
      {Object.keys(folderColors.extra).map(color => (
        <CoverDDOptionWrapper isColors onClick={(e) => pickColor(e, color)} key={color}>
          <ColorPicker isExtraColors>
            <HalfCircle color={folderColors.extra[color].light} />
            <HalfCircle color={folderColors.extra[color].dark} isRightSide />
            <ColorPickerText>{color}</ColorPickerText>
            {color === activeColor && <ColorCheck> <CheckedSvg /> </ColorCheck> }

          </ColorPicker>
        </CoverDDOptionWrapper>
      ))}
    </CoverDropDownWrapper>
  );
};

export default DropDownCover;
