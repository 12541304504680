import styled from 'styled-components/macro';

import React from 'react';
import { DATA_PARENTS } from '../../../../../utils/constants';
import { MiddleDiv } from '../SettingsRightSideBar/styled';
import { useLocalization } from '../../../../../LocalizationContext';


const UPVCommentsWrapper = styled.div`
  width: 360px;
  height: 100vh;
  background: #ffffff;
  filter: drop-shadow(-4px 0px 12px rgba(236, 226, 226, 0.5));
  padding-top: 26px;
  transition: all ease-in-out 0.4s;
`;

const CommentsRightSideBar = ({ wrapperRef }) => {
  const { t } = useLocalization();
  return (
    <UPVCommentsWrapper
      wrapperRef={wrapperRef}
      data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary}`}
    >
      <MiddleDiv>{t.commentsComingSoonT}</MiddleDiv>
    </UPVCommentsWrapper>
  );
};

export default CommentsRightSideBar;
