import React from 'react';
import styles from './index.module.css';
import { ReactComponent as ShortSvg } from '../../images/icons/icon_20/short answer.svg';
import { ReactComponent as LongSvg } from '../../images/icons/icon_20/long answer.svg';
import { ReactComponent as CheckSvg } from '../../images/icons/icon_20/checkbox on.svg';
import { ReactComponent as MultipleAnsSvg } from '../../images/icons/icon_20/radio on.svg';
import { ReactComponent as NumberSvg } from '../../images/icons/icon_20/hashtag.svg';
import { ReactComponent as DateSvg } from '../../images/icons/icon_20/calendar.svg';
import { ReactComponent as RatingSvg } from '../../images/icons/icon_20/favorites.svg';
import { ReactComponent as ScaleSvg } from '../../images/icons/icon_20/scale.svg';
import { ReactComponent as UploadFileSvg } from '../../images/icons/icon_20/upload.svg';
import { ReactComponent as UploadImageSvg } from '../../images/icons/icon_20/image.svg';
import { ReactComponent as VoiceSvg } from '../../images/icons/icon_20/mic.svg';
import { ReactComponent as FillInBlankSvg } from '../../images/icons/icon_20/fill in the blank.svg';
import { ReactComponent as ActionButtonSvg } from '../../images/icons/icon_20/ActionButton.svg';
import { ReactComponent as ApproveButtonSvg } from '../../images/icons/icon_20/ApproveButton.svg';
import { BlockTypes } from '../../utils/constants';
import { useLocalization } from '../../LocalizationContext';

const CreateFormOption = ({ parentRef, ...props }) => {
  const { t } = useLocalization();
  const calculatePosition = () => {
    if (parentRef.current) {
      const height = document.documentElement.clientHeight;
      const positionY = parentRef.current.getBoundingClientRect().y;
      const heightElement = 176;
      const isOpenInUp = height > heightElement + positionY;
      if (isOpenInUp) return 24;
      return -180;
    }
    return 0;
  };
  const divStyle = {
    top: calculatePosition(),
  };

  const addApproveButton = () => {
    props.selectAddEditableBlock(BlockTypes.approveButton);
  };
  const addShortText = () => {
    props.selectAddEditableBlock(BlockTypes.shortText);
  };

  // const addActionButton = () => {
  //   props.selectAddEditableBlock('actionButton');
  // };

  return (
    <div style={divStyle} className={styles.dropdown_create}>
      <div className={styles.text}> {t.formUpT} </div>

      <div className={styles.dropdown_order}>
        <div className={styles.stolb}>
          <div onClick={addShortText} className={styles.dropdown_create__item}>
            <ShortSvg />
            <div>{t.shortTextT}</div>
          </div>

          <div
            className={`${styles.dropdown_create__item_blocked} ${styles.dropdown_create__item}`}
          >
            <LongSvg />
            <div>{t.longTextT}</div>
          </div>

          <div
            className={`${styles.dropdown_create__item_blocked} ${styles.dropdown_create__item}`}
          >
            <CheckSvg />
            <div>{t.CheckboxesT}</div>
          </div>

          <div
            className={`${styles.dropdown_create__item_blocked} ${styles.dropdown_create__item}`}
          >
            <MultipleAnsSvg />
            <div>{t.multipleAnswersT}</div>
          </div>

          <div
            className={`${styles.dropdown_create__item_blocked} ${styles.dropdown_create__item}`}
          >
            <ActionButtonSvg />
            <div>{t.actionButtonT}</div>
          </div>
        </div>

        <div className={styles.stolb}>
          <div
            className={`${styles.dropdown_create__item_blocked} ${styles.dropdown_create__item}`}
          >
            <NumberSvg />
            <div>{t.numberT}</div>
          </div>

          <div
            className={`${styles.dropdown_create__item_blocked} ${styles.dropdown_create__item}`}
          >
            <DateSvg />
            <div>{t.dateT}</div>
          </div>

          <div
            className={`${styles.dropdown_create__item_blocked} ${styles.dropdown_create__item}`}
          >
            <RatingSvg />
            <div>{t.ratingT}</div>
          </div>

          <div
            className={`${styles.dropdown_create__item_blocked} ${styles.dropdown_create__item}`}
          >
            <ScaleSvg />
            <div>{t.scaleT}</div>
          </div>
          <div
            onClick={addApproveButton}
            className={`${styles.dropdown_create__item}`}
          >
            <ApproveButtonSvg />
            <div>{t.approveButtonT}</div>
          </div>
        </div>

        <div className={styles.stolb}>
          <div
            className={`${styles.dropdown_create__item_blocked} ${styles.dropdown_create__item}`}
          >
            <UploadFileSvg />
            <div>{t.uploadFileT}</div>
          </div>

          <div
            className={`${styles.dropdown_create__item_blocked} ${styles.dropdown_create__item}`}
          >
            <UploadImageSvg />
            <div>{t.uploadPictureT}</div>
          </div>

          <div
            className={`${styles.dropdown_create__item_blocked} ${styles.dropdown_create__item}`}
          >
            <VoiceSvg />
            <div>{t.voiceT}</div>
          </div>

          <div
            className={`${styles.dropdown_create__item_blocked} ${styles.dropdown_create__item}`}
          >
            <FillInBlankSvg />
            <div>{t.fillInTheBlankT}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateFormOption;
