import React from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import useComponentVisible from '../../../utils/hooks/useComponentVisible';
import { useLocalization } from '../../../LocalizationContext';

const ChannelWrapper = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #484343;
  &:hover {
    text-decoration-line: underline;
  }
`;

const ChannelTipWrapper = styled.div`
  position: absolute;
  z-index: 1;
  width: 214px;
  background: #ffffff;
  border: 0.6px solid #ece2e2;
  box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.25);
  border-radius: 12px;
  padding-bottom: 4px;
  padding-top: 16px;
  top: 38px;
`;
const ChannelRowWrapper = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #484343;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 8px;
  &:hover {
    background: #ffeebf;
  }
`;

const ChannelTitleTipWrapper = styled.div`
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  padding-left: 16px;
  text-transform: uppercase;
  color: #d3c8c8;
  margin-bottom: 10px;
`;
const ChannelsCell = ({ channels }) => {
  const history = useHistory();
  const { t } = useLocalization();
  const [refMenu, isDropDownVisible, setIsDropDownVisible] = useComponentVisible(null, 'deadKitten');
  if (!channels?.length) {
    return <div />;
  }
  if (channels.length === 1) {
    return (
      <ChannelWrapper
        onClick={() => history.push(`/channel/${channels[0].id}`)}
      >
        {channels[0].name}
      </ChannelWrapper>
    );
  }

  return (
    <>
      <ChannelWrapper onClick={() => setIsDropDownVisible(!isDropDownVisible)}>
        {`${channels.length} channels`}
      </ChannelWrapper>
      {isDropDownVisible && (
        <ChannelTipWrapper ref={refMenu} data-parent="deadKitten">
          <ChannelTitleTipWrapper>{t.listOfChannelsLowT}</ChannelTitleTipWrapper>
          {channels.map((ch) => (
            <ChannelRowWrapper
              onClick={() => history.push(`/channel/${ch.id}`)}
            >
              {ch.name}
            </ChannelRowWrapper>
          ))}
        </ChannelTipWrapper>
      )}
    </>
  );
};

export default ChannelsCell;
