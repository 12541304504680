import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moduleStyles from '../../../pages/Library/Library.module.scss';
import googleDrive16 from '../../../images/icons/google_drive_16.svg';
import dropbox16 from '../../../images/icons/dropbox_16.svg';
import upload32 from '../../../images/icons/upload_32.svg';
import ProgressBar from '../ProgressBar';
import { DATA_PARENTS, MessageType } from '../../../utils/constants';
import AddGoogleFile from './AddGoogleFile';
import AddDropboxFile from './AddDropboxFile';
import Button2023 from '../../Buttons/Button2023';
import { actionCreator } from '../../../shared/redux/actionHelper';
import { LibraryComponents } from '../../../redux/library/types';
import { actionCheckLinkForLibraryComponent } from '../../../redux/library/actions';
import { actionShowMessage } from '../../../redux/support/action';
import { useLocalization } from '../../../LocalizationContext';

const styles = classNames.bind(moduleStyles);
const DnDZone = styled.div``;

const AddFile = ({
  wrapperRef,
  setIsAddFileMenuVisible,
  ...props
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useLocalization();

  const [activeOption, setActiveOption] = useState('computer');
  const close = () => {
    if (setIsAddFileMenuVisible) setIsAddFileMenuVisible(false);
  };
  const [urlText, setUrl] = useState('');
  const changeUrl = (e) => {
    setUrl(e.target.value);
  };
  const handleError = () => {
    dispatch(
      actionShowMessage({
        type: MessageType.InvalidLink,
      }),
    );
  };
  const computeActiveOption = (option) => {
    return option === activeOption ? 'active' : '';
  };
  const onCreateButtonClick = () => {
    if (!urlText) {
      handleError();
      return;
    }
    if (
      urlText
      && urlText.startsWith('<iframe')
      && urlText.endsWith('</iframe>')
    ) {
      const newId = uuidv4();
      dispatch(
        actionCreator(LibraryComponents.CreateEmbedLibraryComponent, {
          iframeText: urlText,
          newId,
          history,
        }),
      );
      close();
      return;
    }
    dispatch(actionCheckLinkForLibraryComponent(urlText, close, handleError));
  };
  return (
    <div
      ref={wrapperRef}
      data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
      className={styles('add_file_popUp', {
        addFile_wrapper_emptyScreen: props.place === 'content',
        addFile_MainSlider_position: props.place === 'upload_button',
        global_modal: props.place === 'GlobalModal',
        PlaylistBuilderAddFile:
          props.place === DATA_PARENTS.PlaylistBuilderAddFileTab,
      })}
    >
      <div className={styles('title')}>{t.uploadFileUpT}</div>

      <div className={styles('options_container')}>
        <div
          data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
          className={styles('options')}
        >
          <div
            data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
            className={styles(
              'text_container',
              'opt',
              `${computeActiveOption('computer')}`,
            )}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setActiveOption('computer');
            }}
          >
            <div
              className={styles('text', `${computeActiveOption('computer')}`)}
            >
              {t.computerT}
            </div>
          </div>
          <div
            data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
            className={styles(
              'text_container',
              'opt',
              `${computeActiveOption('embed')}`,
            )}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setActiveOption('embed');
            }}
          >
            <div className={styles('text', `${computeActiveOption('embed')}`)}>
              {' '}
              {t.embedLinkT}
            </div>
          </div>

          <div
            data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
            className={styles(
              'text_container',
              'opt',
              `${computeActiveOption('google')}`,
            )}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setActiveOption('google');
            }}
          >
            <img
              // onClick={() => props.setIsShowFilter && props.setIsShowFilter(!props.isShowFilter)}
              className={styles('icon')}
              src={googleDrive16}
              alt="google_drive"
            />
            <div className={styles('text', `${computeActiveOption('google')}`)}>
              {' '}
              {t.googleDriveT}
            </div>
          </div>

          <div
            data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
            className={styles(
              'text_container',
              'opt',
              `${computeActiveOption('dropbox')}`,
              'last',
            )}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setActiveOption('dropbox');
            }}
          >
            <img
              // onClick={() => props.setIsShowFilter(!props.isShowFilter)}
              className={styles('icon')}
              src={dropbox16}
              alt="dropbox"
            />
            <div
              className={styles('text', `${computeActiveOption('dropbox')}`)}
            >
              {' '}
              {t.dropboxT}
            </div>
          </div>
        </div>
      </div>

      {activeOption === 'computer' && (
        <div
          data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
          className={styles('drop_section')}
        >
          <DnDZone
            data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
            className={styles('add_file_drop_zone')}
          >
            <div
              data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
              className={styles('dnd_placeholder')}
            >
              <img
                // onClick={() => props.setIsShowFilter(!props.isShowFilter)}
                className={styles('icon')}
                src={upload32}
                alt="upload_file"
              />
              <div className={styles('dnd_text')}>
                {t.dragDropToUploadFileT}
              </div>
              <div className={styles('dnd_text', 'ms')}>{t.orUpT}</div>
            </div>
          </DnDZone>
          <div
            data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
            className={styles('file_picker')}
            onClick={props.onButtonClick}
          >
            {t.browseFileUpT}
          </div>
        </div>
      )}
      {activeOption === 'embed' && (
        <div
          data-parent={`miniPage${DATA_PARENTS.PlaylistBuilderLibrary}`}
          className={styles('embed_section')}
        >
          <div
            data-parent={`miniPage${DATA_PARENTS.PlaylistBuilderLibrary}`}
            className={styles('embed_input_wrapper')}
          >
            <input
              onChange={changeUrl}
              value={urlText}
              type="text"
              data-parent={`miniPage${DATA_PARENTS.PlaylistBuilderLibrary}`}
              className={styles('embed_input')}
              placeholder={t.dropboxInputPlaceholderT}
            />
          </div>
          <Button2023
            width={45}
            height={32}
            variant="secondary"
            text={t.addUpT}
            noMargins
            derivedParentData={DATA_PARENTS.PlaylistBuilderLibrary}
            handleButtonClick={onCreateButtonClick}
          />
        </div>
      )}
      {activeOption === 'google' && (
        <AddGoogleFile
          setIsVisible={props.setIsAddFileMenuVisible}
          isMultiselect
          viewedTypes="DOCS"
        />
      )}
      {activeOption === 'dropbox' && (
        <AddDropboxFile setIsVisible={props.setIsAddFileMenuVisible} />
      )}
      <ProgressBar setIsAddFileMenuVisible={props.setIsAddFileMenuVisible} />
    </div>
  );
};
export default AddFile;
