import React, { useEffect } from 'react';
import { ReactComponent as CalloutSvg } from '../../images/icons/icon_20/callout.svg';
import styles from './index.module.css';

import { ReactComponent as ImageSvg } from '../../images/icons/icon_20/image.svg';
import { ReactComponent as VideoSvg } from '../../images/icons/icon_20/play.svg';
import { ReactComponent as Title1Svg } from '../../images/icons/icon_20/title_1.svg';
import { ReactComponent as FormSvg } from '../../images/icons/icon_20/form.svg';
import { ReactComponent as NumListSvg } from '../../images/icons/icon_20/list-number.svg';
import { ReactComponent as BullListSvg } from '../../images/icons/icon_20/list-bullet.svg';
import { ReactComponent as TableSvg } from '../../images/icons/icon_20/table.svg';
import { ReactComponent as LineSvg } from '../../images/icons/icon_20/line.svg';
import { ReactComponent as LinkPageSvg } from '../../images/icons/icon_20/link-ext.svg';
import CreateBlockOption from '../DropDownOption/CreateBlockOption';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import CreateFormOption from '../DropDownOption/CreateFormOption';
import AddPageModal from '../AddPageModal/AddPageModal';
import useComponentVisibleMouseDown from '../../utils/hooks/useComponentVisibleMouseDown';
import { BlockTypes } from '../../utils/constants';
import { useLocalization } from '../../LocalizationContext';

const CreateBlockButton = ({
  selectAddEditableBlock,
  isShowBrowseBlocks,
  update,
  playlistField,
  componentId,
  isBuilderTextElement,
}) => {
  const [
    refCreateOptionVisible,
    isCreateOptionVisible,
    setIsCreateOptionVisible,
  ] = useComponentVisible(false);
  const [refMoreVisible, isMoreVisible, setIsMoreVisible] = useComponentVisible(false);
  const [refShowBlock, isShowBlock, setIsShowBlock] = useComponentVisible(isShowBrowseBlocks);
  const [refAddPage, isAddPage, setIsAddPage] = useComponentVisibleMouseDown(false);
  const { t } = useLocalization();

  useEffect(() => {
    setTimeout(() => {
      setIsShowBlock(
        isShowBrowseBlocks || isMoreVisible || isCreateOptionVisible,
      );
    }, 0);
  }, [isMoreVisible, isCreateOptionVisible, isShowBrowseBlocks]);

  const isShowAdditionalOptionsOfAddContent = isShowBrowseBlocks || isMoreVisible || isCreateOptionVisible || isShowBlock;
  const createBlock = (type) => (e) => {
    e.stopPropagation();
    if (type === 'page') {
      setIsAddPage(true);
    } else {
      selectAddEditableBlock(type);
      setTimeout(() => setIsCreateOptionVisible(false), 0);
    }
  };

  if (isBuilderTextElement) {
    return <></>;
  }

  return (
    <div ref={refShowBlock}>
      {isShowAdditionalOptionsOfAddContent && (
        <div className={styles.wrapper_change_component}>
          {!isMoreVisible && !isCreateOptionVisible && (
            <>
              <div
                onMouseDown={createBlock(BlockTypes.title)}
                className={styles.change_component}
              >
                <Title1Svg />
              </div>

              <div
                onMouseDown={createBlock(BlockTypes.unordered_list_item0)}
                className={styles.change_component}
              >
                <BullListSvg />
              </div>

              <div
                onMouseDown={createBlock(BlockTypes.ordered_list_item0)}
                className={styles.change_component}
              >
                <NumListSvg />
              </div>

              <div
                onMouseDown={createBlock(BlockTypes.callout)}
                className={styles.change_component}
              >
                <CalloutSvg />
              </div>

              <div
                onMouseDown={createBlock(BlockTypes.lineSeparator)}
                className={`${styles.change_component}`}
              >
                <LineSvg />
              </div>

              <div
                className={`${styles.change_component} ${styles.change_component_disable}`}
              >
                <TableSvg />
              </div>

              <div
                onMouseDown={createBlock(BlockTypes.image)}
                className={styles.change_component}
              >
                <ImageSvg />
              </div>

              <div
                onMouseDown={createBlock(BlockTypes.video)}
                className={styles.change_component}
              >
                <VideoSvg />
              </div>

              <div
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  // createBlock(BlockTypes.page)(e);
                }}
                // onMouseDown={(e) => {
                //   e.preventDefault();
                //   e.stopPropagation();
                // }}
                className={`${styles.change_component} ${
                  styles.change_component_disable
                } ${isAddPage ? styles.change_component_active : ''}`}
              >
                <LinkPageSvg />
              </div>
            </>
          )}

          <div
            ref={refCreateOptionVisible}
            onMouseDown={() => setIsCreateOptionVisible(true)}
            className={`${styles.change_component} 
              ${styles.change_component_text} 
              ${isCreateOptionVisible ? styles.change_component_active : ''}`}
          >
            <div>{t.moreUpT}</div>
            {isCreateOptionVisible && (
              <CreateBlockOption
                selectAddEditableBlock={selectAddEditableBlock}
                setIsCreateOptionVisible={setIsCreateOptionVisible}
                parentRef={refCreateOptionVisible}
                setIsAddPage={setIsAddPage}
                setIsComponentVisible={setIsCreateOptionVisible}
              />
            )}
          </div>

          {!playlistField && (
            <>
              <div className={styles.separate} />

              <div
                ref={refMoreVisible}
                onMouseDown={() => setIsMoreVisible(true)}
                className={`${styles.change_component} 
              ${styles.change_component_text} 
              ${styles.change_component_form} 
              ${isMoreVisible ? styles.change_component_active : ''}`}
              >
                <FormSvg />
                <div>{t.formUpT}</div>
                {isMoreVisible && (
                  <CreateFormOption
                    selectAddEditableBlock={selectAddEditableBlock}
                    parentRef={refMoreVisible}
                    update={update}
                    componentId={componentId}
                  />
                )}
              </div>
            </>
          )}
        </div>
      )}
      {isAddPage && (
        <AddPageModal
          refAddPage={refAddPage}
          selectAddEditableBlock={(item) => {
            selectAddEditableBlock('page', item);
            setIsAddPage(false);
          }}
        />
      )}
    </div>
  );
};

export default CreateBlockButton;
