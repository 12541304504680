import React from 'react';
import classNames from 'classnames/bind';
import { ReactComponent as PlusSvg } from '../../images/icons/thin_plus_20.svg';
import { ReactComponent as FitSvg } from '../../images/icons/fit_items_20.svg';
import { ReactComponent as MinusSvg } from '../../images/icons/minus_20.svg';

import styles from './index.module.scss';
import { DATA_PARENTS } from '../../utils/constants';
import Tooltip from '../Tooltips/Tooltip';
import { useLocalization } from '../../LocalizationContext';

const cn = classNames.bind(styles);
const eventPrevent = e => e.preventDefault();
const ZoomControls = ({ onZoomDefaultClick, onZoomOutClick, onZoomInClick, tooltipDirection = 'down' }) => {
  const { t } = useLocalization();
  return (
    <div
      className={cn('footer__section')}
      data-parent={DATA_PARENTS.TableOfContents}
    >
      <Tooltip text={t.zoomInT} direction={tooltipDirection} place="ZoomControls">
        <div
          className={cn('item', 'footer__item')}
          onClick={onZoomInClick}
          onMouseDown={eventPrevent}
          data-parent={DATA_PARENTS.TableOfContents}
        >
          <PlusSvg />
        </div>
      </Tooltip>
      <Tooltip
        text={t.backToDefaultSizeT}
        direction={tooltipDirection}
        place="ZoomControls"
      >
        <div
          className={cn('item', 'footer__item')}
          onClick={onZoomDefaultClick}
          onMouseDown={eventPrevent}
          data-parent={DATA_PARENTS.TableOfContents}
        >
          <FitSvg />
        </div>
      </Tooltip>
      <Tooltip text={t.zoomOutT} direction={tooltipDirection} place="ZoomControls">
        <div
          className={cn('item', 'footer__item')}
          onClick={onZoomOutClick}
          onMouseDown={eventPrevent}
          data-parent={DATA_PARENTS.TableOfContents}
        >
          <MinusSvg />
        </div>
      </Tooltip>
    </div>
  );
};

export default ZoomControls;
