import React, { useState } from 'react';
import { ReactComponent as ChevronSVG } from '../../../images/icons/chevron.svg';
import {
  ButtonBlockWrapper,
  ButtonWrapper,
  ChannelListWrapper,
  ChannelRowWrapper,
  DropDownWrapper,
} from './styled';
import { DEFAULT_TITLE } from '../../../utils/constants';
import { useLocalization } from '../../../LocalizationContext';

const ButtonDropDown = ({ channelsArr, ...props }) => {
  const { t } = useLocalization();
  const [isDropDownOpened, setIsDropDownOpened] = useState(false);

  const handleButtonClick = (title) => {
    if (props.dropDownMode) {
      setIsDropDownOpened(!isDropDownOpened);
    } else {
      props.setActiveChannel(title);
    }
  };

  if (!channelsArr?.length) {
    return <></>;
  }
  return (
    <>
      <ButtonBlockWrapper>
        <ButtonWrapper
          isChosen={
            props.dropDownMode
              ? true
              : channelsArr[0].name === props.activeChannel.name
          }
          isDropDown={props.dropDownMode}
          marginBot={props.marginBot}
          onClick={() => handleButtonClick(channelsArr[0])}
        >
          {(props.dropDownMode
            ? props.activeChannel.name
            : channelsArr[0].name) || t.defaultChannelTitleT}{' '}
          <ChevronSVG />
        </ButtonWrapper>
        {!props.dropDownMode && channelsArr.length === 2 && (
          <ButtonWrapper
            isChosen={channelsArr[1].id === props.activeChannel.id}
            marginBot={props.marginBot}
            onClick={() => handleButtonClick(channelsArr[1])}
          >
            {channelsArr[1].name || t.defaultChannelTitleT}
          </ButtonWrapper>
        )}
      </ButtonBlockWrapper>

      {props.dropDownMode && isDropDownOpened && (
        <DropDownWrapper>
          <div> {t.listOfChannelsUpT}</div>

          <ChannelListWrapper>
            {channelsArr.map((it) => {
              return (
                <>
                  {it && (
                    <ChannelRowWrapper
                      active={it.id === props.activeChannel.id}
                      onClick={() => {
                        props.setActiveChannel(it);
                        setIsDropDownOpened(false);
                      }}
                    >
                      {it.name || t.defaultChannelTitleT}
                    </ChannelRowWrapper>
                  )}
                </>
              );
            })}
          </ChannelListWrapper>
        </DropDownWrapper>
      )}
    </>
  );
};

export default ButtonDropDown;
