import React from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { ReactComponent as AlertSvg } from '../../images/icons/player/alert_15_16.svg';
import { ReactComponent as CircleAlertSvg } from '../../images/icons/player/circle_aler_15.svg';
import ButtonResumeRestart from '../../components/Buttons/ButtonResumeReset';
import Button2023 from '../../components/Buttons/Button2023';
import { actionUpdatePlaylistReadStatus } from '../../redux/playlists/action';
import { actionMarkAllLinkPagesAsIncomplete } from '../../redux/currentPage/action';
import useViewersProgressMaker from '../../utils/hooks/useViewersProgressMaker';
import { isRoleInPlaylist } from '../../utils/permissions';
import { ReactComponent as CheckedSVG } from '../../images/2023/svg/small/complete/checked_20_20.svg';
import { useLocalization } from '../../LocalizationContext';

const CloseSectionShell = styled.div`
  width: 100%;
  min-height: 100px;
`;

const CloseButtonPlace = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 67px;
  width: 230px;
`;

const NotionPlace = styled.div`
  display: flex;
  margin-top: 4px;
  padding: 0 16px 16px;
`;

const NotionText = styled.div`
  color: rgba(72, 67, 67, 0.40);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  width: 214px;
  line-height: 14px; // da, sdes' eto nado!
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  width: 20px;
  height: ${({ isClosed }) => (isClosed ? 16 : 28)}px;
  margin-right: 5px;

  ${({ isPreview }) => (isPreview
    ? `
    height: 43px;
  `
    : '')}
`;
const SucceedTopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px; 

  svg {
    position: relative;
    top: -2px;
  }
`;

const CompletedNoticeTopText = styled.div`
  color: rgba(72, 67, 67, 0.80);
  font-family: RobotoFlex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 107.143% */
`;


const CloseSection = ({
  isFinished,
  wrapperId,
  currentRole,
}) => {
  const dispatch = useDispatch();
  const { viewed = 0, total = 0 } = useViewersProgressMaker();
  const isCompleted = total !== 0 && (total === viewed || isFinished);
  const { isOwner } = isRoleInPlaylist;
  const isPlaylistOwner = isOwner[currentRole];
  const { t } = useLocalization();
  const toggleCompletion = () => {
    dispatch(actionUpdatePlaylistReadStatus(isFinished, wrapperId));
  };
  const restart = () => {
    dispatch(actionMarkAllLinkPagesAsIncomplete(wrapperId));
  };

  if (isPlaylistOwner) {
    return (
      <CloseSectionShell>
        <NotionPlace>
          <Icon isPreview>
            <CircleAlertSvg />
          </Icon>
          <NotionText>
            {t.closeSectionNotAvailableT}.
          </NotionText>
        </NotionPlace>
      </CloseSectionShell>
    );
  }

  return (
    <CloseSectionShell>
      {isCompleted && (
        <SucceedTopWrapper>
          <CompletedNoticeTopText>
            {t.completedSmartfileT}
          </CompletedNoticeTopText>
          <CheckedSVG />
        </SucceedTopWrapper>
      )}
      <CloseButtonPlace>
        {isCompleted && <ButtonResumeRestart resume={toggleCompletion} restart={restart} />}
        {!isCompleted && (
        <Button2023
          width={156}
          height={32}
          variant="secondary"
          text={t.markAsCompletedUpT}
          handleButtonClick={toggleCompletion}
        />
        )}
      </CloseButtonPlace>
      <NotionPlace>
        {!isCompleted && (
          <>
            <Icon>
              <AlertSvg />
            </Icon>
            <NotionText>
              {t.closeNotionT}
            </NotionText>
          </>
        )}
        {isCompleted && (
          <>
            <Icon isClosed>
              <CircleAlertSvg />
            </Icon>
            <NotionText>
              {t.closedNotionT}
            </NotionText>
          </>
        )}
      </NotionPlace>
    </CloseSectionShell>
  );
};

export default CloseSection;
