import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import rawStyles from './taglist.module.scss';
import TagListItem from './TagListItem';
import TagItem from './TagItem';

import { actionMoveTag } from '../../redux/tags/action';
import { DATA_PARENTS, DEFAULT_TITLE, empty } from '../../utils/constants';
import { useLocalization } from '../../LocalizationContext';

const styles = classnames.bind(rawStyles);

const TagsList = ({
  state,
  itemId,
  inputValue,
  isListActive,
  randomColor,
  type,
  deleteCb,
  saveCb,
  dataParent,
  isCreateLibrary,
  forCreateLink,
  ...props
}) => {
  const { t } = useLocalization();
  const tags = useSelector((reduxState) => reduxState.currentPage.tags);
  const { tags: contentTags } = useSelector((redux) => redux.user) || empty;
  const dispatch = useDispatch();
  const [foundTags, setFoundTags] = useState([]);
  const [dragItem, setDragItem] = useState(null);
  const [closeAllMenus, setCloseAllMenus] = useState(false);
  const [dragOverId, setDragOverId] = useState(null);
  const [dropDepth, setDropDepth] = useState(0);
  const { id: componentId, folderId } = useSelector(
    (reduxState) => reduxState.support.componentDescriptionData || empty,
  );

  useEffect(() => {
    if (closeAllMenus) setCloseAllMenus(false);
  }, [closeAllMenus]);

  useEffect(() => {
    setFoundTags(
      Object.values(contentTags || {}).sort((a, b) => a.position - b.position),
    );
  }, [contentTags, tags]);

  const onDragLeave = (e) => {
    e.stopPropagation();
    setDropDepth((oldDropDepth) => oldDropDepth - 1);
    // if (dropDepth - 1 > 0) return;
  };

  const onDragEnter = (e, idHover) => {
    e.stopPropagation();
    setDropDepth((oldDropDepth) => oldDropDepth + 1);
    setDragOverId(idHover);
  };

  const onDragStart = (e, item) => {
    // dug in chrome
    // https://stackoverflow.com/questions/14203734/dragend-dragenter-and-dragleave-firing-off-immediately-when-i-drag
    setTimeout(() => {
      setDragItem(item);
    }, 10);
    const div = document.createElement('div');
    div.className = 'draggable_tag';
    div.id = 'draggable_tag';

    const testDiv = document.createElement('div');
    testDiv.textContent = `${item.title}`;
    testDiv.style.width = 'fit-content';
    testDiv.id = 'testDiv';
    document.body.appendChild(testDiv);
    let trimTitle = item.title || t.defaultSmartPageTitleT;
    let counter = trimTitle.length;

    while (testDiv.getBoundingClientRect().width > 130) {
      trimTitle = `${trimTitle.slice(0, counter)}...`;
      testDiv.textContent = trimTitle;
      counter--;
    }

    document.body.removeChild(testDiv);
    div.textContent = `${trimTitle}`;

    document.body.appendChild(div);
    e.dataTransfer.setDragImage(div, 117, 20);
  };

  const onDrop = (e, newIndex) => {
    if ((newIndex || newIndex === 0) && !inputValue) {
      if (newIndex !== dragItem.index) {
        dispatch(
          actionMoveTag(dragItem, newIndex, type, componentId, folderId),
        );
      }
      setDragItem(null);
      setDropDepth(0);
      setDragOverId(null);
    }
    const div = document.querySelector('#draggable_tag');
    if (div) document.body.removeChild(div);
  };

  const onDragEnd = () => {
    const div = document.querySelector('#draggable_tag');
    if (div) document.body.removeChild(div);
    setDragItem(null);
    setDropDepth(0);
    setDragOverId(null);
  };

  const checkHasThisTag = (items, title) => {
    return !!items.find((item) => item.title === title);
  };

  if (!isListActive) return <></>;
  return (
    <div
      className={styles('wrapper', { isCreateLink: forCreateLink })}
      data-parent={
        dataParent || dataParent || DATA_PARENTS.PlaylistBuilderLibrary
      }
    >
      <div
        className={styles('container', { createLibrary: isCreateLibrary })}
        data-parent={dataParent || DATA_PARENTS.PlaylistBuilderLibrary}
      >
        <div
          className={styles('hint', {
            emptyHint: !foundTags.filter(
              (item) => !!item.title.includes(inputValue),
            ).length,
          })}
          data-parent={dataParent || DATA_PARENTS.PlaylistBuilderLibrary}
        >
          {inputValue ? state.createHint : state.manageHint}
        </div>
        <div
          className={styles('tags')}
          onScroll={() => {
            setCloseAllMenus(true);
          }}
        >
          {foundTags
            .filter((item) => !!item.title.includes(inputValue)) // (!checkHasThisTag(tags, item.title))
            .sort((a, b) => b.position - a.position)
            .map((item, index) => (
              <TagListItem
                key={item.id}
                item={{ ...item, index }}
                onDragStart={onDragStart}
                onDrop={onDrop}
                isDrag={!!dragItem}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                deleteCb={deleteCb}
                dragOverId={dragOverId}
                type="tags"
                isView
                currentTags
                parent={{ componentId, folderId }}
                isLast={dragOverId && foundTags.length - 1 === index}
                saveCb={saveCb}
                onDragEnd={onDragEnd}
                closeAllMenus={closeAllMenus}
                outerCloseAllMenus={props.outerCloseAllMenus}
                dataParent={dataParent}
                isCreateLibrary={isCreateLibrary}
              />
            ))}
        </div>
        {!!inputValue.trim() && !checkHasThisTag(foundTags, inputValue) && (
          <div>
            <div className={styles('line')} />
            <div className={styles('create')}>
              <div className={styles('listItem')} onMouseDown={saveCb}>
                <p>{t.createNewT}</p>
                <div>
                  <TagItem
                    backgroundColor={randomColor}
                    isView
                    id={itemId}
                    type="tags"
                    title={inputValue}
                    deleteCb={() => {}}
                    dataParent={dataParent}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TagsList;
