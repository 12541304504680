import React from 'react';
import { useSelector } from 'react-redux';
import {
  Column,
  Label,
  Line,
  SectionShell,
  Text,
  HeaderTitle,
} from '../../CommonComponents/Layout';
import AvailablePlans from './AvailablePlans';
import { useLocalization } from '../../../../LocalizationContext';

const WorkspacePlans = () => {
  const { currentPlan } = useSelector((state) => state.user);
  const { t } = useLocalization();
  return (
    <SectionShell paddingForSetting>
      <HeaderTitle marginBottom32>{t.upgradeT}</HeaderTitle>
      <Label isWorkplans>{t.currentPlanT}</Label>

      <Line>
        <Column Upgrade>
          <Text>{t[`plan${currentPlan}TitleT`]}</Text>
        </Column>
      </Line>

      <AvailablePlans />
    </SectionShell>
  );
};

export default WorkspacePlans;
