import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { useHistory } from 'react-router-dom';
import { calculateItemsStylesMap } from '../../utils/helpers';
import { actionSaveComponentDescriptionData } from '../../redux/support/action';
import { ReactComponent as TOC20Svg } from '../../images/icons/table_of_content_20.svg';
import { TOCSpinner } from '../../pages/Channels/MemberRow/RolesCell/Spinner';
import { ReactComponent as SpinnerSvg } from '../../images/icons/spinner.svg';
import { UsedInPageUI } from '../../utils/UIHelpers/TableOfContent/TOCHelpersUI';
import rawStyles from './index.module.scss';
import useComponentVisibleMouseDown from '../../utils/hooks/useComponentVisibleMouseDown';
import { ReactComponent as ShareSvg } from '../../images/icons/paperclip_link_20.svg';
import { useLocalization } from '../../LocalizationContext';

const cn = classNames.bind(rawStyles);

const UsedInPage = ({ itemId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useLocalization();
  const components = useSelector((state) => state.content.contentData);
  const [rawPages, setRawPages] = useState([]);
  const [lineStylesObject, setLineStylesObject] = useState({});
  const [divHeight, setDivHeight] = useState({});
  const refContent = useRef(null);
  const isShowSpinner = !components[itemId]?.nestedTo;
  const dataParent = `UsedInPage_${itemId}`;

  const [refTOC, isTOCOpened, setIsTOCOpened] = useComponentVisibleMouseDown(
    false,
    'test',
  );
  const { id: currentPageId } = useSelector((state) => state.currentPage);

  const loadLinkPages = () => {
    dispatch(actionSaveComponentDescriptionData(itemId));
  };

  useEffect(() => {
    const style = {};
    if (isTOCOpened && refContent && refContent?.current) {
      const MaxAllowedMenuHeight = 163;
      const NoElementsHeight = 23;
      const OneItemHeight = 27;
      const TopSectionOffset = 47;
      const numberOfItems = rawPages.length;
      const hypotheticalHeight = numberOfItems
        ? numberOfItems * OneItemHeight
        : NoElementsHeight;
      style.height = TopSectionOffset
        + (hypotheticalHeight >= MaxAllowedMenuHeight
          ? MaxAllowedMenuHeight
          : hypotheticalHeight);
    } else {
      style.height = 0;
    }
    setDivHeight(style);
  }, [lineStylesObject, isTOCOpened]);

  useEffect(() => {
    if (!components[itemId]) return;
    if (Object.values(components[itemId] || {})) {
      setRawPages(components[itemId].nestedTo?.parentComponents.flat() || []);
    }
  }, [components]);

  useEffect(() => {
    if (rawPages.length) {
      const itemsMap = calculateItemsStylesMap(rawPages);
      setLineStylesObject(itemsMap);
    }
  }, [rawPages]);

  const scrollToItem = (libraryPageId) => {
    if (currentPageId === libraryPageId) return;
    history.push(`/libraryPage/${libraryPageId}`);
  };

  const openTOCHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (!isTOCOpened) loadLinkPages();
    setIsTOCOpened((prev) => !prev);
  };

  return (
    <div className={cn('usedInPage_wrapper')}>
      <div
        data-parent={dataParent}
        className={cn('usedInPageButton', { active: isTOCOpened })}
        onClick={openTOCHandler}
      >
        <div className={cn('svg_wrap')}>
          <ShareSvg data-parent={dataParent} />
        </div>
        {rawPages.length}
      </div>

      <div
        data-parent={dataParent}
        ref={refTOC}
        className={cn('usedInPage', {
          show: isTOCOpened,
          active: isTOCOpened,
        })}
        style={{
          height: divHeight.height,
        }}
      >
        <div
          className={cn('top_section')}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
          }}
        >
          <div
            className={cn('icon_container')}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              setIsTOCOpened(false);
            }}
          >
            <TOC20Svg />
          </div>
          <div className={cn('title')}> {t.usedInPagesT} </div>
        </div>
        <div className={cn('line')} />
        <div
          data-parent={dataParent}
          className={cn('content_section')}
          ref={refContent}
        >
          {isShowSpinner && (
            <div className={cn('spinner_wrapper')}>
              <TOCSpinner>
                <SpinnerSvg />
              </TOCSpinner>
            </div>
          )}
          {!rawPages.length && !isShowSpinner && (
            <div className={cn('noItems')}>{t.noItemsFoundT}</div>
          )}
          {!!rawPages.length
            && rawPages.map((item, index) => (
              <UsedInPageUI
                key={`TableOfContent_${item.id + index}`}
                item={item}
                index={index}
                currentPageId={currentPageId}
                onClickHandler={scrollToItem}
                itemStylesObject={lineStylesObject[item.id]}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default UsedInPage;
