import React from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actionShowMessage } from '../../redux/support/action';
import { MessageType, openModalType } from '../../utils/constants';
import { actionTogglePublishMiniPlaylist } from '../../redux/miniPlaylists/action';
import PlaylistInput, { Inputs } from '../Playlist/PlaylistInput';
import { ReactComponent as Trash20Svg } from '../../images/icons/trash_20.svg';
import TogglerInput, { TogglerInputs } from '../Playlist/TogglerInput';
import ButtonComponent, {
  ChannelButton,
} from '../../components/Buttons/ButtonComponent';
import { actionOpenModal } from '../../redux/user/action';
import { Avatar } from '../../components/MainHeader/User';
import AvatarPlaceholder from '../../components/Avatar';
import { timeSince } from '../../utils/dateConvert';
import { calcNextItemToRedirect } from '../../utils/helpers';
import { useLocalization } from '../../LocalizationContext';

const InputContainer = styled.div`
  margin-bottom: 16px;
  position: relative;
`;

const Description = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #343434;
`;

const UserName = styled.div`
  font-weight: 700;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  color: #596080;
  margin-top: ${({ isMember }) => (isMember ? '0px' : '14px')};
`;

const MainWrapper = styled.div`
  width: 552px;
  height: 568px;
  background: #ffffff;
  border: 0.6px solid #ece2e2;
  box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.1);
  border-radius: 8px;
  padding: 16px;
  padding-left: 14px;
  margin-bottom: 50px;
`;

const Divider = styled.div`
  height: 1px;
  width: ${({ isMember }) => (isMember ? '100%' : '520px')};
  background: rgba(29, 29, 29, 0.08);
  border-radius: 0;
  margin: auto;
  position: relative;
  top: 4px;
  margin-bottom: 24px;
  ${({ theSecond }) => (theSecond ? `
  margin-bottom: 28px;
  top:9px;` : '')}
`;
const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: ${({ isMember }) => (isMember ? '22px' : '29px')};
`;

const AboutChannelContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 870px;
`;

const AboutTitle = styled.div`
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  line-height: 40px;
  color: #343434;
  margin-bottom: 13px;
`;

const Author = styled.div`
  display: flex;
  align-items: center;
`;

const AvatarContainer = styled.div`
  width: 28px;
  height: 28px;
  margin-right: 10px;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;
`;

const Date = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #585858;

  & span {
    font-weight: 500;
    color: #484343;
  }
`;

const Category = styled.div`
  background: rgba(29, 29, 29, 0.08);
  border: 1px solid #979797;
  border-radius: 6px;
  padding: 5px 8px 6px;
  width: fit-content;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #585858;
  margin-bottom: 18px;
`;

const AboutContent = ({ channel = {}, permissions }) => {
  const { isAdmin } = useSelector((state) => state.user);
  const { folderId: activeChannelId } = useSelector((state) => state.user);
  const { myChannels } = useSelector((state) => state.channels);
  const dispatch = useDispatch();
  const { t } = useLocalization();
  const history = useHistory();
  const isOwner = channel?.role === 'admin';
  const showMessageNotEditable = () => {
    dispatch(
      actionShowMessage({
        type: MessageType.NotEditableNotification,
        place: 'Channel',
        callback: () => actionTogglePublishMiniPlaylist(channel.id, false),
      }),
    );
  };

  const handleDeleteClick = () => {
    const nextItem = calcNextItemToRedirect(myChannels, activeChannelId, true);
    dispatch(actionOpenModal(openModalType.DeleteChannel, { id: channel.id, nextItem, history }));
 };

  const openLeaveModal = () => {
    const nextItem = calcNextItemToRedirect(myChannels, activeChannelId, true);
    dispatch(actionOpenModal(openModalType.LeaveChannel, { channelId: channel.id, nextItem }));
};

  if (!isOwner) {
    return (
      <AboutChannelContainer>
        <AboutTitle>{channel.name}</AboutTitle>
        <SectionWrapper>
          <Author>
            <AvatarContainer>
              {(!!channel?.owner?.avatarUrlSmall && channel?.owner?.displayImage === 'AvatarImage')
                && <Avatar width={28} src={channel?.owner?.avatarUrlSmall} alt="avatar" />
              }
              {(!channel?.owner?.avatarUrlSmall || channel?.owner?.displayImage === 'GeneratedAvatar') && (
              <AvatarPlaceholder
                width={28}
                name={channel?.owner?.firstName}
                abbreviation={`${channel?.owner?.firstName[0]}${channel?.owner?.lastName[0]}`}
              />
              ) }
            </AvatarContainer>
            <UserName isMember={!isOwner}>
              {channel?.owner?.showUsername && channel?.owner?.username
                ? `${channel?.owner?.username}`
                : `${channel?.owner?.name}`
              }
            </UserName>
          </Author>
          <Date>
            <span>{t.createdT}</span> {timeSince(channel?.createDate * 1000)}
          </Date>
        </SectionWrapper>
        <Divider isMember={!isOwner} />
        {!!channel.category && <Category>{channel.category}</Category>}
        {!!channel.description && (
          <Description>{channel.description}</Description>
        )}
        <ButtonWrapper isMember={!isOwner}>
          <ButtonComponent text={t.leaveChannelT} isDelete clickCb={openLeaveModal} />
        </ButtonWrapper>
      </AboutChannelContainer>
    );
  }
  return (
    <MainWrapper>
      <InputContainer>
        <PlaylistInput
          state={Inputs.chName}
          showMessageNotEditable={showMessageNotEditable}
          isChannel
          itemId={channel.id}
          disabled={!isOwner}
        />
      </InputContainer>
      <InputContainer>
        <PlaylistInput
          state={Inputs.chDescription}
          disabled={!isOwner}
          showMessageNotEditable={showMessageNotEditable}
          isChannel
          itemId={channel.id}
        />
      </InputContainer>
      <InputContainer>
        <PlaylistInput
          state={Inputs.category}
          disabled={!isOwner}
          showMessageNotEditable={showMessageNotEditable}
          isChannel
          itemId={channel.id}
          forSelect
        />
      </InputContainer>

      {!isAdmin && (
        <>
          <Divider />
          <InputContainer>
            <TogglerInput
              state={TogglerInputs.isPrivate}
              showMessageNotEditable={showMessageNotEditable}
              isChannel
              isEditable={permissions.canEditChannel}
              itemId={channel.id}
            />
          </InputContainer>
        </>
      )}
      {isOwner && (
        <>
          <Divider theSecond />
          <InputContainer>
            <ChannelButton
              title={t.deleteChannelT}
              buttonText={t.deleteChannelT}
              svg={<Trash20Svg />}
              description={t.deleteChannelWarningT}
              clickCb={handleDeleteClick}
            />
          </InputContainer>
        </>
      )}
    </MainWrapper>
  );
  //   return (
  //     <AboutContainer>
  //       {permissions?.canEditChannel && (
  //         <AboutChannel isCardView={isCardView}>
  //           <Title>About Channel</Title>
  //           {!isAdmin && (
  //           <InputContainer>
  //             <PlaylistInput
  //               state={Inputs.category}
  //               showMessageNotEditable={showMessageNotEditable}
  //               isChannel
  //               itemId={channel.id}
  //             />
  //           </InputContainer>
  //           )}
  //           <InputContainer>
  //             <PlaylistInput
  //               state={Inputs.chName}
  //               showMessageNotEditable={showMessageNotEditable}
  //               isChannel
  //               itemId={channel.id}
  //             />
  //           </InputContainer>
  //           <InputContainer>
  //             <PlaylistInput
  //               state={Inputs.chDescription}
  //               showMessageNotEditable={showMessageNotEditable}
  //               isChannel
  //               itemId={channel.id}
  //             />
  //           </InputContainer>
  //           {!isAdmin && (
  //           <InputContainer>
  //             <TogglerInput
  //               state={TogglerInputs.isPrivate}
  //               showMessageNotEditable={showMessageNotEditable}
  //               isChannel
  //               isEditable={permissions.canEditChannel}
  //               itemId={channel.id}
  //             />
  //           </InputContainer>
  //           )}
  //           {!isAdmin && (
  //           <InputContainer>
  //             <ChannelButton
  //               title="Delete Channel"
  //               buttonText="Delete Channel"
  //               svg={<Trash20Svg />}
  //               description="By deleting the channel, you will lose all the data.
  // We can't restore the channel once you delete it."
  //               clickCb={handleDeleteClick}
  //             />
  //           </InputContainer>
  //           )}
  //         </AboutChannel>
  //       )}
  //       {!permissions?.canEditChannel && (
  //         <AboutChannel isCardView={isCardView}>
  //           <Title>About Channel:</Title>
  //           <InputContainer>
  //             <SubTitle>{channel.name}</SubTitle>
  //           </InputContainer>
  //           <InputContainer>
  //             <Bio>{channel.description}</Bio>
  //           </InputContainer>
  //           {!!channel.category && (
  //           <InputContainer>
  //             <SubTitle>Category</SubTitle>

  //             <div
  //               className={styles.category_label}
  //               style={{
  //                 position: 'relative',
  //                 top: 0,
  //                 left: 0,
  //                 width: 'fit-content' }}
  //             >
  //               {channel.category}
  //             </div>
  //           </InputContainer>
  //           )}

  //           <InputContainer>
  //             <SubTitle>Users</SubTitle>
  //             <Number>{channel.totalSubs || 'This is channel for everyone'}</Number>
  //           </InputContainer>

  //           <LeaveContainer>
//             <SubTitle>Leave Channel</SubTitle>
//             <Description>By leaving the channel, you will lose access to all the data.</Description>
//             <ButtonWrapper>
//               <ButtonComponent text="Leave Channel" isDelete clickCb={openLeaveModal} />
//             </ButtonWrapper>
//           </LeaveContainer>
//         </AboutChannel>
//       )}
//       <Line />
//       <AboutAuthor isCardView={isCardView}>
//         <Title>About Author</Title>
//         {(!!channel?.owner?.avatarUrlSmall && channel?.owner?.displayImage === 'AvatarImage') && (
//           <Avatar width={100} src={channel?.owner?.avatarUrlSmall} alt="avatar" />
//         )}
//         {(!channel?.owner?.avatarUrlSmall || channel?.owner?.displayImage === 'GeneratedAvatar') && (
//           <AvatarPlaceholder
//             width={100}
//             name={channel?.owner?.firstName}
//             abbreviation={`${channel?.owner?.firstName[0]}${channel?.owner?.lastName[0]}`}
//           />
//         )}
//         <UserName>
//           {channel?.owner?.showUsername && channel?.owner?.username
//             ? `${channel?.owner?.username}`
//             : `${channel?.owner?.name}`
//           }
//         </UserName>
//         <UserPosition>
//           <b>
//             {channel?.owner?.companyPosition || 'Director'}
//           </b>
//           {' at '}
//           <b>
//             {channel?.owner?.company || 'Metro Goldwyn Mayer'}
//           </b>
//         </UserPosition>
//         <UserMessage>
//           {channel?.owner?.bio || LOREM_TEXT}
//         </UserMessage>
//         <UserContacts>
//           <Email><Envelope13Svg />{channel?.owner?.email}</Email>
//           <Phone><Phone13Svg />{channel?.owner?.phone || EXAMPLE_PHONE}</Phone>
//         </UserContacts>
//       </AboutAuthor>
//     </AboutContainer>
//   );
};

export default AboutContent;
