import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { MainNavigate } from '../../../../../../navigate/navigate';
import { ReactComponent as Chevron } from '../../../../../../images/2023/svg/small/upv/chevron_10_5.svg';
import { DATA_PARENTS, emptyCallback } from '../../../../../../utils/constants';
import { ReactComponent as CloseCrossSVG } from '../../../../../../images/icons/cross_20.svg';
import { CloseSVGWrapper } from '../../../../../../components/MainHeader/MainNavSlider/styled';
import useComponentVisible from '../../../../../../utils/hooks/useComponentVisible';
import { useLocalization } from '../../../../../../LocalizationContext';
import { ReactComponent as ThreeDotsIcon } from '../../../../../../images/2023/svg/upv/three_dots_22_6.svg';
import DropDownDownItem from '../../../../../../components/SmartView/2023/DropDownDownItem';
import { transformedDATA_PARENTS } from '../../../../../../utils/dataUtil';


export const ThreeDotsSVGWrapper = styled.div`
  position: absolute;
  right: 34px;
  width: 24px;
  height: 24px;
  top: 2px;
  border-radius: 4px;
  padding-top: 2px;
  border-radius: 4px;
  padding-left: 3px;
  display: none;
  align-items: center;
  svg {
    width: 17px;
  }

  &:hover {
    background: #ffdf85;
  }

  ${({ isActive }) => (isActive ? 'background:#FFC72C' : '')}
`;

const Counter = styled.span`
    padding-left: 5px;
`;

const CurrentChoiceWrapper = styled.div`
  background: #ffc72c;
  border-radius: 6px;
  height: 28px;
  padding-left: 8px;
  padding-right: 26px;
  width: max-content;
  line-height: 28px;
  position: relative;
  cursor: pointer;
  text-transform: uppercase;
  ${CloseSVGWrapper} svg {
    position: absolute;
    right: 8px;
    top: 12px;
  }
  ${({ dynamicTab, isOpened }) => (!dynamicTab
    ? `
    & svg {
      transition: transform;
      transition-duration: 0.4s;
      position: absolute;
      right: 8px;
      top: 12px;
      ${(!isOpened ? ' transform: rotate(-90deg);' : ' transform: rotate(0deg);')}
    }` : '')}

  ${({ dynamicTab }) => (dynamicTab
    ? `
    ${CloseSVGWrapper} svg{
    right: 3px;
    top: 4px;
  }
  padding-right:32px;`
    : '')}

  ${({ isTag }) => (isTag
    ? 'padding-right:42px;'
    : '')}


  :hover ${ThreeDotsSVGWrapper}{
    display: flex;
  }
  :hover ${Counter}{
    visibility: hidden;
  }
`;


const UPVLibSelectorDropDownWrapper = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  letter-spacing: 0.05em;
  color: #484343;
  position: relative;
`;

const DropDownWrapper = styled.div`
  background-color: #ffffff;
  position: absolute;
  top: 37px;
  padding-top: 14px;
  z-index: 22;
  width: 150px;
  height: 267px;
  border: 0.6px solid #ece2e2;
  box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.25);
  border-radius: 12px;
  letter-spacing: 0.05em;
`;

const DropDownItem = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  color: #484343;
  padding-left: 15px;
  ${({ disabled }) => (disabled
    ? `
        color: #585858;
        opacity: 0.5;
        cursor:not-allowed;
   `
    : `
     &:hover {
        background-color: #FFEEBF;
        cursor: pointer;
    }
   `)}
`;

const DDTitleWrapper = styled.div`
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 12px;

  text-transform: uppercase;
  color: #d3c8c8;
  margin-left: 15px;
  margin-bottom: 7px;
`;

const LineDivider = styled.div`
  width: 118px;
  height: 1px;
  background: rgba(29, 29, 29, 0.08);
  margin: 8px auto 5px;
`;

const SelectorDropDown = ({
  setSelector,
  dynamicTab,
  resetDynamicTab,
  dataP,
  isPage,
  chosenTag = {},
}) => {
  const [refOpen, isOpened, setIsOpened] = useComponentVisible(
    false,
    DATA_PARENTS.newLibSideBarDropDown,
  );
  const [exOptionRef, isExOpened, setIsExOpened] = useComponentVisible(
    false,
    DATA_PARENTS.newLibSideBarDropDown,
  );
  const [currentChoice, setCurrentChoice] = useState(MainNavigate.library);
  const [options, setOptions] = useState([]);
  //
  const toggleDropDown = () => setIsOpened(!isOpened);
  const { t } = useLocalization();

  const chooseSelector = (nav) => {
    setCurrentChoice(nav);
    setIsOpened(false);
  };
  useEffect(() => {
    setSelector(
      currentChoice?.name.toLowerCase()
        || MainNavigate.library.name.toLowerCase(),
    );
    setOptions(
      Object.values(MainNavigate).filter(
        (it) => it.name && it.name !== MainNavigate.smartfiles.name,
      ),
    );
  }, [currentChoice]);

  const closeTagTab = (e) => {
    e.stopPropagation();
    resetDynamicTab();
    setIsOpened(false);
  };

  return (
    <UPVLibSelectorDropDownWrapper ref={refOpen} data-parent={dataP}>
      <CurrentChoiceWrapper
        onClick={toggleDropDown}
        isOpened={isOpened}
        dynamicTab={dynamicTab}
        isTag={!!Object.keys(chosenTag).length}
        data-parent={dataP}
      >
        {dynamicTab || t[currentChoice.textID]}{' '}
        {!!Object.keys(chosenTag) && <Counter>{ chosenTag.itemsCounter}</Counter>}
        {dynamicTab ? (
          <>
            <ThreeDotsSVGWrapper
              onClick={(e) => setIsExOpened(true)}
              data-parent={transformedDATA_PARENTS}
              isActive={isExOpened}
            >
              <ThreeDotsIcon data-parent={dataP} />
            </ThreeDotsSVGWrapper>

            <CloseSVGWrapper
              top={2}
              onClick={closeTagTab}
              data-parent={transformedDATA_PARENTS}
            >
              <CloseCrossSVG />
            </CloseSVGWrapper>
          </>
        ) : (
          <Chevron data-parent={dataP} />
        )}
      </CurrentChoiceWrapper>
      {isExOpened && (
        <DropDownDownItem
          top={42}
          right={-92}
          isLibCallBack
          refMenu={exOptionRef}
          close={() => setIsExOpened(false)}
          isTag
          forTab
          clickedView={chosenTag}
          closeTagTab={closeTagTab}
        />
      )}
      {isOpened && !dynamicTab && (
        <DropDownWrapper
          data-parent={`${dataP} miniPage ${DATA_PARENTS.newLibSideBarDropDown}`}
        >
          <DDTitleWrapper>{t.categoriesT}</DDTitleWrapper>
          {options.map((it) => (
            <React.Fragment key={t[it.textID]}>
              <DropDownItem
                name={t[it.textID]}
                disabled={(!isPage && it.name === MainNavigate.components.name)}
                onClick={(!isPage && it.name === MainNavigate.components.name)
                  ? emptyCallback
                  : () => chooseSelector(it)
                }
                data-parent={`${dataP} miniPage ${DATA_PARENTS.newLibSideBarDropDown}`}
              >
                {t[it.textID]}
              </DropDownItem>
              {it.name === MainNavigate.library.name && <LineDivider />}
            </React.Fragment>
          ))}
        </DropDownWrapper>
      )}
    </UPVLibSelectorDropDownWrapper>
  );
};

export default SelectorDropDown;
