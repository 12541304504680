import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { actionOpenModal } from '../../../../redux/user/action';
import { ChannelsSieveTypes, empty, openModalType } from '../../../../utils/constants';
import Button2023 from '../../../Buttons/Button2023';
import SortAndView from '../SortAndView';
import { Divider, GroupIcon, Wrapper } from './StyledComponents';
import { createNewPlaylistAndJumpIntoIt } from '../../../../utils/helpers';
import { useLocalization } from '../../../../LocalizationContext';

const SecondaryChannel = ({ ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useLocalization();

  const { id: folderId } = useParams();
  const channelPlace = useSelector((state) => state.channels.activeSieveOption);
  const { myChannels } = useSelector((redux) => redux.channels) || empty;
  const addMemberClickHandler = () => dispatch(actionOpenModal(openModalType.AddMemberModal));

  const createNewPlaylist = useCallback(() => {
    createNewPlaylistAndJumpIntoIt(
      { history, dispatch, isChannel: true, addToChannel: folderId },
    );
  }, [dispatch, folderId]);

  const isCanAddMember = myChannels[folderId]?.permissions?.canManageMembers
    && channelPlace === ChannelsSieveTypes.ChannelsMembers;

  const isCanAddSF = myChannels[folderId]?.permissions?.canPostContent
    && (channelPlace === ChannelsSieveTypes.ChannelsAll || channelPlace === ChannelsSieveTypes.ChannelsFavorite);

  return (
    <GroupIcon>
      <Wrapper>
        {isCanAddMember && (
          <Button2023
            text={t.plusMemberUpT}
            width={89}
            height={32}
            variant="secondary"
            handleButtonClick={addMemberClickHandler}
          />
        )}
        {isCanAddSF && (
          <Button2023
            text={t.plusCreateUpT}
            width={74}
            height={32}
            variant="secondary"
            handleButtonClick={createNewPlaylist}
          />
        )}
        <Divider />
      </Wrapper>
      <SortAndView {...props} />
    </GroupIcon>
  );
};

export default SecondaryChannel;
