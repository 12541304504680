import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { ReactComponent as AddUserIcon16Svg } from '../../../images/icons/icon_add user_16.svg';
import useComponentVisible from '../../../utils/hooks/useComponentVisible';
import { DATA_PARENTS, empty } from '../../../utils/constants';
import { ListItem } from './ListItem';
import styles from '../../GlobalModals/index.module.scss';
import { checkIsUserSuits } from './helpers';
import SuggestedContact from './SuggestedContact';
import { useLocalization } from '../../../LocalizationContext';

const cx = classNames.bind(styles);


const InputWithSelectUser = ({
  validateError,
  handleUserOrEmailState,
  deleteEmail,
  setValidateError,
  inputEmail,
  setInputEmail,
  emailsAndUsersToInvite,
  dataParent,
  isSharePage,
  labelText = 'User Name or Email',
  placeholder = '',
  excludedUsers = empty,
  isDarkMode = false,
}) => {
  const { t } = useLocalization();
  const contacts = useSelector((state) => state.support.contactNicknameMap);
  const [userShowedContacts, setUserShowedContacts] = useState(Object.values(contacts));
  const [selectedItem, setSelectedItem] = useState(0);
  const refForScroll = useRef(null);
  const refInput = useRef(null);

  const [refDropMenu, isDropMenu, setIsDropMenu] = useComponentVisible(
    false,
    DATA_PARENTS.AddMember,
  );

  useEffect(() => {
    if (inputEmail) setIsDropMenu(true);
  }, [inputEmail]);

  useEffect(() => {
    setSelectedItem(0);
    refForScroll.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    setUserShowedContacts(
      Object.values(contacts).filter(checkIsUserSuits(inputEmail, emailsAndUsersToInvite, excludedUsers)),
    );
  }, [excludedUsers, inputEmail, contacts, emailsAndUsersToInvite]);


  const handleKeyPress = (e) => {
    if (e.code === 'ArrowDown') {
      if (selectedItem < (userShowedContacts.length - 1)) {
        setSelectedItem(selectedItem + 1);
        refForScroll.current?.scrollTo({
          top: 32 * selectedItem,
          behavior: 'smooth',
        });
      }
      return;
    }
    if (e.code === 'ArrowUp') {
      if (selectedItem && !(selectedItem >= userShowedContacts.length)) {
        refForScroll.current?.scrollTo({
          top: 32 * (selectedItem - 3),
          behavior: 'smooth',
        });
        setSelectedItem(selectedItem ? selectedItem - 1 : 0);
      }
      return;
    }

    if (e.key === 'Enter') {
      if (userShowedContacts[selectedItem]) {
        const user = userShowedContacts[selectedItem];
        handleUserOrEmailState(e, user, inputEmail.trim());
        return;
      }
      handleUserOrEmailState(e, null, inputEmail.trim());
    } else if (
      e.key === 'Backspace'
      && emailsAndUsersToInvite.length > 0
      && !inputEmail.trim()
    ) {
      deleteEmail(emailsAndUsersToInvite.length - 1);
    } else if (validateError) setValidateError('');
  };

  const userClickHandler = useCallback((event, id) => {
    event.stopPropagation();
    const user = Object.values(contacts).find((i) => i.id === id);
    handleUserOrEmailState(event, user, inputEmail.trim());
  }, [contacts, handleUserOrEmailState, inputEmail]);

  return (
    <div
      className={cx('inputEmail_wrapper')}
      data-parent={`${DATA_PARENTS.AddMember} ${dataParent}`}
    >
      <div className={cx('inputEmail_description')}>{labelText}</div>
      <div
        data-cy="nameOrEmail"
        onClick={() => refInput.current.focus()}
        className={cx('input_area', {
          darkMode: isDarkMode,
          sharedModal: isSharePage,
        })}
        data-parent={`${DATA_PARENTS.AddMember} ${dataParent}`}
      >
        {emailsAndUsersToInvite.map((i, index) => (
          <ListItem
            key={i.id || i.email}
            item={i}
            index={index}
            deleteEmail={deleteEmail}
            dataParent={dataParent}
          />
        ))}
        <input
          onKeyDown={handleKeyPress}
          onFocus={() => setIsDropMenu(true)}
          ref={refInput}
          value={inputEmail}
          onChange={(e) => setInputEmail(e.target.value)}
          className={cx('title')}
          autoFocus
          type="email"
          placeholder={placeholder}
          maxLength={55}
        />
      </div>
      {isDropMenu && !!inputEmail.trim() && (
        <div ref={refDropMenu} className={cx('search_wrapper')}>
          {!!userShowedContacts.length && (
            <div ref={refForScroll} className={cx('scroll_search_container')}>
              {userShowedContacts.map((user, index) => (
                <SuggestedContact
                  user={user}
                  key={user.id}
                  active={index === selectedItem}
                  dataParent={dataParent}
                  userClickHandler={userClickHandler}
                />
              ))}
            </div>
          )}
          {!userShowedContacts.length && (
            <div
              className={cx('send_email_invite')}
              onClick={(event) => userClickHandler(event)}
              data-parent={`${DATA_PARENTS.AddMember} ${dataParent}`}
            >
              <div className={cx('icon')}>
                <AddUserIcon16Svg />
              </div>
              <div className={cx('name_without_ellipsis')}>{t.sendInviteToT}</div>
              <div className={cx('quotes')}>“</div>
              <div className={cx('quote_email')}>{inputEmail}</div>
              <div className={cx('quotes')}>”</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default InputWithSelectUser;
