import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.css';
import { ReactComponent as ImageUploadSvg } from '../../images/icons/image_upload.svg';
import { ReactComponent as CloseUploadSvg } from '../../images/icons/close_upload.svg';
import { actionCancelUpload } from '../../redux/filesUpload/action';
import { ReactComponent as UploadErrorSvg } from '../../images/icons/upload_error_20.svg';
import {
  DATA_PARENTS
} from '../../utils/constants';
import Tooltip from '../../shared/Tooltips/Tooltip';
import {
  Circle,
  progressBarStatus,
} from '../../utils/UIHelpers/PlaylistBuilderElements/libraryComponent';
import { useLocalization } from '../../LocalizationContext';

const CardViewUploading = memo(function CardViewUploading({
  localStatus,
  itemUploadData,
  item
}) {
  const { folderId } = useSelector(state => state.user);

  const dispatch = useDispatch();

  const { t } = useLocalization();

  return (
    <div className={`${styles.sideBar_loaded_component}`}>
      {localStatus.uploading && (
        <>
          <div
            style={{ overflow: 'visible' }}
            className={`
              ${styles.preview}
              ${styles.sideBar_loaded_component}
            `}
          >
            <div
              className={`${
                itemUploadData.progress === 0 ? styles.pending_color : ''
              } ${styles.upload_preview} `}
            >
              <ImageUploadSvg />
            </div>
            <div className={styles.folderBufferFail}>
              <Tooltip text={t.cancelUploadingT} direction="down">
                <div
                  className={styles.close_wrapper}
                  onClick={() => dispatch(actionCancelUpload(item.id, folderId))
                  }
                >
                  <CloseUploadSvg />
                </div>
              </Tooltip>
            </div>
            <div className={styles.circle_wrapper}>
              <Circle progress={100} color="#D7DDF7" />
              {progressBarStatus(
                itemUploadData.progress,
                itemUploadData.status,
              )}
            </div>
            <div className={styles.loaderWrap} />
          </div>
          <div
            data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
            className={styles.text}
          >
            {item.title || t.UnnamedComponentT}
          </div>
        </>
      )}

      {localStatus.processing
        && progressBarStatus(itemUploadData.progress, itemUploadData.status)}

      {localStatus.error && (
        <>
          <div className={styles.fail_upload}>
            <div className={styles.folderBufferFail}>
              <Tooltip text={t.closeT} direction="down">
                <div
                  className={styles.close_wrapper}
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(actionCancelUpload(item.id, folderId));
                  }}
                >
                  <CloseUploadSvg />
                </div>
              </Tooltip>
            </div>
            <div className={styles.error}>
              <UploadErrorSvg />
            </div>
            <div className={styles.text}>{itemUploadData.errorMsg}</div>
          </div>
          <div
            data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
            className={styles.failed_name}
          >
            {item.title || t.UnnamedComponentT}
          </div>
        </>
      )}
    </div>
  );
});

export default CardViewUploading;
