import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { actionCreator } from '../../shared/redux/actionHelper';
import { InboxAction } from '../../redux/inbox/types';
import Tooltip from '../../shared/Tooltips/Tooltip';
import { ReactComponent as MailSvg } from '../../images/icons/mail.svg';
import { ReactComponent as MailUnreadSvg } from '../../images/icons/mail_unread.svg';
import { ReactComponent as TrashSvg } from '../../images/icons/trash_Icon.svg';
import { useLocalization } from '../../LocalizationContext';

const CardButtonsWrappers = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${({ inHeader }) => (inHeader
    ? `
    gap: 10px;
    margin-left: 10px;
    ` : ''
  )};
`;
const SvgUnreadWrap = styled.div`
  height: 18px;
  svg{
    path{     stroke-width: 4; }
  }
`;

const CardButton = styled.div`
  ${({ isDelete }) => (isDelete
    ? 'top: 60px;' : 'top: 15px'
  )};
  border: 0.6px solid rgba(255, 199, 44, 0);
  width: 26px;
  height: 26px;
  ${({ inHeader }) => (inHeader
    ? `border: 0.6px solid #ECE2E2;
      width: 32px;
      border-radius: 32px;
      height: 32px;`
    : ''
  )};
  
  border-radius: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    width: 18px;
    height: 18px;
  }

  :hover {
    background: ${({ inHeader }) => (inHeader
    ? '#FFEEBF;'
    : '#FFDF85'
  )};
    border: 0.6px solid #FFC72C;
  }
`;
const convertToReadable = {
  read: 'Read',
  unread: 'Unread',
};

const InboxCardButtons = ({ item, inHeader, items, clearCards }) => {
  const dispatch = useDispatch();
  const { t } = useLocalization();
  const handleDelete = (e) => {
    e.stopPropagation();
    if (inHeader) {
      dispatch(actionCreator(InboxAction.DeleteInboxCards, { items }));
      clearCards();
    } else {
      dispatch(actionCreator(InboxAction.DeleteInboxCard, { item }));
    }
  };

  const calcEnvelopType = useMemo(() => {
    if (inHeader) {
      return items.some(elem => !elem.isRead);
    }
    return !item.isRead;
  }, [items, item, inHeader]);

  const type = calcEnvelopType ? 'read' : 'unread';
  const directionTooltip = inHeader ? 'up' : 'down';

  const handleMarkRead = (e) => {
    e.stopPropagation();
    if (inHeader) {
      dispatch(actionCreator(InboxAction.CardsInboxMarkAsReadOrUnread, { items, type }));
      clearCards();
    } else {
      dispatch(actionCreator(InboxAction.CardInBoxMarkUsReed, { item }));
    }
  };

  return (
    <CardButtonsWrappers inHeader>
      <Tooltip text={t[`markAs${type}T`]} direction={directionTooltip}>
        <CardButton onClick={handleMarkRead}>
          {calcEnvelopType ? <MailSvg />
            : <SvgUnreadWrap>  <MailUnreadSvg />  </SvgUnreadWrap>}
        </CardButton>
      </Tooltip>
      <Tooltip text={t.removeT} direction={directionTooltip}>
        <CardButton isDelete onClick={handleDelete}>
          <TrashSvg />
        </CardButton>
      </Tooltip>
    </CardButtonsWrappers>
  );
};

export default InboxCardButtons;
