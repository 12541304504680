import React from 'react';
import classNames from 'classnames/bind';
import { useHistory } from 'react-router-dom';
import styles from './index.module.scss';
import search from '../../images/icons/search.svg';
import { ReactComponent as RoundedCross20Svg } from '../../images/icons/circle_cross_20.svg';
import { useLocalization } from '../../LocalizationContext';

const cn = classNames.bind(styles);

const SearchInSmartView = (props) => {
  const history = useHistory();
  const { t } = useLocalization();
  const isFullScreen = history.location.pathname === '/pages'
    || history.location.pathname === '/library';
  return (
    <div
      className={cn('search_input_wrapper', {
        fullscreen: isFullScreen,
        isLibrary: props.isLibrary,
      })}
    >
      <img className={cn('search_icon2')} src={search} alt="search" />
      <input
        autoFocus
        value={props.libFilterSearch}
        onChange={(e) => {
          props.setLibFilterSearch(e.target.value);
        }}
        onMouseDown={(event) => event.stopPropagation()}
        maxLength={55}
        placeholder={`${t.searchT}${props.isLibrary ? t.inLibraryAdditionalT : ''}`}
        className={cn('search_input')}
        data-cy={props.isLibrary ? 'search-in-lib' : ''}
      />
      {!!props.libFilterSearch && (
        <RoundedCross20Svg
          className={cn('clear_search')}
          onClick={() => props.setLibFilterSearch('')}
          alt=""
        />
      )}
    </div>
  );
};

export default SearchInSmartView;
