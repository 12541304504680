import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import styles from '../../pages/ManyPlaylists/index.module.scss';
import rawStylesDynamic from '../../pages/Library/LibraryHeader.module.scss';
import { ReactComponent as FavoriteHeaderSvg } from '../../images/icons/favorites20.svg';
import { ReactComponent as PublishHeaderSvg } from '../../images/icons/header_playlist_publ.svg';
import { ReactComponent as DraftHeaderSvg } from '../../images/icons/icon_20/header_playlist_draft.svg';
import recentHeader from '../../images/icons/recent_20.svg';
import search from '../../images/icons/search.svg';
import { ReactComponent as Plus } from '../../images/icons/plus_blue_24.svg';
import sort from '../../images/icons/sort.svg';
import SortOption from '../DropDownOption/SortOption';
import { sortTitleMapping } from '../../utils/dateConvert';
import { ReactComponent as ShareSvg } from '../../images/icons/paperclip_link_20.svg';
import { ListGridButtonWithTooltip } from '../LibraryHeaderMenu/ListGridButton';
import { ReactComponent as RoundedCross20Svg } from '../../images/icons/circle_cross_20.svg';
import { DynamicCollectionSave } from '../DynamicCollectionCreator/TopPart';
import { empty } from '../../utils/constants';
import DynamicCollectionCreator from '../DynamicCollectionCreator/DynamicCollectionCreator';
import { useLocalization } from '../../LocalizationContext';

const stylesDynamic = classNames.bind(rawStylesDynamic);

const ManyPlaylistsHeaderMenu = (props) => {
  const [tmpTitle, setTmpTitle] = useState(props.title || 'Unnamed folder');
  const { selectorType } = useSelector((state) => state.user);
  const { isShowLibraryWidget } = useSelector((state) => state.currentPage);
  const { id: metaId } = useSelector(
    (reduxState) => reduxState.support.componentDescriptionData || empty,
  );
  const { t } = useLocalization();

  useEffect(() => {
    setTmpTitle(props.title);
  }, [props.title]);

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.title_or_search}>
          {props.selectorType === 'favorites' && (
            <FavoriteHeaderSvg
              className={`${styles.folder_icon} ${styles.favorites}`}
            />
          )}
          {props.selectorType === 'drafts' && (
            <DraftHeaderSvg className={styles.folder_icon} />
          )}
          {props.selectorType === 'published' && (
            <PublishHeaderSvg className={styles.folder_icon} />
          )}
          {props.selectorType === 'recent' && (
            <img
              className={styles.folder_icon}
              src={recentHeader}
              alt="is recent"
            />
          )}
          {props.selectorType === 'shared' && (
            <ShareSvg className={styles.folder_icon} alt="is share" />
          )}
          <div className={styles.title_input_wrapper}>
            <div className={styles.title}>{tmpTitle}</div>
          </div>

          {!props.isShowFilter && (
            <div className={styles.search_icon_wrapper}>
              <img
                className={styles.search_icon}
                onClick={() => {
                  props.setIsShowFilter(!props.isShowFilter);
                  props.setFilter('');
                }}
                src={search}
                alt="search"
              />
            </div>
          )}
        </div>
        <div className={styles.flex}>
          <div className={styles.orders}>
            <div className={styles.sort_and_view}>
              {props.isCardsView && (
                <div className={styles.sort_wrapper}>
                  <div
                    ref={props.refShowMenuSort}
                    onClick={() => props.setShowMenuSort(!props.isShowMenuSort)}
                    className={`${styles.sort} ${
                      props.isShowMenuSort ? styles.sort_active : ''
                    } `}
                  >
                    <img src={sort} alt="sort" />
                    <div> {sortTitleMapping[props.sortType]} </div>

                    {props.isShowMenuSort && props.isCardsView && (
                      <SortOption
                        sortType={props.sortType}
                        setSort={props.setSort}
                      />
                    )}
                  </div>
                </div>
              )}

              <ListGridButtonWithTooltip />
            </div>
          </div>
          <div onClick={props.createItem} className={styles.new_page}>
            <Plus />
            <div>{t.smartFileUpT}</div>
          </div>
        </div>
      </div>

      {props.isShowFilter && props.showHeaderDetails && (
        <div
          className={stylesDynamic('resize_wrapper', {
            isTag: selectorType === 'tag',
            show_description: isShowLibraryWidget || metaId,
          })}
        >
          <div className={stylesDynamic('search', 'search_max_size')}>
            <div
              className={stylesDynamic(
                'search_input_wrapper',
                'isPlaylists',
                'allLibraryHeader',
              )}
            >
              <input
                value={props.libFilterSearch}
                onChange={(e) => props.setLibFilterSearch(e.target.value)}
                placeholder={`${t.searchInT} ${props.title}`}
                onMouseDown={(e) => e.stopPropagation()}
                autoFocus
                className={stylesDynamic('search_input', 'isPlaylists')}
              />
              {!!props.libFilterSearch && (
                <RoundedCross20Svg
                  className={stylesDynamic('clear_search', 'isPlaylists')}
                  onClick={() => {
                    props.setLibFilterSearch('');
                  }}
                />
              )}
            </div>
          </div>
          {selectorType === 'all' && (
            <div className={stylesDynamic('save_search_wrapper')}>
              <DynamicCollectionSave {...props} isLibrary />
            </div>
          )}
        </div>
      )}

      {props.showHeaderDetails && props.isShowFilter && (
        <div
          className={stylesDynamic('wrapper_dynamicCollection', {
            show_description: isShowLibraryWidget || metaId,
          })}
        >
          <DynamicCollectionCreator
            isAllLibraryItems={selectorType === 'all'}
            libFilterSearch={props.libFilterSearch}
            isFullScreen
            isShowFilter
            filter={props.filter}
            isHasSomeFilter={
              (props.filter.tags
                && Object.values(props.filter.tags || {}).length)
              || (props.filter.fileType
                && Object.values(props.filter.fileType || {}).length)
              || (props.filter.createDate
                && Object.values(props.filter.createDate || {}).length)
            }
            setFilter={props.setFilter}
            setIsShowFilter={props.setIsShowFilter}
          />
        </div>
      )}
    </div>
  );
};

export default ManyPlaylistsHeaderMenu;
