import React from 'react';
import { maxAvatarSize, maxEmojiSize } from '../../GLOBAL_CONST';
import { requestSaveSettings } from '../request';
import { LANG, TRANSLATIONS } from '../Translations';
import { ReactComponent as RightArrow14Svg } from '../../images/icons/right_arrow_14.svg';
import { ReactComponent as LeftArrow14Svg } from '../../images/icons/left_arrow_14.svg';
import i18n from '../../i18n';

const {
  settings: {
    shortcuts: { values: T_values },
  },
} = TRANSLATIONS;

export const AVATAR_GENERATOR_COLORS: readonly string[] = [
  '#586F7C',
  '#12100E',
  '#F87060',
  '#7FC29B',
  '#A89F68',
  '#F3A712',
  '#4A7B9D',
  '#8F2D56',
];

export const DB_USER_PROFILE_FIELDS: { [key: string]: string } = {
  username: 'username',
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  phone: 'phone',
  company: 'company',
  infoRole: 'infoRole',
  bio: 'bio',
};


export const DB_USER_PREFERENCES_LANGUAGE_FIELDS: { [key: string]: string } = {
  preferredLanguage: 'preferredLanguage',
  timeZone: 'timeZone',
  dateFormat: 'dateFormat',
  timeFormat: 'timeFormat',
};

export const DB_USER_PREFERENCES_GENERAL_FIELDS: { [key: string]: string } = {
  keepInformed: 'keepInformed',
  newsletterSubscription: 'newsletterSubscription',
  emailNotifications: 'emailNotifications',
  showUsername: 'showUsername',
  displayImage: 'displayImage',
};

export const TIME_FORMATS: { [key: string]: string } = {
  H24: 'H24',
  H12: 'H12',
};

export const TIME_REPRESENTATION: { [key: string]: string } = {
  [TIME_FORMATS.H24]: '1 TO 24',
  [TIME_FORMATS.H12]: '1 TO 12',
};

export const SUPPORTED_LANGUAGES: { [key: string]: { id: number; title: string; code: string } } = {
  en: { id: 1, title: 'English', code: LANG.en },
  de: { id: 2, title: 'Deutsch', code: LANG.de },
  fr: { id: 3, title: 'Français', code: LANG.fr },
  nl: { id: 4, title: 'Nederlands', code: LANG.nl },
  es: { id: 5, title: 'Español', code: LANG.es },
  it: { id: 6, title: 'Italiano', code: LANG.it },
  pl: { id: 7, title: 'Polski', code: LANG.pl },
  ru: { id: 8, title: 'Русский', code: LANG.ru },
  ua: { id: 9, title: 'Українська', code: LANG.ua },
};

export const SUPPORTED_FORMATTING: { [key: string]: string } = {
  MMDDYYYY: 'MMDDYYYY',
  YYYYMMDD: 'YYYYMMDD',
};

export const DISPLAY_FORMATTING: { [key: string]: string } = {
  [SUPPORTED_FORMATTING.MMDDYYYY]: 'MM DD, YYYY',
  [SUPPORTED_FORMATTING.YYYYMMDD]: 'YYYY, MM DD',
};

export const AVATAR_CHANGE_OPTIONS: { [key: string]: string } = {
  AvatarImage: 'AvatarImage',
  GeneratedAvatar: 'GeneratedAvatar',
};

export const ERROR_PRIORITY: { [key: string]: number } = {
  One: 1,
  Two: 2,
  Three: 3,
};


export const MIN_LENGTH: number = 3;
export const MAX_LENGTH: number = 15;
export const MAX_LENGTH_PHONE: number = 15;
export const MAX_LENGTH_EMAIL: number = 30;
export const MAX_LENGTH_TEXTAREA: number = 500;
export const SPECIAL_CHAR: RegExp = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~\s]/;
export const STRING_HAS_DIGITS: RegExp = /\d+/;


export const VALIDATE: { [key: string]: string } = {
  IsEmail: 'IsEmail',
  MaxLength: 'MaxLength',
  MaxLengthPhone: 'MaxLengthPhone',
  MinLength: 'MinLength',
  SpecialCharacters: 'SpecialCharacters',
  MaxLengthEmail: 'MaxLengthEmail',
  MaxLengthTextarea: 'MaxLengthTextarea',
  IsRequired: 'IsRequired',
  UsernameIsFree: 'UsernameIsFree',
  EmailIsFree: 'EmailIsFree',
  MaxFileSize: 'MaxFileSize',
  FileType: 'FileType',
  EmojiMaxFileSize: 'EmojiMaxFileSize',
  EmojiFileType: 'EmojiFileType',
  EmojiDimensions: 'EmojiDimensions',
  NoDigits: 'NoDigits',
  IsEmojiNameUnique: 'IsEmojiNameUnique',
  currentEmojiRename: 'currentEmojiRename',
  IsEmojiNameRequired: 'IsEmojiNameRequired',
};

export const ERROR_RESPONSES: { [key in keyof typeof VALIDATE]: string } = {
  [VALIDATE.SpecialCharacters]: `${i18n.t('specialSymbolsT')}`,
  [VALIDATE.MinLength]: `${MIN_LENGTH} ${
    i18n.t('minLengthT')
  }`,
  [VALIDATE.MaxLength]: `${MAX_LENGTH} ${
    i18n.t('maxLengthT')
  }`,
  [VALIDATE.MaxLengthPhone]: `${MAX_LENGTH_PHONE} ${
    i18n.t('maxLengthT')
  }`,
  [VALIDATE.IsEmail]: `${i18n.t('emailInvalidT')}`,
  [VALIDATE.MaxLengthEmail]: `${MAX_LENGTH_EMAIL} ${
    i18n.t('maxLengthT')
  }`,
  [VALIDATE.MaxLengthTextarea]: `${MAX_LENGTH_TEXTAREA} ${
    i18n.t('maxLengthT')
  }`,
  [VALIDATE.IsRequired]: `${i18n.t('isRequiredT')}`,
  [VALIDATE.IsEmojiNameRequired]: `${i18n.t('isRequiredT')}`,
  [VALIDATE.UsernameIsFree]: `${i18n.t('usernameIsFreeT')}`,
  [VALIDATE.EmailIsFree]: `${i18n.t('emailIsFreeT')}`,
  [VALIDATE.MaxFileSize]: `${i18n.t('maxFileSizeT')} ${
    maxAvatarSize.text
  }`,
  [VALIDATE.FileType]: `${i18n.t('fileTypeErrorT')}`,
  [VALIDATE.EmojiMaxFileSize]: `${
    i18n.t('emojiMaxFileSizeT')
  } ${maxEmojiSize.text}`,
  [VALIDATE.EmojiFileType]: `${i18n.t('emojiFileTypeT')}`,
  [VALIDATE.EmojiDimensions]: `${i18n.t('emojiDimensionsT')}`,
  [VALIDATE.NoDigits]: `${i18n.t('emojiNoDigitsT')}`,
  [VALIDATE.IsEmojiNameUnique]: `${i18n.t('isEmojiNameUniqueT')}`,
};

export const FORM_REQUEST_MAP:  { [key: string]: any } = {
  infos: requestSaveSettings,
};

export const FORM_INPUTS:  { [key: string]: string[]} = {
  infos: [
    'username',
    'firstName',
    'lastName',
    'email',
    'phone',
    'company',
    'infoRole',
    'bio',
  ],
  emojiFileUpload: ['emojiFile', 'emojiName'],
};

export const INPUT_TO_MODAL_MAP: { [key: string]: string } = {
  avatar: 'CropUserAvatar',
};

export const VALIDATORS_MAP:  { [key: string]: readonly string[] } = {
  username: [
    VALIDATE.MaxLength,
    VALIDATE.MinLength,
    VALIDATE.SpecialCharacters,
    VALIDATE.UsernameIsFree,
  ],
  firstName: [VALIDATE.MaxLength, VALIDATE.MinLength, VALIDATE.IsRequired],
  lastName: [VALIDATE.MaxLength, VALIDATE.MinLength, VALIDATE.IsRequired],
  email: [
    VALIDATE.MaxLengthEmail,
    VALIDATE.MinLength,
    VALIDATE.IsRequired,
    VALIDATE.IsEmail,
    VALIDATE.EmailIsFree,
  ],
  phone: [VALIDATE.MaxLengthPhone, VALIDATE.MinLength],
  company: [VALIDATE.MaxLength],
  infoRole: [VALIDATE.MaxLength],
  bio: [VALIDATE.MaxLengthTextarea],
  avatar: [VALIDATE.MaxFileSize, VALIDATE.FileType],
  emojiFile: [
    VALIDATE.EmojiMaxFileSize,
    VALIDATE.EmojiFileType,
    VALIDATE.EmojiDimensions,
  ],
  emojiName: [
    VALIDATE.SpecialCharacters,
    VALIDATE.NoDigits,
    VALIDATE.IsEmojiNameRequired,
    VALIDATE.IsEmojiNameUnique,
  ],
  currentEmojiRename: [
    VALIDATE.SpecialCharacters,
    VALIDATE.NoDigits,
    VALIDATE.IsRequired,
    VALIDATE.IsEmojiNameUnique,
  ],
};

export const createError = (id: string, message: string, errorPriority: number) => ({
  id,
  message,
  errorPriority,
});

export const UPGRADE_PLANS: { [key: string]: string } = {
  Free: 'Free',
  Tier1: 'Tier1',
  Tier2: 'Tier2',
};

export const PLAN_FEATURES: { [key: string]: string } = {
  playlistsNumber: 'playlistsNumber',
  availableSpace: 'availableSpace',
  featuresSet: 'featuresSet',
  betaFeatures: 'betaFeatures',
  supportLevel: 'supportLevel',
  specialInvitations: 'specialInvitations',
};

export const PLANS_DATA_FEATURES: { [key in keyof typeof UPGRADE_PLANS]: readonly string[] } = {
  [UPGRADE_PLANS.Free]: [
    PLAN_FEATURES.playlistsNumber,
    PLAN_FEATURES.availableSpace,
    PLAN_FEATURES.featuresSet,
  ],
  [UPGRADE_PLANS.Tier1]: [
    PLAN_FEATURES.playlistsNumber,
    PLAN_FEATURES.availableSpace,
    PLAN_FEATURES.featuresSet,
    PLAN_FEATURES.betaFeatures,
  ],
  [UPGRADE_PLANS.Tier2]: [
    PLAN_FEATURES.playlistsNumber,
    PLAN_FEATURES.availableSpace,
    PLAN_FEATURES.featuresSet,
    PLAN_FEATURES.betaFeatures,
    PLAN_FEATURES.supportLevel,
    PLAN_FEATURES.specialInvitations,
  ],
};

export const SHORTCUTS_UI_MAP: { [key: string]: string | JSX.Element } = {
  space: 'Space',
  option: 'Cmd/Ctrl',
  shift: 'Shift',
  tab: 'Tab',
  rightArrow: <RightArrow14Svg />,
  leftArrow: <LeftArrow14Svg />,
};

export const createShortcutItem = (key: string, value: any) => ({
  key,
  sequences: value.sequences,
  name: T_values[key].name[LANG.en],
  type: T_values[key].type[LANG.en],
  message: T_values[key].message[LANG.en],
});
