import React, { useCallback, useRef } from 'react';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import FloatingTitleFormatToolbarPlugin from './plugin/FloatingTitleFormatToolbarPlugin';
import { EditorUndoRedoPlugin } from '../../shared/lexical/plugin/EditorUndoRedoPlugin';
import { EditorBlurPlugin } from '../../shared/lexical/plugin/EditorBlurPlugin';
import { UpdatePlugin } from '../../shared/lexical/plugin/UpdatePlugin';
import { ParagraphPlaceholderPlugin } from '../../shared/lexical/plugin/ParagraphPlaceholderPlugin';
import { OnlyHeadingPlugin } from './plugin/OnlyHeadingPlugin';
import { titleConfig } from '../../shared/lexical/configs';
import './PlaygroundTitleEditorTheme.css';
import '../../shared/lexical/ui/PlaygroundEditorTheme.css';
import '../../shared/lexical/ui/index.scss';
import { BlurByEnterAndEscPlugin } from './plugin/KeyEnterPlugin';
import { MaxLengthPlugin } from '../../shared/lexical/plugin/MaxLengthPlugin';
import useThrottle from '../../utils/hooks/useThrottle';
import PlaceholderPlugin from '../../shared/lexical/plugin/PlaceHolderPlugin';
import { useLocalization } from '../../LocalizationContext';


function Placeholder() {
  const { t } = useLocalization();
  return <div className="editor-placeholder">{t.writeATitleT}</div>;
}


const TitleEditor = ({
  state,
  onChange,
  isActive,
  onBlurHandler,
}) => {
  const [throttleSave] = useThrottle(300);
  const { t } = useLocalization();

  const preventUpdateOutside = useRef(false);
  const onChangeHandler = useCallback((editorState) => {
    const oldState = JSON.stringify(state);
    const newState = JSON.stringify(editorState);
    if (newState === oldState) {
      return;
    }

    if (!onChange) return;
    preventUpdateOutside.current = true;
    throttleSave(() => () => onChange(editorState.toJSON()));
  }, [onChange, state]);

  return (
    <div className="editor-wrapper">

      <LexicalComposer initialConfig={titleConfig}>
        <FloatingTitleFormatToolbarPlugin />
        <PlaceholderPlugin placeholder={t.emptyTitleT} />
        <OnChangePlugin ignoreSelectionChange onChange={onChangeHandler} />
        <EditorUndoRedoPlugin />
        <BlurByEnterAndEscPlugin />
        <MaxLengthPlugin maxLength={100} />
        <OnlyHeadingPlugin level={1} />
        <ParagraphPlaceholderPlugin placeHolderClass="titlePlaceHolder" />
        <UpdatePlugin state={state} preventUpdateOutside={preventUpdateOutside} isActive={isActive} />
        <EditorBlurPlugin onBlurHandler={onBlurHandler} />
        <RichTextPlugin
          contentEditable={<ContentEditable className="editor-input" />}
          placeholder={<Placeholder />}
          ErrorBoundary={LexicalErrorBoundary}
        />
      </LexicalComposer>
    </div>

  );
};

export default TitleEditor;
