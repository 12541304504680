import React from 'react';
import classNames from 'classnames/bind';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { ReactComponent as Filter20Svg } from '../../images/icons/filter_20.svg';
import styles from './index.module.scss';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import CreatorDynamicCollectionModal from './CreatorDynamicCollectionModal';
import { PlaylistButton } from '../Buttons/ButtonComponent';
import SearchInSmartView from '../../UI/SearchInSmartView/SearchInSmartView';
import { actionEditDynamicCollection } from '../../redux/dynamicCollection/action';
import { useLocalization } from '../../LocalizationContext';

const cn = classNames.bind(styles);

const SearchWrapper = styled.div`
  width: 344px;
  height: 32px;
`;

const FilterWrapper = styled.div`
  margin-left: 19px;
  //height: 56px;
  //padding: 2px 5px;
`;
const SaveWrapper = styled.div`
  margin-right: 25px;

  //
  //height: 56px;
  //padding: 2px 5px;
`;

const ShellDynamicCollectionTopPartLibrary = styled.div`
  width: 695px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DynamicCollectionTopPart = (props) => {
  if (props.isLibrary) return <DynamicCollectionTopPartLibrary {...props} />;
  return <DynamicCollectionTopPartLeftBar {...props} />;
};

const DynamicCollectionTopPartLibrary = (props) => {
  const dispatch = useDispatch();
  const { t } = useLocalization();

  const updateDynamicCollectionHandler = () => {
    dispatch(
      actionEditDynamicCollection({
        dynamicCollectionId: props.dynamicCollectionId,
        filter: props.filter,
        libFilterSearch: props.libFilterSearch,
      }),
    );
    props.setIsEdit(false);
  };
  return (
    <ShellDynamicCollectionTopPartLibrary>
      <FilterWrapper>
        <div
          data-cy="filter"
          className={cn('filters_button', { active: props.isShowFilters })}
          onClick={props.toggleEnableFilters}
        >
          <Filter20Svg />
          <div className={cn('filter_text')}>{t.filtersT}</div>
        </div>
      </FilterWrapper>
      <SearchWrapper>
        <SearchInSmartView
          isLibrary
          libFilterSearch={props.libFilterSearch}
          setLibFilterSearch={props.setLibFilterSearch}
        />
      </SearchWrapper>
      <SaveWrapper>
        {!props.isEdit && <DynamicCollectionSave {...props} />}
        {props.isEdit && (
          <div
            onClick={updateDynamicCollectionHandler}
            className={cn('save_button')}
          >
            <div className={cn('save_text')}>{t.saveChangesT}</div>
          </div>
        )}
      </SaveWrapper>
    </ShellDynamicCollectionTopPartLibrary>
  );
};

const DynamicCollectionTopPartLeftBar = (props) => {
  const { t } = useLocalization();
  return (
    <div className={cn('top')}>
      <div className={cn('filter_left')}>
        <div
          className={cn('filters_button', { active: props.isShowFilters })}
          onClick={props.toggleEnableFilters}
        >
          <Filter20Svg />
          <div className={cn('filter_text')}>{t.filtersT}</div>
        </div>
      </div>

      <div className={cn('save_right')}>
        <DynamicCollectionSave {...props} />
      </div>
    </div>
  );
};

export default DynamicCollectionTopPart;

export const DynamicCollectionSave = (props) => {
  const { t } = useLocalization();
  const [ref, showSaveOption, setShowSaveOption] = useComponentVisible(false);
  if (!props.isLibrary) {
    return (
      <>
        <div
          data-cy="saveSmartViewBtn"
          onClick={() => setShowSaveOption(true)}
          className={cn('save_button', { active: showSaveOption })}
        >
          <div className={cn('save_text')}>{t.saveSmartViewT}</div>
        </div>
        {showSaveOption && (
          <CreatorDynamicCollectionModal
            setShowSaveOption={setShowSaveOption}
            filter={props.filter}
            libFilterSearch={props.libFilterSearch}
            dref={ref}
            isLibrary={props.isLibrary}
          />
        )}
      </>
    );
  }

  return (
    <>
      <PlaylistButton clickCb={() => setShowSaveOption(true)}>
        <div data-cy="saveSmartViewBtn" className={cn('')}>
          {t.saveSmartViewT}
        </div>
      </PlaylistButton>
      {showSaveOption && (
        <CreatorDynamicCollectionModal
          setShowSaveOption={setShowSaveOption}
          filter={props.filter}
          isFullScreen
          libFilterSearch={props.libFilterSearch}
          dref={ref}
          isLibrary={props.isLibrary}
        />
      )}
    </>
  );
};
