import React, { useState } from 'react';
import styled from 'styled-components';
import { CustomPicker } from 'react-color';
import {
  EditableInput,
  Hue,
  Saturation,
} from 'react-color/lib/components/common';
import classNames from 'classnames/bind';
import rawStyles from './index.module.scss';
import { DATA_PARENTS } from '../../utils/constants';
import Button2023 from '../Buttons/Button2023';
import { useLocalization } from '../../LocalizationContext';

const cn = classNames.bind(rawStyles);

const HueContainer = styled.div`
  margin-top: 10px;
  height: 8px;
  position: relative;
  margin-bottom: 10px;
  border-radius: 100px;
  overflow: hidden;
`;

const SaturationContainer = styled.div`
  width: 232px;
  height: 187px;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
`;

const Pointer = styled.div`
  width: 8px;
  height: 8px;
  border: 1px solid white;
  background: transparent;
  border-radius: 50%;
`;

const COLOR_PICKER_STYLES = {
  inputHEX: {
    fontWeight: 400,
    fontSize: 10,
    lineHeight: 18,
    color: '#242633',
    background: '#FFFFFF',
    border: '1px solid #D7DDF7',
    borderRadius: 2,
    height: 20,
    width: 50,
  },
  input: {
    fontWeight: 400,
    fontSize: 10,
    lineHeight: 18,
    color: '#242633',
    background: '#FFFFFF',
    border: '1px solid #D7DDF7',
    borderRadius: 2,
    height: 20,
    width: 27,
  },
};

export const CloseButton = ({
  onCancelClickHandler,
  text = 'Close',
  derivedParentData = '',
}) => {
  return (
    <div
      className={cn('button', 'grey')}
      onClick={onCancelClickHandler}
      data-parent={`${derivedParentData} CloseButton`}
    >
      <div
        className={cn('text')}
        data-parent={`${derivedParentData} CloseButton`}
      >
        {text}
      </div>
    </div>
  );
};
export const SaveButton = ({
  onSaveClickHandler,
  text = 'Save',
  derivedParentData = '',
}) => {
  return (
    <div
      className={cn('button', 'blue')}
      onClick={onSaveClickHandler}
      data-parent={`${derivedParentData} SaveButton`}
    >
      <div
        className={cn('text')}
        data-parent={`${derivedParentData} SaveButton`}
      >
        {text}
      </div>
    </div>
  );
};

export const ColorPicker = ({
  hex,
  hsl,
  hsv,
  rgb,
  onChange,
  pickerClickHandler,
  onSaveClickHandler,
  onCancelClickHandler,
  refPickerHueParent,
  forSmartModal,
  defaultPosition = {
    top: 0,
    right: -258,
  },
  parentElementWidth = 184,
}) => {
  const { t } = useLocalization();
  const [derivedParentData] = useState(
    `${DATA_PARENTS.createLibrary} ColorPalette ColorPicker`,
  );
  const calculateVerticalPosition = () => {
    if (refPickerHueParent && refPickerHueParent?.current) {
      const height = document.documentElement.clientHeight;
      const positionY = defaultPosition.top;
      const refAbsoluteY = refPickerHueParent.current.getBoundingClientRect().y;
      const heightElement = 305;
      const isOpenInDown = height > refAbsoluteY + heightElement + positionY;
      if (isOpenInDown) return positionY;
      return positionY - heightElement;
    }
    return defaultPosition.top;
  };

  const calculateHorizontalPosition = () => {
    if (forSmartModal) {
      return -23;
    }
    if (refPickerHueParent && refPickerHueParent?.current) {
      const width = document.documentElement.clientWidth;
      const positionX = 57;
      const refAbsoluteX = refPickerHueParent?.current.getBoundingClientRect().x;
      const widthElement = 258;
      const isOpenInRight = width > refAbsoluteX + widthElement + positionX;
      if (isOpenInRight) return defaultPosition.right;
      return parentElementWidth;
    }

    return defaultPosition.right;
  };

  const divStyle = {
    top: calculateVerticalPosition(),
    right: calculateHorizontalPosition(),
  };

  return (
    <div
      className={cn('wrapper')}
      data-parent={derivedParentData}
      onClick={pickerClickHandler}
      onMouseDown={pickerClickHandler}
      style={divStyle}
    >
      <SaturationContainer>
        <Saturation hsl={hsl} hsv={hsv} onChange={onChange} pointer={Pointer} />
      </SaturationContainer>

      <HueContainer>
        <Hue hsl={hsl} onChange={onChange} pointer={Pointer} />
      </HueContainer>

      <div className={cn('input_wrapper')} data-parent={derivedParentData}>
        <div className={cn('input_container')} data-parent={derivedParentData}>
          <div className={cn('label')}>HEX:</div>
          <EditableInput
            style={{ input: COLOR_PICKER_STYLES.inputHEX }}
            value={hex}
            onChange={onChange}
            data-parent={derivedParentData}
          />
        </div>

        <div className={cn('input_container')}>
          <div className={cn('label')}>R:</div>
          <EditableInput
            style={{ input: COLOR_PICKER_STYLES.input }}
            value={rgb.r}
            onChange={onChange}
            data-parent={derivedParentData}
          />
        </div>

        <div className={cn('input_container')}>
          <div className={cn('label')}>G:</div>
          <EditableInput
            style={{ input: COLOR_PICKER_STYLES.input }}
            value={rgb.g}
            onChange={onChange}
            data-parent={derivedParentData}
          />
        </div>

        <div className={cn('input_container', 'last')}>
          <div className={cn('label')}>B:</div>
          <EditableInput
            style={{ input: COLOR_PICKER_STYLES.input }}
            value={rgb.b}
            onChange={onChange}
            data-parent={derivedParentData}
          />
        </div>
      </div>

      <div className={cn('button_group')}>
        <Button2023
          variant="primary"
          width={69}
          height={32}
          text={t.closeUpT}
          derivedParentData={derivedParentData}
          // noMargins
          handleButtonClick={onCancelClickHandler}
        />
        <Button2023
          variant="secondary"
          derivedParentData={derivedParentData}
          width={72}
          height={32}
          text={t.saveUpT}
          noMargins
          handleButtonClick={onSaveClickHandler}
        />
      </div>
    </div>
  );
};

export default CustomPicker(ColorPicker);
