import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useLocalization } from '../../LocalizationContext';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px auto;
`;

const LogOut = () => {
  const history = useHistory();
  const { t } = useLocalization();

  useEffect(() => {
    localStorage.removeItem('jwt');
    localStorage.removeItem('userId');
    sessionStorage.removeItem('jwt');
    sessionStorage.removeItem('userId');
    history.push('/login');
  }, []);
  return <Page>{t.pleaseWaitForRedirectsT}</Page>;
};

export default LogOut;
