import React, { useRef } from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_TITLE, empty } from '../../utils/constants';
import { timeSince } from '../../utils/dateConvert';
import { TiniCartPlaylistProgressBar } from '../MiniPlaylist/MiniPlaylistProgressBar';
import { Avatar } from '../MainHeader/User';
import AvatarPlaceholder from '../Avatar';

import styles from './index.module.css';
import { actionPageWillDownload } from '../../redux/currentPage/action';
import { actionCreator } from '../../shared/redux/actionHelper';
import DashboardColumns from '../../redux/dashboardColumns/types';
import { useLocalization } from '../../LocalizationContext';

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1px;
  ${({ isFooter }) => (isFooter ? `
   margin-top: 3px;
  ` : '')}
`;

const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 9px;
  font-size: 14px;
  color: #242633;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  white-space: nowrap;
  padding-bottom: 2px;
  max-width: 200px;
`;
const Channel = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  position: relative;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  ${({ isToday }) => (isToday ? `
    text-transform: capitalize;
  ` : '')}
  color: #596080;
`;
const ReadContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: #585858;

  & svg {
    width: 14px;
    height: 14px;
    margin-left: 0;
  }
`;

const User = styled.div`
  justify-content: left;
  display: flex;
  align-items: center;
  margin-top: 5px;

  p{
    margin: 0;
    color: #939393;
    font-size: 13px;
    font-weight: 500;
  }

  .username {
    margin-left: 8px;
  }
`;

const CardInDashBoard = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const refForVis = useRef();
  const { t } = useLocalization();
  // const [isValidDate, setNumPages] = useState('');
  const canOpen = props.item.isCanReadPublish || props.item.isCanReadDraft;
  const notSeenPlaylist = useSelector(
    (state) => state.dashboardColumns.notSeenPlaylist || empty,
  );
  const goToPlaylist = () => {
    if (!canOpen) return;
    dispatch(actionPageWillDownload());
    if (props.isInbox && notSeenPlaylist[props.item.wrapperId]) {
      dispatch(
        actionCreator(DashboardColumns.card.addViewedPlaylistManagerSR, {
          playlistWrapperId: props.item.wrapperId,
        }),
      );
    }
    if (props.item.isCanReadDraft) {
      const sharedNotOwner = !props.item.isOwner;
      const type = sharedNotOwner ? 'shared' : 'edit';
      const id = sharedNotOwner ? props.item.wrapperId : props.item.playlistId;
      history.push(`/maker/${id}/${type}`);
      return;
    }
    history.push(
      `/maker/${props.item.playlistId}/edit/${props.item.channelValidId}?fromPageType=channel`,
    );

    // if (!props.item.channel[0]?.id && !props.item.isShared) return;
    // const stackTrace = history?.location?.state?.traceStack || [];
    // const localStack = [...stackTrace];
    // const stackStep = createStackStep(history);

    // stackStep.step.playlistId = props.item.id;
    // stackStep.step.from = history.location.pathname;
    // stackStep.step.localSelector = props.localSelector;
    // stackStep.step.activeSliderOption = props.activeNavSlider;
    //
    // stackStep.departureUrl = history.location.pathname;
    //
    // localStack.push(stackStep);

    // if (!props.item.isOwner && !props.item.isShared) return;

    //
    // const type = sharedNotOwner ? 'shared' : 'edit';
    // const id = sharedNotOwner ? props.item.wrapperId : props.item.playlistId;
    //
    // const url = `/maker/${id}/${type}`;
    //
    // history.push(url, { ...history.location.state });
  };

  const abbreviation = props.item.user?.fullName?.split(' ').map(e => e[0]).join('');

  // const cardDatePassed = timeSince(props.item.lastModifiedDate * 1000);
  const sharingTimeRemaining = props.item?.playlist?.usersToSharing?.edges?.[0]?.sharedAvailableTo;
  const sharingTimeRemainingCalculation = sharingTimeRemaining
    ? timeSince(sharingTimeRemaining * 1000, true) : t.openToReadT;
  const cardDate = props.item.isOwner ? t.openToReadT : sharingTimeRemainingCalculation;
  return (
    <div
      ref={refForVis}
      className={`${styles.cardInDashboard} 
            ${props.highlighted === props.item.id
          || props.highlighted === props.item.oldIdForHighlighted
        ? styles.highlighted
        : ''
        } 
            ${props.cardIsDrag ? styles.highlightedDrag : ''}
            ${props.item.isOwner ? styles.isOwn : styles.isNotOwn}
            ${!canOpen ? styles.notPerm : ''}
            ${props.isInbox && notSeenPlaylist[props.item.wrapperId]
          ? styles.notSeen
          : ''
        }`}
      onClick={goToPlaylist}
    >
      <Title>
        {' '}
        {props.item.title || props.item.name || t.defaultSmartFileTitleT}{' '}
      </Title>

      {props.item?.user
        && (
          <User>
            {props.item?.user?.avatar ? (
              <Avatar
                className="avatar_border_hover"
                width={22}
                src={props.item?.user?.avatar}
                alt="avatar"
              />
            ) : (
              <AvatarPlaceholder
                width={22}
                name={props.item?.user?.fullName}
                abbreviation={abbreviation}
              />
            )}
            {props.item.user?.fullName && (
            <p className={`${!props.item?.user?.avatar ? 'username' : ''}`}>{props.item.user.fullName}</p>
            )}
          </User>
        )}
      <Row isFooter>
        {!!props.item.isOwner && (
          <ReadContent>
            {props.item.channel.length > 0 ? '12/25' : t.draftT}
          </ReadContent>
        )}
        {!props.item.isOwner && (
          <TiniCartPlaylistProgressBar percentage={props.item.readPercentage} />
        )}
        <Channel isToday={cardDate === 'today'}>{cardDate}</Channel>
      </Row>
    </div>
  );
};

export default CardInDashBoard;
