import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReactComponent as SectionChevronIcon } from '../../../images/icons/tiny_chevron_down_filled.svg';
import { ExtraTabs } from '../../../navigate/navigate';
import {
  Counter,
  IconPlace,
  Info,
  SectionChevronIconWrapper,
  MajorSelector,
  SelectorWrapper,
  MajorText,
  New,
  Selector,
  CollapsingSectionWrapper,
} from '../styled';
import { useLocalization } from '../../../LocalizationContext';

const NewTolTip = ({ count }) => <New>{count}</New>;

const WorkflowSection = ({ onSelectorClick }) => {
  const history = useHistory();
  const { inBoxCounter } = useSelector((state) => state.inbox);
  const { outBoxCounter } = useSelector((state) => state.outbox);
  const { t } = useLocalization();

  const {
    unseenPagesManagerMapNotification,
    unseenPlaylistManagerMapInAllNotification,
    unseenPlaylistMapInChannelNotification,
    unseenOutbox,
    unseenInvite,
    unseenInbox,
  } = useSelector((state) => state.support);

  const [isWorkflowCollapsed, setWorkflowCollapsed] = useState(false);


  const countInbox = Object.values(unseenInbox).length;
  const countOutbox = Object.values(unseenOutbox).length;

  const calcSectionItem = useCallback(() => {
    if (!isWorkflowCollapsed) return <></>;
    const [key, value] = Object.entries(ExtraTabs).find(([key]) => history.location.pathname.includes(key)) || [];
    if (!key) return <></>;
    return (
      <WorkflowSector
        keyItem={key}
        onSelectorClick={onSelectorClick}
        isActive
        value={value}
        countInbox={countInbox}
        countOutbox={countOutbox}
        inBoxCounter={inBoxCounter}
        outBoxCounter={outBoxCounter}
      />
    );
  }, [isWorkflowCollapsed, countInbox, countOutbox, unseenInbox, inBoxCounter, outBoxCounter, history.location.pathname]);

  return (
    <>
      <MajorSelector>
        <SelectorWrapper>
          <SectionChevronIconWrapper
            isCollapsed={isWorkflowCollapsed}
            onClick={() => setWorkflowCollapsed(!isWorkflowCollapsed)}
          >
            <SectionChevronIcon />
          </SectionChevronIconWrapper>
          <MajorText>{t.workflowT}</MajorText>
        </SelectorWrapper>
      </MajorSelector>
      <CollapsingSectionWrapper
        isCollapsed={isWorkflowCollapsed}
        height="100px"
        time={0.4}
      >
        {Object.entries(ExtraTabs).map(([key, value]) => (
          <WorkflowSector
            key={key}
            isActive={history.location.pathname.includes(key)}
            keyItem={key}
            onSelectorClick={onSelectorClick}
            value={value}
            countInbox={countInbox}
            inBoxCounter={inBoxCounter}
            outBoxCounter={outBoxCounter}
            countOutbox={countOutbox}
          />
        ))}
      </CollapsingSectionWrapper>
      {calcSectionItem()}
    </>
  );
};


const WorkflowSector = React.memo(
  function WorkflowSector({ keyItem, onSelectorClick, value, countInbox, countOutbox, inBoxCounter, outBoxCounter, isActive, ...props }) {
    const { t } = useLocalization();
    return (
      <Selector
        data-cy={keyItem}
        onClick={() => onSelectorClick(keyItem)}
        active={isActive}
        isPlusDisabled
        isDisabled={value.disabled}
      >
        <Info active={isActive}>
          <IconPlace className="fill-icon-hover">
            {value.icon}
          </IconPlace>
          {t[value.textID]}
        </Info>
        {!!countInbox && keyItem === 'inbox' && (
          <NewTolTip count={countInbox} />
        )}
        {!!countOutbox && keyItem === 'outbox' && (
          <NewTolTip count={countOutbox} />
        )}
        {keyItem === 'inbox' && (
          <Counter
            inbox
            active={isActive}
          >
            {inBoxCounter}
          </Counter>
        )}
        {keyItem === 'outbox' && (
          <Counter
            inbox
            active={isActive}
          >
            {outBoxCounter}
          </Counter>
        )}
      </Selector>
    );
  },
);

export default WorkflowSection;
