import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { MuxAudioVideoPlayer } from '../../../pages/Player/childComponents/MuxPlayer';
import { useLocalization } from '../../../LocalizationContext';

const VideoWrapper = styled.div`
  width: 100%;
  height: inherit;
  max-height: calc(100vh - 200px);
  object-fit: contain;
  overflow: hidden;
  display: flex;
  justify-content: center;
  ${({ show }) => (show ? 'visibility: visible;' : 'visibility: hidden;')}
  ${({ transformed, isAudio }) => (transformed ? `
    top: 50%;
    left: 50%;
    transform: translate(${isAudio ? '-50%, -25%' : '-50%, -50%'}) scale(1.25);
    height: calc(100vh - 200px);
    max-height: 55vh;
    transition: top 0.2s;
  ` : `
    transform: none;
    transition: top 0.2s;
  `)}
`;

const LoaderWrapper = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 25px;
  color: #ffffff;
  width: max-content;
  margin: auto;
`;
const VideoPreview = ({ item, isAudio }) => {
  const [show, setShow] = useState(false);
  const { t } = useLocalization();

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 1000);

    return () => setShow(false);
  }, []);

  return (
    <>
      {!show && <LoaderWrapper>{t.loadingT}</LoaderWrapper>}
      <VideoWrapper
        show
        isAudio={isAudio}
      >
        <MuxAudioVideoPlayer item={item} />
      </VideoWrapper>
    </>
  );
};

export default VideoPreview;
