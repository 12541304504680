import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames/bind';
import { actionCreator } from '../../shared/redux/actionHelper';
import { Settings } from '../../redux/settings/types';
import rawStyles from './index.module.scss';
import { ReactComponent as LogoSvg } from '../../images/2023/svg/Logo-maker.svg';
import {
  BlueButton,
  GreyButton,
} from '../SettingsSection/CommonComponents/Buttons';
import { DEFAULT_PAGE_PATH } from '../../utils/constants';
import { ConfirmLoadingSpinner } from '../Channels/MemberRow/RolesCell/Spinner';
import { ReactComponent as SpinnerSvg } from '../../images/icons/spinner.svg';
import { useLocalization } from '../../LocalizationContext';

const cn = classNames.bind(rawStyles);

const ProtectedConfirmEmailChange = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useLocalization();

  const onPasswordChange = (e) => setPassword(e.target.value);

  const confirmEmailChange = () => {
    dispatch(
      actionCreator(Settings.Profile.ConfirmEmailChange, {
        password,
        setIsConfirmed,
        setIsLoading,
      }),
    );
  };

  const goToHomePage = () => history.push(DEFAULT_PAGE_PATH);
  const goToSettings = () => history.push('/settings/profile');

  if (isConfirmed) {
    return (
      <div className={cn('main')}>
        <div className={cn('container')}>
          <div className={cn('logo_wrapper')}>
            <LogoSvg />
          </div>

          <div className={cn('label')}>{t.choseRedirectT}</div>

          <div className={cn('button_group_wrapper')}>
            <GreyButton
              onClick={goToSettings}
              text={t.backToSettingsT}
            />
            <BlueButton
              onClick={goToHomePage}
              text={t.backToHomePageT}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={cn('main')}>
      <div className={cn('container')}>
        <div className={cn('logo_wrapper')}>
          <LogoSvg />
        </div>

        <div className={cn('label', 'password')}>
          {t.enterPasswordT}
        </div>
        <div className={cn('input_wrapper')}>
          <input type="password" value={password} onChange={onPasswordChange} />

          {!!isLoading && (
            <ConfirmLoadingSpinner>
              <SpinnerSvg />
            </ConfirmLoadingSpinner>
          )}
        </div>

        <div className={cn('button_wrapper')}>
          <BlueButton
            onClick={confirmEmailChange}
            text={t.confirmT}
          />
        </div>
      </div>
    </div>
  );
};

export default ProtectedConfirmEmailChange;
