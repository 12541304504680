import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { convertDateListView, convertToDifferanceDates } from '../../../../utils/dateConvert';
import { actionShowMessage } from '../../../../redux/support/action';
import { MessageType } from '../../../../utils/constants';
import { isRoleInPlaylist } from '../../../../utils/permissions';
import { EndDateRed, TextWrapperUPV } from './styled';
import { useLocalization } from '../../../../LocalizationContext';

const { isOwner } = isRoleInPlaylist;

const EndDate = () => {
  const dispatch = useDispatch();
  const { t } = useLocalization();
  const { type } = useParams();
  const history = useHistory();
  const { currentRole } = useSelector(state => state.currentPage);
  const [date, setDate] = useState({ availableTo: '', diff: '', sharedAvailableTo: null });

  const {
    editManager, sharedAvailableTo, sharedAvailableFrom,
  } = useSelector((state) => state.currentPage);

  useEffect(() => {
    const sharedAvailableToEx = sharedAvailableTo !== undefined && sharedAvailableTo !== null
      ? sharedAvailableTo
      : editManager?.sharedAvailableTo;

    const sharedAvailableFromEx = sharedAvailableFrom !== undefined && sharedAvailableFrom !== null
      ? sharedAvailableFrom
      : editManager?.sharedAvailableFrom;

    const timeNow = Math.floor(Date.now() / 1000);
    const openToReed = sharedAvailableToEx === 0 && sharedAvailableToEx === 0;

    const isValidTime = openToReed || (sharedAvailableToEx && sharedAvailableToEx >= timeNow)
      && (sharedAvailableFromEx && sharedAvailableFromEx <= timeNow);

    const isShared = type === 'shared';

    if (isShared && !(!currentRole || isOwner[currentRole]) && !isValidTime) {
      history.push('/content/smartfiles/shared');
      dispatch(actionShowMessage({ type: MessageType.PlaylistNotAvailableAnyMore }));
      return;
    }

    setDate({
      availableTo: convertDateListView(sharedAvailableToEx),
      diff: convertToDifferanceDates(sharedAvailableToEx),
      sharedAvailableTo: sharedAvailableToEx,
    });
  }, [sharedAvailableTo, editManager?.sharedAvailableTo]);


  return (
    <>
      {!!date.sharedAvailableTo && editManager && <EndDateRed> {t.endsT} {date.availableTo} </EndDateRed>}
      {date.sharedAvailableTo === 0 && editManager && <TextWrapperUPV>{t.openToReadT}</TextWrapperUPV>}
      {!editManager && <TextWrapperUPV>{t.openToReadT}</TextWrapperUPV>}
    </>
  );
};

export default EndDate;
