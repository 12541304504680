import React from 'react';
import { DEFAULT_TITLE, INBOX_CARD_TYPE } from '../../utils/constants';
import BoardColumnLabelLayer from '../../components/MiniPlaylist/NewPlaylist/CardView/BoardColumnLabelLayer';
import { convertDateListView } from '../../utils/dateConvert';
import { ReactComponent as CheckboxOnSvg } from '../../images/icons/checkbox_on.svg';
import { ReactComponent as CheckboxOffSvg } from '../../images/icons/checkbox_off.svg';
import { IconRole } from '../../utils/permissions';
import Tooltip from '../../shared/Tooltips/Tooltip';
import InboxPagePreview from './InboxPagePreview';
import InboxCardButtons from './InboxCardButtons';
import styles from './index.module.scss';
import { calculateSingleUserAvatar } from '../Maker/components/Info/helpers';
import InboxSender from './InboxSender';
import {
  BoardColumnWrapper,
  CardBadge,
  CardBadgeWrapper,
  CardBody,
  CardBodyInfo,
  CardInfo,
  CardInfoChannel,
  CardInfoSize,
  CardReadMarker,
  ImageWrap,
  ImageWrapper,
  InboxCardButtonsWrapper,
  InfoBlock,
  InviteChannelName,
  Message,
  SelectCheckBoxWrapper,
  Time,
  TimeAndAvatar,
  Title,
} from './styled';
import FolderIconLayout from '../Maker/FolderIconLayout';
import FolderImage from '../Maker/FolderImage';
import LastUpdateDateDisplay from '../../components/LastUpdateDateDisplay/LastUpdateDateDisplay';
import OutboxRecipients from '../OutBox/OutboxRecipients';
import { useLocalization } from '../../LocalizationContext';


export const InboxCardBody = React.memo(function InboxCardBody({
  item,
  channels,
  pickCard,
  isSelected,
  setSelected,
  pageRef,
}) {
  const { t } = useLocalization();
  const badgeTitle = item.isHasUpdate ? 'UPDATE' : 'NEW';
  const isInvite = item.type === INBOX_CARD_TYPE.invite;
  const isSharedPlaylist = item.type === INBOX_CARD_TYPE.sharedPlaylist;
  const isSharedInChannel = item.type === INBOX_CARD_TYPE.channelPlaylist;
  const isPage = item.type === INBOX_CARD_TYPE.page;
  const messageToDisplay = !item.availableTo ? t.openToReadT : `Ends ${convertDateListView(item.availableTo)}`;
  const isOpenToRead = messageToDisplay === t.openToReadT;
  const isCardSelected = isSelected;

  const sizeInMb = !Number.isNaN(+item.totalSizeInBytes)
    && `${(item.totalSizeInBytes / (1024 * 1024)).toFixed(2)} MB`
    || '';

  const userInRight = (item.userWhoLastUpdate && !item.isRead && item.isHasUpdate)
    ? item.userWhoLastUpdate
    : item.userWhoSent;

  const selectItem = (e) => {
    e.stopPropagation();
    pickCard({ id: item.id, type: item.type, isRead: item.isRead, inChannel: item.inChannel });
    setSelected(!isSelected);
  };
  return (
    <CardBody>
      <CardInfo>
        <div className={styles.selector_place}>
          <SelectCheckBoxWrapper isSelected={isCardSelected} onClick={selectItem}>
            {isCardSelected ? <CheckboxOnSvg /> : <CheckboxOffSvg />}
          </SelectCheckBoxWrapper>
          <CardReadMarker isRead={item.isRead} isSelected={isCardSelected} />
        </div>
        <div className={styles.avatar_and_status}>
          <InboxSender userWhoSent={item.userWhoSent} />
          {!item.isRead && (
            <CardBadgeWrapper>
              <CardBadge isUpdate={item.isHasUpdate}>
                {badgeTitle}
              </CardBadge>
            </CardBadgeWrapper>
          )}
        </div>
      </CardInfo>

      <CardBodyInfo>
        {(isSharedPlaylist || isSharedInChannel) && (
          <ImageWrapper isFolderDefault={!!item.playlist.cropUrl}>        {item.playlist.cropUrl ? (
            <>
              <ImageWrap src={item.playlist.cropUrl} />
              <FolderIconLayout isOverCoverImage />
            </>
          ) : (
            <FolderImage isTable itemId={item.id} currentColor={item.playlist?.defaultPreviewColor} />
          )}
          </ImageWrapper>
        )}
        {isPage && <InboxPagePreview pageRef={pageRef} id={item.id} />}
        {(isSharedPlaylist || isSharedInChannel) && <Title>{item.playlist.title || t.defaultSmartFileTitleT}</Title>}
        {isInvite && (
          <>
            {t.invitationToJoinT}
            <InviteChannelName>
              {/* TODO channels need check */}

              {IconRole[channels[item.channelId]?.role]}
              {item.channelName || t.defaultChannelTitleT}
            </InviteChannelName>
          </>
        )}
        {isPage && <Title>{item.title || t.defaultSmartPageTitleT}</Title>}
      </CardBodyInfo>

      <CardInfoChannel>
        {item.recipients && (
          <OutboxRecipients
            isPage={isPage}
            id={item.id}
            channels={channels}
            recipients={item.recipients}
          />
        )}
      </CardInfoChannel>

      <CardInfoSize isRight>
        {sizeInMb}
      </CardInfoSize>

      <InfoBlock isRight>
        {(isSharedPlaylist || isSharedInChannel) && (
          <>
            <Message isOpenToRead={isOpenToRead}>{`${messageToDisplay} `}</Message>
          </>
        )}
      </InfoBlock>

      <BoardColumnWrapper>
        {(isSharedPlaylist || isSharedInChannel) && (
          <Tooltip text={t.selectAColumnT} direction="down">
            <BoardColumnLabelLayer isInboxCard item={item} />
          </Tooltip>
        )}
      </BoardColumnWrapper>

      <TimeAndAvatar>
        <Time> {item.date && <LastUpdateDateDisplay date={item.date} /> } </Time>
        <div className={styles.avatar_wrapper}>
          {calculateSingleUserAvatar(userInRight, 22)}
        </div>
      </TimeAndAvatar>

      <InboxCardButtonsWrapper>
        <InboxCardButtons item={item} />
      </InboxCardButtonsWrapper>

    </CardBody>
  );
});

export default InboxCardBody;
