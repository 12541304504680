import React, { FC } from 'react';
import { ABPopUpDivider, ABPopUpWrapper } from './styled';
import { ReactComponent as EditSVG } from '../../images/2023/svg/small/approval-buttons/popupedit_19_18.svg';
import { ReactComponent as DeleteSVG } from '../../images/2023/svg/small/approval-buttons/popupdelete_19_18.svg';
import Tooltip from '../../shared/Tooltips/Tooltip';
import { useLocalization } from '../../LocalizationContext';

interface AbPopupProps {
    editCB: () => void;
    deleteCB: () => void;
}


const AbPopup: FC<AbPopupProps> = ({ editCB, deleteCB }) => {
  const { t } = useLocalization();
  return (
    <ABPopUpWrapper className="ab_pop_up">
      <Tooltip text={t.editT} direction="down" place="ab_popup_edit">
        <EditSVG onClick={editCB} />
      </Tooltip>
      <ABPopUpDivider />
      <Tooltip text={t.deleteT} direction="down" place="ab_popup_delete">
        <DeleteSVG className="delete_svg" onClick={deleteCB} />
      </Tooltip>
    </ABPopUpWrapper>
  );
};

export default AbPopup;
