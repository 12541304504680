import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionLoginUser,
  actionSetRequestLoader,
  actionSetServerError,
} from '../../redux/user/action';
import styles from './Login.module.scss';
import validateEmail from '../../utils/validators/validateEmail';
import '../../utils/loader.css';
import { ReactComponent as LogoSvg } from '../../images/2023/svg/Logo_black_text.svg';
import LoginIntro from './Intro';
import { EyeContainer } from './ForgotPassword/ForgotView';
import { ReactComponent as EyeOff16Svg } from '../../images/icons/eye_off.svg';
import { ReactComponent as EyeOn16Svg } from '../../images/icons/eye_on.svg';
import ButtonComponent from '../../components/Buttons/ButtonComponent';
import UI from '../../UIComponents';
import { empty } from '../../utils/constants';
import { useLocalization } from '../../LocalizationContext';

export const LoginBackGround = () => {
  return (
    <div className={styles.login} />
  );
};

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useLocalization();

  const { requestSent, serverErrorMessage } = useSelector((state) => state.user) || empty;

  const [errorEmail, setErrorEmail] = useState('');
  const [errorPass, setErrorPass] = useState('');
  const [password, setPass] = useState('');
  const [email, setEmail] = useState('');

  const [serverError, setServerError] = useState(false);

  const [isRemember] = useState(true);
  const [isShowPass, setIsShowPass] = useState('password');

  useEffect(() => {
    return () => dispatch(actionSetServerError(''));
  }, []);

  useEffect(() => {
    if (serverErrorMessage === 'Invalid email or password') {
      setServerError(true);
    }
  }, [serverErrorMessage]);

  const send = () => {
    dispatch(actionSetRequestLoader(true));
    dispatch(actionLoginUser(email, password, isRemember, history));
  };

  const validate = () => {
    if (serverErrorMessage) dispatch(actionSetServerError(''));
    const emailIsValid = validateEmail(email);
    const passwordIsValid = password && password.length >= 6;
    if (!email) setErrorEmail(t.emailRequiredErrorT);
    else if (!emailIsValid) setErrorEmail(t.invalidEmailErrorT);
    if (!password) setErrorPass(t.passwordRequiredErrorT);
    else if (!passwordIsValid) setErrorPass(t.passwordLengthErrorT);
    if (email && emailIsValid && password && passwordIsValid) {
      send();
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      validate();
    }
  };

  const togglePasswordShow = () => {
    if (isShowPass === 'password') setIsShowPass('text');
    else setIsShowPass('password');
  };

  const onEmailChange = (event) => {
    !!errorEmail && setErrorEmail('');
    setEmail(event.target.value);
    setServerError(false);
  };
  const onPassChange = (event) => {
    !!errorPass && setErrorPass('');
    setPass(event.target.value);
    setServerError(false);
  };


  return (
    <div className={styles.scroll_wrapper} onKeyPress={handleKeyPress}>
      <div className={styles.scroll_balancer}>
        {requestSent && (
          <div className="loader-wrapper">
            <div className="loader">{t.loadingT}</div>
          </div>
        )}

        <LoginBackGround />

        <div className={styles.column_wrapper}>
          <div className={styles.column}>
          <div className={styles.logo_wrapper}>
              <LogoSvg />
          </div>
            <div className={`${styles.header} ${styles.is_login}`}>{t.signInT}</div>

            <div className={`${styles.no_account_block} ${styles.is_login}`}>
              <div className={styles.text_signup}>
                {t.newUserQuestionT}&nbsp;
              </div>
              <NavLink
                className={styles.text_info_link}
                to="/signup"
                activeStyle={{
                  fontWeight: 'bold',
                  color: 'red',
                }}
              >
                {t.createAnAccountT}
              </NavLink>
            </div>
            <UI.Error error={serverErrorMessage} isLogin />
            <div className={styles.email_info_block}>
              <div
                className={`${styles.field} ${
                  (serverError || errorEmail) && styles.field_has_error
                }`}
              >
                <input
                  data-cy="email"
                  disabled={requestSent}
                  className={styles.input}
                  placeholder={t.emailAddressPlaceholerT}
                  autoComplete="username"
                  type="text"
                  value={email}
                  tabIndex={1}
                  onChange={onEmailChange}
                />
              </div>
              <UI.Error error={errorEmail} isLogin />
            </div>

            <div className={styles.password_info_block}>
              <div
                className={`${styles.field} 
                  ${(serverError || errorPass) && styles.field_has_error}`}
              >
                <input
                  data-cy="password"
                  disabled={requestSent}
                  type={isShowPass}
                  className={styles.input}
                  placeholder={t.passwordPlaceholerT}
                  value={password}
                  tabIndex={2}
                  onChange={onPassChange}
                />
                {!!password.length
                  && (
                    <div className={styles.eye_container}>
                      <EyeContainer onClick={togglePasswordShow}>
                        {isShowPass === 'password' && <EyeOff16Svg />}
                        {isShowPass === 'text' && <EyeOn16Svg />}
                      </EyeContainer>
                    </div>
                  )}
              </div>

              <UI.Error error={errorPass} isLogin />
            </div>
            <NavLink
              tabIndex={5}
              to="/forgot_password"
              className={`${styles.text_info_link} ${styles.forgot_password}`}
              activeStyle={{
                fontWeight: 'bold',
                color: 'red',
              }}
            >
              {t.forgotPasswordQuestionT}
            </NavLink>
            <div
              data-cy="submitSignIn"
              tabIndex={3}
              className={styles.sign_in_button_container}
            >
              <ButtonComponent
                text={t.signInT}
                fullWidth
                height={48}
                clickCb={validate}
                isSignIn
              />
            </div>
          </div>
        </div>
        <LoginIntro />
      </div>
    </div>
  );
};

export default Login
