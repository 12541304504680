import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { actionCreator } from '../../shared/redux/actionHelper';
import EditPlaylist from '../../redux/playlists/types';
import { DEFAULT_TITLE, openModalType } from '../../utils/constants';
import NoAvailable from '../AccessPage/NoAvailable';
import EnterAccessCode from '../AccessPage/EnterAccessCode';
import WrongUser from '../AccessPage/WrongUser';
import PlaylistMain from './PlaylistMain';
import UPVpreview from '../../components/GlobalModals/UPVPreview';
import { setServiceWorkerLocation } from '../../utils/registerServiceWorker';
import { useLocalization } from '../../LocalizationContext';

const PlaylistShared = ({ isShowByWeb }) => {
  const dispatch = useDispatch();
  const { t } = useLocalization();
  const { id } = useParams();
  const projectName = 'Quidzi';
  const history = useHistory();
  const { shareState, isDownload, notAvailable, title } = useSelector(
    (state) => state.currentPage,
  );
  const { openModal } = useSelector((state) => state.user);

  const getSharedData = (accessCode) => dispatch(
    actionCreator(EditPlaylist.GetSharedPlaylist, {
      id,
      accessCode,
      history,
      isViewAsCoEditViewMode: true,
      isShowByWeb,
    }),
  );

  useEffect(() => {
    if (isDownload) {
      document.title = projectName;
    } else {
      document.title = `${projectName} ${title || t.defaultSmartFileTitleT}`;
    }
    return () => {
      document.title = projectName;
    };
  }, [title, isDownload]);

  useEffect(() => {
    setServiceWorkerLocation(id);
    if (id) {
      getSharedData();
    }
  }, [id]);

  if (notAvailable) {
    return (
      <NoAvailable
        type="Playlist"
        shareState={shareState}
        getPageData={getSharedData}
        id={id}
      />
    );
  }

  if (shareState.needAccessCode) {
    return (
      <EnterAccessCode
        type="playlist"
        shareState={shareState}
        getPageData={getSharedData}
        id={id}
      />
    );
  }
  if (shareState.wrongUser) {
    return (
      <WrongUser type="SmartFile" wrongUser path={history.location.pathname} />
    );
  }
  if (shareState.needLogin) {
    return (
      <WrongUser type="SmartFile" needLogin path={history.location.pathname} />
    );
  }

  return (
    <>
      {openModal === openModalType.UPVPreview && <UPVpreview />}
      <PlaylistMain isForSharedToWeb />
    </>
  );
};

export default PlaylistShared;
