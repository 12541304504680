// LanguageSwitcher.tsx
import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useLocalization } from '../../LocalizationContext';
import styles from './index.module.scss';

const cn = classNames.bind(styles);

const LanguageSwitcher = ({ isLogin }) => {
  const { i18n } = useTranslation();
  const { changeLanguage } = useLocalization();
  const currentLang = i18n.language;

  const toggleLanguage = () => {
    const newLang = i18n.language === 'en' ? 'fr' : 'en';
    i18n.changeLanguage(newLang);
    changeLanguage(newLang);
  };

  return (
    <div className={`${styles.langSwitcher} ${isLogin && styles.langSwitcherLogin}`}>
      <span className={styles.langName}>EN</span>
      <div className={styles.switch} onClick={toggleLanguage}>
        <input type="checkbox" onChange={toggleLanguage} checked={currentLang === 'fr'} />
        <span className={`${styles.slider} ${styles.round}`} />
      </div>
      <span className={styles.langName}>FR</span>
    </div>
  );
};

export default LanguageSwitcher;
