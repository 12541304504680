import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as PrevSvg } from '../../images/icons/arrow_prev_24.svg';
import { ReactComponent as NextSvg } from '../../images/icons/arrow_next_24.svg';
import { ReactComponent as EndPlaylistSvg } from '../../images/icons/end_recipe_140_32.svg';
import styles from './index.module.scss';
import '../MomentBlock/MomentBlock.css';
import 'draft-js/dist/Draft.css';

import { actionGoToViewPage } from '../../redux/currentPage/action';
import { useLocalization } from '../../LocalizationContext';

const BottomNav = () => {
  const history = useHistory();
  const { t } = useLocalization();
  const isPreview = history.location.pathname.includes('/preview_pages');
  const isPublishedPage = history.location.pathname.includes('/publish_page')
    || history.location.pathname.includes('/shared_page');
  const indexViewLesson = useSelector(
    (state) => state.currentPage.indexViewLesson,
  );
  const playlistId = useSelector((state) => state.currentPage.playlistId);
  const lessons = useSelector((state) => state.lessons);
  const nextPage = lessons && lessons[indexViewLesson + 1];
  const prevPage = lessons && lessons[indexViewLesson - 1];
  const titleNextPage = nextPage && nextPage.title;
  const titlePrevPage = prevPage && prevPage.title;
  const idNextPage = nextPage && nextPage.page[0].id;
  const idPrevPage = prevPage && prevPage.page[0].id;
  const dispatch = useDispatch();
  const { id } = useParams();

  const goToNextPage = () => {
    if (idNextPage) {
      dispatch(
        actionGoToViewPage(
          idNextPage,
          history,
          indexViewLesson + 1,
          id || playlistId,
          isPreview,
        ),
      );
    } else {
      history.push(
        `/${isPreview ? 'preview_playlist' : 'browse_playlists'}/${playlistId}`,
      );
    }
  };
  const goToPrevPage = () => {
    if (idPrevPage) {
      dispatch(
        actionGoToViewPage(
          idPrevPage,
          history,
          indexViewLesson - 1,
          id || playlistId,
          isPreview,
        ),
      );
    } else {
      history.push(
        `/${isPreview ? 'preview_playlist' : 'browse_playlists'}/${playlistId}`,
      );
    }
  };

  const backToEditPlaylist = () => {
    history.push(`/maker/${id}/edit`);
  };

  if (isPublishedPage) return <></>;
  return (
    <>
      <div className={styles.block}>
        {isPreview && (
          <div className={styles.backToEditButton}>
            <div
              className={styles.back_button_wrapper}
              onClick={backToEditPlaylist}
            >
              {t.editLowT}
            </div>
          </div>
        )}
        <div className={styles.nav}>
          <div onClick={goToPrevPage} role="button" className={styles.back}>
            {!!titlePrevPage && (
              <>
                <div className={styles.container}>
                  <PrevSvg />
                  <div>{t.prevUpT}</div>
                </div>
                <div className={styles.pageTitle}>{titlePrevPage}</div>
              </>
            )}
            {!titlePrevPage && (
              <>
                <div className={`${styles.container} ${styles.finish}`}>
                  <PrevSvg />
                  <div className={styles.finish}>{t.backToSmartFileT}</div>
                </div>
              </>
            )}
          </div>

          <div onClick={goToNextPage} role="button" className={styles.forward}>
            {!!titleNextPage && (
              <>
                <div className={styles.container}>
                  <div>{t.nextUpT}</div>
                  <NextSvg />
                </div>
                <div className={styles.pageTitle}>{titleNextPage}</div>
              </>
            )}
            {!titleNextPage && (
              <>
                <div className={styles.finish}>
                  <EndPlaylistSvg />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BottomNav;
