import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { useDropboxChooser } from 'use-dropbox-chooser';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { actionCreator } from '../../../shared/redux/actionHelper';
import { ServiceUser } from '../../../redux/user/types';
import { AddGoogleFileShell } from './AddGoogleFile';
import { DROPBOX_API_KEY } from '../../../environments/environments';
import { validateURL } from '../../../utils/helpers';
import {
  DATA_PARENTS,
  DROPBOX_URI_START,
  LibraryComponentTypes,
} from '../../../utils/constants';
import Button2023 from '../../Buttons/Button2023';
import { useLocalization } from '../../../LocalizationContext';

const Input = styled.input`
  font-family: RobotoFlex;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  border: 1px solid #ece2e2;
  width: 420px;
  height: 32px;
  border-radius: 6px;
  padding-left: 8px;
  border-color: ${({ isError }) => (isError ? 'crimson' : '')};
  transition: all ease-in-out 0.3s;
  &::placeholder {
    color: #7a696980;
  }
  &:focus {
    border: 1px solid #ffc72c;
  }
`;

const InputGroup = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 4px;
  font-family: RobotoFlex;
  font-size: 14px;
  font-weight: ${({ theThird }) => (theThird ? 500 : 400)};
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #585858;
  margin-bottom: ${({ theFirst }) => (theFirst ? '7px' : '')};
  margin-top: ${({ theThird }) => (theThird ? '13px' : '')};
  & div {
    line-height: 32px;
  }
`;

const ErrorContainer = styled.div`
  transition: all ease-in-out 0.3s;
  height: ${({ isActive }) => (isActive ? 12 : 0)}px;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  position: relative;
  color: crimson;
  bottom: 0;
  left: 0;
  font-size: 12px;
  margin-bottom: 4px;
`;

const AddDropboxFile = ({
  setIsVisible = () => {},
  nextItemPosition,
  firstBlockIndex,
  isMediaAddBlock,
  oldUploadBlockId,
  oldUploadBlockPosition,
  isPlaylist,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useLocalization();

  const [inputValue, _setInputValue] = useState('');
  const [localError, setLocalError] = useState(false);

  const { open, isOpen } = useDropboxChooser({
    appKey: DROPBOX_API_KEY,
    chooserOptions: { multiselect: true, linkType: 'preview' },
    onSelected: (files) => {
      setIsVisible(false);

      dispatch(
        actionCreator(ServiceUser.HandleIntegrationPickedFilesS, {
          files: files.map((file) => ({
            ...file,
            newChildId: uuidv4(),
            newLibraryComponentId: uuidv4(),
          })),
          startPosition: oldUploadBlockPosition,
          history,
          endPosition: nextItemPosition,
          firstBlockIndex,
          isWithUpload: !isMediaAddBlock && !isPlaylist,
          oldUploadBlockId,
          oldUploadBlockPosition,
          componentType: LibraryComponentTypes.dropbox_embed_component,
        }),
      );
    },
  });

  const onSaveButtonClick = () => {
    const url = inputValue;
    if (!url) {
      setLocalError(t.notEmptyT);
      return;
    }
    if (!validateURL(url)) {
      setLocalError(t.isNotALinkT);
      return;
    }
    if (!url.startsWith(DROPBOX_URI_START)) {
      setLocalError(t.notDropboxUrlT);
      return;
    }

    setIsVisible(false);

    dispatch(
      actionCreator(ServiceUser.HandleIntegrationFileLinkS, {
        url,
        history,
        isWithUpload: !isMediaAddBlock && !isPlaylist,
        firstBlockIndex,
        oldUploadBlockId,
        oldUploadBlockPosition,
        newChildId: uuidv4(),
        newLibraryComponentId: uuidv4(),
        componentType: LibraryComponentTypes.dropbox_embed_component,
      }),
    );
  };

  const handleInputKeyPress = (e) => {
    if (e.code === 'Enter') {
      onSaveButtonClick(e);
    }
  };

  const setInputValue = (event) => {
    if (localError) setLocalError(false);
    _setInputValue(event.target.value);
  };

  return (
    <AddGoogleFileShell
      data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
      isNoRightPadding
    >
      <InputGroup data-parent={DATA_PARENTS.PlaylistBuilderLibrary} theFirst>
        <Input
          value={inputValue}
          onChange={setInputValue}
          onKeyDown={handleInputKeyPress}
          placeholder={t.dropboxInputPlaceholderT}
          isError={!!localError}
          data-parent={`miniPage${DATA_PARENTS.PlaylistBuilderLibrary}`}

        />
        {/* <ButtonWrapper> */}
        <Button2023
          width={45}
          derivedParentData={DATA_PARENTS.PlaylistBuilderLibrary}
          height={32}
          variant="secondary"
          text={t.addUpT}
          handleButtonClick={onSaveButtonClick}
          noMargins
        />
        {/* <ButtonComponent */}
        {/*  text={t.createDropboxComponentT} */}
        {/*  clickCb={onSaveButtonClick} */}
        {/*  fullWidth */}
        {/* /> */}
        {/* </ButtonWrapper> */}
      </InputGroup>

      <ErrorContainer isActive={!!localError}>{localError}</ErrorContainer>

      <InputGroup data-parent={DATA_PARENTS.PlaylistBuilderLibrary} theThird>
        <div>{t.createLinkAccountT}</div>
        <Button2023
          derivedParentData={DATA_PARENTS.PlaylistBuilderLibrary}
          width={79}
          height={32}
          variant="secondary"
          text={t.connectUpT}
          noMargins
          handleButtonClick={open}
          isDisabled={isOpen}
        />
      </InputGroup>

      {/* <HintText> */}
      {/*  {t.dropboxHintT} */}
      {/* </HintText> */}
    </AddGoogleFileShell>
  );
};

export default AddDropboxFile;
