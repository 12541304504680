import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  ReactComponent as CloseCrossSvg,
} from '../../../../../../images/2023/svg/small/component-description/close_16_16.svg';
import Availability from '../Availability';
import {
  ContainerWithPointer,
  OptionContent,
  OptionDivider,
  OptionHeader,
  OptionItem,
  OptionTitle,
  OptionWrapper,
} from '../styled';
import ShareSingleUser from '../../../../../../components/DropDownOption/Share/ShareSingleUser';
import { DATA_PARENTS, SHARED_PLAYLIST_URL } from '../../../../../../utils/constants';
import { actionCreator } from '../../../../../../shared/redux/actionHelper';
import EditPlaylist from '../../../../../../redux/playlists/types';
import styles from '../../../../../../components/DropDownOption/Share/index.module.scss';
import { ReactComponent as Copy } from '../../../../../../images/icons/icon_20/copy.svg';
import { copyInBuffer } from '../../../../../../utils/helpers';
import { EditPage } from '../../../../../../redux/pages/types';
import useComponentVisible from '../../../../../../utils/hooks/useComponentVisible';
import { useLocalization } from '../../../../../../LocalizationContext';

const ShareOption = ({
  type, items,
  setIsSingleShareOpened,
  shareState,
  playlistId,
  wrapperId,
  itemType,
  setUserEmail,
  ...props
}) => {
  const dispatch = useDispatch();
  const { t } = useLocalization();
  const copyRef = useRef(null);
  const sharedAvailableTo = itemType === 'page' ? shareState.availableTo : shareState.sharedAvailableTo;

  const [refDropMenu, isWebShareStateModalOpened, setIsWebShareOpened] = useComponentVisible(
    false,
    DATA_PARENTS.AddMember,
  );

  const copyHandler = (e) => {
    e.preventDefault();
    copyRef.current.select();
    copyInBuffer(copyRef, dispatch);
  };
  const closeSharingToWeb = () => {
    if (itemType === 'page') {
      dispatch(actionCreator(EditPage.editShareStateToWeb, { isShareToWeb: false }));
      return;
    }
    dispatch(actionCreator(EditPlaylist.updateShareToWebStatePlaylistSR, { isShareToWeb: false }));
  };
  const shareLink = itemType === 'page'
    ? `${document.location.origin}/sharedwebpage/${shareState.link}`
    : `${document.location.origin}/${SHARED_PLAYLIST_URL}/${shareState.link || wrapperId}`;
  return (
    <OptionWrapper data-parent={props.dataParent}>

      <OptionHeader>
        <OptionTitle>{type.title}</OptionTitle>
        <ContainerWithPointer>
          <CloseCrossSvg data-parent={props.dataParent} onClick={() => closeSharingToWeb()} />
        </ContainerWithPointer>
      </OptionHeader>
      <OptionContent>
        <OptionItem data-parent={props.dataParent}>

          <Availability
            isShared
            handleClick={() => {
              setUserEmail('user.email');
              setIsWebShareOpened(true);
            }}
            user={{ sharedAvailableTo }}
            dataParent={props.dataParent}
          />
          <OptionDivider />
          {isWebShareStateModalOpened
            && (
              <ShareSingleUser
                refMenu={refDropMenu}
                close={() => setIsWebShareOpened(false)}
                userEmail="shared to web"
                dataParent={DATA_PARENTS.PlaylistBuilderLibrary}
                isWeb
              />
            )}
        </OptionItem>

      </OptionContent>
      <div
        data-parent={props.dataParent}
        className={styles.button_group_copy}
      >
        <div data-parent={props.dataParent} className={`${styles.share_menu__access_code} ${styles.project_link}`}>
          {t.projectLinkT}
        </div>
        <input
          data-parent={props.dataParent}
          data-cy="copy-link-input"
          onClick={copyHandler}
          ref={copyRef}
          readOnly
          className={`${styles.copyInput} ${styles.copyInput_small}`}
          value={shareLink}
        />
        <button
          data-parent={props.dataParent}
          data-cy="copy-link-btn"
          onClick={copyHandler}
          className={`${styles.copy_button}`}
        >
          <Copy data-parent={props.dataParent} />
          <div data-parent={props.dataParent}>{t.copyT}</div>
        </button>
      </div>
    </OptionWrapper>
  );
};

export default ShareOption;
