import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { ExtraTabs, MainNavigate, HelpNavigate } from '../../navigate/navigate';
import { IconRole } from '../../utils/permissions';
import SearchInHeader from './SearchInHeader';
import User from './User';
import UserMenu from '../UserMenu';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import { DATA_PARENTS } from '../../utils/constants';
import { calcCurrentTitle } from '../../utils/helpers';
import UnifiedChevronNavigation from '../UnifiedNavigation/UnifiedChevronNavigation';
import { MainHeaderDivider, MainHeaderShell } from './styled';
import { ReactComponent as ContactIcon } from '../../images/icons/contacts.svg';
import { ContactName } from '../MainLeftSideBar/styled';
import { AvatarInInput } from '../Inputs/InputUsersWithSelector/AvatarInInput';
import { useLocalization } from '../../LocalizationContext';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcherComponent';

const HeaderTitleWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 900px) and (max-width: 1300.1px) {
    display: none;
  }
`;

const HeaderTitle = styled.div`
  color: #585858;
  font-family: RobotoFlex, sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  margin: 0;

  svg {
    margin-right: 7px;
    margin-left: 2px;
  }

  p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 25vw;

    @media (max-width: 1950px) {
      width: 20vw;
    }

    @media (max-width: 1600px) {
      width: 18vw;
    }

    @media (max-width: 1460px) {
      width: 15vw;
    }

    @media (max-width: 1350px) {
      width: 15vw;
    }

    @media (max-width: 1150px) {
      width: 13vw;
    }

    @media (max-width:930px) {
      width: 11vw;
    }
  }
`;


const MainHeader = () => {
  const history = useHistory();
  const { t } = useLocalization();

  const { selectorType: userToShowId } = useSelector((state) => state.content);
  const { contactNicknameMap } = useSelector((state) => state.support);
  const { myChannels } = useSelector((redux) => redux.channels);
  const { folderId: activeChannelId } = useSelector((state) => state.user);

  const [
    refUserMenu, isComponentVisible, setIsComponentVisible,
  ] = useComponentVisible(false, DATA_PARENTS.UserMenu);

  const currentPath = history.location.pathname;
  const isUser = currentPath.includes('/users_smartfiles');
  const isChannel = currentPath.includes('/channel/');
  const currentTitle = calcCurrentTitle(currentPath);

  const toggleShowUserMenu = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const calcHeader = useCallback(() => {
    if (isUser) {
      const contact = contactNicknameMap[userToShowId];
      if (!contact) return <></>;
      const username = contact.first_name ? `${contact.first_name} ${contact.last_name}` : contact.username;
      return (
        <>
          <AvatarInInput item={contact} />
          <ContactName isHeader>
            {username}
          </ContactName>
        </>
      );
    }
    if (isChannel) {
      return (
        <>
          {IconRole[myChannels?.[activeChannelId]?.role]}
          <p>{myChannels?.[activeChannelId]?.name}</p>
        </>
      );
    }
    if (ExtraTabs[currentTitle] || MainNavigate[currentTitle] || ExtraTabs) {
      return (
        <>
          {ExtraTabs[currentTitle]?.icon
            ?? MainNavigate[currentTitle]?.icon
            ?? MainNavigate.smartfiles.sidebarSelectors[currentTitle]?.icon}
          <p>
            {
              t[ExtraTabs[currentTitle]?.textID]
              ?? t[MainNavigate[currentTitle]?.textID]
              ?? t[MainNavigate.smartfiles.sidebarSelectors[currentTitle]?.textID]
            }
          </p>
        </>
      );
    }
    if (currentPath === '/contacts') {
      return (
        <>
          <ContactIcon />
          <p>  {t.contactsT}</p>
        </>
      );
    }

    return (
      <>
        {HelpNavigate.icon}
        <p>{t[HelpNavigate.textID]}</p>
      </>
    );
  }, [activeChannelId, userToShowId, currentTitle, isChannel, myChannels]);

  return (
    <MainHeaderShell>
      <UnifiedChevronNavigation />
      <HeaderTitleWrapper>
        <HeaderTitle>
          {calcHeader()}
        </HeaderTitle>
      </HeaderTitleWrapper>
      <SearchInHeader />

      <User toggleUserMenu={toggleShowUserMenu} isActive={isComponentVisible} />
      <LanguageSwitcher />
      {isComponentVisible && (
        <UserMenu
          refUserMenu={refUserMenu}
          setIsComponentVisible={setIsComponentVisible}
        />
      )}
      <MainHeaderDivider />
    </MainHeaderShell>
  );
};

export default MainHeader;
