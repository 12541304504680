import React from 'react';
import { useSelector } from 'react-redux';
import styles from '../index.module.css';
import { DATA_PARENTS } from '../../../utils/constants';
import { useLocalization } from '../../../LocalizationContext';

const PlaylistOptionWithX = ({
  refMenu,
  parentRef,
  menuOptions,
  isPdfView,
  isPageEditor,
  isLibrary,
  isLibraryRow,
  isChannelRow,
  isActivityRow,
  isAvatarSelector,
  isEmojiRow,
  isPlaylistCard,
  isPlayerHeader,
  isUniversalPlaylistGeneral,
  isOpenLeft,
  defaultPosition = { top: 45, left: 0 },
}) => {
  const { componentDescription } = useSelector((state) => state.support);
  const { t } = useLocalization();

  const calculateVerticalPosition = () => {
    if (isPdfView || isPageEditor || isLibraryRow || isChannelRow) return 33;
    if (isActivityRow || isEmojiRow) return 7;
    if (isPlaylistCard) return 18;
    if (isUniversalPlaylistGeneral) return isOpenLeft ? 29 : 25;
    if (isPlayerHeader) return 32;
    if (parentRef && parentRef?.current) {
      const height = document.documentElement.clientHeight;
      const positionY = 25;
      const refAbsoluteY = parentRef.current.getBoundingClientRect().y;
      const heightElement = isAvatarSelector ? 76 : 280;
      const isOpenInDown = height > refAbsoluteY + heightElement + positionY;
      if (isOpenInDown) {
        return positionY;
      }
      return positionY - heightElement;
    }
    if (isLibrary) return 25;
    return defaultPosition.top;
  };

  const calculateHorizontalPosition = () => {
    let offset = 0;
    if (componentDescription) offset = 487;
    if (isPdfView || isPageEditor || isLibraryRow || isChannelRow) return -206;
    if (isActivityRow) return -100;
    if (isEmojiRow) return -40;
    if (isPlaylistCard) return -216;
    if (isUniversalPlaylistGeneral) return isOpenLeft ? -210 : 0;
    if (isPlayerHeader) return -147;
    if (parentRef && parentRef?.current) {
      const width = document.documentElement.clientWidth;
      const positionX = 0;
      const refAbsoluteX = parentRef?.current.getBoundingClientRect().x;
      const widthElement = 242;
      const isOpenInRight = width > refAbsoluteX + widthElement + positionX + offset;
      if (isOpenInRight) return positionX;
      return positionX - widthElement;
    }
    return defaultPosition.left;
  };
  const divStyle = {
    top: calculateVerticalPosition(),
    left: calculateHorizontalPosition(),
  };

  return (
    <div
      data-parent={
        !isLibrary
          ? 'miniPage'
          : `${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`
      }
      ref={refMenu}
      style={divStyle}
      className={`${styles.editMenuPage} 
        ${isActivityRow ? styles.activityStyles : ''}
        ${isEmojiRow ? styles.emojiStyles : ''}
        ${isAvatarSelector ? styles.preferencesStyles : ''}
        ${isPlayerHeader ? styles.playerHeaderStyles : ''}
      `}
    >
      {isActivityRow && <div className={`${styles.optionsHeader}`}>{t.optionsT}</div>}
      {menuOptions.map((item) => item())}
    </div>
  );
};

export default PlaylistOptionWithX;
