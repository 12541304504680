import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.scss';
import closeIcon from '../../images/icons/close_24.svg';
import searchIcon from '../../images/icons/search_folder_16.svg';
import foundEmpty from '../../images/icons/no_playlist_found.svg';

import defaultImagePlaylist from '../../images/png/defaultImagePlaylist.png';
import avatar from '../../images/icons/def_avatar_64.svg';
import clock from '../../images/icons/clock_20.svg';
import { actionCloseModal } from '../../redux/user/action';
import { ReactComponent as CheckBoxOffSvg } from '../../images/icons/checkbox_off_24_bg.svg';
import { ReactComponent as CheckBoxOnSvg } from '../../images/icons/checkbox_on_24_bg.svg';
import { actionEditComplementaryPlaylist } from '../../redux/playlists/action';
import { DEFAULT_TITLE, empty } from '../../utils/constants';
import { useLocalization } from '../../LocalizationContext';

const cx = classNames.bind(styles);

const AddComplementaryPlaylist = () => {
  const dispatch = useDispatch();
  const { t } = useLocalization();

  const playlists = useSelector((state) => state.playlists || empty);
  const miniPlaylist = useSelector((state) => state.miniPlaylist || empty);
  const { first_name, last_name } = useSelector((state) => state.user);
  const complementaryPlaylists = useSelector(
    (state) => state.currentPage.complementaryPlaylists || empty,
  );
  const currentPageId = useSelector((state) => state.currentPage.id);

  const [filter, setFilter] = useState('');
  const [selected, setSelected] = useState({ ...complementaryPlaylists });
  const [filtered, setFilteredPlaylists] = useState([]);

  const close = () => {
    dispatch(actionCloseModal());
  };

  const apply = () => {
    const addIds = Object.keys(selected).filter(
      (id) => selected[id] && !complementaryPlaylists[id],
    );
    const deletedIds = Object.keys(complementaryPlaylists).filter(
      (id) => !selected[id] && complementaryPlaylists[id],
    );
    if (addIds.length || deletedIds.length) {
      dispatch(
        actionEditComplementaryPlaylist(currentPageId, addIds, deletedIds),
      );
    }

    dispatch(actionCloseModal());
  };

  const select = (id) => {
    selected[id] = !selected[id];
    setSelected({ ...selected });
  };

  useEffect(() => {
    setFilteredPlaylists(
      Object.values(playlists || {})
        .map((playlist) => {
          if (
            !playlist.movedToTrash
            && playlist.isPublish
            && playlist.id !== currentPageId
            && playlist.title.toLowerCase().includes(filter.toLowerCase())
          ) return playlist.id;
        })
        .filter((i) => i),
    );
  }, [filter]);

  return (
    <div onClick={close} className={cx('modal_wrapper')}>
      <div
        onClick={(e) => e.stopPropagation()}
        className={cx('modal', 'complementary_playlist')}
      >
        <div className={cx('title')}>
          {t.addComplementarySmartFilesT}
          <img
            onClick={close}
            src={closeIcon}
            className={cx('close')}
            alt="close"
          />
        </div>
        <div className={cx('search')}>
          <img src={searchIcon} alt="search" className={cx('search_icon')} />
          <input
            autoFocus
            value={filter}
            placeholder={t.findASmartFileT}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
        <div className={cx('wrapper')}>
          {!!playlists
            && filtered.map((id) => (
              <div key={id}>
                {playlists[id] && (
                  <div
                    onClick={() => select(id)}
                    key={id}
                    className={cx('playlist_wrapper', {
                      selected: selected[id],
                    })}
                  >
                    <div className={cx('image_wrapper')}>
                      {selected[id] && (
                        <CheckBoxOnSvg className={cx('checkbox')} />
                      )}
                      {!selected[id] && (
                        <CheckBoxOffSvg
                          className={cx('checkbox', 'checkbox_off')}
                        />
                      )}
                      {miniPlaylist[id].img && (
                        <img
                          className={cx('image_crop')}
                          style={{ top: -(miniPlaylist[id].position / 2.5) }}
                          src={miniPlaylist[id].img}
                          alt="image_crop"
                        />
                      )}
                      {!miniPlaylist[id].img && (
                        <img
                          className={cx('image')}
                          src={defaultImagePlaylist}
                          alt="image"
                        />
                      )}
                    </div>

                    <div className={cx('info')}>
                      <div className={cx('playlist_title')}>
                        {playlists[id].title || t.defaultSmartFileTitleT}
                      </div>
                      <div className={cx('description')}>
                        {miniPlaylist[id].description}
                      </div>

                      <div className={cx('about')}>
                        <div className={cx('author')}>
                          <img src={avatar} alt="avatar" />
                          {`by ${first_name} ${last_name}`}
                        </div>
                        {miniPlaylist[id].duration && (
                          <div className={cx('time')}>
                            <img src={clock} alt="time" />
                            {miniPlaylist[id].duration}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          {!filtered.length && !filter && (
            <div className={cx('empty')}>
              <img src={foundEmpty} alt="empty" />
              <div className={cx('empty_head')}> {t.noSmartFilesHereT}</div>
              <div className={cx('empty_desc')}>
                {' '}
                {t.findRecentlyCreatedSmartFilesT}{' '}
              </div>
            </div>
          )}
          {!filtered.length && filter && (
            <div className={cx('empty')}>
              <img src={foundEmpty} alt="empty" />
              <div className={cx('empty_head')}> {t.noSmartFilesFoundT} </div>
              <div className={cx('empty_desc')}>
                {' '}
                {t.doupleCheckOrDifferentT}
              </div>
            </div>
          )}
        </div>

        <div className={cx('option')}>
          <div onClick={close} className={cx('cancel')}>
            {t.cancelUpT}
          </div>
          <div onClick={apply} className={cx('add')}>
            {t.addUpT}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddComplementaryPlaylist;
