import React from 'react';
import styled from 'styled-components/macro';
import { useLocation } from 'react-router-dom';
import { groupPlaylistByDayModify } from '../../../utils/libraryContentSelector';
import RecentFilterComponent from './RecentFilterComponent';
import { filterNamesRecently } from '../../../utils/libraryContentSelector/filterNames';
import { useLocalization } from '../../../LocalizationContext';

const PlayListContainerCard = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  margin-left: ${({ marginLeft }) => marginLeft}px;
  margin-top: ${({ marginTop }) => marginTop}px;
`;

const PlayListContainerTable = styled.div`
  display: block;
  position: relative;
  margin-left: ${({ marginLeft }) => marginLeft}px;
  margin-top: ${({ marginTop }) => marginTop}px;
`;

const RecentFilterGroup = ({
  isCardView,
  contentData,
  children,
}) => {
  const { pathname } = useLocation();
  const { t } = useLocalization();

  if (!(contentData && groupPlaylistByDayModify(contentData))) {
    return null;
  }

  const {
    todayPlayLists,
    yesterdayPlayLists,
    currentWeekPlayLists,
    previousWeekPlayLists,
    currentMonthPlayLists,
    previousMonthPlaylists,
    currentYearPlaylists,
    previousYearPlaylists,
    aLongTimeAgoPlaylists,
  } = groupPlaylistByDayModify(contentData);

  const PlayListContainer = isCardView
    ? PlayListContainerCard
    : PlayListContainerTable;

  let hasRawWithHeader = false;

  return (
    <PlayListContainer
      marginLeft={0}
      marginTop={0}
    >
      {[
        todayPlayLists,
        yesterdayPlayLists,
        currentWeekPlayLists,
        previousWeekPlayLists,
        currentMonthPlayLists,
        previousMonthPlaylists,
        currentYearPlaylists,
        previousYearPlaylists,
        aLongTimeAgoPlaylists,
      ].map((playlist, index) => {
        if (!Object.keys(playlist).length) {
          return null;
        }

        const noHeader = hasRawWithHeader;
        hasRawWithHeader = true;

        return (
          <RecentFilterComponent
            noHeader={noHeader}
            key={Object.keys(playlist)[0] + index.toString()}
            isCardView={isCardView}
            filterTitle={t[filterNamesRecently[index]]}
            playlist={playlist}
          >
            {children(Object.keys(playlist), noHeader)}
          </RecentFilterComponent>
        );
      })}
    </PlayListContainer>
  );
};

export default RecentFilterGroup;
