import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import styles from './index.module.scss';
import EmojiNameInput from './EmojiNameInput';
import EmojiUploadInput from './EmojiUploadInput';
import EmojisListTable from './EmojisListTable';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import { Settings } from '../../../../redux/settings/types';
import { Column, Line, SectionShell, HeaderTitle } from '../../CommonComponents/Layout';
import { useLocalization } from '../../../../LocalizationContext';

const cn = classNames;

const PreferencesEmoji = () => {
  const dispatch = useDispatch();
  const { t } = useLocalization();

  useEffect(() => {
    dispatch(actionCreator(Settings.Preferences.InitialGetEmojisS));
  }, []);

  return (
    <SectionShell paddingForSetting>
      <HeaderTitle marginBottom32>{t.emojisT}</HeaderTitle>
      <Line>
        <div className={cn(styles.label)}>{t.uploadT} :</div>
      </Line>

      <Line>
        <Column>
          <div className={cn(styles.text)}>
            {t.uploadCustomEmojisT}
          </div>
        </Column>
      </Line>

      <Line>
        <EmojiUploadInput />
      </Line>

      <Line>
        <Column>
          <div className={cn(styles.text)}>{t.enterNameT}</div>
        </Column>
      </Line>

      <Line>
        <Column>
          <EmojiNameInput />
        </Column>
      </Line>

      <Line>
        <div className={cn(styles.label, styles.emojis)}>
          {t.emojisListT} :
        </div>
      </Line>

      <Line>
        <EmojisListTable />
      </Line>
    </SectionShell>
  );
};

export default PreferencesEmoji;
