import React from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import styles from './index.module.scss';
import { ReactComponent as CheckSvg } from '../../../images/icons/upload_check_20.svg';
import { ReactComponent as CloseSvg } from '../../../images/icons/close32.svg';
import Tooltip from '../../../shared/Tooltips/Tooltip';
import { Circle } from '../../../utils/UIHelpers/PlaylistBuilderElements/libraryComponent';
import { actionCreator } from '../../../shared/redux/actionHelper';
import { CurrentPage } from '../../../redux/currentPage/types';
import { actionCancelUpload } from '../../../redux/filesUpload/action';
import { useLocalization } from '../../../LocalizationContext';

const cx = classNames.bind(styles);

const BlockUpload = ({ id, index, libCompId }) => {
  const dispatch = useDispatch();
  const { t } = useLocalization();
  const upload = useSelector((state) => state.uploads.components);
  const uploadData = upload[libCompId || id] || {};

  const cancel = () => {
    dispatch(actionCreator(CurrentPage.RemoveBlockR, { id, index }));
    dispatch(actionCancelUpload(uploadData.newId));
  };
  const progressBarStatus = (progress) => {
    if (progress === 1) {
      if (uploadData.status === 'READY') {
        return <CheckSvg />;
      }
      return (
        <div className={styles.circle_loader_wrapper}>
          <div className={styles.circle_loader} />
          <div className={styles.uploading_text}>{t.processingT}</div>
        </div>
      );
    }
    return (
      <>
        <Circle progress={(uploadData.progress || 0) * 100} color="#0026E5" />
        <div className={styles.uploading_text}>
          {' '}
          {`${parseInt((uploadData.progress || 0) * 100)}%`}{' '}
        </div>
      </>
    );
  };
  return (
    <>
      <div className={cx('upload_wrapper')}>
        <div className={cx('upload_component')}>
          {uploadData.status !== 'googlePickerPrecessing' && (
            <>
              {progressBarStatus(uploadData.progress, uploadData.status)}
            </>
          )}
          {uploadData.status === 'googlePickerPrecessing' && (
            <>{progressBarStatus(1)}</>
          )}
        </div>
        <div className={cx('menu_option')}>
          <div className={cx('info')}>
            <div className={cx('name')}>{uploadData.title || t.unnamedT}</div>
            {uploadData.size && (
              <div className={cx('description')}>
                <div>{(uploadData.size / 1024).toFixed(1)}KB</div>
              </div>
            )}
          </div>
          <div onClick={cancel} className={cx('cancel')}>
            <Tooltip text={t.cancelUploadingT} direction="down">
              <div className={cx('cancel_image_wrapper')}>
                <CloseSvg />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlockUpload;
