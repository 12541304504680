import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { actionCloseModal } from '../../redux/user/action';
import { actionCreator } from '../../shared/redux/actionHelper';
import DashboardColumns from '../../redux/dashboardColumns/types';
import { COLUMN_CUSTOM_TYPE } from '../../utils/constants';
import Button2023 from '../Buttons/Button2023';
import { GlobalFlexRowWrapper } from '../../pages/Maker/MakerCommon/UiComponents/styled';
import SimpleTextInput from '../SimpleComponents/Inputs/SimpleTextInput';
import ColorPalette from '../ColorPicker/ColorPalette';
import RadioGroupTags from '../Tag/2023/RadioGroupTags';
import { useLocalization } from '../../LocalizationContext';

const ModalWrapper = styled.div`
  position: fixed;
  background: rgba(147, 147, 147, 0.6);
  z-index: 104;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-overflow-style: none;
  font-style: normal;
  font-weight: 400;
`;

const GreyTitleWrapper = styled.div`
  position: relative;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #D3C8C8;
  letter-spacing: 0.05em;
  margin-bottom: 13px;
  text-transform: uppercase;
  font-family: "RobotoFlex", sans-serif;
  ${({ isSecond }) => (isSecond
    ? `
    margin-top: 4px;
    margin-bottom: 13px;
  `
    : '')}
  ${({ isThird }) => (isThird
    ? `
    margin-top: 15px;
    margin-bottom: -2px;
  `
    : '')}
`;

const SmartModalWrapper = styled.div`
  position: relative;
  width: 342px;
  background: #ffffff;
  border: 0.6px solid #ece2e2;
  box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.25);
  border-radius: 12px;
  padding: 16px;
  height:${({ isDefaultColumn }) => (isDefaultColumn ? '430px' : '503px')}
`;

const ButtonBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 169px;
  padding-top: 6px;
  ${({ isForCreate }) => (isForCreate
    ? `
    padding-left: 160px;
  `
    : `
    padding-left: 169px;
  `)}
`;

const ItemTitleWrapper = styled.div`
  width: 250px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  line-height: 12px;
  ${({ bgColor }) => (bgColor ? `background-color: ${bgColor};` : '')}
  ${({ color }) => (color ? `color: ${color};` : '')}
  ${({ color, bgColor }) => (color === bgColor ? `
    text-shadow: -0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000, 0.5px 0.5px 0 #000;
   ` : '')}
  border-radius: 6px;
`;

const Divider = styled.div`
  width: 310px;
  height: 1px;
  margin: 17px auto 3px;
  background: #1d1d1d;
  opacity: 0.08;
  ${({ theSecond }) => (theSecond
    ? `
    margin-top: 15px;
    margin-bottom: 10px;
  `
    : '')}
`;
const EditBoardColumnModal = () => {
  const dispatch = useDispatch();
  const { t } = useLocalization();
  const { dataPayload } = useSelector((state) => state.user);
  const superColorPickerRef = useRef(null);
  const [selectedColor, setColorKey] = useState(dataPayload?.color || '484343');
  const [selectedColorBG, setColorKeyBG] = useState(
    dataPayload?.backgroundColor || 'FFC72C',
  );
  const [inputName, setInputName] = useState(dataPayload?.name ?? 'Untitled');
  const [firstNameValidationError, setFirstNameValidationError] = useState('');
  const [colorPickerMode, setColorPickerMode] = useState(false);
  const close = () => dispatch(actionCloseModal());


  const changeItemColor = (event, color) => (
    colorPickerMode
      ? setColorKey(color.slice(1))
      : setColorKeyBG(color.slice(1))
  );

  const calcIsSelectedTextColor = (color) => {
    return (
      color.includes(selectedColor)
      || (selectedColor && selectedColor.toUpperCase() === color.toUpperCase())
      || (selectedColor
        && color.toUpperCase().includes(selectedColor.toUpperCase()))
    );
  };

  const calcIsSelectedBGColor = (color) => {
    return (
      (selectedColorBG
        && selectedColorBG.toUpperCase() === color.toUpperCase())
      || (selectedColorBG
        && color.toUpperCase().includes(selectedColorBG.toUpperCase()))
    );
  };

  const fixColor = (color) => {
    if (color.slice(0, 1) === '#') {
      return color;
    }
    return `#${color}`;
  };
  const onSave = () => {
    const columnChanges = { columnId: dataPayload.id };
    if (inputName !== dataPayload?.name) {
      columnChanges.newName = inputName;
    }
    if (selectedColorBG !== dataPayload?.backgroundColor) {
      columnChanges.newBackgroundColor = `#${selectedColorBG}`;
    }
    if (selectedColor !== dataPayload?.color) {
      columnChanges.newColor = `#${selectedColor}`;
    }
    if (columnChanges.inputName || columnChanges.newBackgroundColor || columnChanges.newColor) {
      dispatch(
        actionCreator(DashboardColumns.column.updateColorsOrTitle, columnChanges),
      );
    }
    close();
  };

  return (
    <ModalWrapper>
      <SmartModalWrapper isDefaultColumn={dataPayload.id === COLUMN_CUSTOM_TYPE.CLOSE}>
        <GreyTitleWrapper>
          {t.editColumnT}
        </GreyTitleWrapper>
        <GlobalFlexRowWrapper>
          <ItemTitleWrapper
            bgColor={fixColor(selectedColorBG)}
            color={fixColor(selectedColor)}
          >
            {inputName}
          </ItemTitleWrapper>
        </GlobalFlexRowWrapper>
        <Divider />
        {dataPayload.id !== COLUMN_CUSTOM_TYPE.CLOSE
          && (
            <SimpleTextInput
              fieldName=""
              autoFocus
              value={inputName}
              setValue={setInputName}
              validateError={firstNameValidationError}
              setValidateError={setFirstNameValidationError}
              isRequired
              forSmartModal
              isReworkedValidationStyle
            />
          )}
        <GreyTitleWrapper isSecond>{t.selectColorT}</GreyTitleWrapper>
        <RadioGroupTags
          colorPickerMode={colorPickerMode}
          setColorPickerMode={setColorPickerMode}
        />
        <ColorPalette
          isPositionRelative
          selectedColor={dataPayload.color}
          selectedBackgroundColor={dataPayload.backgroundColor}
          refColorPickerParent={superColorPickerRef}
          onChangeColor={changeItemColor}
          isPaletteActive
          defaultPosition={{ top: 0, left: 0 }}
          buttonWidth={0}
          calcIsSelected={
            colorPickerMode ? calcIsSelectedTextColor : calcIsSelectedBGColor
          }
          forSmartModal
        />
        <Divider theSecond />

        <ButtonBlockWrapper>
          <Button2023
            variant="primary"
            width={69}
            height={32}
            text={t.cancelUpT}
            noMargins
            handleButtonClick={close}
          />
          <Button2023
            variant="secondary"
            width={51}
            height={32}
            text={t.saveUpT}
            noMargins
            handleButtonClick={onSave}
          />
        </ButtonBlockWrapper>
      </SmartModalWrapper>
    </ModalWrapper>
  );
};

export default EditBoardColumnModal;
