import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReactComponent as LogoSvg } from '../../images/2023/svg/Logo.svg';
import { ReactComponent as Alert20Svg } from '../../images/icons/alert_circle_20.svg';
import ButtonComponent from '../../components/Buttons/ButtonComponent';
import { ReactComponent as SpinnerSvg } from '../../images/icons/spinner.svg';
import styles from '../loginLogout/Login.module.scss';
import { LoginBackGround } from '../loginLogout/Login';
import {
  ButtonWrapper,
  Container,
  EmailInput,
  Error,
  ForgotWrapper,
  InputField,
  InputTitle,
  Logo,
  SignInReturn,
  Title,
} from '../loginLogout/ForgotPassword/ForgotView';
import { Spinner } from '../Channels/MemberRow/RolesCell/Spinner';
import { DEFAULT_PAGE_PATH } from '../../utils/constants';
import { useLocalization } from '../../LocalizationContext';

const EnterAccessCode = ({ getPageData, shareState, type = 'page' }) => {
  const history = useHistory();
  const { t } = useLocalization();
  const { requestSpinner } = useSelector((state) => state.support);
  const { id } = useSelector((state) => state.user);

  const [emailInputValue, setEmailInputValue] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [isTouched, setIsTouched] = useState(false);

  const onEmailInputChange = (e) => {
    if (e.target.value) setErrorMsg('');
    setEmailInputValue(e.target.value);
  };

  const onInputBlur = () => {
    if (!emailInputValue) setErrorMsg(t.filedIsRequiredT);
  };

  const goToLibrary = () => {
    history.push(DEFAULT_PAGE_PATH);
  };
  const onInputFocus = () => {
    if (!isTouched) setIsTouched(true);
  };

  const onSubmitButtonClick = () => {
    if (!emailInputValue) {
      setErrorMsg(t.enterAccessCodeErrorT);
      return;
    }
    getPageData(emailInputValue);
  };

  const onInputKeyPress = (e) => {
    if (e.code === 'Enter') onSubmitButtonClick();
  };
  const goToSignIn = () => {
    history.push('/login');
  };

  return (
    <>
      <LoginBackGround />

      <div className={styles.scroll_wrapper}>
        <div className={styles.scroll_balancer}>
          <ForgotWrapper>
            <Container>
              <Logo onClick={goToSignIn}>
                <LogoSvg />
              </Logo>
              <Title>{t.accessCodeT}</Title>
              <EmailInput>
                <InputTitle>
                  {t.enterAccessCodeForSharedT} {type}
                </InputTitle>
                <InputField hasError={!!errorMsg}>
                  <input
                    type="text"
                    placeholder={t.enterAccessCodeT}
                    value={emailInputValue}
                    onChange={onEmailInputChange}
                    onBlur={onInputBlur}
                    onFocus={onInputFocus}
                    onKeyDown={onInputKeyPress}
                  />
                </InputField>
                <Error hasError={!!errorMsg && !shareState.wrongAccessCode}>
                  <Alert20Svg /> {errorMsg}
                </Error>
                <Error hasError={shareState.wrongAccessCode}>
                  <Alert20Svg /> {t.invalidCodeT}
                </Error>
              </EmailInput>
              <ButtonWrapper onClick={onSubmitButtonClick}>
                <ButtonComponent
                  text={t.enterAccessCodeT}
                  fullWidth
                  height={40}
                />
              </ButtonWrapper>

              {!id && (
                <SignInReturn onClick={goToSignIn}>
                  {t.returnToSignInT}
                </SignInReturn>
              )}
              {id && (
                <SignInReturn onClick={goToLibrary}>
                  {t.returnToLibraryT}
                </SignInReturn>
              )}
              {!!requestSpinner && (
                <Spinner>
                  <SpinnerSvg />
                </Spinner>
              )}
            </Container>
          </ForgotWrapper>
        </div>
      </div>
    </>
  );
};

export default EnterAccessCode;
