import React from 'react';
import classnames from 'classnames/bind';
import styled from 'styled-components/macro';
import rawStyles from './listDropOptions.module.scss';
import { ReactComponent as AlertSvg } from '../../images/icons/alert_12.svg';
import { useLocalization } from '../../LocalizationContext';

const styles = classnames.bind(rawStyles);
const Title = styled.div`
  font-family: "RobotoFlex", sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  color: #d3c8c8;
  margin-bottom: 10px;
`;
const TagListDropOptionsColorPalette = ({
  hasError,
  errorText,
  setHasError,
  title,
  onTitleChange,
  saveTitle,
  onTitleKeyDown,
  dataParent,
  isLibrarySideBarTags,
}) => {
  const { t } = useLocalization();
  return (
    <div>
      <Title>{t.renameTagT}</Title>
      <div
        data-parent={dataParent}
        className={styles('title_input', { error: hasError })}
        onMouseDown={(e) => {
          e.target.focus();
          e.stopPropagation();
        }}
      >
        <input
          data-parent={dataParent}
          type="text"
          value={title}
          maxLength={55}
          onChange={(e) => {
            if (hasError) setHasError(false);
            if (!e.target.value) setHasError('Please type a value');
            onTitleChange(e.target.value);
          }}
          onBlur={saveTitle}
          onKeyDown={onTitleKeyDown}
          onFocus={() => {
            setHasError(false);
          }}
          onClick={(e) => {
            e.target.focus();
            e.stopPropagation();
          }}
        />
      </div>
      {!!hasError && (
        <div
          className={styles('error_msg_container', { isLibrarySideBarTags })}
          data-parent={dataParent}
        >
          <AlertSvg className={styles('icon')} />
          <div>{errorText}</div>
        </div>
      )}

      <div className={styles('line')} />
    </div>
  );
};

export default TagListDropOptionsColorPalette;
