import React, { useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import styles from './index.module.scss';
import Button2023 from '../Buttons/Button2023';
import { actionCloseModal } from '../../redux/user/action';
import rawStyles from '../../pages/SettingsSection/Profile/ProfileInfo/index.module.scss';
import { NeuralAction } from '../../redux/neural/types';
import { useLocalization } from '../../LocalizationContext';

const cn = classNames.bind(rawStyles);

const cx = classNames.bind(styles);
const SpinnerWrap = styled.div`
  width: 200px;
  margin: auto;
  height: 100%;
`;

const Place = styled.div`
  width: 200px;
  margin: auto;
  height: 30px;
`;


const MAX_LENGTH_TEXTAREA = 1000;
const cancelText = 'Cancel';
const generateText = 'Generate';
const regenerateText = 'Regenerate';
const okText = 'Confirm';
const refreshText = 'Edit';
const placeholder = 'Enter you promt here...';

// TODO hot fix
const actionCreator = (type, value, place) => ({
  type,
  payload: value,
  place,
});


const AddAIBlock = () => {
  const dispatch = useDispatch();
  const { t } = useLocalization();
  const {
    dataPayload: {
      successCB,
    },
  } = useSelector((redux) => redux.user);
  const editorRef = useRef();
  const [promp, setPromt] = useState('');
  const neural = useSelector(state => state.neural);
  const loader = neural.status === 'downloadStatus.pending';
  const someAnswer = neural.neuralText;

  const close = () => {
    dispatch(actionCloseModal());
    dispatch(actionCreator(NeuralAction.ClearText));
  };

  const generate = () => {
    dispatch(actionCreator(NeuralAction.GenerateText, { userText: promp }));
  };

  const editText = () => {
    editorRef.current?.focus();
  };

  const ok = () => {
    successCB(neural.neuralText);
    close();
  };

  const onInputChange = (e) => {
    setPromt(e.target.value);
  };

  return (
    <div onClick={close} className={cx('modal_wrapper')}>
      <div
        onClick={(e) => e.stopPropagation()}
        className={cx('modal', 'DeleteTag')}
      >
        <div className={cx('title')}>{t.createSmartTextT} </div>
        <div className={cn('input_container')}>
          <div className={cn('textarea_border')}>
            <textarea
              className={cn('textarea')}
              onChange={(e) => onInputChange(e, 'textArea')}
              value={promp}
              placeholder={placeholder}
              ref={editorRef}
            />
            <div className={cn('textarea_counter')}>{promp.length}/{MAX_LENGTH_TEXTAREA} </div>
          </div>
        </div>
        <div className={cx('group_buttons')}>
          <Button2023
            variant="secondary"
            width={someAnswer ? 90 : 80}
            height={32}
            text={someAnswer ? regenerateText : generateText}
            noMargins
            handleButtonClick={generate}
            isDisabled={!promp}
          />
        </div>
        <Place />
        {loader && (
        <SpinnerWrap>
          <div className="loader_mini centered">{t.loadingT}</div>
        </SpinnerWrap>
        )}
        {neural.neuralText && !loader
          && <div className={cx(styles.answer)}>{someAnswer}</div>
        }
        <div className={cx('group_buttons')}>
          <Button2023
            variant="primary"
            width={70}
            height={32}
            text={cancelText}
            noMargins
            handleButtonClick={close}
          />
          {someAnswer && !loader && (
            <>
              <Button2023
                variant="secondary"
                width={70}
                height={32}
                text={okText}
                noMargins
                withMinWidth
                handleButtonClick={ok}
              />
              <Button2023
                variant="secondary"
                width={70}
                height={32}
                text={refreshText}
                noMargins
                withMinWidth
                handleButtonClick={editText}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddAIBlock;
