import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { ReactComponent as SmartViewGroupSvg } from '../../images/icons/smartView.svg';
import { DATA_PARENTS } from '../../utils/constants';
import SmartViewDropDown from './2023/DropDown';
import Tooltip from '../../shared/Tooltips/Tooltip';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import DropDownDownItem from './2023/DropDownDownItem';
import { useLocalization } from '../../LocalizationContext';

const defColor = {
  svgActive: '#242633',
  svg: '#596080',
  background: '#FFEEBF',
};

const Tab = styled.div`
  position: ${({ isPage }) => (isPage ? 'absolute' : 'relative')};
  width: 32px;
  height: 32px;
  border: 1px solid transparent;
  border-radius: 8px;
  margin: 0 auto;
  ${({ isLast, isLibrary }) => (isLast || isLibrary ? 'margin-bottom: 0;' : 'margin-bottom: 4px;')}
  ${({ lowMode }) => (lowMode
    ? `
  height:28px;
  width:28px;
  `
    : '')}
  ${({ isHiddenItem }) => (isHiddenItem ? 'margin: 0;' : '')}

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isActive, color, isHiddenItem }) => {
    if (isHiddenItem) return isActive ? '#E8EBFA' : 'transparent';
    return isActive ? color.background : 'transparent';
  }};
  & svg path {
    stroke: ${({ isActive, color, isDynamicCollection }) => {
    if (!isDynamicCollection) return isActive ? color.svgActive : 'rgb(89, 96, 128)';
    return color?.svgActive;
  }};
    fill: transparent;
  }
  ${({ forSecondaryMenu }) => (forSecondaryMenu
    ? `
    display: flex;
    justify-content: center;
    border: 0.6px solid #ECE2E2;
    border-radius: 6px;
    svg path {
    stroke:#484343;
    }
  `
    : '')}

  &:hover {
    background-color: ${({ color, isHiddenItem }) => {
    if (isHiddenItem) return '#E8EBFA';
    return color.background;
  }};

    & svg path {
      stroke: ${({ color }) => {
    return color?.svgActive || '#242633';
  }};
    }

    border: 0.6px solid #ffc72c;
  }
  ${({ isActive }) => (isActive ? '    border: 0.6px solid #FFC72C;' : 'background:white;')}
`;

const SmartViewTab = ({
  smartViewModal,
  forSecondaryMenu,
  moveTop,
  ...props
}) => {
  const { t } = useLocalization();
  const { activeSieveOption } = useSelector((state) => state.library);
  const parentRef = useRef(null);
  const [exOptionRef, isExOpened, setIsExOpened] = useComponentVisible(
    false,
    DATA_PARENTS.smartview,
  );
  const [dropDDPos, setDropDDPos] = useState({ x: 197, y: 0 });
  const [clickedView, setCLickedView] = useState({});
  const setPosition = (rect, view) => {
    const { y } = rect;
    setIsExOpened(true);
    setDropDDPos((oldState) => ({ ...oldState, y: y - 120 }));
    setCLickedView(view);
  };
  const turnOffDD = () => setIsExOpened(false);
  // const [hiddenIcons, setHiddenIcons] = useState([]);
  // const [tabsRef, isMoreOpened, setIsMoreOpened] = useComponentVisible(false,
  //   DATA_PARENTS.PlaylistBuilderLibrary);

  // const { sortType = defaultSortType } = useSelector(state => state.content);
  //
  // const setPreviewContentQuery = ({ newActiveNavSlider, newSelectorType, newSortType }) => {
  //   dispatch(actionCreator(ContentActionType.startUpload, {
  //     activeNavSlider: newActiveNavSlider,
  //     selectorType: newSelectorType,
  //     sortType: newSortType || sortType,
  //   }));
  // };

  //
  // useEffect(() => {
  //   const dynamicCollections = Object.values(dynamicCollection || {}).sort((a, b) => b.position - a.position);
  //   if (!dynamicCollections.length) return;
  //   const availableSpace = windowDimensions.height - TABS_MENU_TOP_SHIFT - TABS_MENU_BOTTOM_SHIFT;
  //   const numberOfVisibleItems = Math.floor(availableSpace / ONE_ICON_HEIGHT);
  //   const hiddenItems = dynamicCollections
  //     .splice(numberOfVisibleItems, dynamicCollections.length - numberOfVisibleItems);
  //   setIconsToShow(dynamicCollections);
  //   setHiddenIcons(hiddenItems);
  // }, [dynamicCollection, windowDimensions.height]);

  // const toggleSideBars = (e, place, id) => {
  //   e.stopPropagation();
  //   e.preventDefault();
  //   if (isSmartViewOpened) setIsSmartViewOpened(false);
  //   if (place === 'slides') {
  //     if (isShowLibraryWidget) dispatch(actionShowLibraryWidget(false));
  //     return;
  //   }
  //   if (place === 'text' || place === 'library' || place === 'pages') {
  //     setPreviewContentQuery({ newActiveNavSlider: 'pages', newSelectorType: 'all' });
  //
  //     dispatch(actionChangeSieveValue(placeSelector(place), 'library'));
  //   } else if (place === 'tag') {
  //     setPreviewContentQuery({ newActiveNavSlider: 'tag', newSelectorType: id });
  //     dispatch(actionChangeSieveValue(place, 'library'));
  //   } else {
  //     setPreviewContentQuery({ newActiveNavSlider: 'dynamicCollection', newSelectorType: place });
  //     dispatch(actionChangeSieveValue(place, 'library'));
  //   }
  //   if (!isShowLibraryWidget) dispatch(actionShowLibraryWidget(!isShowLibraryWidget));
  // };

  // const [dragItem, setDragItem] = useState(null);
  //
  // const dragPageStart = (item, index) => (e) => {
  //   const id = document.getElementById(item.id);
  //   const div2 = id.cloneNode('deep');
  //   // https://stackoverflow.com/questions/14203734/dragend-dragenter-and-dragleave-firing-off-immediately-when-i-drag
  //   setTimeout(() => {
  //     setDragItem({ ...item, index });
  //     dispatch(ActionDraggable.DragSmartView(item.id));
  //   }, 10);
  //   const div = document.createElement('div');
  //   div.className = dragType.smartView;
  //   div.id = dragType.smartView;
  //   div.appendChild(div2);
  //   document.body.appendChild(div);
  //
  //   const rect = id.getBoundingClientRect();
  //
  //   e.dataTransfer.setDragImage(div, e.clientX - rect.left, e.clientY - rect.top);
  // };

  // const endDrop = () => {
  //   setDragItem(false);
  //   const div = document.getElementById(dragType.smartView);
  //   if (div) document.body.removeChild(div);
  //   dispatch(ActionDraggable.Clear());
  // };

  // const onDrop = (e, newIndex) => {
  //   if ((newIndex || newIndex === 0)) {
  //     if (newIndex !== dragItem.index) {
  //       dispatch(actionMoveDynamicCollection(dragItem, newIndex));
  //     }
  //     setDragItem(null);
  //   }
  //   endDrop();
  // };

  return (
    <>
      <Tooltip
        text={t.smartViewT}
        direction={props.isLibrary ? 'up' : 'right'}
        place={props.isLibrary ? 'LibraryTabsSV' : 'PlaylistTabs'}
        moveTop={moveTop}
        data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
      >
        <Tab
          forSecondaryMenu
          data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
          data-place="library"
          color={defColor}
          isLibrary={props.isLibrary}
          isActive={smartViewModal.isComponentVisible}
          onClick={(e) => {
            e.stopPropagation();
            smartViewModal.setIsComponentVisible(true);
          }}
          ref={parentRef}
          newUPVMode={props.newUPVMode}
        >
          <SmartViewGroupSvg
            data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
            data-place="library"
          />
        </Tab>
      </Tooltip>

      {smartViewModal.isComponentVisible && (
        <>
          <SmartViewDropDown
            setShowNewSmartView={props.setShowNewSmartView}
            isLibrary={props.isLibrary}
            smartViewRef={smartViewModal.ref}
            activeSieveOption={activeSieveOption}
            smartViewModal={smartViewModal}
            toggleSideBars={props.toggleSideBars}
            parentRef={parentRef}
            setIsShowFilter={props.setIsShowFilter}
            setPosition={setPosition}
            isExOpened={isExOpened}
            resetSmartview={props.resetSmartview}
            hideDD={turnOffDD}
            newUPVMode={props.newUPVMode}
            setChosenSmartView={props.setChosenSmartView}
          />
          {isExOpened && (
            <DropDownDownItem
              top={dropDDPos.y}
              left={dropDDPos.x}
              close={() => {
                smartViewModal.setIsComponentVisible(false);
                setIsExOpened(false);
              }}
              refMenu={exOptionRef}
              clickedView={clickedView}
              resetSmartview={props.resetSmartview}
              setShowNewSmartView={props.setShowNewSmartView}
              applyFilters={props.setSmartViewForEdit}
            />
          )}
        </>
      )}
    </>
  );
};

export default SmartViewTab;
