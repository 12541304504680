import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CalloutSvg } from '../../images/icons/callout.svg';
import { ReactComponent as ParagraphSvg } from '../../images/icons/paragraph.svg';
import styles from './index.module.css';

import { ReactComponent as Title1Svg } from '../../images/icons/title_1.svg';
import { ReactComponent as Title2Svg } from '../../images/icons/title_2.svg';
import { ReactComponent as Title3Svg } from '../../images/icons/title_3.svg';
import { ReactComponent as NumListSvg } from '../../images/icons/numbered_list.svg';
import { ReactComponent as BullListSvg } from '../../images/icons/list_bullet.svg';
import { ReactComponent as TableSvg } from '../../images/icons/table.svg';
import { ReactComponent as LineSvg } from '../../images/icons/line.svg';
import { BlockTypes } from '../../utils/constants';
import { EditPage } from '../../redux/pages/types';
import { actionCreator } from '../../shared/redux/actionHelper';
import { useLocalization } from '../../LocalizationContext';

const TurnIntoBlock = ({
  selectAddEditableBlock,
  parentRef,
  setIsOpenMenu,
  ...props
}) => {
  const draggableBlocks = useSelector(
    (state) => state.currentPage?.draggableBlocks,
  );
  const dispatch = useDispatch();
  const { t } = useLocalization();

  const turnInto = (type, event) => {
    event.stopPropagation();

    if (draggableBlocks.length && !props.isPlaylist) {
      setIsOpenMenu(false);
      draggableBlocks.forEach((i) => {
        dispatch(
          actionCreator(EditPage.ChangeTypeEditableBlock, { id: i, type }),
        );
      });
    } else selectAddEditableBlock(type);
    props.clear();
  };

  return (
    <div
      ref={parentRef}
      className={`${styles.dropdown_turn_info} ${
        props.isPlaylist ? styles.isPlaylist : ''
      } ${props.isUpv ? styles.isUpv : ''}`}
    >
      <PerfectScrollbar>
        <div
          className={styles.dropdown__item}
          onMouseDown={(event) => turnInto(BlockTypes.title, event)}
        >
          <Title1Svg />
          <div>{t.title1T}</div>
        </div>

        <div
          className={styles.dropdown__item}
          onMouseDown={(event) => turnInto(BlockTypes.title2, event)}
        >
          <Title2Svg />
          <div>{t.title2T}</div>
        </div>

        <div
          className={styles.dropdown__item}
          onMouseDown={(event) => turnInto(BlockTypes.title3, event)}
        >
          <Title3Svg />
          <div>{t.title3T}</div>
        </div>

        <div
          className={styles.dropdown__item}
          onMouseDown={(event) => turnInto(BlockTypes.text, event)}
        >
          <ParagraphSvg />
          <div>{t.paragraphT}</div>
        </div>

        {!props.isUpv && (
          <div
            className={styles.dropdown__item}
            onMouseDown={(event) => turnInto(BlockTypes.unordered_list_item0, event)
            }
          >
            <BullListSvg />
            <div>{t.bulletedListT}</div>
          </div>
        )}

        {!props.isUpv && (
          <div
            className={styles.dropdown__item}
            onMouseDown={(event) => turnInto(BlockTypes.ordered_list_item0, event)
            }
          >
            <NumListSvg />
            <div>{t.numberedListT}</div>
          </div>
        )}
        <div
          className={styles.dropdown__item}
          onMouseDown={(event) => turnInto(BlockTypes.callout, event)}
        >
          <CalloutSvg />
          <div>{t.calloutT}</div>
        </div>

        {!props.isUpv && (
          <div
            onMouseDown={(event) => turnInto(BlockTypes.lineSeparator, event)}
            className={styles.dropdown__item}
          >
            <LineSvg />
            <div>{t.lineT}</div>
          </div>
        )}

        {!props.isUpv && (
          <div className={`${styles.dropdown__item}`}>
            <TableSvg />
            <div>{t.tableT}</div>
          </div>
        )}

        {/* <div className={styles.dropdown__item} onClick={(event) => turnInto('image', event)}> */}
        {/*  <ImageSvg/> */}
        {/*  <div>{t.imageT}</div> */}
        {/* </div> */}

        {/* <div className={styles.dropdown__item} onClick={(event) => turnInto('video', event)}> */}
        {/*  <VideoSvg/> */}
        {/*  <div>{t.videoT}</div> */}
        {/* </div> */}
      </PerfectScrollbar>
    </div>
  );
};

export default TurnIntoBlock;
