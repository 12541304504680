import React from 'react';
import styled from 'styled-components/macro';
import { DEFAULT_TITLE } from '../../../utils/constants';
import { useLocalization } from '../../../LocalizationContext';

export const HoverBlock = styled.div`
  display: none;
  flex-direction: column;
  position: absolute;
  background: #ffffff;
  border: 1px solid #ffc72c;
  border-radius: 2px;
  padding: 7px;
  margin-left: 20px;
  margin-top: -5px;
`;

const Counter = styled.div`
  padding: 3px 6px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  cursor: default;
  margin-left: 5px;

  span {
    font-weight: 700;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    color: rgba(83, 75, 79, 0.8);
  }
`;

const List = styled(HoverBlock)`
  span {
    font-weight: 400;
    font-size: 12px;
    color: #585858;
    padding-bottom: 10px;
    &:last-child {
      padding-bottom: 0;
    }
  }
`;

const Wrapper = styled.div`
  cursor: default;

  &:hover {
    ${List} {
      display: flex;
      z-index: 10;
    }
  }
`;

const ChannelsTab = ({ channels, currentChannelID }) => {
  const { t } = useLocalization();
  const anotherChannels = channels.filter(
    (channel) => channel.id !== currentChannelID,
  );

  return (
    <Wrapper>
      <Counter>
        <span>+{anotherChannels.length}</span>
      </Counter>
      <List>
        {anotherChannels.map((channel) => (
          <span>{channel.name || t.defaultSmartFileTitleT}</span>
        ))}
      </List>
    </Wrapper>
  );
};

export default ChannelsTab;
