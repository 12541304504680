import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import {
  actionLogout,
  actionSaveUser,
} from '../../redux/user/action';
import styles from './Login.module.scss';
import validateEmail from '../../utils/validators/validateEmail';
import '../../utils/loader.css';
import { requestRegister } from '../../utils/request';
import { LoginBackGround } from './Login';
import { ReactComponent as LogoSvg } from '../../images/2023/svg/Logo_black_text.svg';
import { EyeContainer } from './ForgotPassword/ForgotView';
import ButtonComponent from '../../components/Buttons/ButtonComponent';
import { ReactComponent as EyeOff16Svg } from '../../images/icons/eye_off.svg';
import { ReactComponent as EyeOn16Svg } from '../../images/icons/eye_on.svg';
import UI from '../../UIComponents';
import LoginIntro from './Intro';
import { actionSaveRedirect } from '../../redux/support/action';
import { DEFAULT_PAGE_PATH, empty } from '../../utils/constants';
import { useLocalization } from '../../LocalizationContext';

 const Registration = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useLocalization();

  const [password, setPass] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [isSend, setIsSend] = useState(false);
  const { redirectLink } = useSelector((state) => state.support) || empty;

  const [errorLastName, setErrorLastName] = useState('');
  const [errorFirstName, setErrorFirstName] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPass, setErrorPass] = useState('');

  const [isShowPass, setIsShowPass] = useState('password');

  const send = () => {
    dispatch(actionLogout());
    setIsSend(true);
    requestRegister({
      password,
      first_name: firstName,
      last_name: lastName,
      email,
    })
      .then((resp) => {
        setIsSend(false);
        if (resp.data.errors) {
          setIsSend(false);
          setGeneralError(resp.data.message);
          return;
        }

        dispatch(
          actionSaveUser({
            first_name: resp.data.first_name,
            firstName: resp.data.first_name,
            last_name: resp.data.last_name,
            lastName: resp.data.last_name,
            id: resp.data.id,
          }),
        );
        localStorage.setItem('jwt', resp.data.token);
        localStorage.setItem('userId', resp.data.id);

        if (redirectLink) {
          dispatch({
            type: 'UPLOAD_DATA',
            payload: { userId: resp.data.id, history },
          });
          dispatch(actionSaveRedirect(null));
          history.push(redirectLink);
          return;
        }
        history.push(DEFAULT_PAGE_PATH);
        dispatch({
          type: 'UPLOAD_DATA',
          payload: { userId: resp.data.id, history },
        });
        // dispatch(actionCreateChannel());
      })
      .catch((e) => {
        setIsSend(false);
        setGeneralError(
          (e.response && e.response.data && e.response.data.message)
            || 'Something wrong',
        );
      });
  };

  const validate = () => {
    const emailIsValid = validateEmail(email);
    if (generalError) setGeneralError('');
    if (!firstName.trim()) setErrorFirstName(t.fieldRequiredErrorT);
    if (!lastName.trim()) setErrorLastName(t.fieldRequiredErrorT);
    if (!email) setErrorEmail(t.emailRequiredErrorT);
    else if (!emailIsValid) setErrorEmail(t.invalidEmailErrorT);
    if (!password) setErrorPass(t.passwordRequiredErrorT);
    else if (password.length < 6) setErrorPass(t.passwordLengthErrorT);
    else send();
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      validate();
    }
  };

  const togglePasswordShow = () => {
    if (isShowPass === 'password') setIsShowPass('text');
    else setIsShowPass('password');
  };

  const onEmailChange = (event) => {
    !!errorEmail && setErrorEmail('');
    setEmail(event.target.value);
  };
  const onPassChange = (event) => {
    !!errorPass && setErrorPass('');
    setPass(event.target.value);
  };
  const onFirstNameChange = (event) => {
    !!errorFirstName && setErrorFirstName('');
    setFirstName(event.target.value);
  };
  const onLastNameChange = (event) => {
    !!errorLastName && setErrorLastName('');
    setLastName(event.target.value);
  };

  return (
    <div className={styles.scroll_wrapper} onKeyPress={handleKeyPress}>
      <div className={styles.scroll_balancer} onKeyPress={handleKeyPress}>
        {isSend && (
          <div className="loader-wrapper">
            <div className="loader">{t.loadingT}</div>
          </div>
        )}

        <LoginBackGround />

        <div className={styles.column_wrapper}>
          <div className={`${styles.column} ${styles.column_register}`}>
            <div className={styles.scroll}>
              <div className={styles.logo_wrapper}>
                <LogoSvg />
              </div>

              <div className={styles.header}>{t.createAnAccountT}</div>
              <div className={styles.no_account_block}>
                <div className={styles.text_signup}>
                  {t.alreadyHaveAccountQuestionT}&nbsp;
                </div>
                <NavLink
                  className={styles.text_info_link}
                  to="/login"
                  activeStyle={{
                    fontWeight: 'bold',
                    color: 'red',
                  }}
                >
                  {t.signInT}
                </NavLink>
              </div>

              <UI.Error error={generalError} isLogin />

              <div className={styles.one_line_names}>
                <div data-side="left" className={styles.name_container}>
                  <div
                    className={`${styles.field} ${
                      !!errorFirstName && styles.field_has_error
                    }`}
                  >
                    <input
                      data-cy="firstName"
                      type="fname"
                      disabled={isSend}
                      className={styles.input}
                      placeholder={t.firstNameTextT}
                      value={firstName}
                      tabIndex={1}
                      onChange={onFirstNameChange}
                      autoComplete="firstName"
                    />
                  </div>

                  <UI.Error error={errorFirstName} isLogin />
                </div>

                <div data-side="right" className={styles.name_container}>
                  <div
                    className={`${styles.field} ${
                      !!errorLastName && styles.field_has_error
                    }`}
                  >
                    <input
                      data-cy="lastName"
                      name="lname"
                      disabled={isSend}
                      className={styles.input}
                      placeholder={t.lastNameT}
                      value={lastName}
                      tabIndex={2}
                      onChange={onLastNameChange}
                      autoComplete="lastName"
                    />
                  </div>

                  <UI.Error error={errorLastName} isLogin />
                </div>
              </div>

              <div className={styles.email_info_block}>

                <div
                  className={`${styles.field} ${
                    errorEmail && styles.field_has_error
                  }`}
                >
                  <input
                    data-cy="email"
                    disabled={isSend}
                    className={styles.input}
                    placeholder={t.emailAddressPlaceholerT}
                    autoComplete="username"
                    type="text"
                    value={email}
                    tabIndex={3}
                    onChange={onEmailChange}
                  />
                </div>

                <UI.Error error={errorEmail} isLogin />
              </div>

              <div className={styles.password_info_block}>

                <div
                  className={`${styles.field} ${
                    errorPass && styles.field_has_error
                  }`}
                >
                  <input
                    data-cy="password"
                    disabled={isSend}
                    type={isShowPass}
                    className={styles.input}
                    placeholder={t.passwordPlaceholerT}
                    value={password}
                    tabIndex={4}
                    onChange={onPassChange}
                    autoComplete="password"
                  />
                  {!!password.length
                    && (
                      <div className={styles.eye_container}>
                        <EyeContainer onClick={togglePasswordShow} >
                          {isShowPass === 'password' && <EyeOff16Svg />}
                          {isShowPass === 'text' && <EyeOn16Svg />}
                        </EyeContainer>
                      </div>
                    )}
                </div>
                <div className={styles.input_tips}>
                  {t.lengthErrorT}
                </div>

                <UI.Error error={errorPass} isLogin isSignUp />
              </div>

              <div
                data-place="signUp"
                tabIndex={5}
                className={styles.sign_in_button_container}
              >
                <div data-cy="submitSignUp">
                  <ButtonComponent
                    text={t.createAccountT}
                    fullWidth
                    height={48}
                    clickCb={validate}
                    isSignIn
                  />
                </div>
                <div className={styles.text_notice}>
                  {t.createAccountMessage1T}
                  <span className={styles.text_notice_link}>
                    {t.createAccountMessage2T}
                  </span>{' '}
                  {t.createAccountMessage3T}
                  <span className={styles.text_notice_link}>
                    {t.createAccountMessage4T}
                  </span>
                  .
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoginIntro />
      </div>
    </div>
  );
};

export default Registration
