import React from "react";
import styled from "styled-components";
import { groupPlaylistByDayModify } from "../../../utils/libraryContentSelector";
import RecentFilterComponent from "./RecentFilterComponent";
import { filterNamesRecently } from "../../../utils/libraryContentSelector/filterNames";
import { useLocalization } from "../../../LocalizationContext";

const PlayListContainerCard = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 14px;
`;
const PlayListContainerTable = styled.div`
  display: block;
  padding-top: 14px;
`;
const RecentFilterGroupInHelp = ({
  sortedPages,
  refSelectedBlocks,
  isAdmin,
  isSimplifiedMultiselect,
  setSimplifiedMultiselect,
  counterSelectedPage,
  needToDelete,
  channelId,
  isChannelMiniPlaylist,
  permissions,
  isHelp,
  isCardsView,
  cannotBeDeleted,
  isDescriptionOpen,
  ...props
}) => {
  const { t } = useLocalization();
  if (!sortedPages.length) {
    return <></>;
  }

  const {
    todayPlayLists,
    yesterdayPlayLists,
    currentWeekPlayLists,
    previousWeekPlayLists,
    currentMonthPlayLists,
    previousMonthPlaylists,
    currentYearPlaylists,
    previousYearPlaylists,
    aLongTimeAgoPlaylists,
  } = groupPlaylistByDayModify(sortedPages);

  const PlayListContainer = isCardsView
    ? PlayListContainerCard
    : PlayListContainerTable;

  return (
    <PlayListContainer>
      {[
        todayPlayLists,
        yesterdayPlayLists,
        currentWeekPlayLists,
        previousWeekPlayLists,
        currentMonthPlayLists,
        previousMonthPlaylists,
        currentYearPlaylists,
        previousYearPlaylists,
        aLongTimeAgoPlaylists,
      ].map((it, index) => {
        return (
          <RecentFilterComponent
            key={it.length + index.toString()}
            props={props}
            filterTitle={t[filterNamesRecently[index]]}
            sortedPages={it}
            refSelectedBlocks={refSelectedBlocks}
            isAdmin={isAdmin}
            isSimplifiedMultiselect={isSimplifiedMultiselect}
            setSimplifiedMultiselect={setSimplifiedMultiselect}
            counterSelectedPage={counterSelectedPage}
            needToDelete={needToDelete}
            channelId={channelId}
            isChannelMiniPlaylist={isChannelMiniPlaylist}
            permissions={permissions}
            isHelp={isHelp}
            isCardsView={isCardsView}
            cannotBeDeleted={cannotBeDeleted}
            isDescriptionOpen={isDescriptionOpen}
          />
        );
      })}
    </PlayListContainer>
  );
};

export default RecentFilterGroupInHelp;
