import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './LeftSideBar.module.scss';

import { ReactComponent as TrashIcon } from '../../images/icons/trash_Icon.svg';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import TrashOption from '../DropDownOption/TrashOption';
import { actionRemoveAllSelected } from '../../redux/selectedPage/action';
import { DEFAULT_TITLE, dragType, openModalType } from '../../utils/constants';
import { actionDeleteDynamicCollection } from '../../redux/dynamicCollection/action';
import { actionOpenModal } from '../../redux/user/action';
import { actionCreator } from '../../shared/redux/actionHelper';
import DashboardColumns from '../../redux/dashboardColumns/types';
import SupportAction from '../../redux/support/types';
import { DeletedActionType } from '../../redux/deleted/deletedTypes';
import { calcNextItemToRedirect } from '../../utils/helpers';
import { useLocalization } from '../../LocalizationContext';

const Trash = ({ isPlaylist }) => {
  const { t } = useLocalization();
  const [refMenu, isComponentVisible, setIsComponentVisible] = useComponentVisible(false);
  const idDragPage = useSelector((state) => state.currentPage.idDragPage);
  const { type: draggableType, id: draggableItemId } = useSelector(
    (state) => state.draggable,
  );
  const selectedPage = useSelector((state) => state.selectedPage);
  const { myChannels } = useSelector((state) => state.channels);
  const { folderId: activeChannelId } = useSelector((state) => state.user);
  const { tags } = useSelector((state) => state.user);
  const { closeColumn } = useSelector(
    (state) => state.dashboardColumns,
  );
  const [isDragOver, setIsDragOver] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const onDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDragOver(false);
    if (draggableType) {
      if (draggableType === dragType.smartView) {
        dispatch(
          actionDeleteDynamicCollection(
            draggableItemId,
            history.location.pathname,
          ),
        );
      }
      if (draggableType === dragType.tag) {
        const tagName = tags[draggableItemId].title;
        dispatch(actionOpenModal(openModalType.DeleteTag, { id: draggableItemId, tagName }));
      }
      if (draggableType === dragType.channel || draggableType === dragType.foreignChannel) {
        const nextItem = calcNextItemToRedirect(myChannels, activeChannelId, true);
        if (draggableType === dragType.channel) {
          dispatch(actionOpenModal(openModalType.DeleteChannel, { id: draggableItemId, nextItem, history }));
        }
        if (draggableType === dragType.foreignChannel) {
          dispatch(
            actionOpenModal(openModalType.LeaveChannel, {
              channelId: draggableItemId,
              channelName:
                myChannels[draggableItemId]?.name ?? t.defaultChannelTitleT,
              nextItem,
            }),
          );
        }
      }
      if (draggableType === dragType.board) {
        const isMoveFromClose = closeColumn.card.filter(card => card.id === draggableItemId).length > 0;
        dispatch(
          actionCreator(DashboardColumns.card.remove, {
            cardId: draggableItemId,
            isMoveFromClose,
          }),
        );
      }
      return;
    }

    if (idDragPage) {
      dispatch(
        actionCreator(SupportAction.CheckIsInUse, {
          isPlaylist,
          itemId: idDragPage,
        }),
      );
    } else {
      dispatch(
        actionCreator(SupportAction.CheckIsInUse, {
          isPlaylist,
          counterSelectedPage: Object.keys(selectedPage).length,
        }),
      );

      dispatch(actionRemoveAllSelected());
    }
    const div = document.getElementById('draggable_page');
    if (div) document.body.removeChild(div);
  };
  const onDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!isDragOver) setIsDragOver(true);
  };

  const onDragEnd = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDragOver(false);
  };

  useEffect(() => {
    if (isComponentVisible) dispatch(actionCreator(DeletedActionType.getDeleted));
  }, [isComponentVisible]);

  return (
    <div
      onDragOver={onDragOver}
      onDragLeave={onDragEnd}
      onDrop={onDrop}
      ref={refMenu}
      onClick={() => setIsComponentVisible(true)}
      className={`${styles.itemMenu} ${styles.trashWrap} 
      ${isDragOver ? styles.trash_has_drag : ''} 
      ${isComponentVisible ? styles.active : ''} 
      `}
    >
      <TrashIcon />
      <div>{isComponentVisible && <TrashOption parentRef={refMenu} />}</div>
    </div>
  );
};

export default Trash;
