import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styled from 'styled-components/macro';
import styles from './index.module.scss';
import { ReactComponent as CheckboxOff16Svg } from '../../images/icons/checkbox_off_16.svg';
import { ReactComponent as CheckboxOn16Svg } from '../../images/icons/checkbox_on_16.svg';
import { ReactComponent as ThreeDotsIcon } from '../../images/2023/svg/upv/three_dots_22_6.svg';
import {
  SVGWrapperTagsAndSmartviews,
  DDInputWrapper,
} from '../SmartView/2023/styled';
import { filterDCTitle, sortTagsCounterSelected } from '../../utils/helpers';
import useThrottle from '../../utils/hooks/useThrottle';
import { useLocalization } from '../../LocalizationContext';

const cn = classNames.bind(styles);

const ItemWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 10px;
  padding-right: 10px;
  width: max-content;
  border-radius: 16px;
  ${({ bgColor }) => (bgColor ? `background-color:${bgColor};` : 'background-color:#ABA9C3;')}
`;

const SearchWrapper = styled.input`
  padding-left: 16px;
  border: none;
  margin-bottom: 7px;
  margin-top: 11px;

  &::placeholder {
    font-family: "RobotoFlex";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* or 150% */

    color: rgba(122, 105, 105, 0.5);
  }
`;

const Divider = styled.div`
  width: 218px;
  height: 1px;
  background: rgba(29, 29, 29, 0.08);
  margin: 4px auto auto;
`;
const CounterWrapper = styled.div`
  color: #939393;
`;
const CounterAndItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 13px;
  position: relative;
  .show_on_hover {
    display: none;
  }
  &:hover {
    .show_on_hover {
      display: block;
    }
    .hide_on_hover {
      display: none;
    }
  }
`;
const DynamicCollectionDropListOptionTAGS = (props) => {
  const [dynamicTags, setDynamicTags] = useState([]);
  const [search, setSearch] = useState('');
  const [throttle] = useThrottle(300);
  const { t } = useLocalization();
  const filterWithDebounce = () => setDynamicTags(
    sortTagsCounterSelected(
      props.filter,
      Object.values(props.option || {}).filter((it) => filterDCTitle(it, search),
      ),
    ),
  );

  const clickHandler = (e, id) => {
    props.setFilter((prev) => {
      const newState = { ...prev.tags };

      if (newState[id]) {
        delete newState[id];
      } else {
        newState[id] = props.option[id];
      }
      return {
        ...prev,
        tags: { ...newState },
      };
    });
    e.stopPropagation();
  };

  const onInputChange = (event) => {
    setSearch(event.target.value.trim());
  };

  const onInputKeyDown = (event) => {
    if (event.key === 'Backspace' && !search) {
      event.currentTarget.blur();
    }
  };

  useEffect(() => {
    throttle(() => filterWithDebounce);
  }, [search]);
  useEffect(() => {
    if (Object.values(props.option || {})) {
      setDynamicTags(
        sortTagsCounterSelected(
          props.filter,
          Object.values(props.option || {}),
        ),
      );
    }
  }, []);
  return (
    <div
      ref={props.refParent}
      className={cn('drop_list_wrapper', { active: props.active })}
      data-parent={props.data}
    >
      <div className={cn('drop_list_container')}>
        <div className={cn('drop_list_title')}>{t.selectTagsUpT}</div>
        <DDInputWrapper
          data-parent={props.data}
          value={search}
          onChange={onInputChange}
          onBlur={onInputKeyDown}
          placeholder={t.searchATagT}
          onClick={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
        />
        <Divider />
        <div className={cn('drop_list_main')}>
          {dynamicTags.map((item) => (
            <div
              key={item.id}
              className={cn('list_item_container')}
              onMouseDown={(e) => clickHandler(e, item.id)}
            >
              <div className={cn('list_item_checkbox')}>
                {!!props.filter && props.filter[item.id] && <CheckboxOn16Svg />}
                {!(props.filter && props.filter[item.id]) && (
                  <CheckboxOff16Svg />
                )}
              </div>
              <CounterAndItemWrapper>
                <ItemWrapper bgColor={item.backgroundColor}>
                  {item.title}
                </ItemWrapper>
                <CounterWrapper className="hide_on_hover">
                  {item?.itemsCounter || item?.libraryComponentsCounter || 0}
                </CounterWrapper>
                <SVGWrapperTagsAndSmartviews
                  className="show_on_hover"
                  isSecond
                  forTagsThreeDots
                >
                  <ThreeDotsIcon />
                </SVGWrapperTagsAndSmartviews>
              </CounterAndItemWrapper>
            </div>
          ))}
          {!Object.values(props.option || {}).length && (
            <div className={cn('list_item_no_tags')}>{t.noTagsFoundT}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DynamicCollectionDropListOptionTAGS;
