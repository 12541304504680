import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components/macro';
import styles from './index.module.scss';
import {
  actionChangeLibraryComponentInLibraryFromBlocks,
  actionCloseCreateComponent,
  actionCreateLibraryComponentInLibraryFromBlocks,
} from '../../redux/library/actions';
import BlocksEditingArea from '../BlocksEditingArea';
import {
  DEFAULT_TITLE,
  empty,
  emptyCallback,
  MessageType,
  TagsParams,
} from '../../utils/constants';
import CreateComponentHashtagsInput from '../../pages/Playlist/CreteComponentHashtagsInput';
import {
  actionFillComponentSupportBlocks,
  actionShowMessage,
} from '../../redux/support/action';
import SimpleTextInput from '../SimpleComponents/Inputs/SimpleTextInput';
import Button2023 from '../Buttons/Button2023';
import { useLocalization } from '../../LocalizationContext';

const cx = classNames.bind(styles);

const GreyTitleWrapper = styled.div`
  position: relative;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #939393;
  letter-spacing: 0.05em;
  font-family: RobotoFlex, sans-serif;
  text-transform: uppercase;
`;
const Divider = styled.div`
  width: 618px;
  height: 1px;
  background: #1d1d1d;
  opacity: 0.08;
  margin: 6px auto auto;
  ${({ theSecond }) => (theSecond ? 'margin-top:0px;' : '')}
`;
const EditingAreaWrapper = styled.div`
  height: 343px;
  position: relative;
  top: 21px;
  overflow: hidden;
  overflow-y: scroll;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const TagWrapper = styled.div`
  height: 148px;
  position: relative;
`;

const ButtonBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 23px;
  padding-left: 478px;
  padding-right: 16px;
`;

const CreateComponentFromBlocks = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const history = useHistory();
  const { t } = useLocalization();
  const components = useSelector((state) => state.content.contentData) || empty;
  const { id } = useSelector((state) => state.currentPage) || empty;
  const { editComponentId } = useSelector((state) => state.library) || empty;
  const { component, blocks, isLoadedComponent } = useSelector((state) => state.support) || empty;
  const { selectorType, folderId, tags } = useSelector((state) => state.user) || empty;
  const [newLibraryComponentId] = useState(editComponentId || uuid());
  const item = component
    || (editComponentId && components[editComponentId]) || { tags: [] };
  const [tagsToAttach, setTagsToAttach] = useState(item.tags);
  const [needShowWarning, setNeedShowWarning] = useState(true);
  const [showWarningCreate, setShowWarningCreate] = useState(false);

  const isPage = history.location.pathname.startsWith('/libraryPage')
    || history.location.pathname.startsWith('/edit_page');

  useEffect(() => {
    if (selectorType === 'tag' && folderId && tags[folderId]) {
      setTagsToAttach((prev) => [...prev, tags[folderId]]);
    }
    if (editComponentId) {
      dispatch(actionFillComponentSupportBlocks(editComponentId));
    }
  }, [editComponentId]);

  useEffect(() => {
    setName(component?.title || t.defaultComponentTitleT);
    if (component?.tags?.length) setTagsToAttach(component.tags);
  }, [component]);

  const createHandler = () => {
    if (!blocks?.length) {
      dispatch(actionShowMessage({ type: MessageType.AddContentFirst }));
      return;
    }
    if (!needShowWarning) setShowWarningCreate(true);
    else if (!editComponentId) {
      dispatch(
        actionCreateLibraryComponentInLibraryFromBlocks(name, tagsToAttach),
      );
    } else {
      dispatch(
        actionChangeLibraryComponentInLibraryFromBlocks(
          name,
          editComponentId,
          isPage,
          tagsToAttach,
          id,
          history,
        ),
      );
    }
  };

  const close = () => {
    dispatch(actionCloseCreateComponent());
  };

  const blurHandler = () => {
    if (!name) setName(t.unnamedComponentT);
  };

  const keyHandler = (e) => {
    if ((e.key === 'Escape' || e.key === 'Enter') && !name) {
      setName(t.unnamedComponentT);
    }
  };

  if (!isLoadedComponent && editComponentId) {
    return (
      <div onClick={() => close()} className={cx('wrapper_create_component')}>
        <div
          onClick={(e) => e.stopPropagation()}
          className={cx('create_component')}
        >
          <div className="loader-wrapper-page create_component">
            <div className="loader create_component">{t.loadingT}</div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div onClick={() => close()} className={cx('wrapper_create_component')}>
      <div
        onClick={(e) => e.stopPropagation()}
        className={cx('create_component')}
      >
        <div>
          <div onKeyDown={keyHandler} className={cx('header')}>
            <GreyTitleWrapper>
              {editComponentId ? t.editComponentLowT : t.createNewComponentT}
            </GreyTitleWrapper>
            <SimpleTextInput
              fieldName=""
              autoFocus
              value={name}
              fooKeyDown={keyHandler}
              fooBlur={blurHandler}
              setValue={setName}
              setValidateError={emptyCallback}
              isRequired
              forCreateBlock
            />
            <Divider />
          </div>
        </div>
        <EditingAreaWrapper>
          <BlocksEditingArea
            isVisibleFolderOption={false}
            showWarningCreate={showWarningCreate}
            closeShowWarningCreate={() => setShowWarningCreate(false)}
            close={close}
            needShowWarning={needShowWarning}
            setNeedShowWarning={setNeedShowWarning}
            createComponentWidget
            editComponentId={editComponentId}
          />
        </EditingAreaWrapper>
        <TagWrapper>
          <Divider theSecond />
          <div className={cx('wrapper_submit')}>
            <div className={cx('tags_group')}>
              <div className={cx('tags_input')}>
                <CreateComponentHashtagsInput
                  noDescription
                  isEditable
                  place="LibraryComponent"
                  type="LibraryComponent"
                  state={TagsParams.createComponentTags}
                  newLibraryComponentId={newLibraryComponentId}
                  tagsToAttach={tagsToAttach}
                  setTagsToAttach={setTagsToAttach}
                  isCreateFromLibrary
                  forCreateBlock
                />
              </div>
            </div>
          </div>
          <ButtonBlockWrapper isForCreate={!!editComponentId}>
            <Button2023
              variant="primary"
              width={69}
              height={32}
              text={tags.cancelUpT}
              noMargins
              handleButtonClick={close}
            />
            <Button2023
              variant="secondary"
              width={editComponentId ? 52 : 67}
              height={32}
              text={editComponentId ? t.SaveUpT : t.CreateUpT}
              noMargins
              handleButtonClick={createHandler}
            />
          </ButtonBlockWrapper>
        </TagWrapper>
      </div>
    </div>
  );
};

export default CreateComponentFromBlocks;
