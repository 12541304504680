import React from "react";
import styled from "styled-components";
import { groupPlaylistByDayModify } from "../../../utils/libraryContentSelector";
import RecentFilterComponent from "./RecentFilterComponent";
import { filterNamesRecently } from "../../../utils/libraryContentSelector/filterNames";
import { useLocalization } from "../../../LocalizationContext";

const PlayListContainerCard = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 14px;

  @media (min-width: 900px) {
    width: 476px;
    margin: 0 auto;
  }

  @media (min-width: 1070px){
    width: 728px;
  }

  @media (min-width: 1350px)  {
    width: 985px;
  }

  @media (min-width: 1600px)  {
    width: 1234px;
  }

  @media (min-width: 1920px)  {
    width: 1486px;
  }
`;
const PlayListContainerTable = styled.div`
  display: block;
  //padding-top: 14px;
`;
const RecentFilterGroupInChannel = ({
  refSelectedBlocks,
  isSimplifiedMultiselect,
  setSimplifiedMultiselect,
  counterSelectedPage,
  needToDelete,
  today,
  channelId,
  isChannelMiniPlaylist,
  permissions,
  ...props
}) => {
  const isCardView = props?.props?.isCardView;
  const { t } = useLocalization();
  if (!today.length) {
    return <></>;
  }
  const {
    todayPlayLists,
    yesterdayPlayLists,
    currentWeekPlayLists,
    previousWeekPlayLists,
    currentMonthPlayLists,
    previousMonthPlaylists,
    currentYearPlaylists,
    previousYearPlaylists,
    aLongTimeAgoPlaylists,
  } = groupPlaylistByDayModify(today);

  const PlayListContainer = isCardView
    ? PlayListContainerCard
    : PlayListContainerTable;
  return (
    <PlayListContainer>
      {[
        todayPlayLists,
        yesterdayPlayLists,
        currentWeekPlayLists,
        previousWeekPlayLists,
        currentMonthPlayLists,
        previousMonthPlaylists,
        currentYearPlaylists,
        previousYearPlaylists,
        aLongTimeAgoPlaylists,
      ].map((it, index) => {
        return (
          <RecentFilterComponent
            key={it.length + index.toString()}
            props={props}
            filterTitle={t[filterNamesRecently[index]]}
            playlists={it}
            refSelectedBlocks={refSelectedBlocks}
            isSimplifiedMultiselect={isSimplifiedMultiselect}
            setSimplifiedMultiselect={setSimplifiedMultiselect}
            counterSelectedPage={counterSelectedPage}
            needToDelete={needToDelete}
            today={today}
            channelId={channelId}
            isChannelMiniPlaylist={isChannelMiniPlaylist}
            permissions={permissions}
            isDescriptionOpen={props.isDescriptionOpen}
            isCardView={isCardView}
          />
        );
      })}
    </PlayListContainer>
  );
};

export default RecentFilterGroupInChannel;
