import React from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { DATA_PARENTS, COLUMN_CUSTOM_TYPE, openModalType } from '../../../utils/constants';
import { DDTitleWrapper } from '../../../components/SmartView/2023/styled';
import { actionOpenModal } from '../../../redux/user/action';
import { actionCreator } from '../../../shared/redux/actionHelper';
import DashboardColumns from '../../../redux/dashboardColumns/types';
import { useLocalization } from '../../../LocalizationContext';

const MainWrapper = styled.div`
  position: absolute;
  width: 150px;
  background: #ffffff;
  border: 0.6px solid #ece2e2;
  box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.25);
  border-radius: 12px;
  padding-top: 16px;
  z-index: 26;
  ${({ top }) => (top ? `top:${top}px;` : '')}
  ${({ left }) => (left ? `left:${left}px;` : '')}
  ${({ right }) => (right ? `right:${right}px;` : '')}
  height:${({ isDefaultColumn }) => (isDefaultColumn ? '75px' : '122px')}
`;
const OptionWrapper = styled.div`
  padding-left: 15px;
  font-size: 13px;
  height: 30px;
  min-height: 30px;
  color: #000;
  font-weight: 400;
  ${({ isDelete }) => (isDelete ? 'color: #D32F2F;' : '')}
  cursor:pointer;
  padding-top: 4px;
  text-align: left;
  &:hover {
    background: #ffeebf;
    border-color: #ece2e2;
  }
`;
const Divider = styled.div`
  width: 118px;
  height: 1px;
  background: #1d1d1d;
  opacity: 0.08;
  margin: 7px auto 5px;
`;
const DropDownDownItemTags = ({ refMenu, clickedColumn, top, left, right }) => {
  const dispatch = useDispatch();
  const { t } = useLocalization();
  const openModal = (e) => {
    e.stopPropagation();
    dispatch(actionOpenModal(openModalType.EditBoardColumn, clickedColumn));
  };

  const deleteHandler = () => {
    dispatch(
      actionCreator(DashboardColumns.column.remove, { columnId: clickedColumn.id }),
    );
  };

  const isDefaultColumn = clickedColumn.id === COLUMN_CUSTOM_TYPE.CLOSE;

  return (
    <MainWrapper
      ref={refMenu}
      top={top}
      left={left}
      right={right}
      data-parent={DATA_PARENTS.tagList}
      className="drop_down_down"
      isDefaultColumn={isDefaultColumn}
    >
      <DDTitleWrapper style={{ marginBottom: 8, textAlign: 'left' }}>{t.optionsUpT}</DDTitleWrapper>
      <OptionWrapper
        className="drop_dow_down_option"
        data-parent={DATA_PARENTS.tagList}
        onClick={openModal}
      >
        {t.editT}
      </OptionWrapper>
      {!isDefaultColumn && <Divider />}
      {!isDefaultColumn
        && (
          <OptionWrapper onClick={deleteHandler} isDelete>
            {t.deleteT}
          </OptionWrapper>
        )}
    </MainWrapper>
  );
};

export default DropDownDownItemTags;
