import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './index.module.scss';
import { ReactComponent as ApproveButtonSettingsSvg } from '../../../images/icons/icon_20/ApproveButtoSettings.svg';
import { ReactComponent as ArrowSvg } from '../../../images/icons/icon_20/arrow.svg';
import useComponentVisible from '../../../utils/hooks/useComponentVisible';
import { colorsApprovedButton } from '../../../utils/constants';
import { useLocalization } from '../../../LocalizationContext';

const cx = classNames.bind(styles);

const ActionButton = ({ state, saveState, isView }) => {
  const data = 'ActionButton';
  const { t } = useLocalization();
  const [ref, isOpenMenu, setOpenMenu] = useComponentVisible(false, data);
  const [enteredLink, setLink] = useState(state.enteredLink || '');
  const [color, setColor] = useState(state.color || colorsApprovedButton[0]);
  const [buttonName, setButtonName] = useState(state.buttonName || '');

  useEffect(() => {
    if (state.color !== color) setColor(state.color);
    if (state.buttonName !== buttonName) setButtonName(state.buttonName);
  }, [state]);

  const openInNewTab = () => {
    if (enteredLink) {
      const newWindow = window.open(
        enteredLink,
        '_blank',
        'noopener,noreferrer',
      );
      if (newWindow) newWindow.opener = null;
    }
  };

  const handlerOpenMenu = (e) => {
    e.stopPropagation();
    setOpenMenu((v) => !v);
  };

  useEffect(() => {
    if (
      !isOpenMenu
      && (enteredLink !== state.enteredLink
        || buttonName !== state.buttonName
        || color !== state.color)
    ) {
      saveState({ enteredLink, buttonName, color });
    }
  }, [isOpenMenu]);

  return (
    <div className={cx('button_wrapper')}>
      <div onClick={openInNewTab} className={cx('action_button', `${color}`)}>
        {buttonName || 'read more'}
        <ArrowSvg />
      </div>
      {!isView && (
        <div className={cx('button_settings')}>
          {t.actionButtonT}
          <ApproveButtonSettingsSvg
            data-parent={data}
            onClick={handlerOpenMenu}
          />
          {isOpenMenu && (
            <div ref={ref} className={cx('settings_menu')}>
              <div className={cx('text')}>{t.buttonNameT}</div>

              <div className={cx('input_wrapper')}>
                <input
                  maxLength={55}
                  value={buttonName}
                  onChange={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    setButtonName(event.target.value);
                  }}
                  placeholder={t.buttonNameT}
                />
              </div>
              <div className={cx('text')}>{t.linkToT}</div>

              <div className={cx('input_wrapper')}>
                <input
                  maxLength={55}
                  value={enteredLink}
                  onChange={(event) => setLink(event.target.value)}
                  placeholder={t.selectPpageOrPasteLinkT}
                />
              </div>
              <div className={cx('text')}>{t.buttonColorT}</div>
              <div className={cx('select_color')}>
                {colorsApprovedButton.map((selectableColors) => (
                  <div
                    key={selectableColors}
                    onClick={() => setColor(selectableColors)}
                    className={cx('picker_wrapper', {
                      active: selectableColors === color,
                    })}
                  >
                    <div className={cx('picker', `${selectableColors}`)} />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ActionButton;
