import React from 'react';
import { useDispatch } from 'react-redux';
import Button2023 from '../../../Buttons/Button2023';
import SideBarTabs from '../../../../pages/Playlist/PlaylistTabs/SideBarTabs';
import SortAndView from '../SortAndView';
import { Divider, GroupIcon, Wrapper } from './StyledComponents';
import ButtonUpload from '../../../Buttons/ButtonUpload';
import { actionOpenModal } from '../../../../redux/user/action';
import { openModalType } from '../../../../utils/constants';
import { useLocalization } from '../../../../LocalizationContext';

const SecondaryLink = (props) => {
  const dispatch = useDispatch();
  const { t } = useLocalization();

  return (
    <GroupIcon
      data-parent={props.dataParent}
      newUPVMode={props.newUPVMode}
    >
      <Wrapper data-parent={props.dataParent}>
        <Button2023
          derivedParentData={props.dataParent}
          text={t.plusLinkUpT}
          width={59}
          height={props.lowMode ? 28 : 32}
          variant="secondary"
          handleButtonClick={() => dispatch(actionOpenModal(openModalType.CreateLinkModal))}
        />
        <ButtonUpload
          dataParent={props.dataParent}
          newUPVMode={props.newUPVMode}
        />
        <Divider newUPVMode={props.newUPVMode} />
      </Wrapper>
      <SideBarTabs
        newUPVMode={props.newUPVMode}
        forSecondaryMenu
        isLibrary
        setIsShowFilter={() => {}}
        setShowNewSmartView={props.setShowNewSmartView}
        resetSmartview={props.resetSmartview}
        setSmartViewForEdit={props.setSmartViewForEdit}
        setChosenSmartView={props.setChosenSmartView}
        setChosenTag={props.setChosenTag}
      />
      <Divider ms newUPVMode={props.newUPVMode} />
      <SortAndView {...props} />
    </GroupIcon>
  );
};

export default SecondaryLink;
