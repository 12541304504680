import React, { memo, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as DropLineSvg } from '../../images/icons/drop_area.svg';
import { ReactComponent as DropLineX2Svg } from '../../images/icons/drop_area_x2.svg';
import MomentBlock from '../../components/MomentBlock/MomentBlock';
import styles from './index.module.scss';
import CreateBlocksComponent from '../../components/DropDownOption/CreateBlocksComponent';
import ComponentInPage from '../../components/ComponentInPage/ComponentInPage';
import DetachWarning from '../../components/DetachWarning';
import {
  BlockTypes,
  dragType,
} from '../../utils/constants';

import ButtonComponent from '../../components/Buttons/ButtonComponent';
import UsedInPage from '../../components/UsedInPage/UsedInPage';
import { useLocalization } from '../../LocalizationContext';


const checkCreateComponentBlockType = (type) => type === BlockTypes.text
  || type.startsWith(BlockTypes.title)
  || type.startsWith(BlockTypes.ordered_list_item)
  || type.startsWith(BlockTypes.unordered_list_item);


export const BlocksContent = memo((props) => {
  const propsMemo = useMemo(
    () => {
      return props;
    },
    [
      props.selectedIds,
      props.isCreateComponentMenuVisible,
      props.floatingButton,
      props.newFocus,
      props.hasDragHover,
      props.editableBlocks.map(i => i.id),
      Object.keys(props.blocksToCreateLComponent)],
  );
  return <MemoBlocksContent props={propsMemo} />;
});
const MemoBlocksContent = memo(({ props: {
  editableBlocks, selectedIds, selectBlock,
  isViewMode, mouseup, setCanUpdForRedo,
  setIdOpenMenuBlock, idOpenMenuBlock, clear,
  setBlocksToCreateLComponent, blocksToCreateLComponent, floatingButton, addNewEmptyEditableBlock, isLibraryPage,
  addEditableBlock, handlerClick,
  onChangeBlocksWithData,
  selectBlockOnArrow,
  stopPropagationInput, canUpdForRedoUndo, setIsCreateComponentMenuVisible, selectedIndex, onEditComponentClickHandler,
  addDuplicateBlock, isCreateComponentMenuVisible, hasDragHover, onDragLeave, libraryDragState, onEditorAreaMouseDrop,
  refSelectedBlocks, onDragEnter, createComponentAfterDetach,
  onChangeEditableBlock, deleteEditableBlock, changeTypeBlock, goToOtherTextBlock,
  onCreateComponentClickHandler,
  newFocus,
} }) => {
  const { t } = useLocalization();
  const [openMenuDetach, setOpenMenuDetach] = useState('');
  const [isCreateNew, setIsCreateNew] = useState(false);
  const { currentPageId, isLargeRightBar } = useSelector((state) => state.currentPage);

  const {
    draggableBlocks,
    type: draggableType,
  } = useSelector((state) => state.draggable);
  const isShowManyDragHandler = (blockId, index) => {
    if (blockId === 'last') {
      return (
        !!draggableBlocks.length
        && !draggableBlocks.includes(
          editableBlocks[editableBlocks.length - 1]?.id,
        )
        && editableBlocks.length - 1 === index
      );
    }
    return (
      !!draggableBlocks.length
      && !draggableBlocks.includes(blockId)
      && !draggableBlocks.includes(editableBlocks[index - 1]?.id)
    );
  };
  return (
    <>
      {editableBlocks.map(
        (
          {
            id,
            state,
            type,
            position,
            title,
            pageId,
            nestedItemId,
            innerHtml,
            width,
            ...props
          },
          index,
        ) => {
          return (
            <div key={id}>
              {/* {(true) && ( */}
              {/* <DetachWarning setOpenMenuDetach={() => setOpenMenuDetach('')} /> */}
              {/* )} */}
              <div
                className={`${styles.relative} ${
                  isLargeRightBar ? '' : styles.center_valuable_content
                }`}
              >
                {openMenuDetach === id && (
                  <DetachWarning
                    createComponentAfterDetach={createComponentAfterDetach}
                    setOpenMenuDetach={() => setOpenMenuDetach('')}
                  />
                )}

                <div
                  id={id}
                  ref={(ref) => refSelectedBlocks.current.push(ref)}
                  data-type={`${type}`}
                  onDrop={(e) => onEditorAreaMouseDrop(e, id, index)}
                  onDragEnter={() => onDragEnter(id)}
                  onDragLeave={onDragLeave}
                >
                  <div
                    className={`${styles.dashed}
                      
                    ${
                      blocksToCreateLComponent[id]
                      && blocksToCreateLComponent[id].indices[0] === index
                        ? styles.dashed_first
                        : ''
                    }
                     ${
                      blocksToCreateLComponent[id]
                      && blocksToCreateLComponent[id].indices[
                        blocksToCreateLComponent[id].indices.length - 1
                      ] === index
                        ? styles.dashed_last
                        : ''
                    }
                     ${
                      blocksToCreateLComponent[id]
                      && blocksToCreateLComponent[id].indices.includes(index)
                        ? styles.dashed_middle
                        : ''
                    }
           
                                    `}
                  />
                  <div>
                    {(!!Object.keys(draggableBlocks).length
                        || !!Object.keys(libraryDragState).length)
                      && isShowManyDragHandler(id, index)
                      && hasDragHover === id && (
                        <div className={styles.dragLine_wrap}>
                          <DropLineSvg
                            className={`
                            ${styles.dragLineSvg} 
                            ${
                              isLargeRightBar
                                ? styles.dragLineSvg_isLargeRightBar
                                : ''
                            }
                            `}
                          />
                        </div>
                    )}
                    {draggableType !== dragType.smartView
                      && (hasDragHover
                        || (!!libraryDragState
                          && !!Object.keys(libraryDragState).length))
                      && hasDragHover === id && (
                        <div className={styles.dragLine_wrap}>
                          {isLargeRightBar ? (
                            <DropLineX2Svg
                              className={`${styles.dragLineSvg} 
                                          ${isLargeRightBar ? styles.dragLineSvg_isLargeRightBar : ''}
                                          `}
                            />
                          ) : (
                            <DropLineSvg
                              className={`${styles.dragLineSvg} 
                                          ${isLargeRightBar ? styles.dragLineSvg_isLargeRightBar : ''}
                                          `}
                            />
                          )}
                        </div>
                    )}
                    {blocksToCreateLComponent[id]
                      && blocksToCreateLComponent[id]?.indices
                      && blocksToCreateLComponent[id]?.indices[0]
                      === index && (
                      <>
                          {blocksToCreateLComponent[id].status
                            === 'pending' && (
                              <CreateBlocksComponent
                                isCreateComponentMenuVisible={
                                  isCreateComponentMenuVisible
                                }
                                blocksIds={selectedIds}
                                setBlocksToCreateLComponent={
                                  setBlocksToCreateLComponent
                                }
                                blocksToCreateLComponent={
                                  blocksToCreateLComponent
                                }
                                blocksIndices={selectedIndex}
                                refSelectedBlocks={refSelectedBlocks}
                                isVisible={isCreateComponentMenuVisible}
                                setIsVisible={setIsCreateComponentMenuVisible}
                                // createNewCollectionId={createNewCollectionId}
                                isCreateNew={isCreateNew}
                                setIsCreateNew={setIsCreateNew}
                                // setCreateNewCollectionId={setCreateNewCollectionId}
                              />
                          )}
                      </>
                    )}

                    {!props?.components && (
                      <>
                        <MomentBlock
                          setBlocksToCreateLComponent={
                            setBlocksToCreateLComponent
                          }
                          blocksToCreateLComponent={
                            blocksToCreateLComponent
                          }
                          blocksIds={selectedIds}
                          blocksIndices={selectedIndex}
                          mouseup={mouseup}
                          setCanUpdForRedo={setCanUpdForRedo}
                          inComponent={props.inComponent}
                          setIdOpenMenuBlock={setIdOpenMenuBlock}
                          idOpenMenuBlock={idOpenMenuBlock}
                          // handleDashedFrameMouseOver={handleDashedFrameMouseOver}
                          setIsCreateComponentMenuVisible={
                            setIsCreateComponentMenuVisible
                          }
                          stopPropagation={stopPropagationInput}
                          clear={clear}
                          handlerClick={handlerClick}
                          // isDragging={snapshot.isDragging}
                          selectBlock={selectBlock}
                          type1={type}
                          state={state}
                          addDuplicateBlock={() => {
                            addDuplicateBlock(id);
                          }}
                          newFocus={newFocus}
                          goToOtherTextBlock={(
                            order,
                            offset,
                            anchorOffset,
                          ) => {
                            goToOtherTextBlock(
                              id,
                              order,
                              offset,
                              anchorOffset,
                            );
                          }}
                          componentId={id}
                          isBlockHidden={props.isHidden}
                          innerHtml={innerHtml}
                          position={position}
                          setOpenMenuDetach={() => setOpenMenuDetach(id)}
                          currentPageId={currentPageId}
                          index={index}
                          addNewEmptyEditableBlock={(order) => addNewEmptyEditableBlock(id, order)
                          }
                          // dragHandleProps={provided.dragHandleProps}
                          addEditableBlock={(
                            blockType,
                            pageId,
                            isClone,
                            eraseContent,
                            text,
                          ) => {
                            addEditableBlock(
                              id,
                              blockType,
                              pageId,
                              isClone,
                              eraseContent,
                              text,
                            );
                          }}
                          changeTypeBlock={(
                            blockType,
                            eraseContent,
                            addedPage,
                            textParseLink,
                          ) => {
                            changeTypeBlock(
                              id,
                              blockType,
                              eraseContent,
                              addedPage,
                              textParseLink,
                            );
                          }}
                          deleteEditableBlock={deleteEditableBlock(id)}
                          onChangeEditableBlock={onChangeEditableBlock(
                            id,
                            type,
                          )}
                          onChangeBlocksWithData={(
                            newState,
                            newInnerHtml,
                          ) => {
                            return onChangeBlocksWithData(
                              id,
                              newState,
                              newInnerHtml,
                              type,
                            );
                          }}
                          width={width}
                          page={{ title, nestedItemId }}
                          isLibraryPage={isLibraryPage}
                          isViewMode={isViewMode}
                          canUpdForRedoUndo={canUpdForRedoUndo}
                          selectBlockOnArrow={(selection) => selectBlockOnArrow(id, selection)
                          }
                          nextItemPosition={
                            editableBlocks[index + 1]?.position
                          }
                          {...props}
                        />
                        <div
                          data-parent="createLibrary"
                          className={`${styles.createComponentButton}
                                ${
                            floatingButton === id
                            && checkCreateComponentBlockType(type)
                              ? styles.show
                              : ''
                          }
                              `}
                          onMouseDown={(e) => e.stopPropagation()}
                          // onMouseUp={(e) => e.stopPropagation()}
                          onClick={(event) => {
                            floatingButton === id
                            && checkCreateComponentBlockType(type)
                              ? onCreateComponentClickHandler(event)
                              : () => {};
                          }}
                        >
                          <ButtonComponent
                            text={t.createComponentLowT}
                            isNoPointer={
                              !(
                                floatingButton === id
                                && checkCreateComponentBlockType(type)
                              )
                            }
                          />
                        </div>
                      </>
                    )}
                    {!!props?.components && (
                      <div className={styles.editComponentWrapper}>
                        <ComponentInPage
                          selectBlock={selectBlock}
                          deleteEditableBlock={deleteEditableBlock(id)}
                          editableBlocks={props?.components}
                          componentId={id}
                          // onChangeBlocksWithData={onChangeBlocksWithData}
                          onChangeBlocksWithData={(
                            newState,
                            newInnerHtml,
                          ) => {
                            return onChangeBlocksWithData(
                              id,
                              newState,
                              newInnerHtml,
                              type,
                            );
                          }}
                          index={index}
                          type={type}
                          title={title}
                          mouseup={mouseup}
                          onEditComponentClickHandler={(event) => onEditComponentClickHandler(event, nestedItemId)
                          }
                          isBlockHidden={props.isHidden}
                          isLargeRightBar={isLargeRightBar}
                          setOpenMenuDetach={() => setOpenMenuDetach(id)}
                          setIsCreateComponentMenuVisible={
                            setIsCreateComponentMenuVisible
                          }
                          setBlocksToCreateLComponent={
                            setBlocksToCreateLComponent
                          }
                          blocksIds={selectedIds}
                          isLibraryPage={isLibraryPage}
                          isViewMode={isViewMode}
                          handlerClick={handlerClick}
                          addNewEmptyEditableBlock={(order) => addNewEmptyEditableBlock(id, order)
                          }
                          addDuplicateBlock={() => {
                            addDuplicateBlock(id);
                          }}
                        />
                        <div
                          data-parent="createLibrary"
                          className={`${styles.editComponentButton}
                              ${styles.show}
                              ${floatingButton === id ? styles.show : ''}
                            `}
                          onMouseDown={(e) => e.stopPropagation()}
                          onMouseUp={(e) => e.stopPropagation()}
                          onClick={(event) => onEditComponentClickHandler(event, nestedItemId)
                          }
                        >
                          <ButtonComponent
                            text={t.editComponentLowT}
                            isNoPointer={!(floatingButton === id)}
                          />
                        </div>
                        <div
                          className={styles.usedInPage}
                          onMouseDown={(e) => e.stopPropagation()}
                          onMouseUp={(e) => e.stopPropagation()}
                        >
                          <UsedInPage itemId={nestedItemId} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        },
      )}
    </>
  );
});

export default BlocksContent;
