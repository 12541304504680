import './fonts/Roboto/Roboto-Medium.ttf';
import './fonts/Roboto/Roboto-MediumItalic.ttf';
import './fonts/Roboto/Roboto-Light.ttf';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { v4 as uuidv4 } from 'uuid';
import rootReducer from './redux/reducers';
import './index.css';
import './_mixins.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import rootSaga, { rootSaga2 } from './sagas';
import { handlerHardReload, registerServiceWorker, setServiceWorkerBackendUrl } from './utils/registerServiceWorker';
import { LocalizationProvider } from './LocalizationContext';
import './i18n';

const sagaMiddleware = createSagaMiddleware();
const websocketMiddleware = createSagaMiddleware();
const websocket2Middleware = createSagaMiddleware();
const container = document.getElementById('root');
const root = createRoot(container);
const attachMiddleware = () => {
  if (
    process.env.REACT_APP_ENVIRONMENT === 'development'
    || localStorage.getItem('developMod')
  ) {
    const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    return composeEnhancer(
      applyMiddleware(
        sagaMiddleware,
        websocketMiddleware,
        websocket2Middleware,
      ),
    );
  }
  return applyMiddleware(
    sagaMiddleware,
    websocketMiddleware,
    websocket2Middleware,
  );
};

sessionStorage.setItem('uniqSocketId', uuidv4());

const store = createStore(rootReducer, attachMiddleware());
sagaMiddleware.run(rootSaga);
websocketMiddleware.run(rootSaga2);
registerServiceWorker();
handlerHardReload();
setServiceWorkerBackendUrl();

navigator.serviceWorker.addEventListener('message', event => {
  if (event.data.type === 'RELOAD_PAGE') {
    window.location.reload();
  }
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <LocalizationProvider>
          <App />
        </LocalizationProvider>
      </Router>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
