import React, { memo, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { ReactComponent as DropLineSvg } from '../../images/icons/drop_area.svg';
import styles from './index.module.scss';
import plusBlue24Icon from '../../images/icons/plus_blue_24.svg';
import {
  actionClearCurrentPage,
  actionGetCurrentPage,
  actionToggleLibraryDrag,
  actionUpdateDraggableBlocks,
} from '../../redux/currentPage/action';
import {
  actionAddEditableBlock,
  actionAddManyEditableBlock,
  actionChangeEditableBlock,
  actionDeleteManyBlocks,
  actionDragManyBlock,
} from '../../redux/pages/action';
import useCreateLibraryComponentVisible from '../../utils/hooks/useCreateLibraryClickOutside';
import {
  actionCopyBlocksToPage,
  actionOpenCreateComponent,
  CopyBlockImageToPage, CopyUploadToPage,
} from '../../redux/library/actions';
import {
  allowedFormatsImage,
  BlockTypes,
  empty,
  MessageType,
  UiComponentTypes,
} from '../../utils/constants';
import {
  createNodeFromHtml,
  sanitizeToSave,
  stringifyInnerHtml,
  stringifyStateToContent,
} from '../../utils/helpers';
import ActionDraggable from '../../redux/dragable/action';
import { actionCreateUpload } from '../../redux/filesUpload/action';
import useThrottle from '../../utils/hooks/useThrottle';
import PageCoreInfo from './PageCoreInfo';
import { actionCreator } from '../../shared/redux/actionHelper';
import { EditPage } from '../../redux/pages/types';
import { isTextType } from '../../utils/helpersDragt';
import { actionShowMessage } from '../../redux/support/action';
import BlocksContent from './BlocksContent';
import { useLocalization } from '../../LocalizationContext';

const isTextField = (target) => {
  return (
    target.nodeName === 'SPAN'
    || target.nodeName === 'TEXTAREA'
    || target?.firstChild?.nodeName === 'SPAN'
    || target?.firstChild?.firstChild?.nodeName === 'SPAN'
    || target.nodeName === 'P'
  );
};

const getFirstDivParentNode = (target) => {
  if (target.nodeName === 'DIV') return target;
  if (target.parentNode.nodeName === 'DIV') return target.parentNode;
  if (target.parentNode.parentNode.nodeName === 'DIV') return target.parentNode.parentNode;
  if (target.parentNode.parentNode.parentNode.nodeName === 'DIV') return target.parentNode.parentNode.parentNode;
};

const intersect = (boxA, boxB) => {
  return (
    boxA.left <= boxB.left + boxB.width
    && boxA.left + boxA.width >= boxB.left
    && boxA.top <= boxB.top + boxB.height
    && boxA.top + boxA.height >= boxB.top
  );
};

const calculateSelectionBox = (startPoint, endPoint, isText) => {
  if (!endPoint || !startPoint) {
    return null;
  }
  if (startPoint.isText === isText && isText && !endPoint.lessTextField) {
    return null;
  }
  const left = Math.min(startPoint.x, endPoint.x);
  const top = Math.min(startPoint.y, endPoint.y);
  const width = Math.abs(startPoint.x - endPoint.x);
  const height = Math.abs(startPoint.y - endPoint.y);
  if (height < 3 || width < 3) {
    return null;
  }
  return {
    left,
    top,
    width,
    height,
  };
};
const SomeItem = ({ selectionBox }) => (
  <div className={styles.selection_border} style={selectionBox.current} />
);

export const Page = memo(({ isViewMode }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useLocalization();
  const isLibraryPage = history.location.pathname.startsWith('/libraryPage');
  const { id } = useParams();
  const libraryDragState = useSelector(
    (state) => state.library.dragState || empty,
  );
  const targetPageId = useSelector((state) => state.currentPage.id);
  const pressModPlusC = useSelector(
    (state) => state.shortcut.combination['mod+c'],
  );
  const { isDownload, currentPageId, libraryDragStatus, isLargeRightBar } = useSelector((state) => state.currentPage);

  const editableBlocks = useSelector((state) => state.currentPage.blocks) || [];
  const pressModPlusA = useSelector(
    (state) => state.shortcut.combination['mod+a'],
  );
  const {
    draggableBlocks,
    hasDraggable,
    type: draggableType,
  } = useSelector((state) => state.draggable);
  const currentDraggableBlocks = useSelector(
    (state) => state.currentPage.draggableBlocks,
  );
  const refSelectedBlocks = { current: [] };
  const [isCreateComponentMenuVisible, setIsCreateComponentMenuVisible] = useCreateLibraryComponentVisible(false);
  const [blocksToCreateLComponent, setBlocksToCreateLComponent] = useState({});
  const dropDepthRef = useRef(0);
  const [isInitialized, setIsInitialized] = useState(false);
  const [idOpenMenuBlock, setIdOpenMenuBlock] = useState('');
  const [selectedIndex, setSelectedIndex] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [anchorBlock, setAnchorBlock] = useState({});
  const [isHasTitle, setIsHasTitle] = useState(false);
  // const [dragHandlerId, setDragHandlerId] = useState({});
  const [mouseDown, setMouseDown] = useState(false);
  const [startPoint, setStartPoint] = useState(null);
  const [endPoint, setEndPoint] = useState({});
  const [newFocus, setNewFocus] = useState({});
  // const [selectionBox, setSelectionBox] = useState(null);
  const selectionBox = useRef(null);
  const refPage = useRef(null);
  const [showAdd, setShowAdd] = useState(null);
  const [hasDragHover, setHasDragHover] = useState('');
  // const [createNewCollectionId, setCreateNewCollectionId] = useState(null);
  const isShowLastLine = hasDragHover === 'last';
  const isShowFirstLine = hasDragHover === 'first'
    && !!(Object.keys(libraryDragState).length || !!draggableBlocks.length);
  const [floatingButton, setFloatingButton] = useState('');
  const [throttleShowButton] = useThrottle(300);

  const cmdZ = useSelector(
    (reduxState) => reduxState.shortcut?.combination['mod+z'],
  );
  const cmdShZ = useSelector(
    (reduxState) => reduxState.shortcut?.combination['mod+shift+z'],
  );
  const socketUpd = useSelector(
    (reduxState) => reduxState.shortcut?.combination.socketUpd,
  );
  const canUpdForRedoUndo = useRef(null);
  const timerId = useRef(null);

  const setCanUpdForRedo = () => {
    canUpdForRedoUndo.current = true;
    if (timerId.current) {
      clearTimeout(timerId.current);
    }
    timerId.current = setTimeout(() => {
      canUpdForRedoUndo.current = false;
    }, 1000);
  };

  useEffect(() => {
    if (cmdZ || cmdShZ || socketUpd) {
      setCanUpdForRedo();
    }
  }, [cmdZ, cmdShZ, socketUpd]);
  //
  // const setShowLibraryWidget = (isShow) => {
  //   dispatch(actionShowLibraryWidget(isShow));
  // };

  const createComponentAfterDetach = (ids, neweditableBlocks) => {
    const a = {};
    const b = ids
      .map((id) => neweditableBlocks.findIndex((i) => id === i.id))
      .filter((i) => i !== -1)
      .sort((i, b) => i - b);
    ids.forEach((id) => {
      a[id] = { status: 'pending', id, indices: b };
    });
    setTimeout(() => {
      setIsCreateComponentMenuVisible(true);
      setSelectedIds(ids);
      setBlocksToCreateLComponent(a);
      setSelectedIndex(b);
    }, 100);
  };


  useEffect(() => {
    if (!isViewMode) return () => dispatch(actionClearCurrentPage());
  }, []);

  useEffect(() => {
    if (Object.keys(libraryDragState).length) {
      dropDepthRef.current = 0;
      setHasDragHover('');
    }
  }, [libraryDragState]);

  useEffect(() => {
    const idsArray = [];
    const indexArray = [];
    if (pressModPlusA) {
      refSelectedBlocks.current.forEach((item, index) => {
        if (item) {
          idsArray.push(item.id);
          indexArray.push(index);
          item.setAttribute('class', 'selected_block');
        }
      });
      setSelectedIndex(indexArray);
      setSelectedIds(idsArray);
      dispatch(actionUpdateDraggableBlocks(idsArray));
    }
  }, [pressModPlusA]);

  useEffect(() => {
    if (!editableBlocks.length) return;
    const idsArray = [];
    const indexArray = [];
    // if ((inputEl && inputDescription)
    //   && (inputEl?.current?.value !== introTitle
    //     || inputDescription?.current?.value !== introDescription)) return;
    if (selectedIds.length) {
      refSelectedBlocks.current.forEach((item, index) => {
        if (item && selectedIds.includes(item.id)) {
          idsArray.push(item.id);
          indexArray.push(index);
          item.setAttribute('class', 'selected_block');
        } else if (item) item.removeAttribute('class');
      });
    }
    setSelectedIndex(indexArray);
    setSelectedIds(idsArray);
    dispatch(actionUpdateDraggableBlocks(idsArray));
    if (currentDraggableBlocks) dispatch(actionUpdateDraggableBlocks([]));
  }, [editableBlocks.length]);

  const selectBlock = (ids) => {
    const idsArray = [];
    const indexArray = [];

    refSelectedBlocks.current.forEach((item, index) => {
      if (item && ids.includes(item.id)) {
        idsArray.push(item.id);
        indexArray.push(index);
        item.setAttribute('class', 'selected_block');
      } else if (item) item.removeAttribute('class');
    });

    setSelectedIndex(indexArray);
    setSelectedIds(idsArray);
    dispatch(actionUpdateDraggableBlocks(idsArray));
  };

  useEffect(() => {
    setTimeout(() => setIsInitialized(true), 1000);
  }, []);

  useEffect(() => {
    if (!hasDraggable) setHasDragHover(null);
  }, [hasDraggable, libraryDragState]);

  useEffect(() => {
    if (hasDraggable) dispatch(ActionDraggable.SetHasDraggable(false));
    if (libraryDragStatus) dispatch(actionToggleLibraryDrag(false));
    setIsCreateComponentMenuVisible(false);
    const readyLength = Object.values(blocksToCreateLComponent || {}).filter(
      (i) => !i.status === 'ready',
    ).length;
    if (readyLength) setBlocksToCreateLComponent({});
  }, [mouseDown]);

  const onMouseDown = (e) => {
    clear();

    if (e.button === 2 || e.nativeEvent.which === 2 || isLargeRightBar) {
      return;
    }
    const isText = isTextField(e.target);
    if (isText) {
      const parentDiv = getFirstDivParentNode(e.target);
      if (!parentDiv?.parentNode?.parentNode) return;
      parentDiv.parentNode.parentNode.init = true;
    }
    // setTimeout(() => {
    document.addEventListener('mouseup', mouseup);
    setMouseDown(true);
    const scroll = e.pageY
      + (refPage.current.scrollTop || 0)
      - (refPage.current.clientTop || 0);
    setStartPoint({
      x: e.pageX,
      y: e.pageY,
      scroll,
      isText,
    });

    setEndPoint(null);
    // setSelectionBox(null);
    selectionBox.current = null;
  };

  const onMouseMove = (e) => {
    if (mouseDown) {
      const isText = isTextField(e.target);
      const parentDiv = getFirstDivParentNode(e.target);
      const experimental = parentDiv?.parentNode?.parentNode?.init
        || parentDiv?.parentNode.init
        || parentDiv?.init;

      const newEndPoint = {
        x: e.pageX,
        y: e.pageY,
        lessTextField:
          endPoint?.lessTextField || (!experimental && startPoint.isText),
      };
      setEndPoint(newEndPoint);
      selectionBox.current = calculateSelectionBox(
        startPoint,
        newEndPoint,
        isText,
      );

      const selected = [];
      if (selectionBox.current && selectionBox.current.left) {
        refSelectedBlocks.current.forEach((item) => {
          if (item) {
            if (intersect(selectionBox.current, item.getBoundingClientRect())) {
              item.setAttribute('class', 'selected_block');
              selected.push(item);
            } else item.removeAttribute('class');
          }
        });
      }
      let isCreateCondition = true;
      selected.forEach((item) => {
        if (item.dataset?.type === 'component') {
          isCreateCondition = false;
        }
      });

      if (selected.length && isCreateCondition) {
        throttleShowButton(() => () => {
          setFloatingButton(selected[selected.length - 1].id);
        });
      } else {
        throttleShowButton(() => () => {
          setFloatingButton('');
        });
      }

      if (selected.length === 1 && !isCreateCondition) {
        throttleShowButton(() => () => {
          setFloatingButton(selected[0].id);
        });
      }
    }
  };
  const clear = () => {
    // clear selection after click
    refSelectedBlocks.current.forEach((item) => {
      if (item) {
        item.removeAttribute('class');
      }
    });
    if (isCreateComponentMenuVisible) setIsCreateComponentMenuVisible(false);
    if (Object.keys(blocksToCreateLComponent)) setBlocksToCreateLComponent({});
    if (libraryDragStatus) dispatch(actionToggleLibraryDrag(false));
    if (selectedIds.length) setSelectedIds([]);
    if (selectedIndex.length) setSelectedIndex([]);
    if (currentDraggableBlocks.length) dispatch(actionUpdateDraggableBlocks([]));
    if (anchorBlock.blockId) {
      const {
        blockId: newFocusId,
        order,
        anchorOffset,
        offset,
        anchorKey,
      } = anchorBlock;
      setNewFocus({ newFocusId, offset, anchorOffset, order, anchorKey });
      setAnchorBlock({});
    }
    if (floatingButton) setFloatingButton('');
    if (draggableBlocks.length) dispatch(ActionDraggable.BlocksOnPage([]));
  };

  const mouseup = (e) => {
    e.stopPropagation();

    document.removeEventListener('mouseup', mouseup);

    const indexArray = [];
    const idsArray = [];
    setIdOpenMenuBlock('');
    if (libraryDragStatus) dispatch(actionToggleLibraryDrag(false));
    const selectedBlocks = refSelectedBlocks.current
      .map((item, index) => {
        if (item && item.hasAttribute('class')) {
          indexArray.push(index);
          idsArray.push(item.id);
          const { top, bottom, height, width } = item.getBoundingClientRect();
          return { id: item.id, top, bottom, width, height };
        }
        return {};
      })
      .filter((i) => !!Object.keys(i).length);
    setSelectedIndex(indexArray);
    setSelectedIds(idsArray);
    if (selectedBlocks.length) {
      setMouseDown(false);
      setTimeout(
        () => dispatch(
          actionUpdateDraggableBlocks(
            Array.from(new Set(selectedBlocks.map((i) => i.id))),
          ),
        ),
        0,
      );
    } else {
      setMouseDown(false);
      setStartPoint(null);
    }
    if (selectionBox.current) {
      refPage.current?.focus({ focusVisible: false });
      refPage.current?.blur();
      const range = new Range();
      range.setStart(refPage.current, 0);
      range.setEnd(refPage.current, 0);
      document.getSelection().removeAllRanges();
      refPage.current?.focus({ focusVisible: false });
    }
  };

  useEffect(() => {
    return () => {
      document.removeEventListener('mouseup', mouseup, true);
    };
  }, []);

  const onCopyHandler = () => {
    let text = '';
    const parseItems = selectedIndex
      .map((index) => {
        const item = editableBlocks[index];
        const content = {
          ...item,
          innerHtml: stringifyInnerHtml(item?.innerHtml),
        };
        if (item.type === BlockTypes.page) {
          content.pageId = item.pageId;
          content.title = item.title;
        } else if (item.type === BlockTypes.component) {
          content.nestedItemId = item.nestedItemId;
        } else if (
          item.type === BlockTypes.approveButton
          || item.type === BlockTypes.actionButton
          || item.type === BlockTypes.shortText
        ) {
          content.content = sanitizeToSave(JSON.stringify(item.state));
        } else if (
          item.type === BlockTypes.webSite
          || item.type === BlockTypes.lineSeparator
        ) {
          const tmpContent = sanitizeToSave(JSON.stringify(item.state));
          content.content = tmpContent || '';
        } else {
          content.state = stringifyStateToContent(item.state);
        }
        return content;
      })
      .filter((i) => i);

    // eslint-disable-next-line max-len
    let html = `<div name="description" content="${JSON.stringify(parseItems)
      .replaceAll('"', '$@quote123123')
      .replaceAll("'", '$@quote0123123')}" data-end="endo-copy">`;

    let someCopy = false;
    refSelectedBlocks.current.forEach((i) => {
      if (i.classList.contains('selected_block')) {
        someCopy = true;
        const test = i.getElementsByClassName('customEditor')[0];
        html += test?.innerHTML ?? '';
        text += test?.textContent ?? '';
      }
    });
    if (!someCopy) return;
    const data = new Blob([html], { type: 'text/html' });
    const data1 = new Blob([text], { type: 'text/plain' });
    const item = new ClipboardItem({ 'text/html': data, 'text/plain': data1 });
    navigator.clipboard.write([item]);
  };

  useEffect(() => {
    if (pressModPlusC) onCopyHandler();
  }, [pressModPlusC]);

  useEffect(() => {
    if (Object.keys(blocksToCreateLComponent).length) {
      refSelectedBlocks.current.forEach((item) => {
        if (item && item.className) {
          item.removeAttribute('class');
          item.setAttribute('class', 'selected_block_create_block');
        }
      });

      // setIsCreateComponentMenuVisible(false);
      // setBlocksToCreateLComponent({});
      dispatch(actionToggleLibraryDrag(false));
    }
  }, [blocksToCreateLComponent]);

  useEffect(() => {
    refSelectedBlocks.current = [];
  }, [id]);

  const addEditableBlock = (
    blockId,
    type = 'text',
    pageId,
    isClone,
    eraseContent,
    text,
  ) => {
    if (type === 'page') {
      dispatch(
        actionAddEditableBlock(
          blockId,
          type,
          pageId.id,
          isClone,
          undefined,
          eraseContent,
          null,
          pageId.title,
          text,
        ),
      );
    } else {
      dispatch(
        actionAddEditableBlock(
          blockId,
          type,
          pageId,
          isClone,
          undefined,
          eraseContent,
          null,
          null,
          text,
        ),
      );
    }
  };

  const changeTypeBlock = (
    blockId,
    type = 'text',
    eraseContent,
    addedPage,
    textParseLink,
  ) => {
    dispatch(
      actionCreator(EditPage.ChangeTypeEditableBlock, {
        id: blockId,
        type,
        isClear: eraseContent,
        addedPage,
        textParseLink,
      }),
    );
  };

  const addNewEmptyEditableBlock = (blockId, order) => {
    const newId = uuid();
    dispatch(
      actionCreator(EditPage.AddNewEmptyEditableBlock, {
        targetBlockId: blockId,
        newId,
        order,
      }),
    );
  };

  const addDuplicateBlock = (id) => {
    if (selectedIds?.length && selectedIds?.length > 1) {
      const newBlockIds = [];
      selectedIds.forEach(() => {
        newBlockIds.push(uuid());
      });
      dispatch(
        actionCreator(EditPage.multipleDuplicateBlock, {
          selectedIds,
          newBlockIds,
        }),
      );
    } else {
      const newBlockId = uuid();
      dispatch(actionCreator(EditPage.addDuplicateBlock, { id, newBlockId }));
    }
    setTimeout(clear, 10);
  };

  const deleteEditableBlock = (blockId) => (e) => {
    if (
      !Object.values(draggableBlocks || {}).length
      && currentDraggableBlocks.length
      && e.target?.dataset.parent !== 'createLibrary'
    ) {
      dispatch(actionDeleteManyBlocks(currentDraggableBlocks));
    } else {
      dispatch(actionDeleteManyBlocks([blockId]));
    }
  };

  const onChangeEditableBlock = (blockId, type) => (newState, innerHtml, dur) => {
    dispatch(
      actionChangeEditableBlock({
        id: blockId,
        newState,
        innerHtml,
        dur,
        blockType: type,
      }),
    );
  };

  const onChangeBlocksWithData = (blockId, newState, innerHtml, type) => {
    dispatch(
      actionChangeEditableBlock({
        id: blockId,
        newState,
        innerHtml,
        blockType: type,
      }),
    );
  };

  const stopPropagation = (event) => {
    if (!startPoint) {
      event.stopPropagation();
    }
  };
  const stopPropagationInput = (event) => {
    if (!selectionBox.current) {
      event.stopPropagation();
    }
  };
  const handlerClick = (event, open) => {
    if (open) setIdOpenMenuBlock(open);
    if (!selectionBox.current && !open) {
      setMouseDown(false);
      event.stopPropagation();
      event.preventDefault();
    }
  };

  if (isDownload) {
    return (
      <div className="loader-wrapper-page">
        <div className="loader">{t.loadingT}</div>
      </div>
    );
  }

  const onEditorAreaMouseDrop = (e, id, index) => {
    e.preventDefault();
    e.stopPropagation();
    setHasDragHover('');
    const files = [];
    // TODO clear
    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < e.dataTransfer.items.length && i < 6; i++) {
        if (e.dataTransfer.items[i].kind === 'file') {
          const file = e.dataTransfer.items[i].getAsFile();
          files.push(file);
        }
        if (
          e.dataTransfer.types.filter(
            (i) => i === 'text/plain' || i === 'text/uri-list',
          ).length
        ) {
          // const dropDataText = e.dataTransfer.getData('text/plain');
          const dropDataHtml = e.dataTransfer.getData('text/html');
          let hasOneImage;
          // let urlTextToCreate;
          // const isUrl = validateURL(dropDataText);
          // const imageDataTitle = '';
          if (dropDataHtml) {
            const nodes = createNodeFromHtml(dropDataHtml);
            const images = nodes.content.querySelectorAll('img');
            const paragraphs = nodes.content.querySelectorAll('p');
            // imageDataTitle = images[0]?.alt;
            if (images.length === 1 && !paragraphs.length) {
              hasOneImage = images?.length === 1;
              // urlTextToCreate = images[0]?.src;
            }
            if (hasOneImage) {
              files.push(images[0]);
            }
          }
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < e.dataTransfer.files.length && i < 6; i++) {
        files.push(e.dataTransfer.files[i]);
      }
    }
    if (files.length) {
      const newBlock = [];
      const isReplase = false;
      const startIndex = index + (index === editableBlocks.length ? 0 : 0);
      files
        .filter((i) => allowedFormatsImage[i.type] || UiComponentTypes.ms[i.type] || UiComponentTypes.pdf[i.type])
        .forEach((item, fileIndex) => {
          const blockId = uuid();
          const libCompId = uuid();
          let type;
          let callback;
          if (allowedFormatsImage[item.type]) {
            type = BlockTypes.image;
            callback = () => CopyBlockImageToPage(
              blockId,
              startIndex + fileIndex,
              libCompId,
            );
          } else {
            type = BlockTypes.upload;
            callback = () => CopyUploadToPage(
              blockId,
              startIndex + fileIndex,
              libCompId,
            );
          }

          dispatch(
            actionCreateUpload({
              newId: libCompId,
              blockId,
              file: item,
              title: item.name,
              type: item.type,
              history,
              callback,
            }),
          );
          newBlock.push({
            isNew: true,
            id: blockId,
            libCompId,
            type,
          });
        });
      if (newBlock.length) {
        dispatch(
          actionAddManyEditableBlock(id, newBlock, currentPageId, isReplase),
        );
      } else {
        dispatch(actionShowMessage({ type: MessageType.fileNotSupported }));
      }

      return;
    }

    const previousBlockBeforeDrag = editableBlocks[index - 1] || {};
    if (
      draggableBlocks.filter((blockId) => blockId === id).length
      || previousBlockBeforeDrag?.id === id
    ) {
      return;
    }
    if (Object.keys(libraryDragState).length) {
      const libraryDragStateWithIdForNewBlock = {};
      Object.keys(libraryDragState).forEach(
        (i) => (libraryDragStateWithIdForNewBlock[i] = uuid()),
      );
      dispatch(
        actionCopyBlocksToPage(
          targetPageId,
          libraryDragStateWithIdForNewBlock,
          index,
        ),
      );
      return;
    }
    if (draggableBlocks) {
      dispatch(actionDragManyBlock(id, draggableBlocks));
    }
  };
  const selectBlockOnArrow = (blockId, selection) => {
    const blockStartSelect = refSelectedBlocks.current.find(
      (item) => item.id === blockId,
    );
    if (!blockStartSelect) return;
    blockStartSelect.setAttribute('class', 'selected_block');
    setSelectedIds([blockId]);
    setAnchorBlock({ blockId, ...selection });
    const selectIndex = editableBlocks.findIndex((item) => item.id === blockId);
    setSelectedIndex([selectIndex]);
    refPage.current.focus();
  };

  const addToDown = (nextBlock, newIndex) => {
    const blockStartSelect = refSelectedBlocks.current.find(
      (item) => item.id === nextBlock.id,
    );
    blockStartSelect.setAttribute('class', 'selected_block');
    setSelectedIndex([...selectedIndex, newIndex]);
    setSelectedIds([...selectedIds, nextBlock.id]);
    dispatch(actionUpdateDraggableBlocks([...selectedIds, nextBlock.id]));
  };

  const removeToDown = (prewBlock) => {
    refSelectedBlocks.current.forEach((item) => {
      if (item.id === prewBlock.id) {
        item.removeAttribute('class');
      }
    });

    const updIndex = [...selectedIndex];
    updIndex.pop();
    const updId = [...selectedIndex];
    updId.pop();
    setSelectedIndex(updIndex);
    setSelectedIds(updId);
    dispatch(actionUpdateDraggableBlocks(updId));
  };

  const keyPressHandler = (e) => {
    if (e.key === 'Escape') {
      clear();
    }

    if (
      (e.key === 'Backspace' || e.keyCode === 8 || e.keyCode === 46) // windows OS delete fix
      && !Object.values(draggableBlocks || {}).length
      && currentDraggableBlocks.length
      && e.target?.dataset.parent !== 'createLibrary'
    ) {
      dispatch(actionDeleteManyBlocks(currentDraggableBlocks));
    }
    if (!anchorBlock.blockId) return;
    if (e.keyCode === 40 && e.shiftKey) {
      const newIndex = [...selectedIndex];
      const orderedIndex = newIndex.sort((a, b) => a - b);
      const isDownOrderOrOne = orderedIndex[0] === selectedIndex[0];
      if (isDownOrderOrOne) {
        const nextIndex = selectedIndex[orderedIndex.length - 1] + 1;
        const nextBlock = editableBlocks[nextIndex];
        if (!nextBlock) return;
        addToDown(nextBlock, nextIndex);
      } else {
        const prewIndex = selectedIndex[orderedIndex.length - 1];
        const prewBlock = editableBlocks[prewIndex];
        prewBlock && removeToDown(prewBlock);
      }
    }
    if (e.keyCode === 38 && e.shiftKey) {
      const newIndex = [...selectedIndex];
      const isTopOrderOrOne = newIndex.sort((a, b) => b - a)[0] === selectedIndex[0];
      if (isTopOrderOrOne) {
        const nextIndex = selectedIndex[selectedIndex.length - 1] - 1;
        const nextBlock = editableBlocks[nextIndex];
        if (!nextBlock) return;
        addToDown(nextBlock, nextIndex);
      } else {
        const orderedIndex = selectedIndex[selectedIndex.length - 1];

        const prewBlock = editableBlocks[orderedIndex];
        removeToDown(prewBlock);
      }
    }
  };
  const onDragLeave = () => {
    dropDepthRef.current -= 1;

    if (dropDepthRef.current <= 0) {
      dropDepthRef.current = 0;
      setHasDragHover(null);
    }
  };
  const onDragEnter = (idHover) => {
    dropDepthRef.current += 1;
    setHasDragHover(idHover);
  };

  const onCreateComponentClickHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();

    const newObjects = selectedIds.reduce((acc, item) => {
      return {
        ...acc,
        [item]: { indices: selectedIndex, status: 'pending', id: item },
      };
    }, {});
    setBlocksToCreateLComponent({ ...newObjects });
    setIsCreateComponentMenuVisible(true);
  };

  const onEditComponentClickHandler = (event, nestedItemId) => {
    event.stopPropagation();
    dispatch(actionOpenCreateComponent(nestedItemId));
  };

  const goToOtherTextBlock = (id, order, offset) => {
    const textBlock = editableBlocks.filter((i) => isTextType[i.type]);

    if (!id) {
      setNewFocus({ newFocusId: textBlock[0].id });
      return;
    }

    const index = textBlock.findIndex((i) => id === i.id);
    const newFocusIndex = order === 'top' ? index - 1 : index + 1;
    const newFocusId = textBlock[newFocusIndex]?.id;

    if (newFocusId) {
      setNewFocus({ newFocusId, offset, order });
    } else if (order === 'top') {
      setNewFocus({ newFocusId: 'core' });
    }
  };
  const onScrollHandler = () => {
    if (!startPoint || !mouseDown) return;
    const scroll = startPoint.scroll
      - ((refPage.current.scrollTop || 0) - (refPage.current.clientTop || 0));
    const newEndPoint = { ...startPoint, y: scroll };
    selectionBox.current = calculateSelectionBox(
      startPoint,
      endPoint,
      'isText',
    );
    setStartPoint(newEndPoint);

    const selected = [];
    if (selectionBox.current && selectionBox.current.left) {
      refSelectedBlocks.current.forEach((item) => {
        if (item) {
          if (intersect(selectionBox.current, item.getBoundingClientRect())) {
            item.setAttribute('class', 'selected_block');
            selected.push(item);
          } else item.removeAttribute('class');
        }
      });
    }
    let isCreateCondition = true;
    selected.forEach((item) => {
      if (item.dataset?.type === 'component') {
        isCreateCondition = false;
      }
    });

    if (selected.length && isCreateCondition) {
      throttleShowButton(() => () => {
        setFloatingButton(selected[selected.length - 1].id);
      });
    } else {
      throttleShowButton(() => () => {
        setFloatingButton('');
      });
    }

    if (selected.length === 1 && !isCreateCondition) {
      throttleShowButton(() => () => {
        setFloatingButton(selected[0].id);
      });
    }
  };

  return (
    <div
      tabIndex="0"
      onKeyDown={keyPressHandler}
      // onPaste={onPasteHandler}
      onDrop={(e) => onEditorAreaMouseDrop(e, '', editableBlocks.length)}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      className={`${styles.page} ${isLargeRightBar ? styles.small_page : ''} ${
        mouseDown && selectionBox.current ? styles.noSelect : ''
      }`}
      ref={refPage}
      onScroll={onScrollHandler}
    >
      {mouseDown && <SomeItem selectionBox={selectionBox} />}
      <div
        className={`${styles.wrapper} ${
          isLargeRightBar ? styles.wrapper_small : ''
        }`}
      >
        <PageCoreInfo
          stopPropagation={stopPropagation}
          isLargeRightBar={isLargeRightBar}
          isInitialized={isInitialized}
          onDragLeave={onDragLeave}
          onDragEnter={() => onDragEnter(editableBlocks[0]?.id)}
          setShowAdd={setShowAdd}
          setIsHasTitle={setIsHasTitle}
          isHasTitle={isHasTitle}
          createNewBlock={() => addNewEmptyEditableBlock(editableBlocks[0]?.id, 'new_before')
          }
          newFocus={newFocus}
          goToOtherTextBlock={goToOtherTextBlock}
        />
        <div
          className={`${styles.edit_content} ${
            draggableBlocks.length ? 'hasDragBlocks' : ''
          }`}
          onDrop={(e) => {
            if (!editableBlocks.length) onEditorAreaMouseDrop(e, id, 0);
          }}
        >
          {isShowFirstLine && (
            <div className={`${styles.dragLine_wrap} ${styles.first}`}>
              <DropLineSvg
                className={`
                  ${styles.dragLineSvg} 
                  ${isLargeRightBar ? styles.dragLineSvg_isLargeRightBar : ''}
                `}
              />
            </div>
          )}
          <BlocksContent
            editableBlocks={editableBlocks}
            mouseup={mouseup}
            idOpenMenuBlock={idOpenMenuBlock}
            selectBlock={selectBlock}
            clear={clear}
            handlerClick={handlerClick}
            addNewEmptyEditableBlock={addNewEmptyEditableBlock}
            addDuplicateBlock={addDuplicateBlock}
            canUpdForRedoUndo={canUpdForRedoUndo}
            floatingButton={floatingButton}
            isLibraryPage={isLibraryPage}
            setIsCreateComponentMenuVisible={setIsCreateComponentMenuVisible}
            onEditComponentClickHandler={onEditComponentClickHandler}
            isViewMode={isViewMode}
            onCreateComponentClickHandler={onCreateComponentClickHandler}
            libraryDragState={libraryDragState}
            setBlocksToCreateLComponent={setBlocksToCreateLComponent}
            setCanUpdForRedo={setCanUpdForRedo}
            isCreateComponentMenuVisible={isCreateComponentMenuVisible}
            selectedIndex={selectedIndex}
            blocksToCreateLComponent={blocksToCreateLComponent}
            createComponentAfterDetach={createComponentAfterDetach}
            onDragEnter={onDragEnter}
            hasDragHover={hasDragHover}
            selectedIds={selectedIds}
            onDragLeave={onDragLeave}
            refSelectedBlocks={refSelectedBlocks}
            addEditableBlock={addEditableBlock}
            stopPropagationInput={stopPropagationInput}
            setIdOpenMenuBlock={setIdOpenMenuBlock}
            onEditorAreaMouseDrop={onEditorAreaMouseDrop}
            onChangeEditableBlock={onChangeEditableBlock}
            deleteEditableBlock={deleteEditableBlock}
            changeTypeBlock={changeTypeBlock}
            newFocus={newFocus}
            selectBlockOnArrow={selectBlockOnArrow}
            onChangeBlocksWithData={onChangeBlocksWithData}
            goToOtherTextBlock={goToOtherTextBlock}
          />

          {showAdd && (
            <>
              <div
                onDrop={(e) => onEditorAreaMouseDrop(e, 'last', editableBlocks.length)
                }
                onDragEnter={() => onDragEnter('last')}
                onDragLeave={onDragLeave}
                className={`${styles.wrapper_add_block} ${
                  isLargeRightBar ? '' : styles.center_add_block
                }`}
              >
                {isShowLastLine && (
                  <div className={`${styles.dragLine_wrap} ${styles.last}`}>
                    <DropLineSvg
                      className={`${styles.dragLineSvg} ${
                        isLargeRightBar
                          ? styles.dragLineSvg_isLargeRightBar
                          : ''
                      }`}
                    />
                  </div>
                )}

                <div
                  onClick={() => addNewEmptyEditableBlock(
                    editableBlocks[editableBlocks.length - 1]?.id || '',
                  )
                  }
                  className={`
                    ${styles.add_block} 
                    ${isLargeRightBar ? styles.hidden : ''} 
                    ${editableBlocks.length && styles.add_block__on_end}
                  `}
                >
                  <img
                    className={styles.add_block_icon}
                    src={plusBlue24Icon}
                    alt=""
                  />
                  <div>{t.addBlockUpT}</div>
                </div>

                <div className={styles.space_separator3} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
});

const PageDataLayer = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    dispatch(actionGetCurrentPage(id, false, history));
  }, [id]);

  return <Page />;
};

export default PageDataLayer;
