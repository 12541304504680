import React, { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { ReactComponent as ChevronSVG } from '../../images/2023/svg/small/complete/chevron_10_6.svg';
import {
  BRRestartContainer,
  BRRestartStripe,
  ButtonResumeRestartChevronContainer, ButtonResumeRestartMain,
  ButtonResumeRestartShadow, ButtonResumeRestartWrapper,
} from './styled';
import { useLocalization } from '../../LocalizationContext';

const ButtonResumeRestart = ({ resume, restart }) => {
  const [isRestart, setIsRestart] = useState(false);
  const addFileInputRef = useRef(null);
  const { t } = useLocalization();

  const restartClickHandler = (e) => {
    e.stopPropagation();
    restart();
    setIsRestart(false);
  };

  const ThreeDotsWrapper = () => {
    const position = addFileInputRef.current?.getBoundingClientRect();
    const top = position?.top;
    const left = position?.left + 35;
    return (
      <>
        <BRRestartContainer isRestart={{ top, left }}>
          <BRRestartStripe onClick={restartClickHandler}>
            {t.restartT}
          </BRRestartStripe>
        </BRRestartContainer>
        <ButtonResumeRestartShadow onClick={() => setIsRestart(false)} />
      </>
    );
  };
  return (
    <ButtonResumeRestartWrapper>
      <ButtonResumeRestartMain ref={addFileInputRef} onClick={resume}>{t.resumeUpT}</ButtonResumeRestartMain>
      <ButtonResumeRestartChevronContainer
        isActive={isRestart}
        onClick={() => setIsRestart(true)}
      >
        <ChevronSVG />
      </ButtonResumeRestartChevronContainer>
      {isRestart && (
        <>
          {createPortal(ThreeDotsWrapper(), document.body)}
        </>
      )}

    </ButtonResumeRestartWrapper>
  );
};

export default ButtonResumeRestart;
