import React from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { actionDownloadFile } from '../../../redux/downloads/action';
import Button2023, { IconTypesBtn2023 } from '../../Buttons/Button2023';
import { ReactComponent as UnknownFileSVG } from '../../../images/2023/svg/preview/unknown_file_70_70.svg';
import { useLocalization } from '../../../LocalizationContext';

const TextWrapper = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #484343;
  text-align: center;
  margin-bottom: 10px;
  position: relative;
  top: 9px;
  ${({ isSecond }) => (isSecond ? 'top:3px;' : '')}
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 213px;
  align-items: center;
  justify-content: center;
`;

const CannotDisplayPreview = ({ item }) => {
  const dispatch = useDispatch();
  const { t } = useLocalization();
  const downloadFile = () => {
    dispatch(
      actionDownloadFile({
        id: item.id,
        folderId: item.inCollectionId,
        url: item.urlFile,
      }),
    );
  };

  return (
    <MainWrapper>
      <UnknownFileSVG style={{ marginBottom: 4 }} />
      <TextWrapper>{t.noPreviewAvailableT}</TextWrapper>
      <TextWrapper isSecond>
        {t.youCanDownloadAndOpenFileT}.
      </TextWrapper>
      <div style={{ top: 22, position: 'relative' }}>
        <Button2023
          handleButtonClick={downloadFile}
          paddingLeft={30}
          IcoType={IconTypesBtn2023.previewDownload}
          text={t.downloadUpT}
          variant="primary"
          width={113}
          height={32}
        />
      </div>
    </MainWrapper>
  );
};

export default CannotDisplayPreview;
