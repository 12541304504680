import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { actionMoveComponentToTrash } from '../../redux/library/actions';
import {
  actionShowMessage,
  actionWarningDelete,
} from '../../redux/support/action';
import { MessageType } from '../../utils/constants';
import { actionRemoveAllSelected } from '../../redux/selectedPage/action';
import { actionCloseModal } from '../../redux/user/action';
import { actionPlaylistMoveToTrash } from '../../redux/playlists/action';
import { pluralize } from '../../utils/dateConvert';
import Button2023 from '../Buttons/Button2023';
import { useLocalization } from '../../LocalizationContext';

const ModalShell = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(147, 147, 147, 0.6);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const Modal = styled.div`
  background: #fff;
  width: 325px;
  height: 161px;
  padding: 15px 16px 0 17px;
  border: 0.6px solid #ece2e2;
  box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.25);
  border-radius: 12px;
`;

const ModalText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #484343;
  margin-bottom: 10px;
`;

const ModalDescriptionText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #484343;
`;

const WarningDeleteItem = () => {
  const dispatch = useDispatch();
  const { t } = useLocalization();
  const {
    dataPayload: { option, itemsToDelete },
  } = useSelector((state) => state.user);

  const close = () => {
    dispatch(actionCloseModal());
    dispatch(actionWarningDelete());
    dispatch(actionRemoveAllSelected());
  };

  const deleteAll = (e) => {
    e.stopPropagation();
    e.preventDefault();

    dispatch(
      actionShowMessage({
        type:
          option.itemsType === 'Playlist'
            ? MessageType.PlaylistToTrash
            : MessageType.ComponentToTrash,
        ...option,
      }),
    );

    itemsToDelete.forEach((i) => {
      dispatch(
        option.itemsType === 'Playlist'
          ? actionPlaylistMoveToTrash(i, option.useCases[i])
          : actionMoveComponentToTrash(i, option.useCases[i]),
      );
    });

    close();
  };

  const TitleWrapper = styled.div`
    font-size: 12px;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    color: #d3c8c8;
    margin-bottom: 10px;
  `;

  const ButtonBlockWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 139px;
    padding-right: -3px;
    //padding-right: 17px;
    padding-top: 14px;
    width: 100%;
    font-family: "RobotoFlex", sans-serif;
  `;

  return (
    <ModalShell data-cy="modal-alert" onClick={close}>
      <Modal onClick={(e) => e.stopPropagation()}>
        <TitleWrapper>{t.deleteUpT}</TitleWrapper>
        <ModalText>{t.deleteTheseItemsQuestionT}</ModalText>

        <ModalDescriptionText>
          {t.deleteTheseItemsMessageT}
        </ModalDescriptionText>

        <ButtonBlockWrapper>
          <Button2023
            variant="primary"
            width={69}
            height={32}
            text={t.cancelUpT}
            noMargins
            handleButtonClick={close}
          />
          <Button2023
            variant="secondary"
            width={66}
            height={32}
            text={t.deleteUpT}
            noMargins
            handleButtonClick={deleteAll}
          />
        </ButtonBlockWrapper>
      </Modal>
    </ModalShell>
  );
};

export default WarningDeleteItem;
