import React, { useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import 'react-image-crop/dist/ReactCrop.css';
import rawStyles from '../ProfileInfo/index.module.scss';
import 'rc-slider/assets/index.css';
import { GreyButton } from '../../CommonComponents/Buttons';
// eslint-disable-next-line max-len
import AddFilePopUpClickOutsideWithUnderlay from '../../../../components/LibraryHeaderMenu/AddFilePopUpClickOutsideWithUnderlay';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import { Settings } from '../../../../redux/settings/types';
import { ReactComponent as Alert20Svg } from '../../../../images/icons/alert_circle_20.svg';
import { ReactComponent as SpinnerSvg } from '../../../../images/icons/spinner.svg';
import { AvatarLoadingSpinner } from '../../../Channels/MemberRow/RolesCell/Spinner';
import { actionOpenModal } from '../../../../redux/user/action';
import AvatarPlaceholder from '../../../../components/Avatar';
import { openModalType } from '../../../../utils/constants';
import { useLocalization } from '../../../../LocalizationContext';

const cn = classNames.bind(rawStyles);

const UserAvatarInputWrapper = ({ setFormTouched }) => {
  const dispatch = useDispatch();
  const { value, errors, isLoading } = useSelector(
    (state) => state.settings.inputs.avatar,
  );
  const { firstName, lastName, displayImage } = useSelector(
    (state) => state.user,
  );
  const [isAddFile, setIsAddFile] = useState(false);
  const addFileRef = useRef(null);
  const inputFile = useRef(null);
  const { t } = useLocalization();

  const isShowError = !!errors?.length;

  const submitForm = (e) => {
    dispatch(
      actionCreator(Settings.HandleUploadFileS, {
        file: e.target.files[0],
        inputType: 'avatar',
      }),
    );
    setIsAddFile(false);
    inputFile.current.value = '';
  };

  const onRemoveButtonClick = () => {
    if (!value) return;
    dispatch(
      actionOpenModal(openModalType.ConfirmModal, {
        subject: t.deleteAvatarQuestionT,
        description: ' ',
        confirm: () => {
          dispatch(actionCreator(Settings.Profile.DeleteAvatar));
        },
        cancelText: t.cancelT,
        okText: t.deleteT,
      }),
    );
    setFormTouched(true);
  };
  const onUploadButtonClick = () => {
    setIsAddFile(true);
    setFormTouched(true);
  };

  const onChooseFileButtonClick = (event) => {
    event.stopPropagation();
    inputFile?.current.click();
    setFormTouched(true);
  };

  return (
    <div>
      <div className={cn('input_container', 'avatar')}>
        <div className={cn('label')}>
          {t.avatarT}
        </div>
        <div className={cn('avatar_container')}>
          {!!isLoading && (
            <AvatarLoadingSpinner>
              <SpinnerSvg />
            </AvatarLoadingSpinner>
          )}

          {(!value || displayImage === 'GeneratedAvatar') && (
            <div className={cn(rawStyles.avatar_image)}>
              <AvatarPlaceholder
                name={firstName}
                abbreviation={`${firstName[0]}${lastName[0]}`}
              />
            </div>
          )}
          {!!value && displayImage === 'AvatarImage' && (
            <img src={`${value}`} alt="avatar" className={cn('avatar_image')} />
          )}

          <div className={cn('button_group')}>
            <GreyButton
              text={value ? t.changeAvatarT : t.uploadAvatarT}
              onClick={onUploadButtonClick}
              parentRef={addFileRef}
              dataParent="AddAvatarFile"
              isActive={isAddFile}
              primaryGrey
            />
            {isAddFile && (
              <AddFilePopUpClickOutsideWithUnderlay
                parentRef={addFileRef}
                setIsAddFileActive={setIsAddFile}
                onButtonClick={onChooseFileButtonClick}
              />
            )}
            <GreyButton text={t.removeT} onClick={onRemoveButtonClick} secondaryGrey />
          </div>
        </div>

        <div className={cn('error_wrapper', { show: isShowError })}>
          <div className={cn('error_icon')}>
            <Alert20Svg />
          </div>
          <div className={cn('error_message')}>
            {!!errors?.length && errors[0]?.message}
          </div>
        </div>
      </div>
      <input
        type="file"
        name="file"
        ref={inputFile}
        style={{ display: 'none' }}
        accept="image/*"
        onChange={(e) => {
          submitForm(e);
        }}
      />
    </div>
  );
};

export default UserAvatarInputWrapper;
