import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { ReactComponent as VideoBigSvg } from '../../images/icons/elements_video_play_48-48.svg';
import { ReactComponent as CheckSvg } from '../../images/icons/upload_check_20.svg';
import { ReactComponent as ArchiveSvg } from '../../images/icons/libComponentTypeArchivePlaceholder.svg';
import {
  DATA_PARENTS,
  empty,
  MUX_GIF_PATH,
  MUX_IMAGE_PATH,
  PLAYLIST_ELEMENTS,
  UiComponentTypes,
} from '../../utils/constants';
import mimeTypes from '../../utils/mimeTypes';
import FallbackImage from '../FallBackImage/FallBackImage';

import FavoriteIcon from './FavoriteIcon/FavoriteIcon';
import MiniPageUsedMenu from './UsedMenu/MiniPageUsedMenu';
import CardViewActions from './CardViewActions';
import BlockCreatorComponent from './BlockCreatorComponent';
import {
  BadgeWrapper,
  NotReadyWrapper,
} from './StyledComponents';
import styles from './index.module.css';
import { useLocalization } from '../../LocalizationContext';

const VideoPreviewWrapper = styled.div`
  width: 100%;
  padding-top: 42px;
  height: 128px;
  overflow: hidden;

  img {
    position: relative;
    left: 2px;
  }
`;

const CardViewComponent = React.memo(function CardViewComponent({
  isPdf,
  dataP,
  itemUploadData,
  cursorLoader,
  bigSizeCondition,
  item,
  isSelected,
  isChannelMiniPage,
  parentType,
  dragStartHandler,
  dragEndHandler,
  isBlurred,
  isComponentDescriptionOpened,
  ...actionsProps
}) {
  const [isHighlight, setIsHighlight] = useState(false);
  const { t } = useLocalization();

  const componentDescriptionId = useSelector(
    reduxState => reduxState.support.componentDescriptionData?.id || empty,
  );
  const { editComponentId, isOpenCreateComponent } = useSelector(
    state => state.library,
  );

  const isMuxContent = !!(item?.playbackID || item?.assetID);

  const isMuxContentReady = isMuxContent && item.videoProcessingStatus === 'ready';

  const hasPdfPreview = !!item?.pdfPreviewUrl;
  const isAudio = UiComponentTypes.audio[item.type];
  const isVideo = UiComponentTypes.video[item.type];

  const isHasPreview = UiComponentTypes.link[item.type]
    || UiComponentTypes.ms[item.type]
    || UiComponentTypes.component[item.type]
    || UiComponentTypes.presentation[item.type]
    || UiComponentTypes.pdf[item.type]
    || UiComponentTypes.xls[item.type]
    || UiComponentTypes.media[item.type]
    || UiComponentTypes.image[item.type]
    || parentType === PLAYLIST_ELEMENTS.ElementComponent
    || !hasPdfPreview;

  const isComponent = !!item.type && UiComponentTypes.component[item.type];

  const removeHighlight = () => {
    setIsHighlight(false);
    document.removeEventListener('click', removeHighlight, true);
  };

  const handleToggleFavorite = (e) => {
    e.stopPropagation();
    e.preventDefault();
    actionsProps.toggleFavorite(e);
  };

  useEffect(() => {
    if (
      editComponentId === item.id
      && isOpenCreateComponent
      && !isHighlight
    ) {
      setIsHighlight(true);
    } else if (isHighlight) {
      document.addEventListener('click', removeHighlight, true);
    }

    return () => {
      document.removeEventListener('click', removeHighlight, true);
    };
  }, [editComponentId, isOpenCreateComponent]);

  return (
    <>
      {item.playbackID
        && item.videoProcessingStatus !== 'ready'
        && (mimeTypes.audio[item?.type]
          || mimeTypes.video[item?.type]) && (
          <NotReadyWrapper onClick={e => e.stopPropagation()}>
            <BadgeWrapper>{t.processingT}</BadgeWrapper>
          </NotReadyWrapper>
      )}

      <div className={`${styles.success_upload_container}`}>
        <div
          className={`${styles.success_upload} ${
            itemUploadData.success ? styles.show : ''
          }`}
        >
          <CheckSvg />
        </div>
      </div>

      <div
        draggable={!isComponent}
        onDragEnd={dragEndHandler}
        onDragStartCapture={dragStartHandler}
        data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
        className={`
          ${styles.preview}
          ${bigSizeCondition ? styles.previewPage : ''}
          ${isHighlight ? styles.opened : ''}
          ${
            ((isSelected && !isBlurred)
            || componentDescriptionId === item?.id
            || cursorLoader) && isComponentDescriptionOpened
              ? styles.selected_active_meta
              : ''
          }
        `}
      >
        {isHasPreview && !isAudio && (
          <div>
            {!(
              UiComponentTypes.image[item.type]
              || UiComponentTypes.link[item.type]
              || UiComponentTypes.pdf[item.type]
              || hasPdfPreview
            ) && <BlockCreatorComponent isPreview item={item} />}
          </div>
        )}

        {isHasPreview
          && !isComponent
          && !hasPdfPreview
          && (isMuxContentReady || !isMuxContent) && (
            <div
              className={`
              ${styles.image_preview}
              ${((isSelected && !isBlurred)
                || componentDescriptionId === item?.id
                || cursorLoader)
              && isComponentDescriptionOpened
                ? styles.image_preview_correction_meta : ''}
                `}
            >
              <FallbackImage
                src={
                  isVideo && item.playbackID
                    ? MUX_IMAGE_PATH(item.playbackID, 400, 200)
                    : item.urlSmallImage
                      ? item.urlSmallImage
                      : item.urlFile || ''
                }
                isSelected={isSelected}
                alt=""
                type={item.type}
                item={item}
                isChannelMiniPage={isChannelMiniPage}
              />
            </div>
        )}

        {hasPdfPreview && (
          <div className={`${styles.pdf_preview}`}>
            <FallbackImage
              src={item.pdfPreviewUrl || item.urlFile}
              alt=""
              type="application/pdf"
              item={item}
              isChannelMiniPage={isChannelMiniPage}
            />
          </div>
        )}

        <div
          data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
          className={`${styles.editActionsPanel}`}
        >
          {isVideo && item.playbackID && (
            <VideoPreviewWrapper data-parent={dataP}>
              <img
                src={MUX_GIF_PATH(item.playbackID, 168)}
                alt=""
              />
            </VideoPreviewWrapper>
          )}
        </div>
        <div
          data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
          className={`
                  ${bigSizeCondition ? styles.usedItem_page : styles.usedItem}
                  ${styles.overlayUsed}
                  ${styles.overlayUsedOffset}
                `}
        >
          <MiniPageUsedMenu
            dataParent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
            usedRef={actionsProps.usedRef}
            isUsedActive={actionsProps.isUsedActive}
            setIsUsedActive={actionsProps.setIsUsedActive}
            libraryComponentId={item.id}
            usedIn={item.usedIn}
            usedTimesCounter={item.usedTimesCounter}
            isNoChannels={item.isNoChannels}
          />
        </div>
        {item.isFavorite && (
          <FavoriteIcon
            isFavorite={item.isFavorite}
            handleToggleFavorite={handleToggleFavorite}
            bigSizeCondition={bigSizeCondition}
          />
        )}
        <CardViewActions
          item={item}
          bigSizeCondition={bigSizeCondition}
          isSelected={isSelected}
          isChannelMiniPage={isChannelMiniPage}
          isPdf={isPdf}
          {...actionsProps}
        />

        {!isHasPreview && (
          <div className={`${styles.no_preview_placeholder}`}>
            {UiComponentTypes.media[item.type] && (
              <VideoBigSvg />
            )}
            {!UiComponentTypes.media[item.type] && (
              <ArchiveSvg />
            )}
          </div>
        )}
      </div>
    </>
  );
});

export default CardViewComponent;
