import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { actionCloseModal } from '../../redux/user/action';
import Button2023 from '../Buttons/Button2023';
import { actionEditTitlePlaylist } from '../../redux/playlists/action';
import { actionShowMessage } from '../../redux/support/action';
import { DEFAULT_TITLE, MessageType } from '../../utils/constants';
import { actionUpdateLibraryComponent } from '../../redux/library/actions';
import { useLocalization } from '../../LocalizationContext';

const ModalWrapper = styled.div`
  position: fixed;
  background: rgba(147, 147, 147, 0.6);
  z-index: 104;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const ModalWindow = styled.div`
  height: 140px;
  width: 325px;
  border-radius: 12px;
  border: 0.6px solid #ece2e2;
  box-shadow: 1px 1px 6px 0px #7a696940;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #ece2e2, #ece2e2);
  display: flex;
  flex-direction: column;
  padding-left: 14px;
  padding-top: 14px;
`;

const TitleWrapper = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #d3c8c8;
  letter-spacing: 0.05em;
  margin-bottom: 14px;
  font-family: "RobotoFlex", sans-serif;
`;

const InputWrapper = styled.input`
  height: 32px;
  width: 293px;
  border-radius: 6px;
  border: 1px solid #7a696980;
  padding-left: 8px;

  &::placeholder {
    color: #7a696980;
    font-weight: 400;
    font-size: 15px;
  }

  &:focus {
    border: 1px solid #ffc72c;
  }
`;
const ButtonBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 135px;
  padding-right: 17px;
  padding-top: 19px;
`;

const RenameModal = () => {
  const [newTitle, setNewTitle] = useState('');
  const { playlistStatus, playlistID } = useSelector((state) => state.user);
  const title = useSelector(
    (state) => state.content?.contentData[playlistID]?.title,
  );
  const dispatch = useDispatch();
  const { t } = useLocalization();
  const close = () => {
    dispatch(actionCloseModal());
  };
  const listenForTitle = (e) => {
    setNewTitle(e.target.value);
  };
  const saveTitle = () => {
    if (newTitle.trim()) {
      if (playlistStatus === 'media') {
        dispatch(
          actionUpdateLibraryComponent({
            id: playlistID,
            field: 'title',
            value: newTitle.trim(),
          }),
        );
        dispatch(
          actionShowMessage({
            type: MessageType.ItemRenamed,
            itemName: t.defaultSmartPageTitleT,
          }),
        );
      } else {
        dispatch(actionEditTitlePlaylist(playlistID, newTitle.trim()));
        dispatch(
          actionShowMessage({
            type: MessageType.PlaylistRenamed,
            itemName: t.defaultSmartPageTitleT,
          }),
        );
      }
    }
    close();
  };

  const enterFoo = (e) => {
    if (e.keyCode === 27) {
      dispatch(actionCloseModal());
    }
    if (e.key === 'Enter') {
      saveTitle();
    }
  };
  useEffect(() => {
    setNewTitle(title);
  }, [playlistID]);
  return (
    <ModalWrapper onClick={close} onKeyDown={enterFoo}>
      <ModalWindow onClick={(e) => e.stopPropagation()}>
        <TitleWrapper>{t.renameToUpT}</TitleWrapper>
        <InputWrapper
          autoFocus
          placeholder={
            playlistStatus === 'media' ? t.itemTitleT : t.smartFileTitleT
          }
          value={newTitle}
          onChange={listenForTitle}
        />
        <ButtonBlockWrapper>
          <Button2023
            variant="primary"
            width={69}
            height={32}
            text={t.cancelUpT}
            noMargins
            handleButtonClick={close}
          />
          <Button2023
            variant="secondary"
            width={72}
            height={32}
            text={t.renameUpT}
            noMargins
            handleButtonClick={saveTitle}
          />
        </ButtonBlockWrapper>
      </ModalWindow>
    </ModalWrapper>
  );
};

export default RenameModal;
