import React, { useState } from 'react';
import classNames from 'classnames/bind';
import UI from '../../../UIComponents/index';
import rawStyles from './index.module.scss';
import { ValidationMessages } from '../../../utils/constants';
import validateEmail from '../../../utils/validators/validateEmail';
import { useLocalization } from '../../../LocalizationContext';

const cn = classNames.bind(rawStyles);

const SimpleEmailInput = ({
  value,
  setValue,
  validateError,
  setValidateError,
  fieldName,
  isRequired,
  ...props
}) => {
  const { t } = useLocalization();
  const [isTouched, setIsTouched] = useState(false);

  const validate = (inputValue) => {
    if (!validateEmail(inputValue)) {
      setValidateError(t.enterValidEmailAddressT);
    }
  };

  const onInputFirstNameChange = (e) => {
    setValue(e.target.value);
    if (isTouched) setIsTouched(false);
    if (e.target.value.length > 55) {
      setValidateError(ValidationMessages.maxLength(fieldName));
    } else {
      setValidateError('');
    }
  };

  return (
    <>
      <div className={cn('wrapper')}>
        <div className={cn('title_wrapper')}>
          <div className={cn('title', 'hasSubFieldError', { required: isRequired })}>
            {fieldName} {!isRequired && <span>- {t.optionalT}</span>}
          </div>
          <UI.ErrorSubField error={validateError} />
        </div>
        <div
          className={cn('input_area', {
            error: validateError || (isTouched && !value && isRequired),
          })}
        >
          {props.isContactExists && (
            <div className={cn('disabled_input')}>{value}</div>
          )}
          {!props.isContactExists && (
            <input
              data-cy={fieldName}
              value={value}
              onChange={onInputFirstNameChange}
              className={cn('input')}
              onBlur={(e) => {
                setIsTouched(true);
                validate(e.target.value);
              }}
              onFocus={() => !value && setIsTouched(false)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SimpleEmailInput;
