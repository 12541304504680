import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { useLocation } from 'react-router-dom';
import styles from './index.module.scss';
import { stopPropagation } from '../../../utils/constants';
import DatePicker from '../../DatePicker';
import Button2023 from '../../Buttons/Button2023';
import { actionEditPlaylistSingleStateUser } from '../../../redux/playlists/action';
import { actionCreator } from '../../../shared/redux/actionHelper';
import EditPlaylist from '../../../redux/playlists/types';
import { DateRangeMark4SharedPlaylist } from '../../DatePicker/helpers/enum';
import { EditPage } from '../../../redux/pages/types';
import { useLocalization } from '../../../LocalizationContext';

const cx = classNames.bind(styles);

const ShareSingleUser = ({ refMenu, close, dataParent, isMakerStyles, isMultiple, userEmail, isWeb }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useLocalization();

  const { shareState, singleUserShareState } = useSelector((state) => state.currentPage);
  const [localState, setLocalState] = useState({ dateRangeMark: DateRangeMark4SharedPlaylist.OPEN_TO_READ });
  const [selectedOption, setSelectedOption] = useState(null);

  const isPage = location.pathname.startsWith('/libraryPage')
    || location.pathname.startsWith('/content/pages/')
    || location.pathname.startsWith('/content/library');

  const itemTypeReadable = isPage ? t.pageT : t.smartFileT;

  const shareHandler = () => {
    if (isWeb) {
      if (isPage) {
        dispatch(actionCreator(EditPage.editShareStateToWeb, { isShareToWeb: true, updateTime: { ...localState } }));
      } else {
        dispatch(actionCreator(EditPlaylist.updateShareToWebStatePlaylistSR, { updateTime: { ...localState } }));
      }
    } else if (isPage) {
      dispatch(actionCreator(EditPage.editShareStatePageSingleUser, { ...localState, email: userEmail }));
    } else {
      dispatch(actionEditPlaylistSingleStateUser({ ...localState, email: userEmail }));
    }

    close && close();
  };

  useEffect(() => {
    if (Object.keys(singleUserShareState).length && !isWeb) {
      const theUserState = singleUserShareState[userEmail];
      if (theUserState) {
        setLocalState(theUserState);
        setSelectedOption(theUserState.dateRangeMark);
      }
    } else if (!isWeb) {
      setSelectedOption(shareState.dateRangeMark);
    }
  }, [userEmail, isWeb]);

  return (
    <>

      <div
        ref={refMenu}
        className={cx('share_menu', 'isModify', isMakerStyles && 'share_maker_option', {
          isPlaylist: true,
        })}
        data-cy="share-playlist-modal"
        data-parent={dataParent}
        onClick={stopPropagation}
      >
        <div
          data-parent={dataParent}
          className={`${styles.share_menu__heading_block}`}
        >
          <div>{t.modifyAvailabilityT}</div>
        </div>
        <DatePicker
          isModify
          type={`Share${itemTypeReadable}`}
          isMultiple={isMultiple}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          dataParent={dataParent}
          setLocalState={setLocalState}
          localState={localState}
        />

        <div
          data-parent={dataParent}
          className={`${styles.button_group} ${styles.isModify}`}
        >
          <Button2023
            variant="primary"
            height={32}
            text={t.cancelUpT}
            handleButtonClick={close}
            noMargins
          />
          <Button2023
            variant="secondary"
            height={32}
            text={t.SaveUpT}
            handleButtonClick={shareHandler}
            noMargins
          />
        </div>
      </div>
    </>
  );
};

export default ShareSingleUser;
