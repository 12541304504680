import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import classNames from 'classnames/bind';
import PhoneInputNumber from 'react-phone-number-input/input';
import { ReactComponent as Alert20Svg } from '../../../../images/icons/alert_circle_20.svg';
import rawStyles from './index.module.scss';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import { Settings } from '../../../../redux/settings/types';
import CountryPicker from '../CountryPicker/CountryPicker';
import {
  countries,
  countriesPhonePrefixes,
} from '../../../../utils/countriesPhonePrefixUtils';
import 'react-phone-number-input/style.css';
import { useLocalization } from '../../../../LocalizationContext';

const cn = classNames.bind(rawStyles);

const DEFAULT_COUNTRY_CODE = 'US';
// Remember. Always.
// const phoneValue = `+${JSON.stringify(parseInt(phoneValu1e?.match(/\d+/)) || 0)}`;

const ProfilePhoneInputWrapper = ({ isRequired, setFormTouched }) => {
  const { inputs } = useSelector((state) => state.settings);
  const [phoneValue, countryCode = DEFAULT_COUNTRY_CODE] = inputs.phone.value.split('@');
  const dispatch = useDispatch();
  const isShowError = !!inputs.phone?.errors?.length;
  const { t } = useLocalization();

  const getCountry = (code) => countriesPhonePrefixes[countries[code]];

  const onInputChange = (value) => {
    dispatch(
      actionCreator(Settings.Profile.ChangeValue, {
        inputType: 'phone',
        inputValue: `${value}@${countryCode}`,
      }),
    );
    setFormTouched(true);
  };

  const onBlur = () => {
    dispatch(
      actionCreator(Settings.ValidateInputS, {
        inputType: 'phone',
      }),
    );
  };

  const onChangeCountry = (country) => {
    dispatch(
      actionCreator(Settings.Profile.ChangeValue, {
        inputType: 'phone',
        inputValue: `${phoneValue}@${country?.code}`,
      }),
    );
    setFormTouched(true);
  };

  return (
    <div className={cn('input_container')}>
      <div className={cn('label')}>
        {isRequired && <div className={cn('required')}>*</div>}
        {t.contactPhoneT}
      </div>

      <div className={cn('input_border')}>
        <CountryPicker
          selectedCountry={getCountry(countryCode)}
          onChangeCountry={onChangeCountry}
        >
          <PhoneInputNumber
            readOnly={!countryCode}
            placeholder={t.phonePlaceholderT}
            country={countryCode}
            value={phoneValue}
            onChange={onInputChange}
            onBlur={onBlur}
            className={cn('input', 'phone')}
          />
        </CountryPicker>
      </div>

      <div className={cn('error_wrapper', { show: isShowError })}>
        <div className={cn('error_icon')}>
          <Alert20Svg />
        </div>
        <div className={cn('error_message')}>
          {!!inputs.phone?.errors?.length && inputs.phone?.errors[0]?.message}
        </div>
      </div>
    </div>
  );
};

export default ProfilePhoneInputWrapper;
