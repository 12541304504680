import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuid, v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames/bind';
import { actionDuplicateAndSwitchPlaylist, actionUpdateReadState } from '../../../redux/playlists/action';
import {
  builderDragItemTitle,
  calculateIndex,
  calculateItemsStylesMapMaker,
  getLinkPagePosition,
  getManyNewLinkPagePositionByIndexForDuplicate,
  getNewLinkPagePositionByIndex,
} from '../../../utils/helpers';
import { actionCreator } from '../../../shared/redux/actionHelper';
import {
  DEFAULT_POSITION_STEP, DEFAULT_TITLE,
  empty,
  MessageType,
  openModalType,
} from '../../../utils/constants';
import rawStyles from '../MakerCardView/index.module.scss';
import rawStylesBlock from '../MakerCardView/elements.module.scss';
import Support from '../../../redux/support/types';
import {
  actionCreateAndUploadLinkPage,
  actionDuplicateLinkPageSInCurrentPage,
  actionEmptyAddLinkPageToPlaylist,
  actionRemoveLinkPageFromPlaylist,
  actionToggleLibraryDrag,
} from '../../../redux/currentPage/action';
import ActionDraggable from '../../../redux/dragable/action';
import PlaylistsBuilderUIEx from './BuilderUIEx';
import {
  actionCleanBlinkId,
  actionSetBlinkId,
  actionShowMessage,
} from '../../../redux/support/action';
import filterFilesByMaxSize from '../../../utils/validators/filterFilesByMaxSize';
import { actionOpenModal } from '../../../redux/user/action';
import dropInPlaylistHandler from '../../../utils/dnd/dropHandler';
import { isRoleInPlaylist } from '../../../utils/permissions';
import { CurrentPage } from '../../../redux/currentPage/types';
import { useLocalization } from '../../../LocalizationContext';

const cx = classNames.bind(rawStyles);
const cxBlock = classNames.bind(rawStylesBlock);

export const PlaylistMainContentCardView = React.memo(function PlaylistMainContentCardView({
  currentPage,
  currentRole,
  isExpanded,
  handleDragEnd,
  handleDragStart,
  elementRef,
  playlistType,
  activeItemIndex,
  isFinished,
  handleShowPannel,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useLocalization();
  const { isViewer, isOwner } = isRoleInPlaylist;

  const {
    sideBars: { isPages },
    anchorId,
    readyToScroll,
  } = useSelector((state) => state.support);
  const user = useSelector((state) => state.user || empty);
  // const playlists = useSelector((state) => state.playlists || empty);
  const selectedPage = useSelector((state) => state.selectedPage) || empty;
  const { blinkIdsArray } = useSelector((state) => state.support);
  const dropDepthRef = useRef(0);

  const refScrollBlocks = {};
  const refSelectedBlocks = { current: [] };
  const { isDownload, idDragPage, id, linkPages = [] } = currentPage;
  const viewersLinkPages = useMemo(() => {
    if (isViewer[currentRole]) {
      return linkPages.filter(lp => lp.textComponent || lp.libraryComponent);
    }
    return linkPages;
  }, [linkPages, currentRole, isViewer]);

  const setBlinkId = (id) => {
    if (!id) {
      dispatch(actionCleanBlinkId());
      return;
    }
    dispatch(actionSetBlinkId([id]));
  };
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState([]);
  const [onlyTextSelected, setOnlyTextSelected] = useState(false);
  const [rawPages, setRawPages] = useState([]);
  const [mouseDown, setMouseDown] = useState(false);
  const [startPoint, setStartPoint] = useState(null);
  const [draggablePage, setDraggablePage] = useState(false);
  const [indexHover, setIndexHover] = useState(null);
  const [hasDraggable, setHasDraggable] = useState('');
  const [needToShowVioletStripe, setNeedToShowVioletStripe] = useState(false);
  const [lineStylesObject, setLineStylesObject] = useState({});

  const createWithSelected = () => {
    const newId = uuidv4();
    dispatch(actionDuplicateAndSwitchPlaylist(id, newId, history, selectedIds));
  };

  const isDNDInTextBlockRef = useRef(false);

  const setIsDNDInTextBlock = useCallback((isDNDInTextBlock) => {
    isDNDInTextBlockRef.current = isDNDInTextBlock;
  }, []);

  const onMouseMove = (e) => {
    // if (mouseDown) {
    //   e.preventDefault();
    //   const end = {
    //     x: e.pageX,
    //     y: e.pageY,
    //   };
    //   setEndPoint(end);
    //   setSelectionBox(calculateSelectionBox(startPoint, end));
    // }
  };

  const deleteCb = (e, item) => {
    e.stopPropagation();
    if (!selectedIds.length) {
      dispatch(
        actionRemoveLinkPageFromPlaylist(
          id,
          item.id,
          item.textComponent?.id || item.libraryComponent?.id,
        ),
      );
    } else {
      selectedIds.map((itemId) => dispatch(
        actionRemoveLinkPageFromPlaylist(
          id,
          itemId,
          item.textComponent?.id || item.libraryComponent?.id,
        ),
      ),
      );
    }
  };

  const clear = (itemId) => {
    if (!selectedIds?.includes(itemId)) {
      refSelectedBlocks.current.forEach((item) => {
        if (item) {
          item.removeAttribute('class');
        }
      });
      setSelectedIds([]);
      setSelectedIndex([]);
      return true;
    }
    return false;
  };

  const onMouseDown = () => {};

  const dragStartHandler = (props, item) => (e) => {
    if (item.owner.id !== user.id) {
      e.preventDefault();
      return;
    }
    e.stopPropagation();
    const elementTagFocused = document.activeElement.tagName.toLowerCase();
    const isTextInputFocused = elementTagFocused === 'input';
    const isTextAreaInputFocused = elementTagFocused === 'textarea';
    if (isTextAreaInputFocused || isTextInputFocused) {
      e.preventDefault();
      return;
    }

    if (isViewer[currentRole]) {
      return;
    }
    handleDragStart(e);
    const isDragNotSelected = clear(item.id);

    const div = document.createElement('div');
    div.className = 'draggable_page';
    div.id = 'draggable_page';

    if (
      isDragNotSelected
      || !selectedIndex.length
      || selectedIndex.length === 1
    ) {
      const draggingItemTitle = builderDragItemTitle(item);
      const testDiv = document.createElement('div');
      testDiv.textContent = `${draggingItemTitle}`;
      testDiv.style.width = 'fit-content';
      testDiv.id = 'testDiv';
      document.body.appendChild(testDiv);
      let trimTitle = draggingItemTitle;
      let counter = trimTitle.length;

      while (testDiv.getBoundingClientRect().width > 130) {
        trimTitle = `${trimTitle.slice(0, counter)}...`;
        testDiv.textContent = trimTitle;
        counter--;
      }

      document.body.removeChild(testDiv);
      div.textContent = `${trimTitle}`;
      setDraggablePage(props);
    } else {
      div.textContent = `${selectedIndex.length} items`;
    }
    document.body.appendChild(div);
    e.dataTransfer.setDragImage(div, 117, 20);
  };

  const onEditorAreaMouseDrop = (e, linkPageId, index, replace) => {
    setBlinkId('');
    setNeedToShowVioletStripe(false);
    e.preventDefault();
    e.stopPropagation();
    if (isViewer[currentRole]) {
      return;
    }
    handleDragEnd(e);
    setDraggablePage(false);
    dropDepthRef.current = 0;
    setIndexHover(false);
    const files = Array.from(e.dataTransfer.files);
    const filteredFilesArr = filterFilesByMaxSize([...e.dataTransfer.files]);
    if (files.length !== filteredFilesArr.length) {
      dispatch(
        actionShowMessage({
          type: MessageType.CannotBeUploaded,
          itemName: t.defaultSmartPageTitleT,
        }),
      );
    }

    dropInPlaylistHandler(
      e,
      linkPageId,
      index,
      replace,
      linkPages,
      dispatch,
      history,
      draggablePage,
      selectedPage,
      idDragPage,
      selectedIndex,
      setBlinkId,
      selectedIds,
      id,
      filteredFilesArr,
      user,
    );
  };


  const onDragEnter = (index) => {
    if (isDNDInTextBlockRef.current) return;

    if (isViewer[currentRole]) {
      return;
    }
    dropDepthRef.current += 1;
    setIndexHover(index);
    setNeedToShowVioletStripe(true);
  };


  const onDragLeave = () => {
    if (isViewer[currentRole]) {
      return;
    }
    if (isDNDInTextBlockRef.current) return;

    dropDepthRef.current -= 1;
    if (dropDepthRef.current > 0) return;
    setIndexHover(false);
    setNeedToShowVioletStripe(false);
  };

  const createLinkPage = (destinationIndex, targetIndex) => {
    const newPos = getLinkPagePosition(destinationIndex, rawPages, targetIndex);
    const pageData = {
      id: uuid(),
      position: newPos,
      owner: {
        id: user.id,
        last_name: user.last_name,
        avatarUrlVerySmall: user.avatarUrlVerySmall,
        first_name: user.first_name,
      },
      mainUserInterface: {
        id: uuid(),
      },
    };
    dispatch(actionEmptyAddLinkPageToPlaylist(id, pageData));
    return pageData;
  };

  const addNewLinkPageByIndex = (newIndex) => {
    const newPos = getNewLinkPagePositionByIndex(newIndex, rawPages);
    const pageData = {
      id: uuid(),
      position: newPos,
      owner: {
        id: user.id,
        last_name: user.last_name,
        avatarUrlVerySmall: user.avatarUrlVerySmall,
        first_name: user.first_name,
      },
      mainUserInterface: {
        id: uuid(),
      },
    };
    dispatch(actionEmptyAddLinkPageToPlaylist(id, pageData));
  };

  const backSpaceHandler = (e) => {
    if (isViewer[currentRole]) {
      return;
    }
    if (e.key === 'Backspace') {
      if (selectedIds.length) {
        const linkPagesToDelete = rawPages.map((item, index) => ({
          id: item.id,
          pages: item.page,
          index,
          owner: item.owner,
        }));
        const filteredLP = linkPagesToDelete.filter((item) => selectedIndex?.includes(item.index),
        );
        filteredLP.forEach((item) => {
          const isOwner = item.owner?.id === user.id;
          if (isOwner) {
            dispatch(
              actionRemoveLinkPageFromPlaylist(
                id,
                item.id,
                linkPages[item.id]?.textComponent?.id
                  || linkPages[item.id]?.libraryComponent?.id,
              ),
            );
          }
        });
      }
    }
  };

  const duplicateHandler = (item, newIndex) => {
    if (!selectedIds.length) {
      const newPos = getNewLinkPagePositionByIndex(newIndex, rawPages);
      dispatch(actionDuplicateLinkPageSInCurrentPage(item, newPos, id, uuid()));
    } else {
      const newPosAr = getManyNewLinkPagePositionByIndexForDuplicate(
        rawPages,
        selectedIndex,
      );
      newPosAr.map((newPos, index) => dispatch(
        actionDuplicateLinkPageSInCurrentPage(
          rawPages[selectedIndex[index]],
          newPos,
          id,
          uuid(),
        ),
      ),
      );
    }
  };

  const addBlockToScrollRefs = (ref, item) => {
    refScrollBlocks[item.id] = ref;
  };

  const goToLibComponent = (index, itemId, elemPosition, isNeedZoom) => {
    dispatch(actionOpenModal(openModalType.UPVPreview, { id, index, mode: 'UPV', elemPosition, isNeedZoom }));
    if (isOwner[currentRole]) {
      return;
    }
    const currentLinkPage = linkPages[index];
    if (!currentLinkPage.isViewed) {
      dispatch(actionUpdateReadState(currentLinkPage?.mainUserInterface?.id, currentLinkPage.isViewed, currentLinkPage.id));
    }
  };

  const onPasteFile = (linkPage, index) => (eventFiles, replace) => {
    const isPasteInTextBlock = !!linkPage;
    let files;
    if (isPasteInTextBlock) {
      files = eventFiles;
    } else {
      files = [...eventFiles.dataTransfer.files];
    }
    const isDroppedWithPosition = (index || index === 0) && index !== 'last';

    let newPosition;
    if (isDroppedWithPosition) {
      newPosition = calculateIndex(
        rawPages[index]?.position,
        rawPages[index + 1]?.position,
      );
    } else {
      newPosition = calculateIndex(rawPages[rawPages.length]?.position, 0);
    }
    if (files.length) {
      files.forEach((item, localIndex) => {
        if (localIndex > 6) return;
        const itemPosition = newPosition + files.length * ((localIndex + 1) * 50);

        dispatch(
          actionCreator(CurrentPage.CreateAndUploadLinkPage, {
            file: item,
            index: localIndex,
            history,
            newPosition: itemPosition,
            currentItemId: replace && localIndex === 0 ? linkPage.id : null,
            replace:
              replace && localIndex === 0 ? linkPage.textComponent?.id : null,
          }),
        );
      });
    }
  };

  const intersect = (boxA, boxB) => {
    return (
      boxA.left <= boxB.left + boxB.width
      && boxA.left + boxA.width >= boxB.left
      && boxA.top <= boxB.top + boxB.height
      && boxA.top + boxA.height >= boxB.top
    );
  };

  const mouseup = () => {
    document.removeEventListener('mouseup', mouseup, true);
    const indexArray = [];
    const idsArray = [];
    let calcTextElement = 0;
    const selectedBlocks = refSelectedBlocks.current
      .map((item, index) => {
        if (item && item.hasAttribute('class')) {
          indexArray.push(index);
          idsArray.push(item.dataset.id);
          if (item.dataset.type === 'elementText') ++calcTextElement;
          const { top, bottom, height, width } = item.getBoundingClientRect();
          return { id: item.id, top, bottom, width, height };
        }
        return {};
      })
      .filter((i) => !!Object.keys(i).length);
    setSelectedIndex(indexArray);
    setSelectedIds(idsArray);
    setOnlyTextSelected(
      !!idsArray.length && idsArray.length === calcTextElement,
    );
    if (selectedBlocks.length) {
      setMouseDown(false);
      setTimeout(
        () => dispatch(
          ActionDraggable.BlocksOnPage(
            Array.from(new Set(selectedBlocks.map((i) => i.id))),
          ),
        ),
        0,
      );
    } else {
      setMouseDown(false);
      setStartPoint(null);
    }
  };

  const submitForm = (e, itemIndex = 0, item = { position: 0 }) => {
    e.preventDefault();
    e.stopPropagation();

    const files = Array.from(e.target.files);
    const filteredFilesArr = filterFilesByMaxSize([...e.target.files]);
    if (files.length !== filteredFilesArr.length) {
      dispatch(
        actionShowMessage({
          type: MessageType.CannotBeUploaded,
          itemName: t.defaultSmartPageTitleT,
        }),
      );
    }
    const firstFile = filteredFilesArr[0];

    if (!firstFile) return;

    dispatch(actionCreateAndUploadLinkPage(firstFile, itemIndex, history));

    if (filteredFilesArr.length > 1) {
      const availablePositions = {
        start: item.position,
        end: linkPages[itemIndex + 1]?.position,
      };
      const availablePositionRange = availablePositions.end - availablePositions.start;

      Array.from(filteredFilesArr).forEach((file, fileIndex) => {
        if (!fileIndex) return;

        const newPosition = availablePositions.end
          ? item.position + fileIndex * (availablePositionRange / filteredFilesArr.length)
          : item.position + (fileIndex + DEFAULT_POSITION_STEP);

        dispatch(
          actionCreateAndUploadLinkPage(
            file,
            fileIndex,
            history,
            newPosition,
            null,
            true,
          ),
        );
      });
    }
  };

  useEffect(() => {
    if (!isDownload && anchorId) {
      dispatch(actionCreator(Support.ReadyToScroll));
    }
  }, [isDownload]);

  useEffect(() => {
    if (!readyToScroll) return;
    const itemToScrollTo = Object.entries(refScrollBlocks).reduce(
      (acc, pair) => {
        if (pair[0] === anchorId) acc = pair[1];
        return acc;
      },
      {},
    );
    if (anchorId && Object.keys(itemToScrollTo).length) {
      setTimeout(() => {
        itemToScrollTo?.scrollIntoView({ behavior: 'auto' });
      }, 1500);
      dispatch(actionCreator(Support.ClearReadyConfirmation));
    }
  }, [readyToScroll, refScrollBlocks]);

  useEffect(() => {
    if (rawPages.length) {
      const itemsMap = calculateItemsStylesMapMaker(rawPages);
      setLineStylesObject(itemsMap);
    }
  }, [rawPages]);


  useEffect(() => {
    if (!idDragPage) {
      setHasDraggable(false);
      setIndexHover(false);
    } else setHasDraggable(idDragPage);
  }, [idDragPage]);

  useEffect(() => {
    if (!linkPages.length) return;
    setRawPages(viewersLinkPages);
  }, [viewersLinkPages]);
  //
  useEffect(() => {
    if (!rawPages.length) return;
    const idsArray = [];
    const indexArray = [];
    let calcTextElement = 0;
    if (selectedIds.length) {
      refSelectedBlocks.current.forEach((item, index) => {
        if (item && selectedIds?.includes(item.id)) {
          if (item.dataset.type === 'elementText') ++calcTextElement;
          idsArray.push(item.id);
          indexArray.push(index);
          item.setAttribute('class', cxBlock('selected_block'));
        } else if (item) item.removeAttribute('class');
      });
    }
    setSelectedIndex(indexArray);
    setOnlyTextSelected(idsArray.length === calcTextElement);
    setSelectedIds(idsArray);
    dispatch(ActionDraggable.BlocksOnPage(idsArray));
  }, [rawPages]);

  useEffect(() => {
    dispatch(ActionDraggable.SetHasDraggable(false));
    dispatch(actionToggleLibraryDrag(false));
  }, [dispatch, mouseDown]);


  if (!linkPages?.length && isViewer[currentRole]) {
    return <> </>;
  }

  return (
    <PlaylistsBuilderUIEx
      activeItemIndex={activeItemIndex}
      isFinished={isFinished}
      isExpanded={isExpanded}
      blinkIdsArray={blinkIdsArray}
      clear={clear}
      onlyTextSelected={onlyTextSelected}
      createWithSelected={createWithSelected}
      deleteCb={deleteCb}
      isDownload={isDownload}
      rawPages={viewersLinkPages}
      selectedIds={selectedIds}
      selectedIndex={selectedIndex}
      refSelectedBlocks={refSelectedBlocks}
      setSelectedIndex={setSelectedIndex}
      setSelectedIds={setSelectedIds}
      playlistId={id}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      dragStartHandler={dragStartHandler}
      isPages={isPages}
      onEditorAreaMouseDrop={onEditorAreaMouseDrop}
      hasDraggable={hasDraggable}
      linkPages={linkPages}
      indexBranchHover={indexHover}
      setDraggablePage={setDraggablePage}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      createLinkPage={createLinkPage}
      addNewLinkPageByIndex={addNewLinkPageByIndex}
      backSpaceHandler={backSpaceHandler}
      duplicateHandler={duplicateHandler}
      indexHover={indexHover}
      addBlockToScrollRefs={addBlockToScrollRefs}
      lineStylesObject={lineStylesObject}
      goToFullScreenView={goToLibComponent}
      onPasteFile={onPasteFile}
      currentRole={currentRole}
      submitForm={submitForm}
      elementRef={elementRef}
      needToShowVioletStripe={needToShowVioletStripe}
      playlistType={playlistType}
      setIsDNDInTextBlock={setIsDNDInTextBlock}
      handleShowPannel={handleShowPannel}
    />
  );
});

export default PlaylistMainContentCardView;
