import React from 'react';
import { IconRole } from '../../../../../../utils/permissions';
import { ReactComponent as CloseCrossSvg } from '../../../../../../images/2023/svg/small/component-description/close_16_16.svg';
import Availability from '../Availability';
import {
  ContainerWithPointer,
  OptionChannel,
  OptionContent,
  OptionDivider,
  OptionHeader,
  OptionItem,
  OptionTitle,
  OptionWrapper,
  SvgWrapper,
  Text,
} from '../styled';
import { DATA_PARENTS } from '../../../../../../utils/constants';
import useComponentVisible from '../../../../../../utils/hooks/useComponentVisible';
import PublishUpdate from '../../../../../../components/DropDownOption/Share/PublishUpdate';
import { useLocalization } from '../../../../../../LocalizationContext';

const PublishOption = ({
  type, items,
  availableTo,
  removeChannel,
  ...props
}) => {
  const { t } = useLocalization();
  const [refDropMenu, idOpen, setIdOpen] = useComponentVisible(
    false,
    DATA_PARENTS.AddMember,
  );
  return (
    <OptionWrapper data-parent={props.dataParent}>
      <OptionTitle>{type.title}</OptionTitle>
      <OptionContent>
        {!!items.length
        && items.map((channel) => (
          <OptionItem key={channel.id} data-parent={props.dataParent}>
            <OptionHeader data-parent={props.dataParent}>
              <OptionChannel data-parent={props.dataParent}>
                <SvgWrapper data-parent={props.dataParent} isRole>
                  {IconRole[channel.role]}
                </SvgWrapper>
                <Text>{channel.name || t.unnamedChannelT}</Text>
              </OptionChannel>
              <ContainerWithPointer onClick={() => removeChannel(channel.id)}>
                <CloseCrossSvg data-parent={props.dataParent} />
              </ContainerWithPointer>
            </OptionHeader>
            <Availability
              user={{}}
              endDate={channel.availableTo}
              isChannel
              handleClick={() => {
                setIdOpen(channel.id);
              }}
              dataParent={props.dataParent}
            />
            <OptionDivider />
            {idOpen === channel.id
              && (
                <PublishUpdate
                  channelId={channel.id}
                  refMenu={refDropMenu}
                  close={() => setIdOpen(false)}
                  dataParent={DATA_PARENTS.PlaylistBuilderLibrary}
                  isModify
                  isWeb
                />
              )}
          </OptionItem>
        ))}
      </OptionContent>
      {props.children}
    </OptionWrapper>
  );
};

export default PublishOption;
