import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { ReactComponent as Channel64Svg } from '../../images/icons/channel_64.svg';
import { ReactComponent as LogoSvg } from '../../images/2023/svg/Logo.svg';
import ButtonComponent from '../../components/Buttons/ButtonComponent';
import { DEFAULT_PAGE_PATH } from '../../utils/constants';
import { useLocalization } from '../../LocalizationContext';

const ForbiddenWrapper = styled.div`
  display: grid;
  height: 100vh;
  grid-template-rows: 110px 1fr 110px;
`;
const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  color: #a3aacc;
`;
const MainText = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 24px;
  color: #242633;
  margin-bottom: 8px;
`;
const SubText = styled.h3`
  font-size: 16px;
  line-height: 24px;
  color: #596080;
`;
const ButtonWrapper = styled.div`
  margin: 24px auto;
`;
const LinkSpan = styled.span`
  color: #0026e5;
  margin: 0 5px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
const EmailSpan = styled.span`
  color: #596080;
  margin: 0 5px;
`;

const ChannelForbidden = () => {
  const history = useHistory();
  const { t } = useLocalization();
  const { email } = useSelector((state) => state.user);

  const clickCb = () => {
    history.push('/channels');
  };

  const onClickLoginRedirect = () => {
    history.push('/login');
  };

  const goHome = () => {
    history.push(DEFAULT_PAGE_PATH);
  };

  return (
    <ForbiddenWrapper>
      <Logo>
        <LogoSvg onClick={goHome} />
      </Logo>
      <Message>
        <Channel64Svg style={{ marginBottom: 20 }} />
        <MainText>{t.privateAuthorsChannelMessageT}</MainText>
        <SubText>{t.contactChannelOwnerToInviteYouT}</SubText>
        <ButtonWrapper>
          <ButtonComponent clickCb={clickCb} text={t.backToMyContentT} />
        </ButtonWrapper>
      </Message>
      <Footer>
        <div>
          {t.youreLoggedInAsT}
          <EmailSpan>{email}</EmailSpan>
        </div>
        <div>
          {t.channelForbidden1T}
          <LinkSpan onClick={onClickLoginRedirect}>{t.channelForbidden2T}</LinkSpan>
          {t.channelForbidden3T}
        </div>
      </Footer>
    </ForbiddenWrapper>
  );
};

export default ChannelForbidden;
