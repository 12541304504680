import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './index.module.css';
import { ReactComponent as ArrowMergeSvg } from '../../images/icons/arrow_merge_16.svg';
import { ReactComponent as Tag16Svg } from '../../images/2023/svg/small/multiselect-panel/tags_18_15.svg';
import { ReactComponent as FavoriteSvg } from '../../images/2023/svg/small/favorite_16_16.svg';
import { ReactComponent as Share21Svg } from '../../images/2023/svg/small/multiselect-panel/share_17_17.svg';
import { ReactComponent as Trash16Svg } from '../../images/2023/svg/small/multiselect-panel/trash_bin_13_17.svg';
import { ReactComponent as PlaylistSVG } from '../../images/2023/svg/small/left-main-menu/playlist_icon_16_16.svg';

import {
  ReactComponent as ToCurrentPlaylistSVG,
} from '../../images/2023/svg/small/multiselect-panel/add_to_current_playlist.svg';

import useComponentVisible from '../../utils/hooks/useComponentVisible';
import { actionRemoveAllSelected } from '../../redux/selectedPage/action';
import { actionShowMessage } from '../../redux/support/action';
import {
  actionUnsubscribeManyLibraryEntity,
} from '../../redux/library/actions';
import {
  actionCreatePlaylistWithLibItems,
  actionPlaylistToggleFavorite,
} from '../../redux/playlists/action';
import {
  DATA_PARENTS,
  DEFAULT_TITLE,
  empty,
  emptyCallback,
  MessageType,
  openModalType,
  UiComponentTypes,
} from '../../utils/constants';
import SupportAction from '../../redux/support/types';
import { actionCreator } from '../../shared/redux/actionHelper';
import { pluralize } from '../../utils/dateConvert';
import { actionOpenModal } from '../../redux/user/action';
import { LibraryComponents } from '../../redux/library/types';
import PlaylistSelector from '../../pages/Maker/MakerCommon/Table/PlaylistSelector';
import { actionAddMultiplePagesToPlaylistFromSideBar } from '../../redux/currentPage/action';
import { useLocalization } from '../../LocalizationContext';

const MultiSelectPanel = ({
  counterSelectedPage,
  isFavorite,
  setNeedToDelete,
  contentType,
  dataParent = 'miniPage',
  isMaker,
}) => {
  const dispatch = useDispatch();
  const [refAddTagMenu, isAddTagMenuVisible, setIsAddTagMenuVisible] = useComponentVisible(null, DATA_PARENTS.MultiSelect);
  const history = useHistory();
  const [isAllPagesInFavorite, setIsAllPagesInFavorite] = useState(false);
  const [disableCreatePlaylist, setDisableCreatePlaylist] = useState(true);
  const [hasShared, setHasShared] = useState(true);
  const [canAllBeShared, setCanAllBeShared] = useState(true);
  const [refMenu, showAdd, setShowAdd] = useComponentVisible(false);
  const selectedPage = useSelector((state) => state.selectedPage) || empty;
  const contentData = useSelector((state) => state.content.contentData) || empty;
  const { openModal } = useSelector((state) => state.user);
  const { t } = useLocalization();

  const isShareOptionVisible = history.location.pathname.startsWith('/content/pages')
    || history.location.pathname.startsWith('/maker/')
    || (history.location.pathname.startsWith('/content/smartfiles')
      && history.location.pathname !== '/content/smartfiles/shared');

  const isAddTagOptionVisible = !history.location.pathname.startsWith('/content/smartfiles')
    && !history.location.pathname.includes('/shared');

  const isLibrary = contentType === 'library';
  const isPlaylist = contentType === 'playlist';

  const enableShare = (e) => {
    e.stopPropagation();
    if (hasShared || !canAllBeShared) return;
    dispatch(
      actionOpenModal(openModalType.ShareMultiple, {
        itemType: isPlaylist ? 'playlist' : 'libraryComponent',
      }),
    );
  };

  const createPlaylist = (id) => {
    setShowAdd(false);
    if (disableCreatePlaylist || hasShared) return;
    dispatch(
      actionCreatePlaylistWithLibItems(history, Object.keys(selectedPage), id),
    );
  };

  const addToCurrentPlaylist = () => {
    dispatch(actionAddMultiplePagesToPlaylistFromSideBar());
  };
  const getTitlePage = () => {
    const idItem = Object.keys(selectedPage)[0];
    if (isPlaylist) {
      return contentData[idItem]?.title || t.defaultSmartFileTitleT;
    }
    if (isLibrary) {
      if (UiComponentTypes.page[contentData[idItem]?.type]) {
        return contentData[idItem]?.title || t.defaultSmartPageTitleT;
      }
      return contentData[idItem]?.title || t.untitledComponentT;
    }
    return '';
  };

  const toggleFavorite = () => {
    const newStateFavorite = !isAllPagesInFavorite;
    if (isPlaylist) {
      const keys = Object.keys(selectedPage);
      const filtered = keys
        .map((id) => contentData[id])
        .filter((item) => !item?.isFavorite);
      if (filtered.length === 1) {
        if (!filtered[0]?.isFavorite) {
          const itemName = getTitlePage();
          dispatch(
            actionShowMessage({
              type: MessageType.PlaylistToFavorite,
              itemName,
            }),
          );
        }
      } else if (filtered.length) {
        dispatch(
          actionShowMessage({
            type: MessageType.PlaylistToFavorite,
            counter: filtered.length,
          }),
        );
      }

      keys.forEach((i) => {
        dispatch(actionPlaylistToggleFavorite(i, newStateFavorite));
      });
      return;
    }
    Object.keys(selectedPage).forEach((i) => {
      if (typeof selectedPage[i] === 'boolean') {
        dispatch(
          actionCreator(LibraryComponents.ToggleFavorite, {
            componentId: i,
            isFavorite: newStateFavorite,
            libraryComponentId: contentData[i].libraryComponentId,
          }),
        );
      }
    });
    if (newStateFavorite) {
      if (counterSelectedPage === 1) {
        const itemName = getTitlePage();
        dispatch(
          actionShowMessage({
            type: MessageType.LibraryComponentToFavorite,
            itemName,
          }),
        );
      } else {
        dispatch(
          actionShowMessage({
            type: MessageType.LibraryComponentToFavorite,
            counter: counterSelectedPage,
          }),
        );
      }
    }
  };

  const addToFavorite = (e) => {
    e.stopPropagation();
    if (isFavorite && isAllPagesInFavorite) {
      setNeedToDelete(true);
      setTimeout(() => {
        toggleFavorite();
        setNeedToDelete(false);
        dispatch(actionRemoveAllSelected());
      }, 500);
    } else {
      toggleFavorite();
      dispatch(actionRemoveAllSelected());
    }
  };

  const moveToTrash = () => {
    if (hasShared) {
      const unsubscribeEntityType = isLibrary ? 'page' : 'playlist';
      const previewedEntity = isLibrary ? 'smartpage' : 'smartfile';
      const ending = Object.keys(selectedPage).length > 1 ? 's' : '';
      const smartfileIdsObj = Object.values(contentData).reduce((acc, cur) => {
        if (selectedPage[cur.id]) {
          return { ...acc, [cur.id]: { sharedID: cur.type === 'sharedPage' ? cur.id : cur.sharedID } };
        }
        return acc;
      }, {});

      if (!isLibrary || !!Object.values(smartfileIdsObj).length) {
        dispatch(
          actionOpenModal(openModalType.ConfirmModalUniversal, {
            title: t.removeUpT,
            subject: `Remove this ${previewedEntity}${ending} ?`,
            description: `This ${previewedEntity}${ending} will be removed from your feed`,
            confirm: () => {
              dispatch(actionRemoveAllSelected());
              dispatch(
                actionShowMessage({
                  type: MessageType.Regular,
                  text: `${previewedEntity}${ending} successfuly removed!`,
                }),
              );
              dispatch(actionUnsubscribeManyLibraryEntity(smartfileIdsObj, unsubscribeEntityType));
            },
            cancelText: t.cancelUpT,
            okText: t.removeUpT,
          }),
        );
      }
      return;
    }


    dispatch(
      actionCreator(SupportAction.CheckIsInUse, {
        isPlaylist,
        counterSelectedPage,
        itemTitle: getTitlePage(),
      }),
    );
  };

  const openAddTagToItem = () => {
    if (hasShared) return;
    if (!isAddTagMenuVisible) {
      dispatch(actionOpenModal(openModalType.AddTagModal));
    }
  };

  useEffect(() => {
    if (!isAddTagMenuVisible) return;
    dispatch(
      actionCreator(SupportAction.MultiSelectActiveR, {
        isMultiSelectActive: isAddTagMenuVisible,
      }),
    );
    return () => {
      dispatch(
        actionCreator(SupportAction.MultiSelectActiveR, {
          isMultiSelectActive: null,
        }),
      );
    };
  }, [isAddTagMenuVisible]);

  useEffect(() => {
    if (isLibrary) {
      const mergedLibraryItems = contentData;
      setIsAllPagesInFavorite(
        Object.keys(selectedPage).every((i) => {
          if (typeof selectedPage[i] === 'boolean') return mergedLibraryItems[i]?.isFavorite;
          return Object.keys(selectedPage[i]).every(
            (b) => mergedLibraryItems[i]?.nestedPage[b]?.isFavorite,
          );
        }),
      );

      const hasNotAddedItem = Object.keys(selectedPage).every(
        (i) => mergedLibraryItems[i]?.type !== 'component'
          && mergedLibraryItems[i]?.type !== 'sharedPage',
      );
      setDisableCreatePlaylist(!hasNotAddedItem);
      if (!isPlaylist) {
        setCanAllBeShared(
          Object.keys(selectedPage).every(
            (i) => mergedLibraryItems[i]?.type === 'page',
          ),
        );
      }
      setHasShared(
        Object.keys(selectedPage).some(
          (i) => mergedLibraryItems[i]?.type === 'sharedPage',
        ),
      );
    }
    if (isPlaylist) {
      setHasShared(
        Object.keys(selectedPage).some(
          (i) => contentData[i]?.status === 'shared',
        ),
      );
      setIsAllPagesInFavorite(
        Object.keys(selectedPage).every((i) => {
          return contentData[i]?.isFavorite;
        }),
      );
    }
  }, [selectedPage, contentData]);

  return (
    <div
      data-parent={dataParent}
      className={`${styles.multiSelectPanel} 
        ${isLibrary && styles.libraryPanel} 
        ${isPlaylist && styles.playlistPanel}
               ${isMaker && styles.makerPanel}
        ${isShareOptionVisible && styles.withSharedOption}
        ${openModal && styles.hide_me_plx}
    `}
    >
      <div
        data-parent={dataParent}
        className={`${styles.multiSelectPanel_item} 
        ${styles.multiSelectPanel_item_selected} 
        ${isLibrary ? styles.library_selected : ''}`}
      >
        <p className={`${styles.multiSelectPanel_item_selected_text}`}>
          {`${counterSelectedPage} selected ${pluralize(
            'item',
            counterSelectedPage,
          )} `}
        </p>
      </div>
      <div className={`${styles.controls_wrapper}`}>
        {isMaker && (
          <>
            <div
              className={`${styles.multiSelectPanel_right_border} 
        ${isPlaylist ? styles.multiSelectPanel_left_border : ''}`}
            >
              <div
                data-parent={dataParent}
                onClick={addToCurrentPlaylist}
                className={`${styles.multiSelectPanel_item} 
              ${styles.multiSelectPanel_item_favorite} 
              ${
            isAllPagesInFavorite
              ? styles.multiSelectPanel_item_favorite_active
              : ''
          }
              ${isLibrary ? styles.library_favorite : ''}
              ${
            isLibrary && !!Object.values(selectedPage || {}).length
              ? styles.active
              : ''
          }
            `}
              >
                <div data-parent={dataParent} className={`${styles.image_wrapper}`}>
                  <ToCurrentPlaylistSVG
                    className={`${styles.multiSel} ${styles.favorite}`}
                    data-parent={dataParent}
                  />
                </div>
                {/* <p className={`${styles.multiSelectPanel_item_text}`}>Favorite</p> */}
              </div>
            </div>
            <div className={`${styles.makerDivider}`} />
          </>
        )}
        {!isLibrary && !isPlaylist && (
          <div className={styles.multiSelectPanel_right_border}>
            <div
              data-parent={dataParent}
              className={`${styles.multiSelectPanel_item} ${styles.multiSelectPanel_item_playlist} 
          ${styles.multiSelectPanel__item_disable}`}
            >
              <div
                data-parent={dataParent}
                className={`${styles.image_wrapper} ${styles.svg_inactive}`}
              >
                <ArrowMergeSvg className={styles.multiSel} />
              </div>
              <p
                data-parent={dataParent}
                className={`${styles.multiSelectPanel_item_text} 
          ${styles.multiSelectPanel__inactive} 
          ${styles.multiSelectPanel__item_disable}`}
              >
                {t.smartFileT}
              </p>
            </div>
          </div>
        )}
        {isLibrary && (
          <div
            data-parent={dataParent}
            onClick={
              disableCreatePlaylist ? emptyCallback : () => setShowAdd(!showAdd)
            }
            className={`${styles.multiSelectPanel_item} 
          ${styles.multiSelectPanel_item_playlist} 
          ${showAdd ? styles.multiSelectPanel_item_active : ''}
          ${disableCreatePlaylist ? styles.multiSelectPanel__item_disable : ''}
          ${isLibrary ? styles.library_trash : ''}`}
          >
            <div data-parent={dataParent} className={`${styles.image_wrapper}`}>
              <PlaylistSVG
                className={`${styles.multiSel}`}
                data-parent={dataParent}
              />
            </div>
            {/* <p className={`${styles.multiSelectPanel_item_text}`}>Delete</p> */}
            {showAdd && (
              <PlaylistSelector
                isMultiselect
                createPlaylist={createPlaylist}
                pageID="props.item?.id"
                lcID="props.item?.libraryComponent?.id"
                isWholePlaylistOperation={false}
                refMenu={refMenu}
                setIsDropDownVisible={setShowAdd}
              />
            )}
          </div>
        )}
        <div
          className={`${styles.multiSelectPanel_right_border} 
        ${isPlaylist ? styles.multiSelectPanel_left_border : ''}`}
        >
          <div
            data-parent={dataParent}
            onClick={addToFavorite}
            className={`${styles.multiSelectPanel_item} 
              ${styles.multiSelectPanel_item_favorite} 
              ${
                isAllPagesInFavorite
                  ? styles.multiSelectPanel_item_favorite_active
                  : ''
              }
              ${isLibrary ? styles.library_favorite : ''}
              ${
                isLibrary && !!Object.values(selectedPage || {}).length
                  ? styles.active
                  : ''
              }
            `}
          >
            <div data-parent={dataParent} className={`${styles.image_wrapper}`}>
              <FavoriteSvg
                className={`${styles.multiSel} ${styles.favorite}`}
                data-parent={dataParent}
              />
            </div>
            {/* <p className={`${styles.multiSelectPanel_item_text}`}>Favorite</p> */}
          </div>
        </div>

        {isAddTagOptionVisible && (
          <div
            className={`${styles.multiSelectPanel_right_border} ${styles.multiSelectPanel_left_border}`}
            ref={refAddTagMenu}
          >
            <div
              data-parent={dataParent}
              className={`${styles.multiSelectPanel_item} ${
                styles.multiSelectPanel_item_add_tag
              }
                ${isAddTagMenuVisible && styles.multiSelectPanel_active}
                ${hasShared ? styles.multiSelectPanel__item_disable : ''}
                ${isLibrary ? styles.library_move_collection : ''} 
              `}
              onClick={openAddTagToItem}
            >
              <div data-parent={dataParent} className={`${styles.image_wrapper}`}>
                <Tag16Svg className={styles.multiSel} data-parent={dataParent} />
              </div>
            </div>
          </div>
        )}
        <div
          className={`${styles.multiSelectPanel_right_border}`}
          onClick={enableShare}
        >
          <div
            data-parent={dataParent}
            className={`${styles.multiSelectPanel_item} 
            ${hasShared || !canAllBeShared ? styles.multiSelectPanel__item_disable : ''}
            ${styles.multiSelectPanel_item_share} 
          `}
          >
            <div
              data-parent={dataParent}
              className={`${styles.image_wrapper} ${styles.share_icon}`}
            >
              <Share21Svg className={styles.multiSel} />
            </div>
          </div>
        </div>
        <div
          data-parent={dataParent}
          onClick={moveToTrash}
          className={`${styles.multiSelectPanel_item} 
          ${styles.multiSelectPanel_item_trash} 
          ${isLibrary ? styles.library_trash : ''}`}
        >
          <div data-parent={dataParent} className={`${styles.image_wrapper}`}>
            <Trash16Svg
              className={`${styles.multiSel} ${styles.move_trash}`}
              data-parent={dataParent}
            />
          </div>
          {/* <p className={`${styles.multiSelectPanel_item_text}`}>Delete</p> */}
        </div>
      </div>
    </div>
  );
};

export default MultiSelectPanel;
