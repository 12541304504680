import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components/macro';
import { DEFAULT_TITLE, stopPropagation } from '../../../utils/constants';
import { getFileName } from '../../../utils/helpers';
import { useLocalization } from '../../../LocalizationContext';

const InputShell = styled.div`
  display: flex;
  align-items: top;
  transition:height, ease-out 0.25s;
  ${({ isExpandedView }) => (isExpandedView ? 'height: 23px;' : 'height: 63px;')}
  ${({ isCompressView }) => (isCompressView ? 'height: 23px;' : '')}
  ${({ isExpandedView, isCompressView }) => (isExpandedView || isCompressView ? '' : 'margin-bottom: 8px;')}
`;

const Input = styled.textarea`
  background: transparent;
  border: none;
  border-radius: 6px;
  text-overflow: ellipsis;
  resize: none;
  width: 335px;
  max-height: 44px;
  font-family: "RobotoFlex";
  font-style: normal;
  font-weight: 500;
  ${({ isCompressView }) => (isCompressView ? 'font-size: 14px;' : 'font-size: 16px;')}
  line-height: 20px;
  /* or 125% */
  display:none;
  color: #484343;

  &::placeholder,
  &::-webkit-input-placeholder,
  &:-moz-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder {
    color: #a3aacc;
  }

  overflow: hidden;
  ${({ isExpandedView }) => (isExpandedView ? 'height: 15px;' : 'height: 27px;')}
  ${({ isExpandedView }) => (isExpandedView ? '  width: 372px;' : '  width: 335px;')}
 
  :focus{
    ${({ isDisabled }) => (isDisabled
    ? ''
    : `border-radius: 8px;
       border: 1px solid #ffc72c;
       background: #ffffff;`)}
  }
`;

const FakeDiv = styled.div`
  border: none;
  width: 100%;
  height: 54px;
  font-family: "RobotoFlex";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  display:block;
  ${({ isExpandedView }) => (!isExpandedView
    ? ''
    : 'width: 372px;')}
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const TitleTextAreaComponent = ({
  title,
  renameCB,
  show,
  setShow,
  placeholder,
  isDisabled,
  isCompressView,
  ...props
}) => {
  const { t } = useLocalization();
  const [initialTitle, setInitialTitle] = useState(title);
  const [tmpTitle, setTmpTitle] = useState(title);
  const titleRef = useRef(null);
  const fakeTitleRef = useRef(null);
  useEffect(() => {
    setTmpTitle(title);
  }, [title]);

  useEffect(() => {
    if (show) {
      setTmpTitle(title);
      setInitialTitle(title);
    }
  }, [show]);

  useEffect(() => {
    if (props.renameMode) {
      titleRef?.current.focus();
    }
  }, [props.renameMode]);

  const calcHeight = (e) => {
    const isDecrementRow = titleRef.current.scrollHeight - parseInt(titleRef.current.style.height) < 0;
    if (isDecrementRow) {
      const minHeight = 27;
      const newHeight = Math.max((parseInt(titleRef.current.style.height) - 15), minHeight);
      titleRef.current.style.height = `${newHeight}px`;
    } else {
      titleRef.current.style.height = `${titleRef.current.scrollHeight}px`;
    }
    e.stopPropagation();
  };

  const calcThreeDotsNeed = useCallback(() => {
    const multilineSplit = tmpTitle.split('\n');
    if (multilineSplit.length > 1) {
      return `${multilineSplit[0].slice(0, 41)}...`;
    }
    return tmpTitle;
  }, [tmpTitle]);

  const onChangeTitle = (e) => {
    if (isDisabled) return;
    if (!e.currentTarget.value && tmpTitle === getFileName(initialTitle)) setTmpTitle('');
    setTmpTitle(e.currentTarget.value);
    // setWidth(onChanger());
    calcHeight(e);
    e.preventDefault();
  };

  const handleBlur = () => {
    setTmpTitle(`${tmpTitle || t.defaultuntitledElementT}`);
    renameCB(`${tmpTitle || t.defaultuntitledElementT}`);
    props.setRenameMode(false);
    setShow(false);
    fakeTitleRef.current.style.display = 'block';
    titleRef.current.style.display = 'none';
    titleRef.current.style.height = '27px';
  };

  const handleReplaceWithTextArea = (e) => {
    e.stopPropagation();
    const textarea = titleRef?.current;
    if (textarea) {
      textarea.style.display = 'block';
      textarea.focus();
      textarea.setSelectionRange(textarea.value.length, textarea.value.length);
      calcHeight(e);
      fakeTitleRef.current.style.display = 'none';
    }
  };

  const onKeyPressTitle = (e) => {
    if (isDisabled) return;
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      setTmpTitle(`${tmpTitle || t.defaultuntitledElementT}`);
      renameCB(`${tmpTitle || t.defaultuntitledElementT}`);
      setShow(false);
      titleRef?.current.blur();
    }
    if (e.key === 'Escape') {
      titleRef?.current.blur();
      e.stopPropagation();
      e.preventDefault();
      setShow('close');
      setTmpTitle(title || t.defaultuntitledElementT);
    }
  };

  return (
    <InputShell isCompressView={isCompressView} isExpandedView={props.isExpandedView}>
      <Input
        onContextMenu={stopPropagation}
        isDisabled={isDisabled}
        isExpandedView={props.isExpandedView}
        cols={22}
        maxLength={100}
        placeholder={placeholder}
        value={tmpTitle}
        ref={titleRef}
        onChange={onChangeTitle}
        onClick={calcHeight}
        onKeyDown={onKeyPressTitle}
        onBlur={handleBlur}
      />
      <FakeDiv
        ref={fakeTitleRef}
        isExpandedView={props.isExpandedView}
        onClick={handleReplaceWithTextArea}
      >
        {calcThreeDotsNeed()}
      </FakeDiv>
    </InputShell>
  );
};
