import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.scss';
import {
  actionClearCurrentPage,
  actionGetCurrentPage,
} from '../../redux/currentPage/action';
import SharedComponent from '../../components/SharedComponent';
import NoAvailable from '../AccessPage/NoAvailable';
import { actionCreator } from '../../shared/redux/actionHelper';
import EnterAccessCode from '../AccessPage/EnterAccessCode';
import WrongUser from '../AccessPage/WrongUser';
import SharedHeader from './SharedHeader';
import { SUBSCRIBE_SOCKET } from '../../sagas/SocketClusterHelper';
import { sendMessage } from '../../sagas/SocketCluster/action';
import { CurrentPage } from '../../redux/currentPage/types';
import { setServiceWorkerLocation } from '../../utils/registerServiceWorker';
import { useLocalization } from '../../LocalizationContext';

const PublishPage = ({ isShared, isShowByWeb }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useLocalization();

  const titlePage = useSelector(state => state.currentPage.title);
  const description = useSelector(state => state.currentPage.description);
  const isDownload = useSelector(state => state.currentPage.isDownload);
  const editableBlocks = useSelector(state => state.currentPage.blocks) || [];
  const { notAvailable, shareState, owner, socketId } = useSelector(
    state => state.currentPage,
  );
  const getPageData = (accessCode) => {
    if (isShared || isShowByWeb) {
      dispatch(
        actionCreator(CurrentPage.Share.GET_CURRENT_SHARED_PAGE, {
          id,
          history,
          accessCode,
          isShowByWeb,
        }),
      );
    } else dispatch(actionGetCurrentPage(id, true, history, true));
  };
  useEffect(() => {
    setServiceWorkerLocation(id);
    getPageData();
    return () => {
      dispatch(actionClearCurrentPage());
    };
  }, [id]);

  useEffect(() => {
    if (socketId && isShared) {
      dispatch(
        actionCreator(SUBSCRIBE_SOCKET, {
          channelName: 'channelName',
          id: socketId,
          dispatch,
          history,
        }),
      );
    }
    return () => {
      dispatch(
        sendMessage({
          dataSend: {
            unsubscribeChannel: 'unsubscribeChannel',
          },
          unsubscribeChannel: socketId,
        }),
      );
    };
  }, [socketId]);

  if (isDownload) {
    return (
      <div className="loader-wrapper-page">
        <div className="loader">{t.loadingT}</div>
      </div>
    );
  }

  if (notAvailable) {
    return (
      <NoAvailable shareState={shareState} getPageData={getPageData} id={id} />
    );
  }

  if (shareState.needAccessCode) {
    return (
      <EnterAccessCode
        shareState={shareState}
        getPageData={getPageData}
        id={id}
      />
    );
  }
  if (shareState.wrongUser) return <WrongUser wrongUser path={history.location.pathname} />;
  if (shareState.needLogin) return <WrongUser needLogin path={history.location.pathname} />;

  return (
    <>
      <SharedHeader type="sharedPage" owner={owner} />
      <div className={styles.main}>
        <div className={styles.info}>
          <div className={styles.input_title}>{titlePage} </div>
          <div className={styles.input_description}>{description} </div>
        </div>

        <div className={styles.space_separator} />
        <div className={styles.content}>
          {editableBlocks.map((item, index) => {
            if (item.isHidden) return <div key={item.id} />;
            if (!item.components) {
              return (
                <SharedComponent
                  key={item.id}
                  type1={item.type}
                  state={item.state}
                  componentId={item.id}
                  index={index}
                  width={item.width}
                  urlFile={item.urlFile}
                  page={item}
                  innerHtml={item.innerHtml}
                  isSharePage
                />
              );
            }
            return item.components.map((component, index) => {
              if (component.isHidden) return <div key={component.id} />;
              return (
                <SharedComponent
                  key={id}
                  type1={component.type}
                  state={component.state}
                  componentId={id}
                  index={index}
                  width={component.width}
                  page={component}
                  innerHtml={component.innerHtml}
                  isSharePage
                />
              );
            });
          })}
        </div>
      </div>
    </>
  );
};

export default PublishPage;
