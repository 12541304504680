import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import classNames from 'classnames/bind';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import rawStyles from './index.module.scss';
import { sortedFunctions } from '../../utils/dateConvert';
import { ChannelPagesSchema } from '../../utils/tableHelpers/tableUtils';
import HelpHeader from './HelpHeader';
import { empty, MessageType, TutorialsSieveTypes, emptyCallback } from '../../utils/constants';
import {
  actionPageWillDownload,
  actionToggleIsCardView,
} from '../../redux/currentPage/action';
import { actionCreator } from '../../shared/redux/actionHelper';
import { Channels } from '../../redux/channels/types';
import { ReactComponent as Help65Svg } from '../../images/icons/help_icon_65_61.svg';
import HelpContentSelector from './HelpContentSelector';
import EditPlaylist from '../../redux/playlists/types';
import { actionShowMessage } from '../../redux/support/action';
import { useLocalization } from '../../LocalizationContext';

const cn = classNames.bind(rawStyles);


const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  height: calc(100vh - 124px);
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f6ff;
  }

  &::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 8px;
    background-color: #e2e1e1;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-track-piece {
    display: none;
    background-color: #f5f6ff;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
`;

const Help = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useLocalization();
  const playlists = useSelector((state) => state.help.playlists || empty);
  const { isDownload } = useSelector((state) => state.help);
  const isCardView = useSelector((state) => state.currentPage.isCardView);
  const isDescriptionOpen = useSelector(
    (state) => state.support?.componentDescription,
  );
  const { selectorType, folderId, isAdmin, id } = useSelector(
    (state) => state.user,
  );
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('');
  const [isShowFilter, setIsShowFilter] = useState(false);
  const scroller = useRef(null);
  const [needToDelete] = useState(false);
  const [items, setItems] = useState({});
  const [sortType, setSort] = useState(Object.keys(sortedFunctions)[2]); // ?
  const refSelectedBlocks = { current: [] };
  const setIsCardView = () => {
    dispatch(actionToggleIsCardView());
  };
  useEffect(() => {
    dispatch(actionCreator(Channels.GetHelpChannel, sortType));
    const exPlaylists = Object.values(playlists);
    setItems({ [TutorialsSieveTypes.Tutorials]: exPlaylists });
  }, [sortType]);

  useEffect(() => {
    const exPlaylists = Object.values(playlists);
    setItems({ [TutorialsSieveTypes.Tutorials]: exPlaylists });
    if (loading) setLoading(false);
  }, [playlists]);

  useEffect(() => {
    if (!filter) setIsShowFilter(false);
  }, [selectorType]);

  const createPlaylist = () => {
    const idPlaylist = uuidv4();
    const payload = {
      userId: id,
      idPlaylist,
      goToPlaylists: () => history.push(`/maker/${idPlaylist}/edit`, { backUrl: '/playlists' }),
    };
    if (isAdmin) {
      payload.addToChannel = 'help';
    }
    dispatch({ type: EditPlaylist.Create, payload });
    dispatch(actionShowMessage({ type: MessageType.PlaylistCreated }));
    dispatch(actionPageWillDownload());
  };

  if (isDownload) {
    return (
      <div className="loader-wrapper-page">
        <div className="loader">{t.loadingT}</div>
      </div>
    );
  }

  return (
    <div className={cn('wrapper')}>
      <div ref={scroller} className={cn('wrapper2')}>
        <HelpHeader
          filter={filter}
          isFavoriteEmpty
          createPage={createPlaylist}
          refShowMenuSort={null}
          setShowMenuSort={() => {}}
          isShowMenuSort={false}
          sortType={sortType}
          setSort={setSort}
          setIsCardsView={setIsCardView}
          selectorType={selectorType}
          setFilter={setFilter}
          setIsShowFilter={setIsShowFilter}
          isShowFilter={isShowFilter}
          folderId={folderId}
          isCardsView
          totalFavorites={0}
          activeNavSlider={TutorialsSieveTypes.Tutorials}
          sieveOptions={TutorialsSieveTypes}
          items={items}
        />
        {items[TutorialsSieveTypes.Tutorials]?.length > 0 ? (
          <div
            className={cn('cards_content', {
              rows_content:
                !isCardView && !!items[TutorialsSieveTypes.Tutorials]?.length,
            })}
          >
            <InfiniteScroll
              pageStart={0}
              loadMore={emptyCallback}
              useWindow={false}
              hasMore={false}
              loader={<div key={0}>{t.loadingT}</div>}
              getScrollParent={() => scroller.current}
            >
              <ContentWrapper>
                <HelpContentSelector
                  sortedPages={items[TutorialsSieveTypes.Tutorials] || []}
                  isCardsView={isCardView}
                  setSimplifiedMultiselect={() => {}}
                  isSimplifiedMultiselect={false}
                  counterSelectedPage={0}
                  needToDelete={needToDelete}
                  selectorType={selectorType}
                  setSort={setSort}
                  sortType={sortType}
                  isFilter={!!filter}
                  isFavoriteEmpty
                  filter={filter}
                  totalFavorites={0}
                  displayedPages={Object.values(playlists)}
                  refSelectedBlocks={refSelectedBlocks}
                  createPage={createPlaylist}
                  folderId={folderId}
                  loading={loading}
                  tableSchema={ChannelPagesSchema}
                  isChannelMiniPlaylist
                  isDescriptionOpen={isDescriptionOpen}
                />
              </ContentWrapper>
            </InfiniteScroll>
          </div>
        ) : (
          <div className={cn('empty')}>
            <Help65Svg className={cn('empty_icon')} />
            <div className={cn('empty_mainText')}>{t.noTutorialsT}</div>
            <div className={cn('empty_infoText')}>{t.emptyTutorialSmartFilesMessageT}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Help;
