import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { useHistory, useParams } from 'react-router-dom';
import { actionMarkAllLinkPagesAsIncomplete,
} from '../../redux/currentPage/action';
import { PlaylistLastFrameShell,
} from './styled';
import ProgressBar from '../../components/ViewersProgressBars';
import { progressBarType } from '../../components/ViewersProgressBars/enum';
import useViewersProgressMaker from '../../utils/hooks/useViewersProgressMaker';
import { ReactComponent as ResetSVG } from '../../images/icons/player/reset_last_item.svg';
import { ReactComponent as CloseSVG } from '../../images/icons/player/close_last_item.svg';
import BgPNG from '../../images/png/defaultImagePlaylist_v2.png';
import { actionUpdatePlaylistReadStatus } from '../../redux/playlists/action';
import Tooltip from '../../shared/Tooltips/Tooltip';
import { traceStackPush } from '../../utils/helpers';
import { useLocalization } from '../../LocalizationContext';

export const Test = styled.div`
  width: 384px;
  height: 266px;
  border-radius: 36px;
  background: #343434a6;
  color: #FFF;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-top: 27px;
  padding-bottom: 35px;
  position: relative;
`;
export const BackGroundImage = styled.img`
  height: 100vh;
  position: absolute;
  margin: auto;
`;
export const TestFirst = styled.div`
font-size: 32px;
  ${({ hard }) => (hard ? 'font-weight: 500;' : ' ')}
`;
export const Mark = styled.div`
  width: 298px;
  height: 49px;
  border-radius: 12px;
  background-color: #343434db;
  font-size: 24px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor:pointer;
  letter-spacing: 1.2px;
  :hover{
    color: #FFC72C;
  }
`;
export const Option = styled.div`
  position: absolute;
  right: -110px;

  ${({ isLast }) => (isLast ? `
    top: 149px;


  ` : '  top: 56px;')}
 
  }
`;
export const OptionSvgWrap = styled.div`
  background-color: #343434db;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  cursor: pointer;

  :hover{
    svg{
      path{
        stroke:  #FFC72C;
      }
    }
  }
`;

const PlaylistLastFrame = ({ closePlayer, setIsEnd, setPrevItemNumber }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useLocalization();

  const isForSharedToWeb = history.location.pathname.includes('shared_playlist');
  const { id: playlistId, channelId } = useParams();

  const currentPage = useSelector((state) => state.currentPage);
  const user = useSelector((state) => state.user);
  const viewersProgress = useViewersProgressMaker();
  const isOwner = user.id && (user.id === currentPage.owner?.id);
  const toggleCompletion = () => {
    dispatch(actionUpdatePlaylistReadStatus(currentPage.isFinished, currentPage.wrapperId));
  };

  const restart = () => {
    dispatch(actionMarkAllLinkPagesAsIncomplete(currentPage.wrapperId));
    setIsEnd(false);
    setPrevItemNumber(0);
    traceStackPush(
      isForSharedToWeb
        ? `/shared_player/shared/${playlistId}/1`
        : `/player/${channelId}/${playlistId}/1`,
      history,
      { type: 'page' },
      dispatch,
    );
  };
  return (
    <>
      <BackGroundImage src={currentPage.cropUrl || BgPNG} />

      <PlaylistLastFrameShell>
        <Test>
          {isOwner && (
            <>
              <TestFirst>{t.youAreAtTheEndT}</TestFirst>
              <TestFirst>{t.ofYourSmartFileT} </TestFirst>
            </>
          ) }
          {(!isOwner && currentPage.isFinished) && (
            <>
              <TestFirst>{t.thisSmartFileIsNowT} </TestFirst>
              <TestFirst hard>{t.completedMarkLowT} </TestFirst>
            </>
          ) }
          {(!isOwner && !currentPage.isFinished) && (
            <>
              <TestFirst>{t.YouHaveReadT} </TestFirst>
              <TestFirst>{viewersProgress.viewed}/{viewersProgress.total} {t.itemsLowT} </TestFirst>
            </>
          )}
          {!isOwner && (
          <ProgressBar
            type={progressBarType.player}
            isFinished={currentPage.isFinished}
            wrapperId={currentPage.wrapperId}
            viewersProgress={viewersProgress}
          />
          )}
          {(!isOwner && !currentPage.isFinished) && (
            <Tooltip
              text={t.markAsReadT}
              direction="up"
              place="PlaylistLastFrameConfirm"
            >
              <Mark onClick={toggleCompletion}>{t.markAsCompletedUpT}</Mark>
            </Tooltip>

          )}
          {(isOwner) && (

          <Mark onClick={closePlayer}>{t.GoToYourEditorT}</Mark>
          )}
          <Option onClick={closePlayer}>
            <Tooltip
              text={t.exitT}
              direction="left"
              place="PlaylistLastFrameClose"
            >
              <OptionSvgWrap>
                <CloseSVG />
              </OptionSvgWrap>
            </Tooltip>
          </Option>
          <Option onClick={restart} isLast>

            <Tooltip
              text={t.replayT}
              direction="left"
              place="PlaylistLastFrameReset"
            >
              <OptionSvgWrap>
                <ResetSVG />
              </OptionSvgWrap>
            </Tooltip>
          </Option>

        </Test>

        {/* <img src="" /> */}
        {/* <BgPNG /> */}

      </PlaylistLastFrameShell>
    </>
  );
};

export default PlaylistLastFrame;
