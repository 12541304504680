import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import rawStyles from '../../index.module.scss';
import ProfileInputWrapper from './ProfileInputWrapper';
import FormInformer from '../../FormInformer';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import ProfilePhoneInputWrapper from './ProfilePhoneInputWrapper';
import UserAvatarInputWrapper from '../UserAvatarInputWrapper';
import { GreyButton } from '../../CommonComponents/Buttons';
import { DB_USER_PROFILE_FIELDS } from '../../../../utils/SettingsHelpers/constants';
import { Settings } from '../../../../redux/settings/types';
import { Column, Line, SectionShell, HeaderTitle } from '../../CommonComponents/Layout';
import { actionCloseModal } from '../../../../redux/user/action';
import { useLocalization } from '../../../../LocalizationContext';

const cn = classNames.bind(rawStyles);

const Profile = () => {
  const dispatch = useDispatch();
  const { inputs } = useSelector((state) => state.settings);
  const [isFormValid, setFormValid] = useState(
    inputs.firstName.errors.length === 0
    && inputs.lastName.errors.length === 0
    && inputs.email.errors.length === 0);
  const [isFormTouched, setFormTouched] = useState(false);
  const { t } = useLocalization();

  const onFormSubmit = () => {
    dispatch(actionCreator(Settings.SendForm, { formName: 'infos' }));
  };

  const onResetClick = () => {
    dispatch(actionCreator(Settings.ResetForm, { formName: 'infos' }));
    dispatch(actionCloseModal());
  };

  useEffect(() => {
    setFormValid(inputs.firstName.errors.length === 0
      && inputs.lastName.errors.length === 0
      && inputs.email.errors.length === 0);
  }, [inputs.firstName.errors, inputs.lastName.errors, inputs.email.errors]);

  return (
    <SectionShell paddingForSetting>
      <HeaderTitle marginBottom32>{t.infosT}</HeaderTitle>
      <Line Info>
        <Column Info>
          <UserAvatarInputWrapper setFormTouched={setFormTouched} />
        </Column>
      </Line>
      <Line Info>
        <Column Info Double>
          <ProfileInputWrapper
            isRequired
            inputType={DB_USER_PROFILE_FIELDS.firstName}
            setFormTouched={setFormTouched}
            placeholder={t.firstNameTextT}
          />
          <ProfileInputWrapper
            isRequired
            inputType={DB_USER_PROFILE_FIELDS.lastName}
            setFormTouched={setFormTouched}
            placeholder={t.lastNameT}
          />
        </Column>
      </Line>
      <Line Info>
        <Column Info>
          <ProfileInputWrapper
            inputType={DB_USER_PROFILE_FIELDS.username}
            setFormTouched={setFormTouched}
            placeholder={t.usernameT}
          />
        </Column>
      </Line>
      <Line Info>
        <Column Info>
          <ProfileInputWrapper
            isRequired
            inputType={DB_USER_PROFILE_FIELDS.email}
            setFormTouched={setFormTouched}
            placeholder="email@example.com"
            disabled
          />
        </Column>
      </Line>
      <Line Info>
        <Column Info>
          <ProfilePhoneInputWrapper inputType={DB_USER_PROFILE_FIELDS.phone} setFormTouched={setFormTouched} />
        </Column>
      </Line>
      <Line Info>
        <Column Info>
          <ProfileInputWrapper
            inputType={DB_USER_PROFILE_FIELDS.company}
            setFormTouched={setFormTouched}
            placeholder={t.companyT}
          />
        </Column>
      </Line>
      <Line Info>
        <Column Info>
          <ProfileInputWrapper
            inputType={DB_USER_PROFILE_FIELDS.infoRole}
            setFormTouched={setFormTouched}
            placeholder={t.professionT}
          />
        </Column>
      </Line>
      <Line Info>
        <Column Info>
          <ProfileInputWrapper
            isTextarea
            inputType={DB_USER_PROFILE_FIELDS.bio}
            setFormTouched={setFormTouched}
            placeholder={t.writeAboutYouT}
          />
        </Column>
      </Line>
      <Line Info formButtons>
        <div className={cn('button_group')}>
          <GreyButton onClick={onResetClick} text={t.closeT} secondaryGrey />
          <GreyButton
            text={t.updateProfileT}
            onClick={onFormSubmit}
            primaryGrey
            disabled={!isFormValid || !isFormTouched}
          />
        </div>
        <FormInformer formName="infos" />
      </Line>
    </SectionShell>
  );
};

export default Profile;
