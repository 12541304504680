import React, { useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { ReactComponent as HelpIcon } from '../../images/icons/help_Icon.svg';
import { ReactComponent as ContactIcon } from '../../images/icons/contacts.svg';
import { ReactComponent as WhitePlusIcon } from '../../images/icons/icon_16/plus_16.svg';
import Trash from '../LeftSideBar/Trash';
import { actionCreator } from '../../shared/redux/actionHelper';
import { actionCreateUpload } from '../../redux/filesUpload/action';
import { MainNavigate, ExtraTabs } from '../../navigate/navigate';
import { actionRemoveAllSelected } from '../../redux/selectedPage/action';
import Logo from '../MainHeader/Logo';
import {
  Bottom,
  ImageWrap,
  MainLeftSideBarShell,
  SelectorBoard,
  TrashWrapper,
  NewSFButton,
} from './styled';
import { CurrentPage } from '../../redux/currentPage/types';
import { createNewPlaylistAndJumpIntoIt } from '../../utils/helpers';
import { sortOptionChooser } from '../../utils/sort/sortTypeChooser';
import { actionSwitchContentSortType } from '../../redux/content/actions';
import WorkflowSection from './sidebar-sections/WorkflowSection';
import SmartfilesSection from './sidebar-sections/SmartfilesSection';
import ContactsSection from './sidebar-sections/ContactsSection';
import ChannelsSection from './sidebar-sections/ChannelsSection';
import LibrarySection from './sidebar-sections/LibrarySection';
import { useLocalization } from '../../LocalizationContext';

const MainLeftSideBar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useLocalization();

  const addFileInputRef = useRef();
  const {
    selectorType,
  } = useSelector((state) => state.content);
  const sortOptions = useSelector((state) => state.settings.sortOptions);
  const {
    tags,
    folderId,
    isAdmin,
  } = useSelector((state) => state.user);

  const createNewPlaylist = useCallback(() => {
    createNewPlaylistAndJumpIntoIt({ history, isAdmin, dispatch });
  }, [isAdmin]);


  const submitForm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    [...e.target.files].forEach((item) => {
      const localTags = [];
      if (selectorType === 'tag' && folderId) {
        localTags.push({ ...tags[folderId] });
      }

      dispatch(
        actionCreateUpload({
          newId: v4(),
          file: item,
          title: item.name,
          type: item.type,
          history,
          isFavorite: selectorType === 'favorites',
          tags: localTags,
        }),
      );
    });
    addFileInputRef.current.value = '';
  };

  const onSelectorClick = (key) => {
    if (ExtraTabs[key]?.disabled) {
      return;
    }
    dispatch(actionRemoveAllSelected());
    dispatch(actionCreator(CurrentPage.clearId, {}));
    const firstSelector = ExtraTabs[key] ? ExtraTabs[key]?.selectorsOrder[0] : MainNavigate[key]?.selectorsOrder[0];
    const sortName = sortOptionChooser(key, firstSelector);
    if (sortName && sortOptions[sortName]) {
      dispatch(actionSwitchContentSortType(sortOptions[sortName]));
    }
    if (ExtraTabs[key]) {
      history.push(`/${key}/${firstSelector}`);
      return;
    }
    history.push(`/content/${key}/${firstSelector}`);
  };


  return (
    <MainLeftSideBarShell place="MainLeftSideBar">
      <Logo />
      <NewSFButton
        onClick={createNewPlaylist}
      >
        <div data-cy="createEntity"><WhitePlusIcon />{t.createUpT}</div>
      </NewSFButton>
      <SelectorBoard>
        <WorkflowSection
          onSelectorClick={onSelectorClick}
        />
        <SmartfilesSection
          createNewPlaylist={createNewPlaylist}
          sortOptions={sortOptions}
        />
        <LibrarySection
          onSelectorClick={onSelectorClick}
          addFileInputRef={addFileInputRef}
          tags={tags}
          folderId={folderId}
        />
        <ContactsSection />
        <ChannelsSection sortOptions={sortOptions} />
      </SelectorBoard>
      <Bottom>
        <ImageWrap
          data-cy="help"
          active={history.location.pathname === '/help'}
          onClick={() => history.push('/help')}
          place="help"
        >
          <HelpIcon />
        </ImageWrap>
        <ImageWrap
          data-cy="contacts"
          active={history.location.pathname === '/contacts'}
          onClick={() => history.push('/contacts')}
          place="contacts"
        >
          <ContactIcon />
        </ImageWrap>
        <TrashWrapper data-cy="trash">
          <Trash
            isPlaylist={history.location.pathname.includes('/smartfiles')}
          />
        </TrashWrapper>
      </Bottom>
      <input
        type="file"
        name="file"
        multiple
        ref={addFileInputRef}
        style={{ display: 'none' }}
        onChange={(e) => {
          submitForm(e);
        }}
      />
    </MainLeftSideBarShell>
  );
};

export default MainLeftSideBar;
