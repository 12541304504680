import React, { useRef } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as ThreeDotsSvg } from '../../../../images/2023/svg/small/3dots_13_2.svg';
import { ReactComponent as EyeOff } from '../../../../images/icons/eye_off.svg';
import { ReactComponent as EyeOn } from '../../../../images/icons/eye_on.svg';
import { ReactComponent as Favorite2Svg } from '../../../../images/2023/svg/small/favorite_16_16.svg';
import { actionShowMessage } from '../../../../redux/support/action';
import { DEFAULT_TITLE, empty, MessageType } from '../../../../utils/constants';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import { MiniPlaylists } from '../../../../redux/miniPlaylists/types';
import ThreeDotsDropDown from '../ThreeDotsDropDown';
import EditIcon from '../../../EditIcon';
import EditPlaylist from '../../../../redux/playlists/types';
import { useLocalization } from '../../../../LocalizationContext';

const SvgIcoWrapper = styled.div`
  visibility: hidden;
  position: relative;
  width: 22px;
  height: 22px;
  margin-right: 10px;
  svg {
    left: 2px;
    position: relative;
  }
  ${({ isFavourite }) => (isFavourite
    ? `
  svg{
   top:2px;
  }
  `
    : '')};

  ${({ isEye }) => (isEye
    ? `
  svg{
   top:3px;
  }
  `
    : '')};

  & svg path {
    stroke: #484343;
    fill: transparent;
  }

  ${({ is3Dots }) => (is3Dots
    ? `
    svg{
      top: -1px;
    }
  `
    : '')}

  ${({ active, isFavourite }) => (active && isFavourite
    ? `
   visibility: visible;
   background:none;
    & svg path{
        stroke:none;
        fill: rgba(255, 199, 44, 1);
    }
    &:hover {
         background: rgba(52, 52, 52, 1);
       & svg path{
        stroke:#FFC72C;
        fill:rgba(255, 199, 44, 0.6);
         }
    }
  `
    : '')}
  ${({ active, is3Dots }) => (active && is3Dots
    ? `
      border-radius: 4px;
      background:rgba(255, 199, 44, 1);
       & svg path{
        stroke:#343434;
       }
       &:hover {
         background:rgba(255, 199, 44, 1);
       }
  `
    : '')}
  &:hover {
    background: rgba(255, 199, 44, 0.4);
    border-radius: 4px;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  //width: 82px;
`;

const EditWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  visibility: hidden;
`;

const ActionsForTable = ({ item, refMenu, isFavorite, isDropDownVisible, isLib, setIsDropDownVisible, ...props }) => {
  const dispatch = useDispatch();
  const { t } = useLocalization();
  const isEditable = item?.type === 'page' || item?.type === 'component';
  const { folderId } = useSelector((state) => state.user) || empty;
  const currentPage = useSelector((state) => state.currentPage);
  const refPage = useRef(null);
  const isSharedToMe = item?.type === 'sharedPage';

  const toggleFavorite = (e) => {
    e.stopPropagation();
    setIsDropDownVisible(false);
    if (!item.isFavorite) {
      dispatch(
        actionShowMessage({
          type: MessageType.PageToFavorite,
          itemName: item?.title || t.defaultSmartPageTitleT,
        }),
      );
    }
    if (props.isFavorite && refPage.current) {
      refPage.current.className = `${refPage.current.className}${
        currentPage.isCardView ? 'to_delete' : 'to_delete_row'
      }`;
      setTimeout(() => {
        dispatch(
          actionCreator(EditPlaylist.ToggleFavorite, {
            id: item.id,
            isFavorite: !item.isFavorite,
            channelId: props.isChannel ? folderId : null,
            wrapperId: item.wrapperId,
          }),
        );
      }, 1000);
    } else {
      dispatch(
        actionCreator(EditPlaylist.ToggleFavorite, {
          id: item.id,
          isFavorite: !item.isFavorite,
          channelId: props.isChannel ? folderId : null,
          wrapperId: item.wrapperId,
        }),
      );
    }
  };

  const toggleShowThreeDots = (e) => {
    e.stopPropagation();
    setIsDropDownVisible(!isDropDownVisible);
  };

  const toggleShowHideHandler = (event) => {
    event.stopPropagation();
    dispatch(
      actionCreator(MiniPlaylists.ToggleHide, {
        id: item.wrapperId,
        playlistId: item.id,
        isHide: !item.isHide,
      }),
    );
  };

  return (
    <ActionsWrapper className="actions_fluent">
      {isEditable
        && (
          <EditWrapper className="actions_svg_wrapper">
            <EditIcon isComponent={item?.type === 'component'} type="ActionsWrapper" id={item.id} />
          </EditWrapper>
        )}
      <SvgIcoWrapper
        isFavourite
        active={item.isFavorite}
        onClick={
          item.isSelected
            ? (e) => e.stopPropagation()
            : (e) => toggleFavorite(e)
        }
        className={`actions_svg_wrapper actions_svg_wrapper-favorite  ${
          item.isFavorite ? 'shut_up_and_show_me' : ''
        }`}
      >
        {' '}
        <Favorite2Svg />
      </SvgIcoWrapper>
      <SvgIcoWrapper
        isEye
        onClick={
          item.isSelected
            ? (e) => e.stopPropagation()
            : (e) => toggleShowHideHandler(e)
        }
        className={
          item.isHide
            ? 'hide_opacity actions_svg_wrapper'
            : 'actions_svg_wrapper'
        }
      >
        {' '}
        {item.isHide ? <EyeOn /> : <EyeOff />}
      </SvgIcoWrapper>
      <SvgIcoWrapper
        className="actions_svg_wrapper"
        is3Dots
        onClick={
          item.isSelected
            ? (e) => e.stopPropagation()
            : (e) => toggleShowThreeDots(e)
        }
        active={isDropDownVisible}
      >
        {' '}
        <ThreeDotsSvg />
      </SvgIcoWrapper>

      {isDropDownVisible && (
        <ThreeDotsDropDown
          isSharedToMe={isSharedToMe}
          isTableView
          isLib={isLib}
          singleClickHandler={props.singleClickHandler}
          setIsDropDownVisible={setIsDropDownVisible}
          userRole={props.userRole}
          itemID={item.id}
          item={item}
          isCanBeOpen={!!item.contentCounter}
          itemType={item.type}
          status={item.status}
          refMenu={refMenu}
          toggleFavorite={toggleFavorite}
          show={isDropDownVisible}
          index={props.index}
          itemWrapperID={item.wrapperId}
          itemIsFavorite={item.isFavorite}
          sharedID={item.sharedID}
          inChannels={item.usedInChannels}
        />
      )}
    </ActionsWrapper>
  );
};

export default ActionsForTable;
