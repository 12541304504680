import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { actionCloseModal } from '../../redux/user/action';
import Button2023 from '../Buttons/Button2023';
import { actionUnPublishSPlaylistS } from '../../redux/playlists/action';
import { useLocalization } from '../../LocalizationContext';

const ModalWrapper = styled.div`
  position: fixed;
  background: rgba(147, 147, 147, 0.6);
  z-index: 104;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-overflow-style: none;
  font-style: normal;
  font-weight: 400;
`;

const GreyTitleWrapper = styled.div`
  position: relative;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #939393;
  letter-spacing: 0.05em;
  margin-bottom: 7px;
  text-transform: uppercase;
  font-family: "RobotoFlex", sans-serif;
  ${({ isSecond }) => (isSecond
    ? `
    margin-top: 4px;
    margin-bottom: 13px;
  `
    : '')}
  ${({ isThird }) => (isThird
    ? `
    margin-top: 15px;
    margin-bottom: -2px;
  `
    : '')}
`;

const CreatePlaylistFromMultiselectWrapper = styled.div`
  position: relative;
  width: 325px;
  height: 161px;
  background: #ffffff;
  border: 0.6px solid #ece2e2;
  box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.25);
  border-radius: 12px;
  padding: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #484343;
`;

const ButtonBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 16px;
  padding-left: 122px;
`;

const ItemTitleWrapper = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 8px;
`;

const UnpublishModal = () => {
  const dispatch = useDispatch();
  const { t } = useLocalization();
  const { dataPayload: { currentPageId, wrapperId } } = useSelector((state) => state.user);

  const createNewPlaylist = useCallback(() => {
    dispatch(actionUnPublishSPlaylistS(currentPageId, wrapperId));
    dispatch(actionCloseModal());
  }, []);

  const close = useCallback(() => {
    dispatch(actionCloseModal());
  }, []);

  return (
    <ModalWrapper>
      <CreatePlaylistFromMultiselectWrapper>
        <GreyTitleWrapper>{t.unshareUpT}</GreyTitleWrapper>
        <ItemTitleWrapper>{t.unshareThisSmartFileQuestionT}</ItemTitleWrapper>
        <div>
          {t.smartFileWillBeRemovedMessageT}
        </div>
        <ButtonBlockWrapper>
          <Button2023
            variant="primary"
            width={69}
            height={32}
            text={t.cancelUpT}
            noMargins
            handleButtonClick={close}
          />
          <Button2023
            variant="secondary"
            width={80}
            text={t.unshareUpT}
            noMargins
            handleButtonClick={createNewPlaylist}
          />
        </ButtonBlockWrapper>
      </CreatePlaylistFromMultiselectWrapper>
    </ModalWrapper>
  );
};

export default UnpublishModal;
