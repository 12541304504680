import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { empty, DATA_PARENTS, DEFAULT_TITLE } from '../../utils/constants';
import useThrottle from '../../utils/hooks/useThrottle';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import {
  actionSaveComponentDescriptionData,
} from '../../redux/support/action';
import {
  Type,
  TypeLabelComponent,
} from '../../utils/UIHelpers/ItemTypes/library';
import { Circle } from '../../utils/UIHelpers/PlaylistBuilderElements/libraryComponent';
import { Spinner } from '../MiniPlaylist/NewPlaylist/CardView/StyledComponents';

import {
  DisruptOpacityForUsedItemsWrapper,
  UsedBadgeWrapper,
} from './StyledComponents';
import CardViewComponent from './CardViewComponent';
import CardViewPage from './CardViewPage';
import styles from './index.module.css';
import { useLocalization } from '../../LocalizationContext';

let interval;

const CardView = React.memo(function CardView({
  isUPV,
  isExtended,
  itemUploadData,
  bigSizeCondition,
  setTitle,
  openRenameItem,
  item,
  isMenuActive,
  ...props
}) {
  const [cursorLoader, setCursorLoader] = useState(0);
  const [count, setCount] = useState(0);
  const { t } = useLocalization();

  const currentPage = useSelector(state => state.currentPage);
  const isShown = useSelector(state => state.support.componentDescription);
  const componentDescriptionId = useSelector(
    reduxState => reduxState.support.componentDescriptionData?.id || empty,
  );

  const isMetaAffected = !!componentDescriptionId && componentDescriptionId === item.id;

  const [usedRef, isUsedActive, setIsUsedActive] = useComponentVisible(false);

  const dispatch = useDispatch();

  const [throttleSaveValue] = useThrottle(1000);

  const loaderRef = useRef(null);

  useEffect(() => {
    if (!cursorLoader) {
      if (count) setCount(0);
      return;
    }

    if (isShown) {
      interval = setInterval(() => {
        setCount(prevCount => prevCount + 1);
      }, 10);
    }

    return () => clearInterval(interval);
  }, [cursorLoader]);

  const isUsed = isUPV
    && ((!props.isPageEditor
        && currentPage.linkPages
        && !!currentPage.linkPages.length
        && currentPage.linkPages.find(
          it => it.libraryComponent?.id === item.id,
        ))
      || (currentPage.blocks
        && !!currentPage.blocks.length
        && currentPage.blocks.find(it => it?.nestedItemId === item.id)));

  const isOverlayActive = props.isSelected || item.isSelected || props.isMenuVisible || isUsedActive;

  const title = item.title || (isExtended ? t.defaultSmartPageTitleT : t.unnamedComponentT);

  const placeholder = isExtended ? t.pageTitleT : t.componentTitleT;

  const showPlaylistInfo = useCallback(() => {
    dispatch(
      actionSaveComponentDescriptionData(
        item.id,
        'item.folderId',
        'libComponent',
      ),
    );
  }, [item.id, dispatch]);

  const handleMouseEnter = useCallback((event) => {
    if (!componentDescriptionId || isMetaAffected || !isShown) return;
    setCursorLoader(true);
    throttleSaveValue(() => () => {
      showPlaylistInfo(event);
      setCursorLoader(false);
    });
  }, [
    setCursorLoader,
    throttleSaveValue,
    showPlaylistInfo,
    componentDescriptionId,
    isMetaAffected,
    isShown,
  ]);

  const handleMouseLeave = useCallback(() => {
    if (!componentDescriptionId) return;
    setCursorLoader(false);
    throttleSaveValue(() => () => {});
  }, [
    componentDescriptionId,
    setCursorLoader,
    throttleSaveValue,
  ]);

  const handleRename = useCallback((e) => {
    if (
      !isExtended
      || item.type !== 'sharedPage'
    ) {
      openRenameItem(e);
    }
  }, [
    isExtended,
    openRenameItem,
    item.type,
  ]);

  const handleTitleChange = useCallback((e) => {
    setTitle(e.target.value);
  }, [setTitle]);

  const stopPropagation = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <div
      className={`
      ${styles.fadein_animation}
      ${props.needToDelete && props.isSelected ? 'to_delete' : ''}
      ${props.isBlurred ? styles.page_blurred : ''}
      ${isOverlayActive ? styles.hoverLikeEffect : ''}
      ${!isExtended && props.isSelected ? styles.selected : ''}
      ${isExtended && isMenuActive ? styles.active_page : ''}
      `}
      style={props.isMenuVisible ? { zIndex: 500 } : {}}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isUsed && <UsedBadgeWrapper>{t.usedT}</UsedBadgeWrapper>}

      <DisruptOpacityForUsedItemsWrapper activated={isUsed && !props.isMenuVisible}>
        {!isMetaAffected && (
          <Spinner ref={loaderRef} cursorLoader={cursorLoader}>
            <Circle
              progress={count || 0}
              color="#FFC72C"
              radius={25}
              stroke={5}
            />
          </Spinner>
        )}

        <div
          data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
          className={`
          ${styles.label_socket}
          ${isUPV && isExtended ? styles.label_upv : ''}
          ${bigSizeCondition ? styles.label_socket_page : ''}
          `}
        >
          <TypeLabelComponent selected={props.isSelected}>
            <Type item={item} />
          </TypeLabelComponent>
        </div>

        { isExtended
          && (
            <CardViewPage
              componentDescriptionId={componentDescriptionId}
              bigSizeCondition={bigSizeCondition}
              usedRef={usedRef}
              isUsedActive={isUsedActive}
              setIsUsedActive={setIsUsedActive}
              isUPV={isUPV}
              cursorLoader={cursorLoader}
              item={item}
              isOverlayActive={isOverlayActive}
              {...props}
            />
          ) || (
            <CardViewComponent
              componentDescriptionId={componentDescriptionId}
              bigSizeCondition={bigSizeCondition}
              usedRef={usedRef}
              isUsedActive={isUsedActive}
              setIsUsedActive={setIsUsedActive}
              isUPV={isUPV}
              cursorLoader={cursorLoader}
              itemUploadData={itemUploadData}
              item={item}
              isOverlayActive={isOverlayActive}
              {...props}
            />
        )}
      </DisruptOpacityForUsedItemsWrapper>
      <div>
        {!props.isComponentRename ? (
          <div
            data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
            className={`${styles.text}`}
            onDoubleClick={handleRename}
            onClick={stopPropagation}
          >
            {title}
          </div>
        ) : (
          <input
            ref={props.refItem}
            placeholder={placeholder}
            onKeyPress={props.handleKeyPress}
            onBlur={props.saveTitle}
            value={props.title}
            onClick={stopPropagation}
            onMouseDown={stopPropagation}
            maxLength="55"
            onChange={handleTitleChange}
            className={`${styles.input}`}
          />
        )}
      </div>
    </div>
  );
});

export default CardView;
