import {
  createTableColumn,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
  useTableColumnSizing_unstable,
  useTableFeatures,
} from '@fluentui/react-components';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import '../table.scss';
import styled from 'styled-components/macro';

import { useSelector } from 'react-redux';
import { timeSince } from '../../../../utils/dateConvert';
import BoardColumnLabelLayer from '../../NewPlaylist/CardView/BoardColumnLabelLayer';
import ActionsForTable from '../childComponents/ActionsForTable';
import TableCheckBoxComponent from '../childComponents/CheckBoxComponent';
import SelectedWrapper from '../childComponents/SelectedWrapper';
import useWindowDimensions from '../../../../utils/hooks/useWindowDimensions';
import { generateColumns, generateColumnSizing } from './Columns';
import { Roles } from '../../../../utils/permissions';
import { useOpenPlaylist } from '../helpers/openPlaylistHook';
import ImageWithPreview from '../../../LibraryTableView/ImageWithPreview';
import { getAvailability, getPlaylistType } from '../../../../utils/helpers';
import ProgressBar from '../../../ViewersProgressBars';
import { progressBarType } from '../../../ViewersProgressBars/enum';
import { FluentTableViewRow } from './FluentTableViewRow';
import { BubbleInRow, BubbleUpdateInRow } from '../styled';
import { useLocalization } from '../../../../LocalizationContext';
import { sortOptionChooser } from '../../../../utils/sort/sortTypeChooser';

const TableWrapper = styled.div`
  tbody:before {
    content: "@";
    display: block;
    line-height: 10px;
    text-indent: -99999px;
  }
`;
const TextWrap = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const FluentTableView = ({ ...props }) => {
  const [data, setData] = React.useState([]);
  const handlerOpenPlaylist = useOpenPlaylist();
  const history = useHistory();
  const { t } = useLocalization();
  const shouldDisplayProgressBar = history.location.pathname.endsWith('/shared')
    || history.location.pathname.endsWith('/favorites')
    || history.location.pathname.includes('/channel/');
  const columnsDef = generateColumns(createTableColumn, props.setSort, shouldDisplayProgressBar);
  const [columns] = React.useState(columnsDef);
  const {
    unseenPlaylistMapInChannel = {},
    unseenPlaylistManagerMapInAll = {},
  } = useSelector((state) => state.support);
  const isChannel = history.location.pathname.startsWith('/channel');

  const [columnSizingOptions, setHer] = React.useState(generateColumnSizing(shouldDisplayProgressBar));
  const { width } = useWindowDimensions();
  const loaderRef = useRef(null);
  const { getRows, columnSizing_unstable, tableRef } = useTableFeatures(
    {
      columns,
      items: data,
    },
    [useTableColumnSizing_unstable({ columnSizingOptions })],
  );

  const rows = getRows();

  const { place = 'smartfiles', selector = 'all' } = useParams();
  const sortOptions = useSelector((state) => state.settings.sortOptions);
  const sortName = sortOptionChooser(place, selector);

  const openPlaylist = (itemId, isShared, event) => {
    handlerOpenPlaylist({ playlistId: itemId, isShared, event });
  };

  useEffect(() => {
    setHer(generateColumnSizing(shouldDisplayProgressBar));
  }, [width]);

  useEffect(() => {
    if (props.data) {
      const keys = Object.keys(props.data);
      const dataArr = [];
      keys.forEach((key, index) => {
        const item = props.data[key];
        if (item.moveToTrash) return;
        const coverImageSrc = item.img;
        const isUnseen = isChannel
          ? unseenPlaylistMapInChannel[item.playlistManagerId]
          : item.wrapperId && unseenPlaylistManagerMapInAll[item.wrapperId];

        const hasUpdate = item.hasUpdate;
        const calcSize = `${(item.totalSizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
        let userRole = '';
        if (item.isOwnContent) {
          userRole = Roles.Admin;
        } else if (!item.isCoEdit) {
          userRole = Roles.Viewer;
        } else {
          userRole = Roles['Co-Admin'];
        }

        const name = item.owner?.first_name
          ? `${item.owner.first_name} ${item.owner.last_name}` : item.owner?.username;
        const objToBeInserted = {
          id: item.id,
          isShared: item.status === 'shared',
          checkbox: {
            label: (
              <TableCheckBoxComponent
                isSimplifiedMultiselect={!props.isSimplifiedMultiselect}
                setSimplifiedMultiselect={props.setSimplifiedMultiselect}
                itemID={item.id}
              />
            ),
          },
          image: {
            label: (
              <ImageWithPreview item={item} src={coverImageSrc} isPlaylists />
            ),
          },

          name: {
            label: (
              <SelectedWrapper
                type="playlist"
                refO={loaderRef}
                itemID={item.id}
              >
                <TextWrap>  {item.title || t.untitledSmartFileT}</TextWrap>
                {!hasUpdate && isUnseen && <BubbleInRow>{t.newUpT}</BubbleInRow>}
                {hasUpdate && <BubbleUpdateInRow>{t.updateUpT}</BubbleUpdateInRow>}
              </SelectedWrapper>
            ),
          },
          sender: { label: name },
          board: { label: <BoardColumnLabelLayer item={item} tableView /> },
          availability: {
            label: getAvailability(
              getPlaylistType({
                path: history.location.pathname,
                forMeta: true,
              }),
              item,
            ),
          },
          created: { label: timeSince(item.createDate * 1000) },
          modified: { label: timeSince(item.lastModifiedDate * 1000) },
          size: { label: calcSize },
          progress: { label: item.viewersProgress
            ? (
              <ProgressBar
                type={progressBarType.list}
                viewersProgress={item.viewersProgress}
                isFinished={item.isFinished}
              />
            )
            : <></> },
          actions1: {
            label: (
              <ActionsForTable
                isFavorite={item.isFavorite}
                item={item}
                index={index}
                userRole={userRole}
              />
            ),
          },
        };
        dataArr.push(objToBeInserted);
      });
      setData(dataArr);
    }
  }, [props.data]);
  if (!data.length) {
    return <></>;
  }

  return (
    <TableWrapper
      className={`
        table_in_row_view
        ${sortOptions[sortName] === 'recent' ? 'is_recent' : ''}
        ${props.noHeader ? 'disable_headers' : ''}
        ${!props.noHeader && props.isRecent ? 'make_before_great_again' : ''}
     `}
    >
      <Table sortable aria-label="Table with sort" ref={tableRef}>
        <TableHeader>
          <TableRow>
            {columns.map((column) => (
              <TableHeaderCell
                key={column.columnId}
                {...columnSizing_unstable.getTableHeaderCellProps(
                  column.columnId,
                )}
              >
                {column.renderHeaderCell()}
              </TableHeaderCell>
            ))}
          </TableRow>
        </TableHeader>
        {/* )} */}
        <TableBody>
          {rows.map(({ item }, index) => (
            <FluentTableViewRow
              key={item.availability.label + item.id}
              item={item}
              index={index}
              columnSizing_unstable={columnSizing_unstable}
            />
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};
