import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { v4 as uuidv4 } from 'uuid';
import { actionCloseModal } from '../../redux/user/action';
import { DEFAULT_POSITION_STEP } from '../../utils/constants';
import Button2023 from '../Buttons/Button2023';
import { GlobalFlexRowWrapper } from '../../pages/Maker/MakerCommon/UiComponents/styled';
import SimpleTextInput from '../SimpleComponents/Inputs/SimpleTextInput';
import ColorPalette from '../ColorPicker/ColorPalette';
import RadioGroupTags from '../Tag/2023/RadioGroupTags';
import { createNewName } from '../../utils/helpers';
import { actionCreateTag, actionUpdateTag } from '../../redux/tags/action';
import { useLocalization } from '../../LocalizationContext';

const ModalWrapper = styled.div`
  position: fixed;
  background: rgba(147, 147, 147, 0.6);
  z-index: 104;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-overflow-style: none;
  font-style: normal;
  font-weight: 400;
`;

const GreyTitleWrapper = styled.div`
  position: relative;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #939393;
  letter-spacing: 0.05em;
  margin-bottom: 13px;
  text-transform: uppercase;
  font-family: "RobotoFlex", sans-serif;
  ${({ isSecond }) => (isSecond
    ? `
    margin-top: 4px;
    margin-bottom: 13px;
  `
    : '')}
  ${({ isThird }) => (isThird
    ? `
    margin-top: 15px;
    margin-bottom: -2px;
  `
    : '')}
`;

const SmartModalWrapper = styled.div`
  position: relative;
  width: 342px;
  height: fit-content;
  background: #ffffff;
  border: 0.6px solid #ece2e2;
  box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.25);
  border-radius: 12px;
  padding: 16px;
`;

const ButtonBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 169px;
  padding-top: 6px;
  ${({ isForCreate }) => (isForCreate
    ? `
    padding-left: 160px;
  `
    : `
    padding-left: 169px;
  `)}
`;

const ItemTitleWrapper = styled.div`
  font-size: 14px;
  line-height: 21px;
  ${({ bgColor }) => (bgColor ? `background-color: ${bgColor};` : '')}
  ${({ color }) => (color ? `color: ${color};` : '')}
  border-radius: 16px;
  padding-left: 10px;
  padding-right: 10px;
`;

const Divider = styled.div`
  width: 310px;
  height: 1px;
  margin: 17px auto 3px;
  background: #1d1d1d;
  opacity: 0.08;
  ${({ theSecond }) => (theSecond
    ? `
    margin-top: 15px;
    margin-bottom: 10px;
  `
    : '')}
`;
const TagModal2023 = () => {
  const dispatch = useDispatch();
  const { t } = useLocalization();
  const { dataPayload } = useSelector((state) => state.user);

  const superColorPickerRef = useRef(null);
  const userTags = useSelector((state) => state.user.tags);
  const [selectedColor, setColorKey] = useState(dataPayload?.color || '030303');
  const [selectedColorBG, setColorKeyBG] = useState(
    dataPayload?.backgroundColor || 'FFC72C',
  );
  const [inputName, setInputName] = useState(
    dataPayload?.title || createNewName(userTags, 'Untitled tag'),
  );
  const [firstNameValidationError, setFirstNameValidationError] = useState('');
  const isCreateMode = !dataPayload?.title;
  // false = background for choice / true = text for choice
  const [colorPickerMode, setColorPickerMode] = useState(false);
  const close = () => dispatch(actionCloseModal());

  const changeItemColor = (event, color) => {
    colorPickerMode
      ? setColorKey(color.slice(1))
      : setColorKeyBG(color.slice(1));
  };
  const calcIsSelectedTextColor = (color) => {
    return (
      color.includes(selectedColor)
      || (selectedColor && selectedColor.toUpperCase() === color.toUpperCase())
      || (selectedColor
        && color.toUpperCase().includes(selectedColor.toUpperCase()))
    );
  };
  const calcIsSelectedBGColor = (color) => {
    return (
      (selectedColorBG
        && selectedColorBG.toUpperCase() === color.toUpperCase())
      || (selectedColorBG
        && color.toUpperCase().includes(selectedColorBG.toUpperCase()))
    );
  };

  const fixColor = (color) => {
    if (color.slice(0, 1) === '#') {
      return color;
    }
    return `#${color}`;
  };
  const onSave = () => {
    if (isCreateMode) {
      const id = uuidv4();
      const position = Math.max(
        ...Object.values(userTags || {}).map((tag) => tag.position || 0),
        0,
      ) + DEFAULT_POSITION_STEP;
      const newTag = {
        id,
        title: inputName,
        color: fixColor(selectedColor),
        backgroundColor: fixColor(selectedColorBG),
        position,
        type: 'tags',
        itemsCounter: 0,
        libraryComponentsCounter: 0,
        playlistsCounter: 0,
        lastModifiedDate: Math.floor(Date.now() / 1000),
      };

      dispatch(actionCreateTag(newTag));
    } else {
      const updatedTag = {
        id: dataPayload.id,
        title: inputName,
        color: fixColor(selectedColor),
        backgroundColor: fixColor(selectedColorBG),
      };
      dispatch(actionUpdateTag(updatedTag));
    }
    close();
  };

  return (
    <ModalWrapper>
      <SmartModalWrapper>
        <GreyTitleWrapper>
          {!isCreateMode ? t.editTagT : t.createTagT}
        </GreyTitleWrapper>
        <GlobalFlexRowWrapper>
          <ItemTitleWrapper
            bgColor={fixColor(selectedColorBG)}
            color={fixColor(selectedColor)}
          >
            {inputName}
          </ItemTitleWrapper>
        </GlobalFlexRowWrapper>
        <Divider />
        <SimpleTextInput
          fieldName=""
          autoFocus
          value={inputName}
          setValue={setInputName}
          validateError={firstNameValidationError}
          setValidateError={setFirstNameValidationError}
          isRequired
          forSmartModal
        />
        <GreyTitleWrapper isSecond>{t.selectColorT}</GreyTitleWrapper>
        <RadioGroupTags
          colorPickerMode={colorPickerMode}
          setColorPickerMode={setColorPickerMode}
        />
        <ColorPalette
          isPositionRelative
          selectedColor={fixColor(selectedColor)}
          selectedBackgroundColor={fixColor(selectedColorBG)}
          refColorPickerParent={superColorPickerRef}
          onChangeColor={changeItemColor}
          isPaletteActive
          defaultPosition={{ top: 0, left: 0 }}
          buttonWidth={0}
          calcIsSelected={
            colorPickerMode ? calcIsSelectedTextColor : calcIsSelectedBGColor
          }
          forSmartModal
        />
        <Divider theSecond />

        <ButtonBlockWrapper isForCreate={isCreateMode}>
          <Button2023
            variant="primary"
            width={69}
            height={32}
            text={t.cancelUpT}
            noMargins
            handleButtonClick={close}
          />
          <Button2023
            variant="secondary"
            width={!isCreateMode ? 52 : 67}
            height={32}
            text={!isCreateMode ? t.saveUpT : t.createUpT}
            noMargins
            handleButtonClick={onSave}
          />
        </ButtonBlockWrapper>
      </SmartModalWrapper>
    </ModalWrapper>
  );
};

export default TagModal2023;
