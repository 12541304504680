import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './index.module.scss';
import { TagsParams, empty } from '../../utils/constants';
import HashtagsInput from '../../pages/Playlist/HashtagsInput';
import { actionCreator } from '../../shared/redux/actionHelper';
import SupportAction from '../../redux/support/types';
import { CurrentPage } from '../../redux/currentPage/types';
import Button2023 from '../Buttons/Button2023';
import { actionCloseModal } from '../../redux/user/action';
import { ServiceUser } from '../../redux/user/types';
import { Tags } from '../../redux/tags/types';
import { useLocalization } from '../../LocalizationContext';

const cx = classNames.bind(styles);

export default () => {
  const dispatch = useDispatch();
  const { t } = useLocalization();
  const { dataPayload } = useSelector((redux) => redux.user);
  const currentPage = useSelector((redux) => redux.currentPage);
  const isCurrentPage = !!currentPage.id && currentPage.id === dataPayload?.id;
  const selectedPage = useSelector((redux) => redux.selectedPage) || empty;
  const tagsToUpdate = useSelector((redux) => redux.support.tagsToUpdate) || empty;
  const [newTag, setNewTag] = useState({});
  const isSelected = Object.keys(selectedPage).length > 0;
  const history = useHistory();

  useEffect(() => {
    if (dataPayload) {
      dispatch(
        actionCreator(SupportAction.LoadPageSettings, { ...dataPayload }),
      );
    }
    return () => {
      if (!isCurrentPage) dispatch(actionCreator(CurrentPage.ClearCurrentPage));
    };
  }, []);

  const close = () => {
    dispatch(actionCreator(SupportAction.updateTag.Clear, { selectedPage }));
    dispatch(actionCloseModal(ServiceUser.CloseModal));
  };
  const save = () => {
    if (isSelected) {
      dispatch(
        actionCreator(Tags.AddContentTagToSeveralItems, {
          tagIds: Object.values(tagsToUpdate.add),
          selectedPage,
          history,
        }),
      );
    } else {
      dispatch(
        actionCreator(SupportAction.updateTag.Save, { id: currentPage.id, history }),
      );
    }
    dispatch(actionCloseModal(ServiceUser.CloseModal));
  };
  return (
    <div
      data-cy="addTagModal"
      onClick={close}
      className={cx('modal_wrapper', 'addTagModal')}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={cx('modal', 'modal_tag')}
      >
        <HashtagsInput
          isPage
          noDescription
          isEditable
          isTemplate
          isModalOnCurrentPage={isCurrentPage}
          isLibraryComponentSettings={isCurrentPage}
          place="Page"
          type="LibraryComponent"
          state={TagsParams.tags}
          modalComponentId={dataPayload?.id}
          setNewTag={setNewTag}
          selectedPage={selectedPage}
          isSelected={isSelected}
        />

        <div className={cx('group_buttons', 'addTagModal')}>
          {/* <div onClick={close} className={cx('group_buttons_cancel')}> */}
          {/*  Cancel */}
          {/* </div> */}

          <Button2023
            variant="primary"
            width={69}
            height={32}
            text={t.closeUpT}
            // noMargins
            handleButtonClick={close}
          />
          <Button2023
            variant="secondary"
            width={72}
            height={32}
            text={t.saveUpT}
            noMargins
            handleButtonClick={save}
            isDisabled={Object.keys(tagsToUpdate.add).length > 10}
          />
        </div>
      </div>
    </div>
  );
};
